import React from "react";
import {
  Button,
  Col,
  Row,
  DatePicker,
  Select,
  Progress,
  Form,
  Input,
  Spin
} from "antd";
import { connect } from "react-redux";
import _ from "lodash";
/* import NumberFormat from "react-number-format"; */
import moment from "moment-timezone";
import ReactExport from "react-export-excel";
import Widget from "components/Widget/index";
/* import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js"; */
import { AllHtmlEntities } from "html-entities";
import firebase from "firebase/app";
import Api from "../../util/api";

const { Option } = Select;
const entities = new AllHtmlEntities();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;
const db = firebase.firestore();
const FormItem = Form.Item;
let query;

let dataList = [];

class SumaryReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      timeZone: "America/Santiago",
      title: "summary report ",
      filename: `summary report`,
      mensaje: "",
      dataCashin: {},
      dataCashout: {},
      dataPayku: {},
      commerces: [],
      merchant: "",
      /*    totalWebPay: 0,
         totalCahin: 0,
         totalCashout: 0,
         totalPayku: 0, */
      isopen: false,
      time: null,
      download: true,
      dataCashinlength: 0,
      dataCashoutlength: 0,
      dataPaykulength: 0,
      urlFileDownload:""
          /*   commerces: {}, */
    };
  }

  componentDidMount = async () => {
    moment.tz.setDefault(this.state.timeZone);
    let commerces = [];
    //SE TRAE LOS ID DE MERCHANT
    var ret = await db
      .collection("commerces")
      .get()
      .catch(console.log);
    if(ret && ret.docs) {
      ret.docs.map(e => {
        commerces.push({ id: e.id, name: e.data().name });
      });
    }
  /*   this.setState({ commerces })
    console.log("commerces", commerces); */

    //jc:esto es temporal , se le asignan a horacio ciertos merchant, ver como ajustar en el futuro
    /* Dotworkers
E-management
PayRetailers */
    if (this.props.authUser === "hsalasnovikov@stratechcorp.com") {
      let comm = commerces.filter(
        e =>
          e.id === "2020dw-6d9w" ||
          e.id === "2020e-Management2u5i" ||
          e.id === "2020Payretailers7g21"||
          e.id === "2021abudantia-2m5i"||
          e.id === "2020techsolutions22gf" ||
          e.id === "2021juegaloCom-9n3u" ||
          e.id === "2019CL1xbet-8k3y" ||
          e.id === "2022Betboom-9d2k" ||
          e.id === "2022Zavbin-6k9f"
      );  

      this.setState({
        commerces: comm
      });
    } else {
      this.setState({
        commerces: commerces
      });
    }
  };

  getData= async (firstDay, lastDay, merchant,country) => {


//est excel desde node 
/* console.log("getExcelNode llamado")
await Api.utils
.getExcelNode(parametros)
.then(datos => {
console.log("getExcelNode",datos)
}).catch(error=>console.log("error",error))
 */


    let totales = [];
    let commissionesBd = this.props.comissionData
      ? this.props.comissionData
      : JSON.parse(localStorage.getItem("comisiones"));
   /*  console.log("commissionesBd", commissionesBd); */
    commissionesBd = commissionesBd.filter(item => item.merchant === merchant);

    
  /*   console.log("commissionesBd", commissionesBd); */
    commissionesBd = commissionesBd[0].commission.filter(
      item => item.countrie === country
    );

    let parametros = {
      merchant: merchant,
      firstDay: firstDay,
      lastDay: lastDay,
      comission: commissionesBd[0],
      country:country
    };

    let nameCommece ="" ;
    this.state.commerces.map(d => {
      if(d.id===merchant)
      {
        nameCommece =d.name
      }
    })

    console.log("nameCommece",nameCommece,this.state.commerces);
    console.log("parametros",parametros,nameCommece);
    try {
      await Api.utils
        .getMultiQueryDate(parametros)
        .then(datos => {
          console.log("getMultiQueryDate", datos);

          this.setState({
            filename:datos.fileName,
               dataCashinlength: datos.cashin,
               dataCashoutlength: datos.cashout,
               dataPaykulength:datos.payku,
              /*  merchant:nameCommece,  */
            urlFileDownload: datos.urlFileDownload,
            download:false
          });
        })
        .catch(error => { console.log("error getMultiQueryDate", error); })
    } catch (error) {
      console.log("este es el error " + error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
    
  }


  onTimeZoneChange = zone => {
    console.log("zone", "=>", zone);
    this.setState({
      timeZone: zone
    });
  };

  handleChange = value => {
    console.log(`selected ${value}`);
  };

  OnGenerateReport = value => {
    console.log(`selected ${value}`);
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          dataCashin: 0,
          dataCashout:0,
          dataPayku: 0,
          dataCashinlength: null,
          dataCashoutlength: null,
          dataPaykulength: null,
          download: true
        });

        console.log("values===>", values);
        let firstDay = moment(values.date)
          .tz(this.state.timeZone)
          .toDate();
        let lastDay = moment(values.date)
          .tz(this.state.timeZone)
          .endOf("month")
          .toDate();
        let mes = moment(values.date)
          .tz(this.state.timeZone)
          .format("MMMM");
        let year = moment(values.date)
          .tz(this.state.timeZone)
          .format("YYYY");

        this.setState({
          mes: mes,
          year
        });

       /*  this.gettingData(firstDay, lastDay, values.id); */
        this.getData(firstDay, lastDay, values.id,values.country);
      }
    });
  };

  Onclean = value => {
    this.setState({
      mes: "",
      dataCashin: {},
      dataCashout: {},
      dataPayku: {},
      year: "",
      dataCashinlength: 0,
      dataCashoutlength: 0,
      dataPaykulength: 0,
      merchant: ""
    });
    this.props.form.setFieldsValue({
      ["id"]: "",
      ["date"]: "",
      ["country"]: ""
    });
  };

  dateFormat = data => {
  /*   debugger; */
    if (data.dateRequest != "") {
      return moment(new Date(data.dateRequest._seconds * 1000).toISOString())
        .toDate()
        .toLocaleString("es-CL", {
          timeZone: this.state.timeZone,
          timeZoneName: "short"
        });
    } else {
      return "";
    }
  };
  render() {
    /*   const { isopen, time } = this.state */
    const { getFieldDecorator } = this.props.form;
    const { commerces } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 4
        },
        sm: {
          span: 4
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 20
        }
      }
    };
    return (
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Widget>
            <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
              <i className="icon icon-editor gx-mr-3" />
              summary report{" "}
            </h4>
            <hr></hr>
            <h6 className="gx-mb-3 gx-mb-m-2 gx-font-weight-light">
              Generate Report
            </h6>
            <Row>
              <Col lg={10} md={10} sm={24} xs={24}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <FormItem label="Month           :">
                    <div className="">
                      {this.state.time ? this.state.time : null}
                      {getFieldDecorator("date", {
                        rules: [
                          {
                            required: true
                            //  message: "Please enter the Month!!"
                          }
                        ]
                      })(
                        <Input
                          type="month"
                          placeholder="Date"
                          className="input-text"
                        />
                      )}
                    </div>
                  </FormItem>
                  <FormItem label="Merchant:">
                    <div>
                      {getFieldDecorator("id", {
                        validateTrigger: ["onChange", "onBlur"],
                        initialValue: "",
                        rules: [
                          {
                            required: true,
                            message: "Please enter the Merchant!!"
                          }
                        ]
                      })(
                        <Select onChange={this.handleChange}>
                          <Option value="">Select Merchant</Option>
                          {commerces.map(d => (
                            <Option key={d.id}>{d.name}</Option>
                          ))}
                        </Select>
                      )}
                    </div>
                  </FormItem>


                  <Form.Item name="country" label="Country">
                    {getFieldDecorator("country", {
                      initialValue: "",
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                            required: true,
                          message: "Please enter the Country!"
                        }
                      ]
                    })(
                      <Select onChange={this.onChangeID} >
                        <Option value={""}>Select Country </Option>
                        <Option value={"AR"}><i className={`flag flag-24 gx-mr-2 flag-ar`}></i>Argentina</Option>
                        <Option value={"BR"}><i className={`flag flag-24 gx-mr-2 flag-br`}></i>Brazil</Option>
                        <Option value={"CL"}><i className={`flag flag-24 gx-mr-2 flag-cl`}></i>Chile</Option>
                        <Option value={"CO"}><i className={`flag flag-24 gx-mr-2 flag-co`}></i>Colombia</Option>
                        <Option value={"CR"}><i className={`flag flag-24 gx-mr-2 flag-cr`}></i>Costa Rica</Option>
                        <Option value={"EC"}><i className={`flag flag-24 gx-mr-2 flag-ec`}></i>Ecuador</Option>
                        <Option value={"MX"}> <i className={`flag flag-24 gx-mr-2 flag-mx`}></i>Mexico</Option>
                        <Option value={"PE"}> <i className={`flag flag-24 gx-mr-2 flag-pe`}></i>Peru</Option>
                      </Select>
                    )}
                  </Form.Item>

                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    Generate Report
                  </Button>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    onClick={this.Onclean}
                  >
                    clean
                  </Button>
                </Form>
                <hr></hr>
              </Col>

              <Col lg={14} md={14} sm={24} xs={24}>
                <h4 className="gx-mb-3 gx-mb-m-2 gx-font-weight-light">
                  Month: {this.state.mes}
                </h4>
                <div>
                  <Row>
                    <Col
                      lg={5}
                      md={5}
                      sm={8}
                      xs={8}
                      style={{ textAlign: "center", alignItems: "center" }}
                    >
                      <h5>PayIn(BankTransfer)</h5>
                      <Progress
                        type="circle"
                        percent={this.state.dataCashinlength > 0 ? 100 : 0}
                        width={60}
                        style={{ display: "list-item", marginRight: "-3px" }}
                      ></Progress>
                      {/*     <div >{this.state.dataCashinlength == null ? <Spin></Spin> : this.state.dataCashinlength == null? this.state.dataCashinlength}</div> */}
                      <div>
                        {this.state.dataCashinlength == null ? (
                          <Spin></Spin>
                        ) : (
                          this.state.dataCashinlength===-1?0:this.state.dataCashinlength
                        )}
                      </div>
                    </Col>
                  
                    <Col
                      lg={5}
                      md={5}
                      sm={8}
                      xs={8}
                      style={{ textAlign: "center" }}
                    >
                      <h5>PayOut</h5>
                      <Progress
                        type="circle"
                        percent={this.state.dataCashoutlength > 0 ? 100 : 0}
                        width={60}
                        style={{ display: "list-item", marginRight: "-3px" }}
                      />
                      {/*      {this.state.dataCashoutlength} */}
                      <div>
                        {this.state.dataCashoutlength === null ? (
                          <Spin></Spin>
                        ) : (
                          this.state.dataCashoutlength===-1?0:this.state.dataCashoutlength
                        )}
                      </div>
                    </Col>
                    <Col
                      lg={5}
                      md={5}
                      sm={8}
                      xs={8}
                      style={{ textAlign: "center" }}
                    >
                      <h5>BankCard</h5>
                      <Progress
                        type="circle"
                        percent={this.state.dataPaykulength > 0 ? 100 : 0}
                        width={60}
                        style={{ display: "list-item", marginRight: "-3px" }}
                      />

                      <div>
                        {this.state.dataPaykulength === null ? (
                          <Spin></Spin>
                        ) : (
                          this.state.dataPaykulength===-1?0:this.state.dataPaykulength
                        )}
                      </div>
                    </Col>

                 
                  </Row>
                  <hr></hr>
                </div>

                <div>
                  <tr></tr>
                {/*   <a href={this.state.urlFileDownload} style={this.state.urlFileDownload!==""?{background:"red"}:{background:"white"}} class="btn btn-success">¿Soy un botón o un enlace?</a>
                  */}
                  <Button
                          className="ant-btn-primary"
                          // disabled={this.state.dataCashinlength!=null?this.state.dataCashoutlength!=null?this.state.dataPaykulength!=null?false:true:true:true}
                          disabled={this.state.download}
                          href={this.state.urlFileDownload}
                        >


                          {" "}
                          Download<i className="icon icon-long-arrow-down"></i>
                        </Button>
                 
                 
                  <ExcelFile
                    filename={`${this.state.filename}_${this.state.merchant}_${this.state.mes} ${this.state.year}`}
                    element={
                      <div
                        style={{ textAlign: "center", alignItems: "center" }}
                      >
                      {/*   <Button
                          className="ant-btn-primary"
                          // disabled={this.state.dataCashinlength!=null?this.state.dataCashoutlength!=null?this.state.dataPaykulength!=null?false:true:true:true}
                          disabled={this.state.download}
                          href={this.state.urlFileDownload}
                        >


                          {" "}
                          Download<i className="icon icon-long-arrow-down"></i>
                        </Button> */}
                     
                      </div>
                    }
                  >
                    <ExcelSheet data={this.state.dataCashin} name="CASHIN-BT">
                      <ExcelColumn
                        label="Date"
                      /*   value={col => this.dateFormat(col)} */
                        value="dateRequestForm"
                      />

                      <ExcelColumn
                        label="Name"
                    /*     value={col => entities.decode(col.name)} */
                      />
                      <ExcelColumn label="Rut" value="rut" />
                      <ExcelColumn
                    /*   numFmt ="0.00%" */
                        label="Amount"
                       value="quantityForm"
                      />
                      <ExcelColumn label="ID Zippy" value="zippyId" />
                      <ExcelColumn
                        label="Operation Code"
                        value="operationCode"
                      />
                      <ExcelColumn
                        label={`ID Commerce`}
                      /*   value={col => col.commerceReqId} */
                        value="commerceReqId"
                      />
                      <ExcelColumn label="Tot Commision" value="totCommision" />
                      <ExcelColumn label="Total" value="total" />
                    </ExcelSheet>          
                    <ExcelSheet data={this.state.dataPayku} name="WEBPAY">
                      <ExcelColumn
                        label="Date"
                      /*   value={col => this.dateFormat(col)} */
                        value="dateRequestForm"
                      />
                      <ExcelColumn
                        label="Name"
                     /*    value={col => entities.decode(col.name)}*/
                      />
                      <ExcelColumn label="Rut" value="rut" />
                      <ExcelColumn
                        label="Amount"
                      value="quantityForm"
                      />
                      <ExcelColumn label="ID Zippy" value="zippyId" />
                      <ExcelColumn
                        label="Operation Code"
                        value="operationCode"
                      />
                      <ExcelColumn
                        label={`ID Commerce`}
                       // value={col => col.commerceReqId}

                        value="commerceReqId"
                      />
                      <ExcelColumn label="Tot Commision" value="totCommision" />
                      <ExcelColumn label="Total" value="total" />
                    </ExcelSheet>                
                    <ExcelSheet data={this.state.dataCashout} name="CASHOUT-BT">
                      <ExcelColumn
                        label="Date"
                    /*     value={col => this.dateFormat(col)} */
                        value="dateRequestForm"
                      />

                      <ExcelColumn
                        label="Name"
                       /*  value={col => entities.decode(col.name)} */
                       value="name"
                      />
                      <ExcelColumn label="Rut" value="rut" />
                      <ExcelColumn
                      
                        label="Amount"
                      /*   value={col => Number(col.quantity)} */
                      value="quantityForm"
                      />
                      <ExcelColumn label="ID Zippy" value="zippyId" />
                      <ExcelColumn
                        label={`ID Commerce`}
                       // value={col => col.commerceReqId}
                        value="commerceReqId"
                      />
                      <ExcelColumn label="Tot Commision" value="totCommision" />
                      <ExcelColumn label="Total" value="total" />
                    </ExcelSheet>
                    <ExcelSheet data={this.state.totales} name="RESUMEN">
                      <ExcelColumn label="" value="" />
                      <ExcelColumn label="" value="" />
                      <ExcelColumn label="" value="" />
                      <ExcelColumn label="" value="id" />
                      <ExcelColumn
                        label="CLP"
                       /*  value={col => Number(col.total)} */

                        value="total"
                      />
                    </ExcelSheet>
                  </ExcelFile>
                </div>
                {this.state.mensaje}
              </Col>
            </Row>
          </Widget>
        </Col>
      </Row>
    );
  }
}
const SumaryReportForm = Form.create()(SumaryReport);
const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, comissionData, country, dataUser } = settings;
  return { authUser, merchant, comissionData, country };
};
export default connect(mapStateToProps)(SumaryReportForm);
