import React from "react";
import { Select } from "antd";
const { Option } = Select;

const TimeZoneSelect = ({ onTimeZoneChange, value }) => {
  return (
    <div>
      <span style={{ margin: 0, textAlign: "right" }}>Time zone: </span>
      <Select
        defaultValue="America/Santiago"
        style={{}}
        value={value}
        onChange={onTimeZoneChange}
      >
        <Option value="America/Santiago">
          <div style={{ display: "flex", alignItems: "center" }}>
            <i className={`flag flag-24 gx-mr-2 flag-cl`}></i>
            <span>America/Santiago</span>
          </div>
        </Option>
        <Option value="Europe/Moscow">
          <div style={{ display: "flex", alignItems: "center" }}>
            <i className={`flag flag-24 gx-mr-2 flag-ru`}></i>
            <span>Europe/Moscow</span>
          </div>
        </Option>
      </Select>
    </div>
  );
};

export default TimeZoneSelect;
