import React from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Input,
  DatePicker,
  Button,
  Tooltip
} from "antd";
import moment from "moment";
import _ from "lodash";
import { firebase } from "../../firebase/firebase";
import API from "../../util/api";
import GrowthCardCartola from "components/dashboard/CRM/GrowthCardCartola";
import NumberFormat from "react-number-format";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import Auxiliary from "util/Auxiliary";
const db = firebase.firestore();
const pagination = { position: "bottom" }; //bottom top both none
const { RangePicker } = DatePicker;

class cartolaBancoEstado extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeZone: "America/Santiago",
      bordered: false,
      loading: false,
      pagination,
      size: "middle", // default middle small
      title: undefined,
      scroll: undefined,
      searchFilter: "",
      cartola: [],
      cartolaInfo: [],
      cartolaList: [],
      month: "Enero",
      sortedInfo: {
        order: "descend",
        columnKey: "datetime"
      },
      timeZone: "America/Santiago"
    };
  }
  componentDidMount = async () => {
    try {
      this.obtenerDetalleCartola();
    } catch (error) {
      console.log("error en la cartola", error);
    }
  };

  obtenerDetalleCartola = async () => {
    this.setState({
      loading: true
    });
    await API.utils.getCartola().then(cons => {
      if (cons.length > 0) {
        this.setState({ cartolaList: cons, cartola: cons });
      }
      /*   console.log("data en la cartolaaaaaaaaaa", cons); */
    });
    await API.utils.getDetalleCartola().then(cons => {
      this.setState({ cartolaInfo: cons.infoImportant[0] });
      console.log(cons)
      if (cons.searchPending.length > 0) {
        this.setState({ searchPending: cons.searchPending });
      }
      if (cons.searchFailed.length > 0) {
        this.setState({ searchFailed: cons.searchFailed });
      }
      if (cons.lost.length > 0) {
        this.setState({ lost: cons.lost });
        console.log("cons.lost", cons.lost);
      }

      /*   if(cons.cartola.length>0)
      { this.setState({ searchPending: cons.cartola });
      }
 */
      /*   let cartolaFina = [];
      this.state.searchPending.map(req=>{
        cartolaFina.push(req.bank);
      }
      ) */

      console.log("detalle de la cartola Obtenido", cons);
    });

    this.setState({
      loading: false
    });
  };

  componentWillReceiveProps(nextProps) {}

  onChangeRange = (dates, dateStrings) => {
    let firstDay = moment(dateStrings[0], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .toDate();
    let lastDay = moment(dateStrings[1], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .endOf("day")
      .toDate();
    console.log(firstDay);
    console.log(lastDay);

    /* let filterCartola = this.state.cartola.filter(
      reg =>
        moment(reg.datetime, "DD/MM/YYYY").tz(this.state.timeZone).toDate() > lastDay
          && moment(reg.datetime, "DD/MM/YYYY").tz(this.state.timeZone).toDate()  < firstDay
    );
 */

    let filterCartola = this.state.cartola.filter(
      reg =>
      moment(reg.datetime).format("DD/MM/YYYY") > lastDay &&
      moment(reg.datetime).format("DD/MM/YYYY")  < firstDay
    );


   /*  moment(reg.datetime).format("DD/MM/YYYY") */
    console.log(filterCartola, "filterCartola");

    /* this.props.updateQuery(firstDay, lastDay); */
  };

  onChangeRange = async (dates, dateStrings) => {
    //before antes de
    //after despues de
    let firstDay = moment(dateStrings[0], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .toDate();
    let lastDay = moment(dateStrings[1], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .endOf("day")
      .toDate();

    let cartolaFina = [];

    let payload = {
      date: moment(firstDay).format('DD/MM/YYYY'),
      date2: moment(lastDay).format('DD/MM/YYYY')
    }
    console.log(payload)

    let res = await API.utils.getCartolaWithParams(payload)

    if(res.length > 0){
      this.setState({
        cartola: res
      });
    } else {
      this.setState({
        cartola: []
      });
    }
    
  };

  filter = searchValue => {
    if (searchValue === "") {
      this.setState({
        cartola: this.state.cartolaList
      });
    } else {
      try {
        const filter = _.filter(
          this.state.cartolaList,
          cartola =>
            (cartola.datetime
              ? cartola.datetime
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cartola.account
              ? cartola.account
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cartola.rut
              ? cartola.rut.toLowerCase().indexOf(searchValue.toLowerCase()) >
                -1
              : "") ||
            (cartola.bankOperationCode
              ? cartola.bankOperationCode
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cartola.bank
              ? cartola.bank.toLowerCase().indexOf(searchValue.toLowerCase()) >
                -1
              : "") ||
            (cartola.operationCode
              ? cartola.operationCode
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cartola.amount
              ? cartola.amount.toString().indexOf(searchValue) > -1
              : "")
        );
        this.setState({
          cartola: filter
        });
      } catch {
        console.log("Error en  el filtro ");
      }
    }
  };

  updateSearchFilter(evt) {
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }

  onTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };


  render() {
    this.columns = [
      /* {
        title: "operationCode",
        dataIndex: "operationCode",
        key: "operationCode",
        width: 150
      }, */

      {
        title: "Fecha",
        dataIndex: "datetime",
        key: "datetime",
        width: 100,
       // render: datetime => moment(datetime).format("DD/MM/YYYY HH:mm:ss"),
        //render: datetime =>  moment(datetime, "DD/MM/YYYY HH:mm:ss").tz("America/Santiago"),
      sorter: (a, b) => a.datetime - b.datetime,
      sortOrder:
          this.state.sortedInfo.columnKey === "datetime" &&
          this.state.sortedInfo.order
    /*     render: datetime => (
          <span>{moment(datetime).toDate().toLocaleString("es-CL", {
            timeZone: "America/Santiago",
            timeZoneName: "short"
          })}</span>
        ), */
        /* sorter: (a, b) => a.datetime - b.datetime,
        sortOrder:
          this.state.sortedInfo.columnKey === "datetime" &&
          this.state.sortedInfo.order  */
      },
      {
        title: "bankOperationCode",
        dataIndex: "bankOperationCode",
        key: "bankOperationCode",
        width: 100
      },
      {
        title: "rut",
        dataIndex: "rut",
        key: "rut",
        width: 100,
        render: rut => formatRut(rut)
      },
      {
        title: "account",
        dataIndex: "account",
        key: "account",
        width: 100
      },

      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        width: 100,
        render: text => (
          <div style={{ float: "right" }}>
            <NumberFormat
              value={text}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
            />
          </div>
        )
      },
      {
        title: "bank",
        dataIndex: "bank",
        key: "bank",
        width: 100 /* ,
        sorter: (a, b) => a.type - b.type,
        sortOrder:
          this.state.sortedInfo.columnKey === "tipo" &&
          this.state.sortedInfo.order */
      }
    ];

    const { transactionsPending } = this.state.cartolaInfo;
    /*   console.log(this.state.cartolaInfo); */
    /*    console.log(transactionsPending); */
    return (
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Button
            onClick={() => {
              this.obtenerDetalleCartola();
            }}
          >
            Update
          </Button>
        </Col>
        <Col xl={5} lg={12} md={12} sm={12} xs={24}>
          <GrowthCardCartola
            loading={this.state.loading}
            trafficData={[]}
            title="Cartola"
            help="daily cartola"
            month={this.state.month}
            quantityTransaction={this.state.cartolaInfo.registrosEnCartola}
            amountTransaction={this.state.cartolaInfo.montoEnCartola}
            balanced={this.state.cartolaInfo.montoLost > 0 ? false : true}
            typeWidget={"Cua"}
            prefix={"$"}
          />
        </Col>
        <Col xl={19} lg={24} md={24} sm={24} xs={24} className="gx-pr-md-2">
          <Row>
            <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-pr-md-2">
              <GrowthCardCartola
                 help="these are the cashin Ok records found within the Cartola "
                loading={this.state.loading}
                trafficData={[]}
                title="Cashin Ok"
                month={this.state.month}
                quantityTransaction={this.state.cartolaInfo.cantidadOk}
                amountTransaction={this.state.cartolaInfo.montoOk}
                balanced={false}
                typeWidget={"Ok"}
                lostAmount={0}
                prefix={"$"}
                lostAmount={this.state.cartolaInfo.montoLost}
              />
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-pr-md-2">
              <GrowthCardCartola
               help="these are the cashin Pending records found within the Cartola "
                loading={this.state.loading}
                trafficData={[]}
                title="Cashin Pending"
                month={this.state.month}
                quantityTransaction={this.state.cartolaInfo.cantidadPending}
                amountTransaction={this.state.cartolaInfo.montoPending}
                balanced={true}
                TransactionPending={[]}
                typeWidget={"Pending"}
                lostAmount={0}
                dataPending={this.state.searchPending}
                prefix={"$"}
              />
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-pr-md-2">
              <GrowthCardCartola
                 help="these are the cashin Failed records found within the Cartola"
                loading={this.state.loading}
                trafficData={[]}
                title="Cashin Failed"
                month={this.state.month}
                quantityTransaction={this.state.cartolaInfo.cantidadFailed}
                amountTransaction={this.state.cartolaInfo.montoFailed}
                dataFailed={this.state.searchFailed}
                balanced={true}
                typeWidget={"Failed"}
                lostAmount={0}
                prefix={"$"}
              />
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-pr-md-2">
              <GrowthCardCartola
               help="These are the transactions of which we do not have a record in the database, but they are in the map. Example: the user deposited but did not fill in the zippy form. "
                loading={this.state.loading}
                trafficData={[]}
                title="Lost"
                month={this.state.month}
                quantityTransaction={this.state.cartolaInfo.cantidadlost}
                amountTransaction={this.state.cartolaInfo.montolost}
                dataLost={this.state.lost}
                balanced={true}
                typeWidget={"Lost"}
                lostAmount={0}
                prefix={"$"}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Card
            title={"CARTOLA"}
            extra={
              <Auxiliary>
                TimeZone: {this.state.timeZone}{" "}
                {/*  <i
              className={`flag flag-24 gx-mr-2 flag-ru`}
              onClick={this.onTimeZoneChange.bind(this, "Europe/Moscow")}
            ></i> */}
                {/*   <i
              className={`flag flag-24 gx-mr-2 flag-cl`}
              onClick={this.onTimeZoneChange.bind(this, "America/Santiago")}
            ></i> */}
              </Auxiliary>
            }
          >
            <div className="components-table-demo-control-bar">
              <Row>
                <Col
                  xl={15}
                  lg={15}
                  md={15}
                  sm={24}
                  xs={24}
                  className="gx-pr-md-2"
                >
            {<RangePicker
                    className="gx-mb-3 gx-w-50"
                    size="small"
                    format="DD/MM/YYYY"
                    ranges={{
                      All: [
                        moment("01-01-2019", "MM-DD-YYYY").tz(
                          this.state.timeZone
                        ),
                        moment().tz(this.state.timeZone)
                      ],
                      Today: [
                        moment().tz(this.state.timeZone),
                        moment().tz(this.state.timeZone)
                      ],
                      Yesterday: [
                        moment()
                          .tz(this.state.timeZone)
                          .subtract(1, "days"),
                        moment()
                          .tz(this.state.timeZone)
                          .subtract(1, "days")
                      ],
                      "This week": [
                        moment()
                          .tz(this.state.timeZone)
                          .startOf("isoweek"),
                        moment()
                          .tz(this.state.timeZone)
                          .endOf("isoweek")
                      ],
                      "Last 7 Days": [
                        moment()
                          .tz(this.state.timeZone)
                          .subtract(6, "days"),
                        moment().tz(this.state.timeZone)
                      ],
                      "Last Month": [
                        moment()
                          .tz(this.state.timeZone)
                          .startOf("month")
                          .subtract(1, "months"),
                        moment()
                          .tz(this.state.timeZone)
                          .endOf("month")
                          .subtract(1, "months")
                      ],
                      "This Month": [
                        moment()
                          .tz(this.state.timeZone)
                          .startOf("month"),
                        moment()
                          .tz(this.state.timeZone)
                          .endOf("month")
                      ]
                    }}
                    onChange={this.onChangeRange}
                    defaultValue={[
                      //  moment().tz(this.state.timeZone).startOf("month"),
                      //  moment().tz(this.state.timeZone).endOf("month")
                      moment().tz(this.state.timeZone),//.subtract(1, "days"),
                      moment().tz(this.state.timeZone)
                    ]}
                  />}
                </Col>
                <Col
                  xl={9}
                  lg={9}
                  md={9}
                  sm={24}
                  xs={24}
                  className="gx-pr-md-2"
                >
                  <Tooltip
                    title="
                  search without dots or dashes"
                  >
                    {" "}
                    <Input
                      size="small"
                      placeholder="Search..."
                      onChange={this.updateSearchFilter.bind(this)}
                    ></Input>
                  </Tooltip>
                </Col>
              </Row>
            </div>
            <Table
              className="gx-table-responsive"
              columns={this.props.columns || this.columns}
              dataSource={this.state.cartola}
              rowKey="valor"
              onChange={this.onTableChange}
              pagination={{
                defaultPageSize: 5,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30", "50", "100"]
              }}
            />
          </Card>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={24}
          className="gx-pr-md-2"
        ></Col>
      </Row>
    );
  }
}

export default cartolaBancoEstado;
