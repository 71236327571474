import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert, Button, Col, Divider, notification, Row, Spin, Tabs } from "antd";
import { firebase } from "../../../firebase/firebase";
import Alto from "./cashoutPendientesAlto";
import Bajo from "./cashoutPendientesBajo";
import All from "./cashoutPendientesAll";
import Api from "../../../util/api";
import { AllHtmlEntities } from "html-entities";
import ModalGenratePayroll from "./modal-generate-payroll";
import api from "../../../util/api";
import { isActiveNewCashout, isActiveOldCashout } from "../../../util/check-availability-new-cashout";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const entities = new AllHtmlEntities();
const db = firebase.firestore();
const { TabPane } = Tabs;

export class cashoutPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      averageAmount: 0,
      banks: [],
      typeAccount: [],
      isVisibleModalGeneratePayroll: false,
      loadingParametrosGenerales: true,
      loadingGeneratePayroll: false,
      parametrosGenerales: {
        isActiveNewCashout: false,
        commerces: {}
      },
      dataModalGeneratePayroll: {
        payrollMaxAmount: 0,
        payrollMinAmount: 0,
        payrollTxnsRange: 0
      }
    };

  }
  componentDidMount = async () => {
    await this.getParameter();
    this.getParametrosGenerales()
  };

  componentDidUpdate(prevProps) {
    if (this.props.merchant !== prevProps.merchant) {
      console.log("componentDidUpdate en cashoutPendientes");

    }
    if (this.props.averageAmount !== prevProps.averageAmount) {
      console.log("componentDidUpdate cambio averageAmount");

    }
  }

  getParameter = async e => {
    console.log("getParameter");
    await db
      .collection("parametrosGenerales")
      .doc("cashout")
      .get()
      .then(doc => {
        this.setState({
          averageAmount: doc.data().averageAmount
        });
      })
      .catch(err => {
        console.log("ERROR IN getParameter", err);
      });

    console.log("fin getParameter");


    //API getParamCashout 
    await Api.cashout.getParamCashout().then(resp => {

      this.setState({
        banks: resp.banks,
        typeAccount: resp.typeAccount

      });
      console.log("resp getParamCashout", "=>", resp);
    })
      .catch(error => {
        console.log("error getParamCashout", "=>", error);
      })
  }

  onCancelModalGeneratePayroll = () => {
    this.setState({
      isVisibleModalGeneratePayroll: false
    });
  }

  openModalGeneratePayroll = () => {
    this.setState({
      isVisibleModalGeneratePayroll: true
    });
  }

  onHandleSubmit = (values) => {
    this.setState({
      loadingGeneratePayroll: true
    });
    api.utils.payrollCreation({
      commerceId: this.props.merchant.code,
      type: 'manual',
      ...values
    }).then(res => {
      if(res.success == true) {
        notification.success({
          message: 'Payroll generated',
          description:
            'The payroll has been generated successfully.'
        });
      }else {
        throw 'Error';
      }
    }).catch(error => {
      notification.error({
        message: 'Error in payroll generated',
        description:
          'There was an error in the generation of the payroll'
      });
      console.log(error);
    })
    .finally(() => {
      this.setState({
        loadingGeneratePayroll: false
      });
    })
    this.onCancelModalGeneratePayroll();
  }

  getParametrosGenerales= () => {
    this.setState({
      loadingParametrosGenerales: true
    });
    db.collection("parametrosGenerales").doc('cashout').get()
    .then(parametrosGenerales => {
      const { payrollMaxAmount = 0, payrollMinAmount = 0, payrollTxnsRange = 0, commerces = {}, isActiveNewCashout = false  } = parametrosGenerales.data();
      this.setState({
        parametrosGenerales: {
          isActiveNewCashout,
          commerces
        },
        dataModalGeneratePayroll: {
          payrollMaxAmount,
          payrollMinAmount,
          payrollTxnsRange
        }
      });
    })
    .catch(err => {
      console.log("ERROR IN getParametrosGenerales", err);
    })
    .finally(() => {
      this.setState({
        loadingParametrosGenerales: false
      });
    })
  }

  

  render() {

    return (
      <Row>
        <Col span={24}>
          {isActiveNewCashout(this.state.parametrosGenerales, this.props.merchant.code, this.state.loadingParametrosGenerales) && (
            <>
              <Alert
                message="Warning"
                description="The cashout is disabled and the new cashout (payrolls) is enabled"
                type="warning"
                showIcon
              />
              <hr />
              {(this.props.dataUser.typeUser === "admin" ||
              this.props.dataUser.typeUser === "soporteCashout") && (
                <>
                  <Button 
                    style={{marginBottom: 0}} 
                    type="primary" 
                    onClick={this.openModalGeneratePayroll} 
                    loading={this.loadingGeneratePayroll}
                    icon="plus"
                  >
                    Generate payroll
                  </Button>
                  <ModalGenratePayroll 
                    isVisible={this.state.isVisibleModalGeneratePayroll}
                    onCancel={this.onCancelModalGeneratePayroll} 
                    data={this.state.dataModalGeneratePayroll} 
                    onHandleSubmit={this.onHandleSubmit}
                  />
                  <hr />
                </>
              )}
            </>
          )}
        </Col>
        <Col span={24}>
          {/*   <Tabs defaultActiveKey="1">
          <TabPane tab="All Amounts" key="1"> */}
          <All
            banks={this.state.banks}
            typeAccount={this.state.typeAccount}
            averageAmount={this.state.averageAmount}
            isActiveOldCashout={isActiveOldCashout(this.state.parametrosGenerales, this.props.merchant.code, this.state.loadingParametrosGenerales)}
          />

{/* {JSON.stringify(this.state.commerces[this.props.merchant.code])} */}
          {/*     </TabPane>
             <TabPane tab="High Amounts" key="2" >
            <All   averageAmount ={this.state.averageAmount} operador={">="}/>
            </TabPane>

            <TabPane tab="Low Amounts" key="3" >
            <All  averageAmount ={this.state.averageAmount} operador={"<"} />
            </TabPane>
          </Tabs> */}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { merchant, dataUser } = settings;
  return { merchant, dataUser };
};
/* const cashoutPendingForm = Form.create()(cashoutPending); */
export default connect(mapStateToProps)(cashoutPending);
