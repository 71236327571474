import React, { Component } from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
/* import { firebase } from "../../../firebase/firebase"; */
import Dynamic from "./Dynamic";
import moment from "moment-timezone";
import API from "../../../util/api";

/* const db = firebase.firestore(); */
/* let query; */
let dataList = [];

export class webpayOk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: "Cashin Pending",
      filename: `Cashin Pending ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      defaultValueRangeFilter:[
        moment().tz("America/Santiago").startOf("month"),
      moment().tz("America/Santiago").endOf("month")
      ]

    };
  }

  componentDidMount = () => {
    moment.tz.setDefault(this.state.timeZone);

    let firstDay = moment
      .tz(this.state.timeZone)
      .startOf("month")
      .toDate();
    let lastDay = moment
      .tz(this.state.timeZone)
      .endOf("month")
      .toDate();
    this.getData(firstDay, lastDay);
  };

  componentDidUpdate(prevProps) {
    if (this.props.merchant !== prevProps.merchant) {
     /*  console.log("componentDidUpdate", "=>", "componentDidUpdate"); */

     /*  const merchant = localStorage.getItem("merchant_code"); */
      moment.tz.setDefault(this.state.timeZone);

       let firstDay = moment
        .tz(this.state.timeZone)
        .startOf("month")
        .toDate();
      let lastDay = moment
        .tz(this.state.timeZone)
        .endOf("month")
        .toDate();
     /* query = db
        .collection("transfers")
        .where("commerceId", "==", merchant)
        .where("code", "==", 9)
        .where("dateRequest", ">=", new Date(Number(firstDay)))
        .where("dateRequest", "<=", new Date(Number(lastDay))); */

        this.getData(firstDay, lastDay);
      this.setState({
        filename: `Cashin validated ${localStorage.getItem("merchant_name")}`
      });
    }
  }

  getData = (firstDay, lastDay) => {
  /*   query = db
      .collection("transfers")
      .where("commerceId", "==", this.props.merchant.code)
      .where("code", "==", 9)
      .where("dateRequest", ">=", new Date(Number(firstDay)))
      .where("dateRequest", "<=", new Date(Number(lastDay))); */
    this.updateQuery(firstDay, lastDay);
  };

  updateQuery = async (firstDay,lastDay) => {
    dataList = [];
    let parametros = {
      "merchant": this.props.merchant.code,
      "firstDay": firstDay,
      "lastDay": lastDay
    }
    await API.utils.getTransfersForParamsPending(parametros).then( cons=>{
      console.log(cons)
      this.setState({
        dataList: cons
      })

    });
  };
  update =(e)=>
  {
    this.componentDidUpdate(this.state);
  }
  render() {
    this.expandedRowRender = record => (
      <div>
        <p>
          Email: <b>{record.email}</b>
        </p>
      </div>
    );
    return (
      <Row>
        <Col span={24}>
          <Dynamic
            defaultValue={this.state.defaultValueRangeFilter}
            updateQuery={this.updateQuery}
            update={this.update}
            filename={this.state.filename}
            title={this.state.title}
            dataList={this.state.dataList}
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            merchant={this.props.merchant}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { merchant } = settings;
  return { merchant };
};
export default connect(mapStateToProps)(webpayOk);
