import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard";
import Cashin from "./cashin/cashinOk";
import CashinPending from "./cashin/cashinPendientes";
import CashinFailed from "./cashin/cashinRechazadas";
import CashoutPending from "./cashout/cashoutPendientes";
import CashoutPendingAlto from "./cashout/cashoutPendientesAlto";
import CashoutPendingBajo from "./cashout/cashoutPendientesBajo";

import Webpay from "./webpay/webpayOk";
import WebpayPending from "./webpay/merchant/webpayPending";
import WebpayFailed from "./webpay/merchant/webpayFailed";

/*webpay all*/
import WebpayAll from "./webpay/merchant/webpayOk";

import Flow from "./flow/flowOk";
import FlowPending from "./flow/flowPendientes";
import FlowFailed from "./flow/flowRechazadas";



import Payku from "./payku/paykuOk";
import PaykuPending from "./payku/paykuPendientes";
import PaykuFailed from "./payku/paykuRechazadas";


import BankTransferOk from "./bankTransfer/transferOk";
import BankTransferPending from "./bankTransfer/transferPendientes";
import BankTransferFailed from "./bankTransfer/transferRechazadas";


import CardOk from "./bankCard/cardOk";
import CardPending from "./bankCard/cardPendientes";
import CardFailed from "./bankCard/cardRechazadas";


import CashOk from "./cash/cashOk";
import CashPending from "./cash/cashPendientes";
import CashFailed from "./cash/cashRechazadas";

import PayOutOk from "./payout/payOutOk";
import PayOutPending from "./payout/payOutPendientes";
import PayOutFailed from "./payout/payOutRechazadas";


import SkinsBackOk from "./skinsback/skinsbackOk";
import SkinsBackPending from "./skinsback/skinsbackPendientes";
import SkinsBackFailed from "./skinsback/skinsbackRechazadas";

import KushkiWebpayOk from "./kushki/webpay/kushkiOk";
import KushkiWebpayPendientes from "./kushki/webpay/kushkiPendientes";
import KushkiWebpayRechazadas from "./kushki/webpay/kushkiRechazadas";

import KushkiBanktransferOk from "./kushki/banktransfer/kushkiOk";
import KushkiBanktransferPendientes from "./kushki/banktransfer/kushkiPendientes";
import KushkiBanktransferRechazadas from "./kushki/banktransfer/kushkiRechazadas";


import AirtmOk from "./airtm/airtmOk";
import AirtmPendientes from "./airtm/airtmPendientes";
import AirtmRechazadas from "./airtm/airtmRechazadas";

import AirtmPayOutOk from "./airtmPayOut/airtmPayOutOk";
import AirtmPayOutPendientes from "./airtmPayOut/airtmPayOutPendientes";
import AirtmPayOutRechazadas from "./airtmPayOut/airtmPayOutRechazadas";

import MachOk from "./mach/machOk";
import MachPending from "./mach/machPendientes";
import MachFailed from "./mach/machRechazadas";

import FriOk from "./fri/friOk";
import FriPending from "./fri/friPendientes";
import FriFailed from "./fri/friRechazadas";

import CoinspaidOk from "./coinspaid/coinspaidOk";
import CoinspaidPending from "./coinspaid/coinspaidPendientes";
import CoinspaidFailed from "./coinspaid/coinspaidRechazadas";

import CryptoMaintainer from "./crypto/maintainer";

import Callback from "./admin/callback";
import CallbackWebPay from "./admin/callbackWebPay";
import CallbackFlow from "./admin/callbackFlow";
import CallbackPayku from "./admin/callbackPayku";
import CallbackCashout from "./admin/callbackCashout";
import CallbackPayIn from "./admin/callbackPayIn";
import CallbackPayOut from "./admin/callbackPayOut";
import MultySearch from "./admin/multySearch";
import Changelog from "./admin/changelog";
import Retention from "./admin/retentions";
import BurnCode from "./admin/burnCode";
import CierresDiarios from "./admin/cierresDiarios";
import Reports from "./admin/reports";
import Cartola from "./admin/cartola";
import CierresInvoiceFilter from "./admin/cierresInvoiceFilter";
import Billing from "./billing";
import Billing2 from "./billing-2";
import CashoutOk from "./cashout/cashoutOk";
import CashoutFallida from "./cashout/cashoutFallida";
import Blacklist from "./admin/blacklist";
import commerces from "./admin/maintainer/merchants";
import users from "./admin/maintainer/users";
import DashboardMx from "./dashboardMx";
import Pct from "./pct/pctOk";
import PctPending from "./pct/pctPendientes";
import PctFailed from "./pct/pctRechazadas";
import Effectiveness from "./effectiveness";
import OperationalTraffic from "./operationalTraffic";
import PayrollDetail from "./cashout/payroll-detail";
import PayrollList from "./cashout/payroll/PayrollList";
import AdminPayroll from "./admin/payroll";

const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard} />
    <Route path={`${match.url}/cashin/validated`} component={Cashin} />
    <Route path={`${match.url}/cashin/failed`} component={CashinFailed} />
    <Route path={`${match.url}/cashin/pending`} component={CashinPending} />
    <Route path={`${match.url}/cashout/pending`} component={CashoutPending} />
    <Route path={`${match.url}/cashout/CashoutPendingAlto`} component={CashoutPendingAlto} />
    <Route path={`${match.url}/cashout/CashoutPendingBajo`} component={CashoutPendingBajo} />

    <Route path={`${match.url}/payroll/created`} render={(routeProps) => <PayrollList key="payroll/created" type="created" title="Created Payrolls" status={1} path="/main/payroll/created" {...routeProps}/>} />
    
    <Route path={`${match.url}/payroll/failed`} render={(routeProps) => <PayrollList key="payroll/failed" type="failed" title="Failed Payrolls" status={12} path="/main/payroll/failed" {...routeProps}/>} />
    
    <Route path={`${match.url}/payroll/pending`} render={(routeProps) => <PayrollList key="payroll/pending" type="pending" title="Pending Payrolls" status={9} path="/main/payroll/pending" {...routeProps}/>} />

    <Route path={`${match.url}/payroll/disabled`} render={(routeProps) => <PayrollList key="payroll/disabled" type="disabled" title="Disabled Payrolls" isEnabled={false} path="/main/payroll/disabled" {...routeProps}/>} />
    
    <Route path={`${match.url}/payroll/authorized`} render={(routeProps) => <PayrollList key="payroll/authorized" type="authorized" title="Authorized Payrolls" status={7} path="/main/payroll/authorized" {...routeProps}/>} />
    
    <Route path={`${match.url}/payroll/validated`} render={(routeProps) => <PayrollList key="payroll/validated" type="validated" sort="-1" title="Validated Payrolls" status={0} path="/main/payroll/validated" {...routeProps}/>} />

    {/* <Route path={`${match.url}/payroll/failed`} component={PayrollFailed} />
    <Route path={`${match.url}/payroll/pending`} component={PayrollPending} />
    <Route path={`${match.url}/payroll/blocked`} component={Payroll} /> */}
    <Route path={`${match.url}/payroll/payroll-detail/:id`} component={PayrollDetail} />
    
    <Route path={`${match.url}/cashout/failed`} component={CashoutFallida} />
    <Route path={`${match.url}/cashout/validated`} component={CashoutOk} />
    <Route path={`${match.url}/webpay/merchant/validated`} component={WebpayAll} />
    <Route path={`${match.url}/webpay/validated`} component={Webpay} />
    <Route path={`${match.url}/webpay/failed`} component={WebpayFailed} />
    <Route path={`${match.url}/webpay/pending`} component={WebpayPending} />
    <Route path={`${match.url}/flow/validated`} component={Flow} />
    <Route path={`${match.url}/flow/failed`} component={FlowFailed} />
    <Route path={`${match.url}/flow/pending`} component={FlowPending} />

    <Route path={`${match.url}/payku/validated`} component={Payku} />
    <Route path={`${match.url}/payku/failed`} component={PaykuFailed} />
    <Route path={`${match.url}/payku/pending`} component={PaykuPending} />


    <Route path={`${match.url}/banktransfer/validated`} component={BankTransferOk} />
    <Route path={`${match.url}/banktransfer/failed`} component={BankTransferFailed} />
    <Route path={`${match.url}/banktransfer/pending`} component={BankTransferPending} />


    <Route path={`${match.url}/card/validated`} component={CardOk} />
    <Route path={`${match.url}/card/failed`} component={CardFailed} />
    <Route path={`${match.url}/card/pending`} component={CardPending} />

    <Route path={`${match.url}/mach/validated`} component={MachOk} />
    <Route path={`${match.url}/mach/failed`} component={MachFailed} />
    <Route path={`${match.url}/mach/pending`} component={MachPending} />

    <Route path={`${match.url}/fri/validated`} component={FriOk} />
    <Route path={`${match.url}/fri/failed`} component={FriFailed} />
    <Route path={`${match.url}/fri/pending`} component={FriPending} />

    <Route path={`${match.url}/coinspaid/validated`} component={CoinspaidOk} />
    <Route path={`${match.url}/coinspaid/failed`} component={CoinspaidFailed} />
    <Route path={`${match.url}/coinspaid/pending`} component={CoinspaidPending} />

    <Route path={`${match.url}/cash/validated`} component={CashOk} />
    <Route path={`${match.url}/cash/failed`} component={CashFailed} />
    <Route path={`${match.url}/cash/pending`} component={CashPending} />

    <Route path={`${match.url}/payout/validated`} component={PayOutOk} />
    <Route path={`${match.url}/payout/failed`} component={PayOutFailed} />
    <Route path={`${match.url}/payout/pending`} component={PayOutPending} />

    <Route path={`${match.url}/skinsback/validated`} component={SkinsBackOk} />
    <Route path={`${match.url}/skinsback/failed`} component={SkinsBackFailed} />
    <Route path={`${match.url}/skinsback/pending`} component={SkinsBackPending} />

    <Route path={`${match.url}/kushkiBankTransfer/validated`} component={KushkiBanktransferOk} />
    <Route path={`${match.url}/kushkiBankTransfer/pending`} component={KushkiBanktransferPendientes} />
    <Route path={`${match.url}/kushkiBankTransfer/failed`} component={KushkiBanktransferRechazadas} />

    <Route path={`${match.url}/kushkiWebpay/validated`} component={KushkiWebpayOk} />
    <Route path={`${match.url}/kushkiWebpay/pending`} component={KushkiWebpayPendientes} />
    <Route path={`${match.url}/kushkiWebpay/failed`} component={KushkiWebpayRechazadas} />

    <Route path={`${match.url}/airtm/validated`} component={AirtmOk} />
    <Route path={`${match.url}/airtm/pending`} component={AirtmPendientes} />
    <Route path={`${match.url}/airtm/failed`} component={AirtmRechazadas} />



    <Route path={`${match.url}/airtmPayOut/validated`} component={AirtmPayOutOk} />
    <Route path={`${match.url}/airtmPayOut/pending`} component={AirtmPayOutPendientes} />
    <Route path={`${match.url}/airtmPayOut/failed`} component={AirtmPayOutRechazadas} />

    <Route path={`${match.url}/crypto`} component={CryptoMaintainer} />

    <Route path={`${match.url}/admin/changelog`} component={Changelog} />
    <Route path={`${match.url}/admin/callback`} component={Callback} />
    <Route path={`${match.url}/admin/callbackwebpay`} component={CallbackWebPay} />
    <Route path={`${match.url}/admin/callbackflow`} component={CallbackFlow} />
    <Route path={`${match.url}/admin/callbackpayku`} component={CallbackPayku} />
    <Route path={`${match.url}/admin/callbackCashout`} component={CallbackCashout} />
    <Route path={`${match.url}/admin/callbackPayIn`} component={CallbackPayIn} />
    <Route path={`${match.url}/admin/callbackPayOut`} component={CallbackPayOut} />
    <Route path={`${match.url}/admin/multySearch`} component={MultySearch} />
    <Route path={`${match.url}/admin/retention`} component={Retention} />
    <Route path={`${match.url}/admin/burnCode`} component={BurnCode} />

    <Route path={`${match.url}/admin/cierres`} component={CierresDiarios} />
    <Route path={`${match.url}/admin/reports`} component={Reports} />

    <Route path={`${match.url}/admin/CierresInvoiceFilter`} component={CierresInvoiceFilter} />
    <Route path={`${match.url}/admin/blacklist`} component={Blacklist} />
    {/*  <Route path={`${match.url}/admin/blacklistCashout`} component={blacklistCashout}  /> */}
    <Route path={`${match.url}/admin/commerces`} component={commerces} />
    <Route path={`${match.url}/admin/users`} component={users} />


    <Route path={`${match.url}/admin/cartola`} component={Cartola} />
    <Route path={`${match.url}/billing`} component={Billing} />
    <Route path={`${match.url}/billing2`} component={Billing2} />
    <Route path={`${match.url}/dashboardMx`} component={DashboardMx} />
    <Route path={`${match.url}/pct/validated`} component={Pct} />
    <Route path={`${match.url}/pct/failed`} component={PctFailed} />
    <Route path={`${match.url}/pct/pending`} component={PctPending} />

    <Route path={`${match.url}/admin/effectiveness`} component={Effectiveness} />
    <Route path={`${match.url}/admin/payroll`} component={AdminPayroll} />
    <Route path={`${match.url}/admin/operationalTraffic`} component={OperationalTraffic} />
  </Switch>
);

export default Main;
