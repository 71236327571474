import React, { Component } from "react";
// import { render } from "react-dom";
import { firebase } from "../../../firebase/firebase";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

const db = firebase.firestore();

class CierresDiarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verTotalesFooter: true,
      modules: AllModules,
      columnDefs: [
        {
          headerName: "Sum Cashin Ok",
          field: "sumCashinOk",
          width: 60,
          aggFunc: "sum",
          enableValue: true,
          valueFormatter: currencyFormatter
        },
        {
          headerName: "Count Cashin Ok",
          field: "countCashinOk",
          width: 60,
          aggFunc: "sum",
          enableValue: true
        },
        // {
        //   headerName: "Sum Cashin Fail",
        //   field: "sumCashinFail",
        //   width: 60,
        //   aggFunc: "sum",
        //   enableValue: true,
        //   valueFormatter: currencyFormatter
        // },
        // {
        //   headerName: "Count Cashin Fail",
        //   field: "countCashinFail",
        //   width: 60,
        //   aggFunc: "sum",
        //   enableValue: true
        // },
        {
          headerName: "Sum Webpay Ok",
          field: "sumWebpayOk",
          width: 60,
          aggFunc: "sum",
          enableValue: true,
          valueFormatter: currencyFormatter
        },
        {
          headerName: "Count Webpay Ok",
          field: "countWebpayOk",
          width: 60,
          aggFunc: "sum",
          enableValue: true
        },
        // {
        //   headerName: "Sum Webpay Fail",
        //   field: "sumWebpayFail",
        //   width: 60,
        //   aggFunc: "sum",
        //   enableValue: true,
        //   valueFormatter: currencyFormatter
        // },
        // {
        //   headerName: "Count Webpay Fail",
        //   field: "countWebpayFail",
        //   width: 60,
        //   aggFunc: "sum",
        //   enableValue: true
        // },
        {
          field: "year",
          rowGroup: true,
          hide: true
        },
        {
          field: "month",
          rowGroup: true,
          hide: true,
          rowStyle: { background: "blue" }
        },
        {
          field: "week",
          rowGroup: true,
          hide: true
        }
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      rowData: null,
      autoGroupColumnDef: {
        headerName: "Date",
        field: "date",
        width: 100,
        sort: "asc"
      },
      aggFuncs: {}
    };
  }

  onGridReady = async params => {
    // console.log("PARAMS", params);

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    await db
      .collection("dailyResumes")
      .get()
      .then(snapshot => {
        let docs = snapshot.docs.map(doc => {
          return doc.data();
        });
        this.setState({ rowData: docs });
        console.log(docs);
      })
      .catch(err => {
        console.log("Error getting document", err);
      });

    //params.api.addAggFunc("xyz", xyzFunc);
    params.api.sizeColumnsToFit();
  };

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  render() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div
          id="myGrid"
          style={{
            height: "100%",
            width: "100%"
          }}
          className="ag-theme-balham"
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.state.rowData}
            groupUseEntireRow={false}
            enableRangeSelection={true}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            groupIncludeFooter={this.state.verTotalesFooter}
            groupIncludeTotalFooter={this.state.verTotalesFooter}
            suppressAggFuncInHeader={true}
            aggFuncs={this.state.aggFuncs}
            sideBar={false}
            onGridReady={this.onGridReady}
            onFirstDataRendered={this.onFirstDataRendered.bind(this)}
          />
        </div>
      </div>
    );
  }
}

function currencyFormatter(params) {
  if (!isNaN(params.value)) return "$" + formatNumber(params.value);
}
function formatNumber(number) {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

// render(<CierresDiarios />, document.querySelector("#root"));

export default CierresDiarios;
