import React, { Component } from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
/* import { firebase } from "../../../firebase/firebase"; */
import Dynamic from "./Dynamic";
import moment from "moment-timezone";
import API from "../../../util/api";


/* let query; */
/* let dataList = []; */

export class friFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: "Fri Failed",
      filename: `Fri Failed ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      defaultValueRangeFilter:[
        moment().tz("America/Santiago").startOf("month"),
        moment().tz("America/Santiago").endOf("month")
      ]
    };
  }

  componentDidMount = () => {
    moment.tz.setDefault(this.state.timeZone);

    let firstDay = moment
      .tz(this.state.timeZone)
      .startOf("month")
      .toDate();
    let lastDay = moment
      .tz(this.state.timeZone)
      .endOf("month")
      .toDate();
      this.updateQuery(firstDay,lastDay);
  };
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
   /*    console.log("componentDidUpdate", "=>", "componentDidUpdate"); */

      /* const merchant = localStorage.getItem("merchant_code"); */
      moment.tz.setDefault(this.state.timeZone);

      let firstDay = moment
        .tz(this.state.timeZone)
        .startOf("month")
        .toDate();
      let lastDay = moment
        .tz(this.state.timeZone)
        .endOf("month")
        .toDate();


      this.updateQuery(firstDay,lastDay);
      this.setState({
        filename: `Fri validated ${localStorage.getItem("merchant_name")}`
      });
    }
  }



  updateQuery = async (firstDay,lastDay) => {
 /*    dataList = []; */
 let parametros = {
  "merchant": this.props.merchant.code,
  "firstDay": firstDay,
  "lastDay": lastDay,
  "country":this.props.country.code,
  "code":12,
  "payMethod":"fri",

}
    await API.utils.getFriForParamsFailed(parametros).then( cons=>{
      console.log(cons)
      this.setState({
        dataList: cons
      })
    });
  };

  onTableChange = (pagination, filters, sorter) => {
  /*   console.log("Various parameters", pagination, filters, sorter); */
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  update =(e)=>
  {
    this.componentDidUpdate(this.state);
  }

  render() {
    this.expandedRowRender = record => (
      <div>
        <p>
          Email: <b>{record.email}</b>
        </p>
        {this.props.dataUser.typeUser === "admin" ? (

          <p>
            Admin Callback:<b>{record.adminCallBack}</b>
          </p>
        ) : (
          ""
        )}
        {record.payMethod === "bankCard"  && (record.lastFourDigits || record.cardNumber || record.last_four_digits)? (
          <div>
              <p>
               Last Four Digits: <b>{record.lastFourDigits || record.cardNumber || record.last_four_digits}</b>
              </p>
            
          </div>
        ) : (
          <> </>
        )}

       {record.payMethod === "bankCard" &&
        (record.binCard ) ? (
          <div>
            <p>
            First Digits:{" "}
              <b>
                {record.binCard}
              </b>
            </p>
          </div>
        ) : (
          <> </>
        )}

      </div>
    );

    return (
      <Row>
        <Col span={24}>
          <Dynamic
           defaultValue={this.state.defaultValueRangeFilter}
            updateQuery={this.updateQuery}
            update={this.update}
            filename={this.state.filename}
            title={this.state.title}
            dataList={this.state.dataList}
            columns={this.columns}
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            onTableChange={this.onTableChange}
            merchant={this.props.merchant}
            typeUser={this.props.dataUser.typeUser}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { merchant,dataUser,country } = settings;
  return { merchant,dataUser,country};
};
export default connect(mapStateToProps)(friFailed);
