import React from "react";
import { Col, Row, Spin } from "antd";
import NumberFormat from "react-number-format";
import Metrics from "components/Metrics";
import currencyNumberFormat from "../../../util/currencyNumberFormat"
const GrowthCard = ({
  trafficData,
  title,
  month,
  suma,
  avg,
  dailyAvg,
  prefix,
  param,
  css,
  countryCode
}) => {
  switch (param) {
    case "0":
      return (
        <Metrics styleName={`gx-card-full`} title={title + " (" + month + ")"}>
          <Row>
            <Col lg={24} md={24} sm={24} xs={24}>
              <div className="gx-pb-0 gx-pl-4  gx-pr-4 gx-pt-3 gx-text-right" >
                <h2 className="gx-fs-xxxl gx-font-weight-medium gx-chart-up">
                  {currencyNumberFormat(suma, countryCode)}
                </h2>
              </div>
            </Col>
          </Row>
        </Metrics>
      );
    case "count":
      return (
        <Metrics styleName={`gx-card-full `} title={title + " (" + month + ")"}>
          <Row>
            <Col lg={24} md={24} sm={24} xs={24}>
              <div className="gx-pb-0 gx-pl-4 gx-pt-3">
                {/* <p className="gx-mb-0 gx-text-grey">{month}</p> */}
                <h2 className="gx-fs-xxxl gx-font-weight-medium gx-chart-up">

                  <NumberFormat
                    value={parseFloat(suma).toFixed(0)}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </h2>

                <div className="gx-fs-xs">
                  This Week.:{" "}
                  <NumberFormat
                    value={parseFloat(avg).toFixed(0)}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
              </div>
              <br />
            </Col>
          </Row>
        </Metrics>
      )
    default:
      return (
        <Metrics styleName={`gx-card-full`} title={title + " (" + month + ")"}>
          <Row>
            <Col lg={24} md={24} sm={24} xs={24}>
              <div className="gx-pb-0 gx-pl-4 gx-pt-3">
                <h2 className="gx-fs-xxxl gx-font-weight-medium gx-chart-up">
                  {currencyNumberFormat(suma, countryCode)}
                </h2>
                <div className="gx-fs-xs">
                  This Week.:{" "}
                  {currencyNumberFormat(avg, countryCode)}
                </div>
              </div>
              <br />
            </Col>

          </Row>
        </Metrics>
      );
  }
};

export default GrowthCard;
