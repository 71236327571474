export const unreleasedData = [
/*   {
    description:
      "main/customer/edit/id: En sección balances --> formatear montos segu corresponda",
  },
  {
    description:
      "main/customer/edit/id: En sección balances --> Tabla que contiene el historial de correcciones (tipo debit correction y credit correction) bajo los balances.",
  },
  {
    description:
      "main/customer/edit/id: En sección accounts history --> corregir error en la paginación",
  },
  {
    description:
      "main/customer/edit/id: En sección accounts history --> formatear montos segu corresponda",
  },
  {
    description:
      "main/customer/edit/id: En sección main --> validar funcionalidad de los campos active, email validation, user validation, phone validation y añadir boton para recuperar contraseña",
  }, */
  {
    title: "Mejorar performance de  Black List",
    date: "12 de agosto de 2021",
    details: [
      {
       /*  type: "added", */
        description:
          "Se necesita mejorar la performance del black list , que reciba mas antecedentes  que lleven a un mejor analisis sobre casos futuros  .",
       // issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-174",
      },
    ],
   },
];
