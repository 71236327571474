import React from "react";
import { Table } from "antd";
import Widget from "components/Widget/index";

const columns = [
  {
    title: "Method",
    dataIndex: "name"
  },
  {
    title: "Validated",
    dataIndex: "validated"
  },
  {
    title: "Pending",
    dataIndex: "pending"
  },
  {
    title: "Failed",
    dataIndex: "failed"
  },
  {
    title: "effectiveness",
    dataIndex: "eff",
    render: text => {
      return <span className="gx-text-red">{text === "NaN%" ? "0%" : text}</span>;
    }
  } 
];

const OrderHistory = props => {

  const data1 = [];

  if (props.cashinVal) {

    data1.push({
      key: "1",
      name: props.name1,
      validated: props.cashinVal,
      pending: props.cashinPen,
      failed: props.cashinFail,
      eff:
        parseFloat(
          (props.cashinVal * 100) /
          (props.cashinVal + props.cashinPen + props.cashinFail)
        ).toFixed(0) + "%"
    })
  }
  if (props.usePayku || props.useWebpay) {
    data1.push({
      key: "2",
      name: props.name2,
      validated: props.webpayVal,
      pending: props.webpayPen,
      failed: props.webpayFail,
      eff:
        parseFloat(
          (props.webpayVal * 100) /
          (props.webpayVal + props.webpayPen + props.webpayFail)
        ).toFixed(0) + "%"
    });
  }
  if (props.useCashout) {
    data1.push({
      key: "3",
      name: props.name3,
      validated: props.cashoutVal,
      pending: props.cashoutPen,
      failed: props.cashoutFail,
      eff:
        parseFloat(
          (props.cashoutVal * 100) /
          (props.cashoutVal + props.cashoutPen + props.cashoutFail)
        ).toFixed(0) + "%"
    });
  }
  if (props.useBankCard) {
    data1.push({
      key: "4",
      name: props.name4,
      validated: props.bankcardVal,
      pending: props.bankcardPen,
      failed: props.bankcardFail,
      eff:
        parseFloat(
          (props.bankcardVal * 100) /
          (props.bankcardVal + props.bankcardPen + props.bankcardFail)
        ).toFixed(0) + "%"
    });
  }
  if (props.useBankTransfer) {
    data1.push({
      key: "5",
      name: props.name5,
      validated: props.banktransferVal,
      pending: props.banktransferPen,
      failed: props.banktransferFail,
      eff:
        parseFloat(
          (props.banktransferVal * 100) /
          (props.banktransferVal + props.banktransferPen + props.banktransferFail)
        ).toFixed(0) + "%"
    });
  }
  if (props.useCash) {
    data1.push({
      key: "6",
      name: props.name6,
      validated: props.cashVal,
      pending: props.cashPen,
      failed: props.cashFail,
      eff: 
        parseFloat(
          (props.cashVal * 100) /
          (props.cashVal + props.cashPen + props.cashFail)
        ).toFixed(0) + "%"
    });
  }
  if (props.usePayOut) {
    data1.push({
      key: "7",
      name: props.name7,
      validated: props.payoutVal,
      pending: props.payoutPen,
      failed: props.payoutFail,
      eff:
        parseFloat(
          (props.payoutVal * 100) /
          (props.payoutVal + props.payoutPen + props.payoutFail)
        ).toFixed(0) + "%"
    });
  }

  if (props.useSkinsBacks) {
    data1.push({
      key: "8",
      name: props.name8,
      validated: props.skinBacksVal,
      pending: props.skinBacksPen,
      failed: props.skinBacksFail,
      eff:
        parseFloat(
          (props.skinBacksVal * 100) /
          (props.skinBacksVal + props.skinBacksPen + props.skinBacksFail)
        ).toFixed(0) + "%"
    });
  }

  if (props.airtmVal) {
    data1.push({
      key: "9",
      name: props.name9,
      validated: props.airtmVal,
      pending: props.airtmPen,
      failed: props.airtmFail,
      eff:
        parseFloat(
          (props.airtmVal * 100) /
          (props.airtmVal + props.airtmPen + props.airtmFail)
        ).toFixed(0) + "%"
    });
  }
  if (props.kushikiWebPayVal) {
    data1.push({
      key: "10",
      name: props.name10,
      validated: props.kushikiWebPayVal,
      pending: props.kushikiWebPayPen,
      failed: props.kushikiWebPayFail,
      eff:
        parseFloat(
          (props.kushikiWebPayVal * 100) /
          (props.kushikiWebPayVal + props.kushikiWebPayPen + props.kushikiWebPayFail)
        ).toFixed(0) + "%"
    });
  }
  if (props.KushikiBankTransferVal) {
    data1.push({
      key: "11",
      name: props.name11,
      validated: props.KushikiBankTransferVal,
      pending: props.KushikiBankTransferPen,
      failed: props.KushikiBankTransferFail,
      eff:
        parseFloat(
          (props.KushikiBankTransferVal * 100) /
          (props.KushikiBankTransferVal + props.KushikiBankTransferPen + props.KushikiBankTransferFail)
        ).toFixed(0) + "%"
    });
  }

  

  const data = [
    {
      key: "1",
      name: props.name1,
      validated: props.cashinVal,
      pending: props.cashinPen,
      failed: props.cashinFail,
      eff:
        parseFloat(
          (props.cashinVal * 100) /
          (props.cashinVal + props.cashinPen + props.cashinFail)
        ).toFixed(0) + "%"
    },
    {
      key: "2",
      name: props.name2,
      validated: props.webpayVal,
      pending: props.webpayPen,
      failed: props.webpayFail,
      eff:
        parseFloat(
          (props.webpayVal * 100) /
          (props.webpayVal + props.webpayPen + props.webpayFail)
        ).toFixed(0) + "%"
    },
    {
      key: "3",
      name: props.name3,
      validated: props.cashoutVal,
      pending: props.cashoutPen,
      failed: props.cashoutFail,
      eff:
        parseFloat(
          (props.cashoutVal * 100) /
          (props.cashoutVal + props.cashoutPen + props.cashoutFail)
        ).toFixed(0) + "%"
    },
    {
      key: "4",
      name: props.name4,
      validated: props.bankcardVal,
      pending: props.bankcardPen,
      failed: props.bankcardFail,
      eff:
        parseFloat(
          (props.bankcardVal * 100) /
          (props.bankcardVal + props.bankcardPen + props.bankcardFail)
        ).toFixed(0) + "%"
    },
    {
      key: "5",
      name: props.name5,
      validated: props.banktransferVal,
      pending: props.banktransferPen,
      failed: props.banktransferFail,
      eff:
        parseFloat(
          (props.banktransferVal * 100) /
          (props.banktransferVal + props.banktransferPen + props.banktransferFail)
        ).toFixed(0) + "%"
    },
    {
      key: "6",
      name: props.name6,
      validated: props.cashVal,
      pending: props.cashPen,
      failed: props.cashFail,
      eff:
        parseFloat(
          (props.cashVal * 100) /
          (props.cashVal + props.cashPen + props.cashFail)
        ).toFixed(0) + "%"
    },
    {
      key: "7",
      name: props.name7,
      validated: props.payoutVal,
      pending: props.payoutPen,
      failed: props.payoutFail,
      eff:
        parseFloat(
          (props.payoutVal * 100) /
          (props.payoutVal + props.payoutPen + props.payoutFail)
        ).toFixed(0) + "%"
    },
    {
      key: "8",
      name: props.name8,
      validated: props.skinBacksVal,
      pending: props.skinBacksPen,
      failed: props.skinBacksFail,
      eff:
        parseFloat(
          (props.skinBacksVal * 100) /
          (props.skinBacksVal + props.skinBacksPen + props.skinBacksFail)
        ).toFixed(0) + "%"
    }

    ,
    {
      key: "9",
      name: props.name9,
      validated: props.kushikiWebPayVal,
      pending: props.kushikiWebPayPen,
      failed: props.kushikiWebPayFail,
      eff:
        parseFloat(
          (props.kushikiWebPayVal * 100) /
          (props.kushikiWebPayVal + props.kushikiWebPayPen + props.kushikiWebPayFail)
        ).toFixed(0) + "%"
    }

    ,
    {
      key: "10",
      name: props.name10,
      validated: props.KushikiBankTransferVal,
      pending: props.KushikiBankTransferPen,
      failed: props.KushikiBankTransferFail,
      eff:
        parseFloat(
          (props.KushikiBankTransferVal * 100) /
          (props.KushikiBankTransferVal + props.KushikiBankTransferPen + props.KushikiBankTransferFail)
        ).toFixed(0) + "%"
    }

  ];
  /* console.log("data",data); */
  return (
    <Widget
      styleName="gx-order-history"
      title={<h2 className="h4 gx-text-capitalize gx-mb-0">Order History</h2>}
    // extra={
    //   <p className="gx-text-primary gx-mb-0 gx-pointer">Detailed History</p>
    // }
    >
      <div className="gx-table-responsive">
        <Table
          className="gx-table-no-bordered"
          columns={columns}
          dataSource={data1}
          pagination={false}
          bordered={false}
          size="small"
        />
      </div>
    </Widget>
  );
};

export default OrderHistory;
