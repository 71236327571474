import { Col, Row, Spin } from "antd";
import React, { useState } from "react";
import AggregatorInformation from "../../../components/Effectiveness/aggregator-information";
import EffectivenessFilter from "../../../components/Effectiveness/filter";
import EffectivenessResults from "../../../components/Effectiveness/results";
import * as moment from "moment";
import api from "../../../util/api";
import DownloadEffectiveness from "../../../components/Effectiveness/download-effectiveness";
import ReportEffectiveness from "../../../components/Effectiveness/report-effectiveness";

const Effectiveness = ({}) => {
  const [loading, setLoading] = useState(false);

  const [dataMerchant_1, setDataMerchant_1] = useState({});
  const [errorsMerchant_1, setErrorsMerchant_1] = useState("");

  const [dataMerchant_2, setDataMerchant_2] = useState({});
  const [errorsMerchant_2, setErrorsMerchant_2] = useState("");

  const fetchFilter = params => {
    // let startDate = moment(params.rangeDate[0]).format("DD/MM/YYYY");
    // let endDate = moment(params.rangeDate[1]).format("DD/MM/YYYY");
    return new Promise(function(resolve, reject) {
      api.utils
        .getEffectivenessByCommerce({
          aggregator: params.aggregator,
          aggregatorType: params.aggregatorType,
          commerceId: params.commerceId,
          country: params.country,
          startDate: moment(params.rangeDate[0])
            .startOf("day")
            .format("DD/MM/YYYY HH:mm:ss"),
          endDate: moment(params.rangeDate[1])
            .endOf("day")
            .format("DD/MM/YYYY HH:mm:ss")
        })
        .then(result => resolve(result))
        .catch(error => {
          reject(error);
        });
    });
  };

  const defaultState = () => {
    setDataMerchant_1({});
    setErrorsMerchant_1("");

    setDataMerchant_2({});
    setErrorsMerchant_2("");
  };

  const onSubmitForm = params => {
    setLoading(true);
    defaultState();

    Promise.all([
      fetchFilter(params.merchant_1),
      fetchFilter(params.merchant_2)
    ])
      .then(response => {
        setDataMerchant_1(response[0].data);
        setDataMerchant_2(response[1].data);

        if (response[0].data.success == false) {
          setErrorsMerchant_1(response[0].data.message);
        }

        if (response[1].data.success == false) {
          setErrorsMerchant_2(response[1].data.message);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <h2>Effectiveness</h2>

      <div>
        <EffectivenessFilter
          onSubmitForm={onSubmitForm}
          loading={loading}
          errorsMerchant_1={errorsMerchant_1}
          errorsMerchant_2={errorsMerchant_2}
        />
      </div>
      <hr />
      {loading ? (
        <div className="loading">
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[30, 10]}>
            <Col span={24}>
              <h3>Aggregator information</h3>
            </Col>
            <Col xl={12} lg={24} md={12} sm={24} xs={24}>
              <AggregatorInformation data={dataMerchant_1} />
            </Col>
            <Col xl={12} lg={24} md={12} sm={24} xs={24}>
              <AggregatorInformation data={dataMerchant_2} />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row align="middle" type="flex" gutter={[10, 20]}>
                <Col span={12}>
                  <h3>Effectiveness result</h3>
                </Col>
                <Col span={12}>
                  <div className="section-button">
                    <DownloadEffectiveness
                      data={[
                        ...[
                          {
                            ...(dataMerchant_1.effectiveness || {}),
                            aggregator: dataMerchant_1.aggregator || ""
                          } || {}
                        ],
                        ...[
                          {
                            ...(dataMerchant_2.effectiveness || {}),
                            aggregator: dataMerchant_2.aggregator || ""
                          } || {}
                        ]
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={12} lg={24} md={12} sm={24} xs={24}>
              <EffectivenessResults data={dataMerchant_1} />
            </Col>
            <Col xl={12} lg={24} md={12} sm={24} xs={24}>
              <EffectivenessResults data={dataMerchant_2} />
            </Col>
          </Row>
          <hr />
          <div>
            <ReportEffectiveness />
          </div>
        </>
      )}

      <style jsx="true">{`
        .loading {
          width: 100%;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .section-button {
          display: flex;
          justify-content: flex-end;
        }
      `}</style>
    </>
  );
};

export default Effectiveness;
