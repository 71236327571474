import React, { Component } from "react";
import { Button, Form, Input, Alert, Row, Col, Select, DatePicker, Badge } from "antd";
import Widget from "components/Widget/index";
import { firebase, auth } from "../../firebase/firebase";
import { connect } from "react-redux";
import moment from "moment";
import API from "../../util/api";
import Dynamic from "./Dynamic";
import {
  // format as formatRut
  // validate as validateRut,
  clean as cleanRut
} from "rut.js";
const md5 = require("md5"); 
const db = firebase.firestore();
/* const FormItem = Form.Item;
const { TextArea } = Input; */
/* let query; */
const { RangePicker } = DatePicker;
class RetentionForm extends Component {
  state = {
    resp: "",
    earlyPayments: "0",
    ivaCobro: true,
    ivaValue: "0",
    retention: "0",

  };
  componentDidMount = async e => {
    this.getCommerces();

  };


  componentDidUpdate (prevProps) {

    if (this.props !== prevProps) {
      console.log("ALGO CAMBIO :");

      //si cambia el combo de merchant pais 
   /*    if (
        this.props.merchant !== prevProps.merchant ||
        this.props.country !== prevProps.country
      ) {
        console.log(" CAMBIO MERCHANT O PAIS:");
        let payload = {
          merchant: localStorage.getItem("merchant_code"),
          country: localStorage.getItem("country_code"),
        };
        API.merchant.getRetentionErlyPayment(payload).then(trxData => {
        this.setState({ earlyPayments: trxData.earlyPayments, ivaValue: trxData.ivaValue, retention: trxData.retention, resp: "OK" })
        })
       .catch(error => {
            console.log("Error en getRetentionErlyPayment :", error);
       })
      } */
    }
  };



  handleSubmit = async e => {
    e.preventDefault();
  /*   this.setState({ loading: true, dataList: [], suma: 0, totalRegister: 0 }) */
    this.props.form.validateFields((err, values) => {
      try {
        if (!err) {
          console.log("!!!!!!!!!!!!!! ");
          console.log("Received values of form: ", values);

        let    secretKey = 'qTXgKFvjV3f4cC5fny';
      /*     [06-10-2021 16:57] Pablo Catalan */
          
          let signCreated = md5(values.operationCode + values.quantity + secretKey)
          

          let email=values.email
          let rut=values.rut
          let bankOperationCode=values.bankOperationCode
          let commerceReqId=values.commerceReqId
          let commerceId=values.commerceId
          
          let payload = {
            originBank: values.originBank,//"BC",
            bankOperationCode:bankOperationCode,
            operationCode:values.operationCode,
            adminCallBack:this.props.authUser,
            quantity: values.quantity,
            rut:rut,
            sign: signCreated,
            commerceReqId:commerceReqId,//"243435335",
            email:email,
            commerceId:commerceId// "juegalo"
          };

          console.log("payload",payload);
           //SE LLAMA API editRetentionErlyPayment
          API.utils.burnCode(payload).then(trxData => {
            console.log("trxData :", trxData);
          this.setState({  resp: trxData.description })
      
          })
         .catch(error => {
              console.log("Error en burnCode :", error);
         })


         setTimeout(
          function () {
            this.setState({ resp: "" });
          }.bind(this),
          20000
        );



        }
      } catch (error) {
        console.error(error);
        this.setState({
          resp: `Commerce: Error when trying to add a record .\nerror:: ${error}\n`,
          updateTable: !this.state.updateTable,
          typeAlert: "error"
        });
      }
    });
  };
  onChangeRange = (dates, dateStrings) => {
    let firstDay = ""; let lastDay = ""; let defaultValueRange = "";
    console.log("dates", dates)
    if (dates) {
      firstDay = moment(dateStrings[0], "DD/MM/YYYY").tz(this.state.timeZone).toDate();
      lastDay = moment(dateStrings[1], "DD/MM/YYYY").tz(this.state.timeZone).endOf("day").toDate();
    }

    console.log(firstDay)
    console.log(lastDay)
    this.setState({ firstDay, lastDay })

  }
  getCommerces = async () => {
    let merchant = [];
    await db
      .collection("commerces")
      .get()
      .then(snapshot => {
        snapshot.docs.map(async docCommerce => {
          merchant.push({
            code: docCommerce.id,
            name: docCommerce.data().name
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN merchant", err);
      });
    this.setState({ boxMerchant: merchant });
  };

  clearFrom = () => {
    this.props.form.resetFields();
   
   // this.setState({
     // dataList: [], suma: 0, totalRegister: 0, country: "CL"
   // })
  };
 

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const rangeConfig = {
      rules: [
        {
          type: 'array',
          required: true,
          message: 'Please select time!',
        },
      ],
    };
    /*    const AutoCompleteOption = AutoComplete.Option; */

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 12
        },
        sm: {
          span: 8
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16
        }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    const children = [];

      if (this.state.boxMerchant) {
        this.state.boxMerchant.map(merchant => {
          children.push(
            <Option key={merchant.code.toString()}>
              {merchant.name.toString()}
            </Option>
          );
        });
      }


    return (
      <Row>

        <Col lg={24} md={24} sm={24} xs={24}>
          <Widget
            title={
              <h4
                className=" gx-text-capitalize gx-mb-0"
                style={{ color: "#070707" }}
              >
                <i className="icon icon-callout" />
                <span>{" Burn Operation Code"}</span>
              </h4>
            }
          >
                <hr></hr>
            {this.state.resp ? (
              <Alert
                message="Resultado"
                description={this.state.resp}
                type="success"
              />
            ) : null} 
            <br></br>
            <Form
              {...formItemLayout}
              onSubmit={this.handleSubmit}
              //form={form}
              name="register"
              scrollToFirstError
            >
              <Row>
                <Col lg={12} md={12} sm={24} xs={24}>
                  {/*  {this.state.type==="edit"? */}
                  <Form.Item name="email" label="Email">
                    {getFieldDecorator("email", {
                      initialValue: "",
                      rules: [
                        {
                         /*  pattern: '^([-]?[1-9][0-9]*|0)$', */
                          message: 'Please enter the Email '
                        }
                      ]
                    })(<Input placeholder="Email" />)}
                  </Form.Item>
                  <Form.Item name="quantity" label="Amount">
                    {getFieldDecorator("quantity", {
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          pattern: '^([-]?[1-9][0-9]*|0)$',
                          message: 'Please enter the Amount '
                        }
                      ]
                    })(<Input placeholder="Amount" />)}
                  </Form.Item>
                  <Form.Item name="rut" label="rut">
                    {getFieldDecorator("rut", {
                      initialValue:"",// this.state.retention,
                      rules: [
                        {
                         /*  pattern: '^([-]?[1-9][0-9]*|0)$', */
                         required: true,
                          message: 'Please enter the rut '
                        }
                      ]
                    })(<Input placeholder="rut" />)}
                  </Form.Item>
                  <Form.Item name="originBank" label="Origin Bank">
                    {getFieldDecorator("originBank", {
                      initialValue: "",
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                            required: true,
                          message: "Please enter the Origin Bank!"
                        }
                      ]
                    })(
                      <Select onChange={this.onChangeID} >
                        <Option value={""}>Select Origin Bank </Option>
                        <Option value={"BC"}><i ></i>Banco de chile</Option>
                        <Option value={"BE"}> <i ></i>Banco Estado</Option>
                       
                      </Select>
                    )}
                  </Form.Item>

                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Form.Item name="operationCode" label="Operation Code">
                    {getFieldDecorator("operationCode", {
                      initialValue: "",
                      rules: [
                        {
                      /*     pattern: '^([-]?[1-9][0-9]*|0)$', */
                      required: true,
                          message: "Please enter the Operation Code!"
                        }
                      ]
                    })(<Input placeholder="Operation Code" />)}
                  </Form.Item>

                  <Form.Item name="bankOperationCode" label="Bank Operation Code">
                    {getFieldDecorator("bankOperationCode", {
                       initialValue: "",
                      rules: [
                        {
             /*              pattern: '^([-]?[1-9][0-9]*|0)$', */
                          message: "Please enter the Bank Operation Code!"
                        }
                      ]
                    })(<Input placeholder="Bank Operation Code" />)}
                  </Form.Item>

                  <Form.Item name="merchant" label="Merchant">
                    {getFieldDecorator("merchant", {
                      initialValue: "",
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                             required: true,
                          message: "Please enter the Merchant Default!"
                        }
                      ]
                    })(
                      <Select
                        //mode="tags"
                        // mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Please select Merchant "
                      // defaultValue={this.state.type !== "edit"?children:['a10', 'c12']}
                      >
                        <Option value={""}>Select Merchant </Option>
                        {children}
                      </Select>
                    )}
                  </Form.Item>

                  <Form.Item name="commerceReqId" label="commerceReqId">
                    {getFieldDecorator("commerceReqId", {
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Please enter the commerceReqId!"
                        }
                      ]
                    })(<Input placeholder="commerceReqId" />)}
                  </Form.Item>

                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item {...tailFormItemLayout}>
                
                        <Button type="primary" htmlType="submit">
                          Burn
                        </Button>
                        <Button type="primary" onClick={this.clearFrom}>
                          Clean 
                        </Button>
                   
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Widget>
        </Col>
         
      </Row>
    );
  }
}

const UsersForm = Form.create()(RetentionForm);

/* export default UsersForm; */

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, country, } = settings;
  return { authUser, country, merchant, };
};

export default connect(mapStateToProps)(UsersForm);