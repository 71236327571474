import React from "react";
import { Button, Divider, Popconfirm, Table, Tag } from "antd";
const { Column } = Table;
const ButtonGroup = Button.Group;

export default function TableMerchantAdministration({
  merchants,
  onOpenModalEdit,
  onDelete
}) {
  return (
    <>
      <Table
        dataSource={merchants}
        pagination={false}
        size="small"
        style={{ background: "white" }}
        scroll={{ y: 500 }}
      >
        <Column
          title="Commerce"
          dataIndex="commerce_id"
          key="commerce_id"
          render={(text, record, index) => <span>{text}</span>}
        />
        <Column
          title="is Active New Cashout"
          dataIndex="isActiveNewCashout"
          key="isActiveNewCashout"
          render={(text, record, index) => (
            <Tag color={text ? "green" : "volcano"} style={{ margin: 0 }}>
              {text ? "Actived" : "Disabled"}
            </Tag>
          )}
        />
        <Column
          title="is Allowed New Cashout"
          dataIndex="isAllowedNewCashout"
          key="isAllowedNewCashout"
          render={(text, record, index) => (
            <Tag color={text ? "green" : "volcano"} style={{ margin: 0 }}>
              {text ? "Actived" : "Disabled"}
            </Tag>
          )}
        />
        <Column
          title="Actions"
          dataIndex="action"
          key="action"
          render={(text, record, index) => (
            <ButtonGroup>
              <Button
                onClick={() => onOpenModalEdit(record)}
                style={{ marginBottom: 0 }}
              >
                Edit
              </Button>
              <Popconfirm
                title="Are you sure delete this commerce?"
                onConfirm={() => onDelete(record.commerce_id)}
                okText="Yes, delete"
                cancelText="No"
                okType="danger"
              >
                <Button color="red" icon="delete" style={{ marginBottom: 0 }} />
              </Popconfirm>
            </ButtonGroup>
          )}
        />
      </Table>
    </>
  );
}
