import React, { Component } from "react";
import { Col, Icon, Row,Tag,Tooltip } from "antd";
import { connect } from "react-redux";
import { db } from "../../../firebase/firebase";
import Dynamic from "./Dynamic";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import GrowthCard from "components/dashboard/CRM/GrowthCardMerchant";
import _ from "lodash";
import Api from "../../../util/api";
import { BancoChileIcon, SantanderIcon } from "../../../styles/icons-svg/svg-icons";

let query;
let queryWeek;
let dataList = [];

const TypeBankIcon = ({ bank }) => {
  return (
    <>
      {bank == 'Banco de Chile' ? (
        <Icon style={{fontSize: '1.2rem' }} component={BancoChileIcon} />
      ) : null}
      {bank == 'Banco Santander' ? (
        <Icon style={{fontSize: '1.2rem' }} component={SantanderIcon} />
      ) : null}
    </>
  )
}

export class cashoutOk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: "Cashout validated",
      filename: `Cashout validated ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      commerces: {},
      indicadores: {
        dolar: {
          valor: ""
        }
      },
      date: "",
      dataCashout: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        TotalSinCommission: 0
      },
      dataCashoutWeek: {
        dataWeek: "",
        sumWeek: 0,
        countdocsWeek: 0
      },
      firstTime: true,
      banks: [],
      typeAccount: [],
    };
  }
  componentDidMount =async () => {
      await this.getParamCashout();
    moment.tz.setDefault(this.state.timeZone);

    let firstDay = moment
      .tz(this.state.timeZone)
      .startOf("month")
      .toDate();
    let lastDay = moment
      .tz(this.state.timeZone)
      .endOf("month")
      .toDate();

    let firstDayWeek = moment
      .tz(this.state.timeZone)
      .startOf("isoWeek")
      .toDate();

    let lastDayWeek = moment
      .tz(this.state.timeZone)
      .endOf("isoWeek")
      .toDate();

    this.getData(firstDay, lastDay, firstDayWeek, lastDayWeek);

    // CALCULO EL MES ACTUAL
    let mes = moment.tz(this.state.timeZone).format("MMMM");
    this.setState({
      mes: mes
    });

    this.getParametrosGenerales();
  };

  getParametrosGenerales= () => {
    db.collection("parametrosGenerales").doc('cashout').get().then(parametrosGenerales => {
      const { payrollMaxAmount = 0, payrollMinAmount = 0, payrollTxnsRange = 0, commerces  } = parametrosGenerales.data();
      this.setState({
        dataModalGeneratePayroll: {
          payrollMaxAmount,
          payrollMinAmount,
          payrollTxnsRange
        },
        commerces
      });
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.merchant !== prevProps.merchant) {
      const merchant = localStorage.getItem("merchant_code");
      moment.tz.setDefault(this.state.timeZone);

      let firstDay = moment
        .tz(this.state.timeZone)
        .startOf("month")
        .toDate();
      let lastDay = moment
        .tz(this.state.timeZone)
        .endOf("month")
        .toDate();

      let firstDayWeek = moment
        .tz(this.state.timeZone)
        .startOf("isoWeek")
        .toDate();

      let lastDayWeek = moment
        .tz(this.state.timeZone)
        .endOf("isoWeek")
        .toDate();

      // CALCULO EL MES ACTUAL
      let mes = moment.tz(this.state.timeZone).format("MMMM");
      this.setState({
        mes: mes
      });
      query = db
        .collection("cashouts")
        .where("commerceId", "==", merchant)
        .where("code", "==", 0)
        .where(
          "dateRequest",
          ">=",
          moment(firstDay)
            .tz(this.state.timeZone)
            .toDate()
        )
        .where(
          "dateRequest",
          "<=",
          moment(lastDay)
            .tz(this.state.timeZone)
            .toDate()
        );

      if (firstDayWeek !== null && lastDayWeek !== null) {
        queryWeek = db
          .collection("cashouts")
          .where("commerceId", "==", merchant)
          .where("code", "==", 0)
          .where(
            "dateRequest",
            ">=",
            moment(firstDayWeek)
              .tz(this.state.timeZone)
              .toDate()
          )
          .where(
            "dateRequest",
            "<=",
            moment(lastDayWeek)
              .tz(this.state.timeZone)
              .toDate()
          );
      }
      this.updateQuery();

      this.setState({
        filename: `Cashout validated ${localStorage.getItem("merchant_name")}`
      });
    }
  }

  getData = (firstDay, lastDay, firstDayWeek = null, lastDayWeek = null) => {
    console.log("firstDay", "=>", firstDay);
    console.log("lastDay", "=>", lastDay);
    query = db
      .collection("cashouts")
      .where("commerceId", "==", this.props.merchant.code)
      .where("code", "==", 0)
      .where(
        "dateRequest",
        ">=",
        moment(firstDay)
          .tz(this.state.timeZone)
          .toDate()
      )
      .where(
        "dateRequest",
        "<=",
        moment(lastDay)
          .tz(this.state.timeZone)
          .toDate()
      );

    if (firstDayWeek !== null && lastDayWeek !== null) {
      queryWeek = db
        .collection("cashouts")
        .where("commerceId", "==", this.props.merchant.code)
        .where("code", "==", 0)
        .where(
          "dateRequest",
          ">=",
          moment(firstDayWeek)
            .tz(this.state.timeZone)
            .toDate()
        )
        .where(
          "dateRequest",
          "<=",
          moment(lastDayWeek)
            .tz(this.state.timeZone)
            .toDate()
        );
    }
    this.updateQuery();
  };


  getParamCashout= async () => {
    await Api.cashout.getParamCashout().then(resp => {
  
      this.setState({
        banks: resp.banks,
        typeAccount: resp.typeAccount
  
      });
      console.log("resp getParamCashout", "=>", resp);
    })
      .catch(error => {
        console.log("error getParamCashout", "=>", error);
      })
    }
  updateQuery = () => {

  /************************************COMISIONES******************************************* */
  let commissionesBd=this.props.comissionData?this.props.comissionData: JSON.parse(localStorage.getItem("comisiones"));
    commissionesBd = commissionesBd.filter(item=> item.merchant===this.props.merchant.code)
    commissionesBd = commissionesBd[0].commission.filter(item=> item.countrie===this.props.country.code)
    let factor =commissionesBd[0].cashout;
  /************************************************************************************************ */
    dataList = [];
    query
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          this.setState({
            dataList
          });
          return;
        }

        let bankName = "";
        let typeAccount = "";
        let resultadosOk = [];
        snapshot.forEach(doc => {
          if (this.state.firstTime) {
            resultadosOk.push({
              date: moment(doc.data().dateRequest.toDate()).toLocaleString(
                "es-CL",
                {
                  timeZone: this.state.timeZone,
                  timeZoneName: "short"
                }
              ),
              quantity: doc.data().quantity
            });

            // OBJETO DE RESULTADO
            var docs = snapshot.docs.map(doc => doc.data());

            // SUMA TOTAL DEL MES
            let sum = _.sumBy(docs, item => Number(item.quantity));
            let count = docs.length;

            // CALCULO COMISIONES


            let commission = Number(
              parseFloat(Number(sum) * factor).toFixed(2)
            );
            let ivaCommission = Number(
              parseFloat(commission * 0.19).toFixed(2)
            );

            if (isNaN(commission)) {
              commission = 0;
              ivaCommission = 0;
            }

            //retirar plata
            //factor distinto
            //

            let TotalSinCommission = 0;
            TotalSinCommission = sum + commission + ivaCommission;

            let response;
            console.log(
              "TotalSinCommission " +
                TotalSinCommission +
                "count: " +
                count +
                "suma: " +
                sum +
                "commission " +
                commission +
                "ivaCommission " +
                ivaCommission
            );
            response = {
              sum,
              count,
              TotalSinCommission
            };

            //Week
            queryWeek.get().then(async querySnapshotWeek => {
              let resultadosOkWeek = [];

              querySnapshotWeek.forEach(doc => {
                resultadosOkWeek.push({
                  date: moment(doc.data().dateRequest.toDate()).toLocaleString(
                    "es-CL",
                    {
                      timeZone: this.state.timeZone,
                      timeZoneName: "short"
                    }
                  ),
                  quantity: doc.data().quantity
                });
              });

              // OBJETO DE RESULTADO
              var docsWeek = querySnapshotWeek.docs.map(doc => doc.data());

              // SUMA TOTAL DE LA SEMANA
              let sumdocsWeek = _.sumBy(docsWeek, item =>
                Number(item.quantity)
              );

              let countdocsWeek = docsWeek.length;

              // OBTENGO DATA AGRUPADA POR FECHA
              let dataWeek = _(resultadosOkWeek)
                .groupBy("date")
                .map((objs, key) => {
                  return {
                    date: key,
                    value: _.sumBy(objs, item => Number(item.quantity))
                  };
                })
                .value();

              let responseWeek;

              responseWeek = {
                dataWeek,
                sumdocsWeek,
                countdocsWeek
              };

              if (dataWeek.length > 0) {
                this.setState({
                  dataCashoutWeek: responseWeek
                });

                console.log(this.state.dataCashoutWeek.sumdocsWeek);
              }
            });

            this.setState({
              dataCashout: response,
              firstTime: false
            });
          }

          let cashoutId = doc.id;
          var databank = this.state.banks.filter(dato => dato.id == doc.data().bankId.toString())
          bankName = databank[0].name;
     /*      switch (Number(doc.data().bankId).toString()) {
            case "12":
              bankName = "BANCO ESTADO";
              break;
            case "0":
              bankName = "BANCO ESTADO";
              break;
            case "1":
              bankName = "BANCO DE CHILE";
              break;
            case "37":
              bankName = "BANCO SANTANDER";
              break;
            case "9":
              bankName = "BANCO INTERNACIONAL";
              break;
            case "14":
              bankName = "SCOTIABANK-DESARROLLO";
              break;
            case "16":
              bankName = "BCI";
              break;
            case "27":
              bankName = "CORP-BANCA";
              break;
            case "28":
              bankName = "BICE";
              break;
            case "31":
              bankName = "HSBC BANK CHILE";
              break;
            case "39":
              bankName = "BANCO ITAU";
              break;
            case "45":
              bankName = "MUFG BANK, LTD.";
              break;
            case "49":
              bankName = "BANCO SECURITY";
              break;
            case "51":
              bankName = "BANCO FALABELLA";
              break;
            case "53":
              bankName = "BANCO RIPLEY";
              break;
            case "55":
              bankName = "BANCO CONSORCIO";
              break;
            case "504":
              bankName = "BANCO BBVA";
              break;
            case "672":
              bankName = "COOPEUCH";
              break;
            default:
              bankName = "---";
              break;
          } */
          var datatypeAccount = this.state.typeAccount.filter(dato => dato.id == doc.data().typeAccountId)
          console.log("data datatypeAccount", datatypeAccount)
          typeAccount = datatypeAccount[0].name
     /*      switch (doc.data().typeAccountId) {
            case "CCT":
              typeAccount = "Cuenta Corriente";
              break;
            case "CTV":
              typeAccount = "Chequera Electrónica / Cuenta Vista";
              break;
            case "CRUT":
              typeAccount = "CuentaRUT";
              break;
            case "AHO":
              typeAccount = "Cuenta Ahorro";
              break;
            default:
              typeAccount = "---";
              break;
          }
 */
          let commision = Number(Number(doc.data().quantity * factor).toFixed(0));
          let iva = Number((commision * 0.19).toFixed(0));

          dataList.push({
            ...doc.data(),
            bankName,
            typeAccount,
            cashoutId,
            commision,
            iva,
            totCommision: commision + iva,
            total: Number(doc.data().quantity) + (commision + iva)
          });
        });

        this.setState({
          dataList
        });

        console.log("DATALIST", "=>", dataList);
      })
      .catch(console.log);
  };

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    this.columns = [
      {
        title: "Date",
        dataIndex: "dateRequest",
        key: "dateRequest",
        width: 200,
        render: date =>
          date.toDate().toLocaleString("es-CL", {
            timeZone: this.state.timeZone,
            timeZoneName: "short"
          }),
        sorter: (a, b) => a.dateRequest.seconds - b.dateRequest.seconds,
        sortOrder: sortedInfo.columnKey === "dateRequest" && sortedInfo.order
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 150
      },
      {
        title: "Amount",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        render: text => (
          <NumberFormat
            value={text}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        )
      },
      {
        title: "Request ID",
        dataIndex: "commerceReqId",
        key: "commerceReqId",
        width: 100,
        sorter: (a, b) => a.commerceReqId - b.commerceReqId
      },
      {
        title: "Zippy ID",
        dataIndex: "cashoutId",
        key: "cashoutId",
        width: 100
      },
      {
        title: "Validation type",
        dataIndex: "bankValidate",
        key: "bankValidate",
        width: 100,
        render: (text = 'Banco Santander', record) => (
          <>
            {record.payrollId ? (
              <Tooltip title={`Authorized with by: ${text} and payroll robot`}>
                <div className="icons-list">
                  <Icon style={{fontSize: '1.2rem', color: "#038fde"}} type="robot" />
                  {" "}
                  <Icon  type="plus" />
                  {" "}
                  <TypeBankIcon bank={text} />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title={`Authorized by: ${text}`}>
                <TypeBankIcon bank={text} />
              </Tooltip>
            )}
          </>
      )}
    ];
    this.expandedRowRender = record => (
      <div>
        <p>
          RUT: <b>{formatRut(record.rut)}</b>
        </p>
        <p>
          Bank: <b>{record.bankName}</b>
        </p>
        <p>
          Account Type: <b>{record.typeAccount}</b>
        </p>
        <p>
          Account Number: <b>{record.numAccount}</b>
        </p>
        <p>
          Email: <b>{record.email}</b>
        </p>
      </div>
    );

    switch (this.props.authUser) {
      case "latamerica@1xbet.com":
        return (
          <Row>
            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
              <GrowthCard
                avg={parseFloat(this.state.dataCashoutWeek.sumdocsWeek).toFixed(
                  0
                )}
                title="Transaction Amount"
                month={this.state.mes}
                suma={this.state.dataCashout.sum}
                prefix={"$"}
              />
            </Col>
            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
              <GrowthCard
                title="Total Transactions"
                month={this.state.mes}
                suma={this.state.dataCashout.count}
                avg={this.state.dataCashoutWeek.countdocsWeek}
              />
            </Col>
            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
              <GrowthCard
                param="0"
                title="Total without commision"
                month={this.state.mes}
                suma={this.state.dataCashout.TotalSinCommission}
                prefix={"$"}
              />
            </Col>
            <Col span={24}>
              <Dynamic
                filename={this.state.filename}
                title={this.state.title}
                dataList={this.state.dataList}
                columns={this.columns}
                expandedRowRender={this.expandedRowRender}
                getData={this.getData}
                onTableChange={this.onTableChange}
                merchant={this.props.merchant}
              />
            </Col>
          </Row>
        );
      // break;

      default:
        return (
          <Row>
            <Col span={24}>
              <Dynamic
                key="dynamic-cashout-ok"
                filename={this.state.filename}
                title={this.state.title}
                dataList={this.state.dataList}
                columns={this.columns}
                expandedRowRender={this.expandedRowRender}
                getData={this.getData}
                onTableChange={this.onTableChange}
                merchant={this.props.merchant}
                newCashoutConfig={this.state.commerces[this.props.merchant.code]}
              />
            </Col>
          </Row>
        );
    }
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant ,country,comissionData } = settings;
  return { authUser, merchant,country,comissionData  };
};
export default connect(mapStateToProps)(cashoutOk);
