import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  Alert,
  Row,
  Col,
  Checkbox,
  Select,
  Badge,
  Spin,
  Collapse
} from "antd";
import Widget from "components/Widget/index";
import Api from "../../../util/api";
import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebase";
import moment from "moment-timezone";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import { AllHtmlEntities } from "html-entities";
import NumberFormat from "react-number-format";
import "./stylesCallback.css";

const FormItem = Form.Item;
const db = firebase.firestore();
const entities = new AllHtmlEntities();
const { Panel } = Collapse;
class Callback extends Component {
  state = {
    resp: "",
    Resend: false,
    deco: false,
    dataTransfer: [],
    timeZone: "America/Santiago",
    dateRequest: "",
    colorStatus: "rgb(8,5,5,0.13489145658263302)",
    status: "",
    merchant: "",
    spin: false,
    spinListAdmin: false,
    Limit: 45000,
    TransactionPendingAdmin: [],
    ErrorLimit: false,
    codigoDropdown: "",
    botonResubmit:false
  };

  componentDidMount = async e => {
    this.getLimit();
    this.getAdminApproval();
  };


  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      resp: "",
      spin: true
    });

    let codigoStatus, operationCodeEntry;

    let validateCommerce = true;
    if (this.state.dataTransfer.commerceId !== "2020juegalo-5n2q" && this.state.Resend !== true) {
      validateCommerce = await this.validateCommerceReqId(this.state.dataTransfer.commerceReqId);
    }

    let validateOperationCode = {
      operationCodeBankisValid: true,
      OperationCodeisValid: true,
      operationcode: "",
      zippyId:""

    };
    /*   if(this.state.Resend !==true && values.codigo === "0")
      {
        validateOperationCode  =await this.validateOperationCode(this.state.dataTransfer.OperationCode);
      }
    
    
    
    if(validateOperationCode)
    { */
    if (validateCommerce) {

      let validator = true;
      //   console.log(Number(this.state.dataTransfer.quantity),Number(this.state.Limit))

      if (Number(this.state.dataTransfer.quantity) >= Number(this.state.Limit)) {
        validator = false;
      }
      if (this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporteAdmin") {
        validator = true;
      }

      this.props.form.validateFields(async (err, values) => {
        let idZippy = values.idZippy;
        if (!err) {
          if (validator) {
            console.log(
              "Received values of form: ",
              values,
              "resend",
              this.state.Resend
            );


            if (this.state.Resend === true) {


              console.log("this.state.dataTransfer", this.state.dataTransfer);
              codigoStatus = this.state.dataTransfer.code;
              operationCodeEntry = this.state.dataTransfer.operationCode;

            }
            else {
              codigoStatus = values.codigo;
              operationCodeEntry = values.operationCode;
            }
            // console.log("codigoStatus",codigoStatus,"operationCodeEntry" ,operationCodeEntry,"operationCodeBankEntry" ,operationCodeBankEntry)

            if (codigoStatus.toString() === "0") {
              //ESTO SE EJECUTA EN CODIGO 0

              let operationCodeBank = "";
              let operationCode = "";

              if (this.state.Resend !== true) {

                //por el momento  identificamos si es del banco estado por el formato de lo que entra
                var countSlash = (operationCodeEntry.match(/[/]/g) || []).length;
                var countPuntos = (operationCodeEntry.match(/[:]/g) || []).length;


                //validacion de formato codigo operacion        
                if (countSlash === 2 && countPuntos === 2)//banco estado
                {
                  let code = operationCodeEntry.replace(/[\t]/g, " ");
                  try {
                    let operationData = code
                      .trimLeft()
                      .replace(/[/:.]/g, "")
                      .split(" ");

                    operationCode =
                      operationData[0].trimLeft() +
                      operationData[1].trimLeft() +
                      "-" +
                      operationData[2].trimLeft();
                    operationCodeBank = operationData[2].trimLeft();

                  } catch (error) {
                    this.setState({
                      resp: {
                        message: "Format Error in Operation"
                      }
                    });
                    return;
                  }
                }
                else {
                  if (operationCodeEntry.match(/^[a-z0-9]+$/i))//se valida que sea alfanumerico
                  {
                    operationCode = operationCodeEntry;
                    operationCodeBank = operationCodeEntry;
                  }
                  else {
                    this.setState({
                      resp: {
                        message:
                          " Format Error in Operation!"
                      }
                    });
                    return;
                  }
                }

              }

              console.log("this.state.botonResubmit",this.state.botonResubmit)
              if (this.state.Resend !== true) {
                if (this.state.botonResubmit !== true) {
                
                validateOperationCode = await this.validateOperationCode(operationCodeBank, operationCode);
                }
              }
              /* else{

                validateOperationCode.operationCodeBankisValid=true;
                validateOperationCode.OperationCodeisValid=true;
              }
 */
              if (validateOperationCode.operationCodeBankisValid && validateOperationCode.OperationCodeisValid) {

                // LLAMAR A LA API
                await Api.utils
                  .callback({
                    ID: values.idZippy,
                    code: codigoStatus,
                    operationCode: operationCode,
                    operationCodeBank: operationCodeBank,
                    user: this.props.authUser,
                    resend: this.state.Resend
                  })
                  .then(async resp => {
                    console.log("resp", resp);
                    if (resp.status === "OK" && this.state.Resend === true) {
                      this.setState({
                        resp: {
                          message:
                            " Transaction re-reported to the merchant successfully!"
                        }
                      });
                      this.saveUserCallBack(idZippy, this.props.authUser, "12/12/12");
                      this.updateQuery(idZippy);
                      await this.deleteAdminApproval(idZippy);
                      setTimeout(() => {
                        this.getAdminApproval();
                      }, 1000);
                    } else {
                      switch (resp.code.toString()) {
                        case "0":
                          this.setState({
                            resp: {
                              message:
                                " The status of the transaction is changed to Validated!"
                            }
                          });
                          this.saveUserCallBack(idZippy, this.props.authUser, "12/12/12");
                          this.updateQuery(idZippy);
                          await this.deleteAdminApproval(idZippy);
                          setTimeout(() => {
                            this.getAdminApproval();
                          }, 1000);
                          break;
                        case "9":
                          this.setState({
                            resp: {
                              message:
                                " The status of the transaction is changed to Pending!"
                            }
                          });
                          this.saveUserCallBack(idZippy, this.props.authUser, "12/12/12");
                          this.updateQuery(idZippy);
                          await this.deleteAdminApproval(idZippy);
                          setTimeout(() => {
                            this.getAdminApproval();
                          }, 1000);
                          break;
                        case "12":
                          this.setState({
                            resp: {
                              message:
                                " The status of the transaction is changed to Failed!"
                            }
                          });
                          this.saveUserCallBack(idZippy, this.props.authUser, "12/12/12");
                          this.updateQuery(idZippy);
                          await this.deleteAdminApproval(idZippy);
                          setTimeout(() => {
                            this.getAdminApproval();
                          }, 1000);
                          break;
                        default:
                          this.setState({
                            resp
                          });
                          break;
                      }
                    }
                  });

              } else {

                this.setState({
                  spin: false
                });

                if (validateOperationCode.operationCodeBankisValid === false && validateOperationCode.OperationCodeisValid === false) {

                  this.setState({
                    resp: {
                      message:
                        "Operation Code duplicate!. " + operationCode + ", Please check again that the code is correct"
                    },
                  });
                }
                else if (validateOperationCode.operationCodeBankisValid === false && validateOperationCode.OperationCodeisValid) {
                  this.setState({
                    botonResubmit:true,
                    resp: {
                      message:
                        "Operation Code Bank duplicate!. " + operationCodeBank + ", but there is an operation code with a different date than the one you are trying to enter : (" + validateOperationCode.zippyId+
                        ") if you want to validate it, press again the (Validate) button"
                    
                      }
                    
                  });
                }
              }
            } else {
              console.log("ESTO SE EJECUTA EN CODIGO 12 y 9")

              await Api.utils
                .callback({
                  ID: values.idZippy,
                  code: codigoStatus,
                  operationCode: "",
                  operationCodeBank: "",
                  user: this.props.authUser,
                  resend: this.state.Resend
                })
                .then(async resp => {
                  /*       console.log(resp);
       */
                  switch (resp.code.toString()) {
                    case "0":
                      this.setState({
                        resp: {
                          message:
                            " The status of the transaction is changed to Validated!"
                        }
                      });
                      this.updateQuery(idZippy);
                      await this.deleteAdminApproval(idZippy);
                      setTimeout(() => {
                        this.getAdminApproval();
                      }, 1000);
                      break;
                    case "9":
                      this.setState({
                        resp: {
                          message:
                            " The status of the transaction is changed to Pending!"
                        }
                      });
                      this.updateQuery(idZippy);
                      await this.deleteAdminApproval(idZippy);
                      setTimeout(() => {
                        this.getAdminApproval();
                      }, 1000);
                      break;
                    case "12":
                      this.setState({
                        resp: {
                          message:
                            " The status of the transaction is changed to Failed!"
                        }
                      });
                      this.updateQuery(idZippy);
                      await this.deleteAdminApproval(idZippy);
                      setTimeout(() => {
                        this.getAdminApproval();
                      }, 1000);
                      break;

                    default:
                      this.setState({
                        resp
                      });
                      break;
                  }
                });
            }
          } else {
            this.setState({
              spin: false
            });
            this.setState({
              resp: {
                message:
                  "You cannot Validate this Transaction. Only the Admin can do it. "
              },
              ErrorLimit: true
            });
          }
        } else {
          this.setState({
            resp: "",
            spin: false
          });
        }
      });
    } else {
      this.setState({
        spin: false
      });
      this.setState({
        resp: {
          message:
            "Commerce Request ID duplicate!. " + this.state.dataTransfer.commerceReqId
        },
        /*  ErrorLimit: true */
      });
    }

    /*   this.setState({
      spin: false
    }); */
  };

  validateCommerceReqId = async (commerceReqId) => {
    let isValid = false
    console.log("validateCommerceReqId")
    if (commerceReqId) {
      await db
        .collection("transfers")
        .where("commerceReqId", "==", commerceReqId)
        .where("code", "==", 0)
        .get()
        .then(async (snapshot) => {
          let resFirestore = snapshot.docs.map((doc) => {
            return doc.data()
          })
          if (resFirestore.length === 0) {
            isValid = true
          }
        })
        .catch((err) => {
          console.log("Error getting documents VALIDATING commerceReqId", err)
        })
    }

    return isValid
  }

  validateOperationCode = async (operationCodeBank, OperationCode) => {

    console.log("validateOperationCode",operationCodeBank,OperationCode);
    let resp = {
      operationCodeBankisValid: true,
      OperationCodeisValid: true,
      operationcode: "",
      zippyId:""
    };
    debugger;
    let resFirestore,zippyId;
    //bankOperationCode
    if (OperationCode) {
      await db
        .collection("transfers")
        .where("bankOperationCode", "==", operationCodeBank)
        .where("code", "==", 0)
        .get()
        .then(async (snapshot) => {
         /*  resFirestore = */ snapshot.docs.map((doc) => {
            console.log("data", doc.data(),doc.id);
            resFirestore=doc.data();
            zippyId=doc.id
            return doc.data()
          })
        /*   console.log("esto es  la data ", resFirestore.length, resFirestore.operationCode); */
          if (resFirestore.length === 0) {
            resp = {
              operationCodeBankisValid: true,
              OperationCodeisValid: true,
              operationcode:resFirestore.operationCode,
              zippyId:zippyId
            }
          }
          else {
            if (resFirestore.operationCode !== OperationCode) {
              resp = {
                operationCodeBankisValid: false,
                OperationCodeisValid: true,
                operationcode: resFirestore.operationCode,
                zippyId:zippyId
              }
            }
            else {
              resp = {
                operationCodeBankisValid: false,
                OperationCodeisValid: false,
                operationcode:  resFirestore.operationCode,
                zippyId:zippyId
              }
            }
          }
        })
        .catch((err) => {
          console.log("Error getting documents VALIDATING Operation Code", err)
        })
    }
    return resp
  }

  resetTransactionInfo = () => {
    this.setState({
      ErrorLimit: false,
      dataTransfer: [],
      colorStatus: "rgb(8,5,5,0.13489145658263302)",
      status: "",
      resp: "",
      merchant: "",
      spin: false,
      dateRequest: "",
      Resend: false
    });
  };

  resetAll = () => {
    this.props.form.setFieldsValue({
      ["idZippy"]: "",
      ["codigo"]: "",
      ["operationCode"]: ""
    });

    this.setState({
      resp: "",
      deco: false,
      dataTransfer: [],
      dateRequest: "",
      colorStatus: "rgb(8,5,5,0.13489145658263302)",
      status: "",
      merchant: "",
      spin: false,
      spinListAdmin: false,
      ErrorLimit: false,
      Resend: false,
      botonResubmit:false

    });
  };

  addTransactionListAdmin = () => {

    let codigoStatus, operationCodeEntry;

    if (this.state.TransactionPendingAdmin.length > 0) {
      let index = this.state.TransactionPendingAdmin.findIndex(
        transaction => transaction.id === this.state.dataTransfer.id
      );
      if (index !== -1) {
        this.setState({
          resp: {
            message: "This Transaction is already being reviewed by the Admin.."
          }
        });
        return false;
      } else {

        this.props.form.validateFields(async (err, values) => {
          if (this.state.Resend === true) {
            codigoStatus = this.state.dataTransfer.code;
            operationCodeEntry = this.state.dataTransfer.operationCode;
          }
          else {
            if (values) { codigoStatus = values.codigo; }
            if (values) { operationCodeEntry = values.operationCode; }
          }
        });
        this.saveRecommended(codigoStatus, this.state.Resend, operationCodeEntry);
        setTimeout(() => {
          this.setState({
            resp: "",
            ErrorLimit: false
          });
        }, 2000);
        this.resetAll();
      }
    } else {

      this.props.form.validateFields(async (err, values) => {
        if (this.state.Resend === true) {
          codigoStatus = this.state.dataTransfer.code;
          operationCodeEntry = this.state.dataTransfer.operationCode;
        }
        else {
          if (values) { codigoStatus = values.codigo; }
          if (values) { operationCodeEntry = values.operationCode; }
        }
      });
      this.saveRecommended(codigoStatus, this.state.Resend, operationCodeEntry);
      setTimeout(() => {
        this.setState({
          resp: "",
          ErrorLimit: false
        });
      }, 2000);
      this.resetAll();
    }
  };

  validarTransaction = () => {
    if (this.props.dataUser.typeUser === "Admin" || this.props.dataUser.typeUser === "soporteAdmin") {
      let id = this.state.TransactionPendingAdmin[0].id;
      let index = this.state.TransactionPendingAdmin.findIndex(
        transaction => transaction.id === id
      );
      if (index !== -1) this.state.TransactionPendingAdmin.splice(index, 1);
      console.log(this.state.TransactionPendingAdmin);
    } else {
      console.log("Usuario sin Autorizacion");
    }
  };

  saveRecommended = async (codigo, resend, operationCode) => {
    let _el = this;
    var transfer = db.collection("transfers");
    let updateData = {};
    if (codigo === "0" && !resend) {
      updateData = {
        adminApproval: 1,
        recommended: codigo,
        resend: resend,
        recommendedOperation: operationCode,
      };

    }
    else {
      updateData = {
        adminApproval: 1,
        recommended: codigo,
        resend: resend,
      };
    }


    await transfer
      .doc(this.state.dataTransfer.id)
      .update(
        updateData
      )
      .then(function () {
        console.log("Document successfully updated!");
        _el.getAdminApproval();
      })
      .catch(err => {
        console.log("ERROR IN saveRecommended", err);
      });
  };

  cancelTransacion = async id => {
    await this.deleteAdminApproval(id);
    setTimeout(() => {
      this.getAdminApproval();
    }, 1000);
  };

  deleteAdminApproval = id => {
    new Promise(async (resolve, reject) => {
      var transfer = db.collection("transfers");
      await transfer
        .doc(id)
        .update({
          adminApproval: 0,
          recommended: "",
          resend: false,
          recommendedOperation: ""
        })
        .then(function () {
          resolve(true);
          console.log("Document successfully updated!");
        })
        .catch(err => {
          reject(false);
          console.log("ERROR IN saveRecommended", err);
        });
    });
  };

  getAdminApproval = async () => {
    //para buscar el objeto
    this.setState({
      spinListAdmin: true
    });

    let data = [];
    var transfer = db.collection("transfers").where("adminApproval", "==", 1);

    await transfer
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          this.setState({
            TransactionPendingAdmin: [],
            spinListAdmin: false
          });
          return;
        }
        snapshot.forEach(doc => {
          data.push({
            id: doc.id,
            ...doc.data()
          });
        });
        this.setState({
          TransactionPendingAdmin: data,
          spinListAdmin: false
        });
        console.log(this.state.TransactionPendingAdmin);
      })
      .catch(err => {
        console.log("ERROR IN getLocalStore", err);
        this.setState({
          spinListAdmin: false
        });
      });
  };

  onChange = e => {
    console.log("onChange", e.target.checked);
    this.setState({ Resend: e.target.checked });
    /* if(e.target.checked)
    {
    //  this.props.form.resetFields(
     //   ["operationCode"]
     // );
      this.props.form.setFieldsValue({
        ["codigo"]: '0',
    
      });
    } */



  };

  onChangeID = e => {
    console.log("onChangeID", e);
    if (e === "0") {
      this.setState({ deco: true });
      console.log("onChangeID", e);
    } else {
      this.setState({ deco: false });
      console.log("onChangeID", "false");
    }
  };

  getDataTrx = async e => {
    this.setState({
      spin: true
    });
    let id = e.target.value;
    console.log("getDataTrx", e.target.value);
    this.updateQuery(id);
  };

  getDataTrxAdmin = async (id, recommendCode, operationCode, resend, recommendedOperation) => {
    //("getDataTrxAdmin", id, recommendCode, operationCode, resend, recommendedOperation);
    this.resetAll();

    if (Number(recommendCode) === 0) {
      this.setState({
        deco: true,
        resp: ""
      });
      if (resend) {
        this.props.form.setFieldsValue({
          ["idZippy"]: id,
          ["codigo"]: recommendCode,
          ["operationCode"]: ""
        });
        this.setState({
          Resend: resend
        });
      } else {
        this.props.form.setFieldsValue({
          ["idZippy"]: id,
          ["codigo"]: recommendCode,
          ["operationCode"]: recommendedOperation
        });

        this.setState({
          recommendedOperation
        });

        this.setState({
          Resend: false
        });
      }
    } else {
      this.setState({
        resp: "",
        Resend: false
      });
      this.props.form.setFieldsValue({
        ["idZippy"]: id,
        ["codigo"]: recommendCode
      });
    }

    this.setState({
      spin: true
    });
    this.updateQuery(id);
    return true;
  };

  transformCommerceID = commerceId => {
    let name = "";
    this.props.dataUser.merchants.filter(val => {
      if (val.code.toString() === commerceId.toString()) {
        name = val.name
      }
    })
    return name;
  };

  updateQuery = async id => {
    try {
      var transfer = db.collection("transfers");
      let data = [];
      await transfer
        .doc(id)
        .get()
        .then(snapshot => {
          if (!snapshot.empty) {
            console.log("data en la query", snapshot.data());

            let dateRequest = moment(
              snapshot.data().dateRequest.toDate()
            ).toLocaleString("es-CL", {
              timeZone: this.state.timeZone,
              timeZoneName: "short"
            });
            let format = moment(dateRequest).format("YYYY-MM-DD HH:mm:ss");
            this.setState({ dateRequest: format });

            switch (snapshot.data().code.toString()) {
              case "0":
                this.setState({
                  colorStatus: "rgb(53 138 42 / 13%)",
                  status: "OK"
                });
                break;
              case "9":
                this.setState({
                  colorStatus: "rgb(243 216 26 / 13%)",
                  status: "Pending"
                });

                break;
              case "12":
                this.setState({
                  colorStatus: "rgb(243 26 26 / 13%)",
                  status: "Failed"
                });
                break;
              default:
                this.setState({
                  colorStatus: "rgb(8,5,5,0.13489145658263302)"
                });
                break;
            }
            this.props.dataUser.merchants.filter(val => {
              if (val.code.toString() === snapshot.data().commerceId.toString()) {
                this.setState({
                  merchant: val.name
                });
              }
            })
            data.push({
              id,
              ...snapshot.data()
            });

            if (data.status === "OK") {
              this.setState({
                Resend: true
              });
            }
            this.setState({ dataTransfer: data[0], spin: false });
          } else {
            this.resetTransactionInfo();
          }
        })
        .catch(err => {
          console.log("ERROR IN updateQuery", err);
          this.resetTransactionInfo();
        });
    } catch (err) {
      console.log("Error en updateQuery :", err);
      this.resetTransactionInfo();
    }
  };


  saveUserCallBack = async (id, user, date) => {
    var myTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
    console.log("saveUserCallBack", myTimestamp);

    var transfer = db.collection("transfers");
    let updateData = {};

    updateData = {
      user,
      adminCallBackDate: myTimestamp,
    };

    await transfer
      .doc(id)
      .update(
        updateData
      )
      .then(function () {
        console.log("Document successfully updated!");

      })
      .catch(err => {
        console.log("ERROR IN saveRecommended", err);
      });
  };

  getLimit = async e => {
    await db
      .collection("parametrosGenerales")
      .doc("callback")
      .get()
      .then(doc => {
        this.setState({
          Limit: doc.data().limite
        });
      })
      .catch(err => {
        console.log("ERROR IN getLimit", err);
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const codigoDropdown = this.state.codigoDropdown;
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 12
        },
        sm: {
          span: 8
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16
        }
      }
    };

    return (
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Widget
            title={
              <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
                <i className="icon icon-mail-open gx-mr-3" />
                Callback Manual Cash In
              </h4>
            }
          >
            <hr></hr>
            <div
              class="ant-card gx-card-widget "
              style={{ background: this.state.colorStatus }}
            >
              <div class="ant-card-body">
                <span class="gx-widget-badge">
                  transaction info{" "}
                  {this.state.status === "OK" ? (
                    <Badge color="green" text={"Validated"} />
                  ) : this.state.status === "Pending" ? (
                    <Badge color="yellow" text={this.state.status} />
                  ) : (
                        <Badge color="red" text={this.state.status} />
                      )}
                </span>
                <br></br>
                <Row justify="space-between">
                  <Col lg={8} md={10} sm={24} xs={24}>
                    <h1 class="gx-fs-xxxl gx-font-weight-semi-bold gx-mb-3 gx-mb-sm-4">
                      Amount:
                    </h1>
                  </Col>
                  <Col lg={16} md={14} sm={24} xs={24}>
                    <h1 class="gx-fs-xxxl gx-font-weight-semi-bold gx-mb-3 gx-mb-sm-4">
                    {this.state.dataTransfer ? (
                    <>
                      {this.state.dataTransfer.quantity ? (
                        <NumberFormat
                          value={this.state.dataTransfer.quantity}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                        />
                      ) : null}
                    </> //this.state.dataTransfer.quantity
                  ) : (
                      null
                    )}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={24} xs={24}>
                    <b>Zippy Id: </b>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    {this.state.dataTransfer ? this.state.dataTransfer.id : ""}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>Name: </b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer
                      ? entities.decode(this.state.dataTransfer.name)
                      : ""}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>rut: </b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer.rut
                      ? formatRut(this.state.dataTransfer.rut)
                      : ""}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col lg={6} md={6} sm={24} xs={24}>
                    <b>Email:</b>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    {this.state.dataTransfer
                      ? this.state.dataTransfer.email
                      : ""}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b> Date Request:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dateRequest ? this.state.dateRequest : ""}
                  </Col>
                </Row>
                <br/>
                 <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>Merchant/Commerce:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.merchant}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>Merchant/Commerce Request ID:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                  {this.state.dataTransfer.commerceReqId}
                  </Col>
                </Row>
              
                {this.state.dataTransfer.operationCode ? (
                   <Row>
                   <Col lg={6} md={8} sm={24} xs={24}>
                     <b> Operation Code:</b>
                   </Col>
                   <Col lg={18} md={12} sm={24} xs={24}>
                     <b>{this.state.dataTransfer.operationCode}</b>
                   </Col>
                 </Row>
                ) : (
                    ""
                  )}
                <hr></hr>

                <h6 className="gx-mb-3 gx-mb-m-2 gx-font-weight-light">
                  Operation Example = 08/04/2020 00:13:27 07019313 , only Banco Estado!!!!!
                </h6>

                <h6>
                  Resend: send Validated request to the merchant ,only
                  transactions ok!
                </h6>
              </div>
            </div>

            {this.state.resp ? (
              this.state.ErrorLimit ? (
                <Row>
                  <Col lg={24} className="contenedorAlert">
                    <Alert
                      message="Result"
                      description={`${this.state.resp.message} `}
                      type="success"
                    />
                  </Col>
                </Row>
              ) : (
                  <div>
                    <Alert
                      message="Result"
                      description={`  ${this.state.resp.message}  `}
                      type="success"
                    />
                    <hr></hr>
                  </div>
                )
            ) : (
                <div>
                  {this.state.spin ? (
                    <div>
                      Loading Operation......
                      <Spin size="large" />
                    </div>
                  ) : (
                      ""
                    )}
                  <hr></hr>
                </div>
              )}
            {this.state.status === "OK" ? <Checkbox checked={this.state.Resend} onChange={this.onChange}>
              Resend
            </Checkbox> : ""}
            <Form
               {...formItemLayout}
              className="gx-signup-form gx-form-row0 gx-mb-0"
              onSubmit={this.handleSubmit}
            >
              <FormItem label="ID Zippy:">
                <div className="gx-mb-3" style={{padding: "0px auto"}}>
                  {getFieldDecorator("idZippy", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter the Trx ID!!"
                      }
                    ]
                  })(
                    <Input placeholder="ID Zippy" onChange={this.getDataTrx} />
                  )}
                </div>
              </FormItem>

              {this.state.Resend !== true || this.state.status !== "OK" ?
                <FormItem
                label={this.state.ErrorLimit ? "Update To" : "Status Code: "}
                >
                  <div className="gx-mb-3">
                    {getFieldDecorator("codigo", {
                      validateTrigger: ["onChange", "onBlur"],
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Please enter the response code!"
                        }
                      ]
                    })(
                      // <Input placeholder="Código" onChange={this.onChangeID}/>
                      <Select
                        style={{padding: "0px auto" }}
                        onChange={this.onChangeID}
                      >
                        <Option value="">Select state por trasnsaction</Option>
                        <Option value="0">Validate (0) </Option>
                        <Option value="9">Pending (9)</Option>
                        <Option value="12">Failed (12)</Option>
                      </Select>
                    )}
                  </div>

                </FormItem>
                : ""}

              {this.state.Resend !== true && this.state.status !== "OK" && this.state.deco ?
                //this.state.deco ?
                <FormItem label="Operation:">

                  <div className="gx-mb-3" style={{ padding: "0px auto" }}>
                    {getFieldDecorator("operationCode", {
                      initialValue: this.state.recommendedOperation,
                      rules: [
                        {
                          required: true,
                          message: "Please enter the Operation code!!"
                        }
                      ]
                    })(<Input placeholder="código de Operación" />)}
                  </div>
                </FormItem>
                //: ""
                : ""}
              {this.state.ErrorLimit ? (
                <Row>
                  <Col lg={12} className="centrarBtn">
                    <Button
                      type="primary"
                      onClick={this.addTransactionListAdmin}
                    >
                      {" "}
                      + admin approval
                    </Button>
                    {/*  </Col>
                  <Col lg={12} className="centrarBtn"> */}

                    <Button
                      //blacklist.jsstyle={{ width: "50%" }}
                      block
                      type="danger"
                      onClick={this.resetAll}
                    >
                      Cancel
                    </Button>
                    <hr></hr>
                  </Col>
                </Row>
              ) : (
                  <>
                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                      Validate
                  </Button>
                    <Button
                      type="primary"
                      className="gx-mb-0"
                      onClick={this.resetAll}
                    >
                      clean
                  </Button>
                  </>
                )}
            </Form>
          </Widget>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Widget
            title={
              <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
                <i className="icon icon-mail-open gx-mr-3" />
                waiting for administrator approval
              </h4>
            }
          >
            <Row>
              <Col lg={24}>
                {this.state.spinListAdmin ? (
                  <div>
                    Loading Information......
                    <Spin size="large" />
                  </div>
                ) : (
                    <>
                      {this.state.TransactionPendingAdmin.map(
                        (transaction, index) => {
                          return (
                            <Row
                              className="contenedorPendientes"
                              style={{ fontSize: 12 }}
                            >
                              <Collapse bordered={false}>
                                <Panel
                                  header={
                                    <span>
                                      {/*   <div> */}
                                      <b> ID Zippy:</b>
                                      <a
                                        onClick={() =>
                                          this.getDataTrxAdmin(
                                            transaction.id,
                                            transaction.recommended,
                                            transaction.operationCode,
                                            transaction.resend,
                                            transaction.recommendedOperation
                                          )
                                        }
                                      >
                                        {" "}
                                        {transaction.id}
                                      </a>{" "}
                                    </span>
                                  }
                                  style={{ fontSize: 12, borderBottom: 0 }}
                                >
                                  <Col lg={24}>
                                    <span>
                                      <b>Monto : </b>
                                      <NumberFormat
                                        value={transaction.quantity}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"$"}
                                      />
                                    </span>
                                  </Col>

                                  <Col lg={24}>
                                    <span>
                                      <b>Nombre: </b>
                                      {transaction.name}
                                    </span>
                                  </Col>
                                  <Col lg={24}>
                                    <span>
                                      <b>Rut:</b> {transaction.rut}
                                    </span>
                                  </Col>
                                  <Col lg={24}>
                                    <span>
                                      <b>Comercio:</b>
                                      {this.transformCommerceID(
                                        transaction.commerceId
                                      )}
                                    </span>
                                  </Col>

                                  <Col lg={24}>
                                    <span>
                                      <b> Codigo Recomendado:</b>{" "}
                                      {transaction.recommended}
                                    </span>
                                  </Col>
                                  {transaction.resend ? (
                                    <Col lg={24}>
                                      <span>
                                        <b> Resend:</b> ✈️
                                    </span>
                                    </Col>
                                  ) : null}

                                  {transaction.recommendedOperation ? (
                                    <Col lg={24}>
                                      <span>
                                        <b> Operation Recomendado:</b>{" "}
                                        {transaction.recommendedOperation}
                                      </span>
                                    </Col>
                                  ) : null}

                                  <Col lg={24}>
                                    <span>
                                      <div style={{ paddingTop: "8px" }}>
                                        <Button
                                          type="danger"
                                          onClick={() => {
                                            this.cancelTransacion(transaction.id);
                                          }}
                                        >
                                          Cancel
                                      </Button>
                                      </div>
                                    </span>
                                  </Col>
                                </Panel>
                              </Collapse>
                            </Row>
                          );
                        }
                      )}
                    </>
                  )}
              </Col>
            </Row>
          </Widget>
        </Col>
      </Row>
    );
  }
}
const CallbackForm = Form.create()(Callback);
const mapStateToProps = ({ auth, settings }) => {
const { authUser } = auth;
const { dataUser } = settings;
return { authUser, dataUser };
};
export default connect(mapStateToProps)(CallbackForm);

