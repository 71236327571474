import React from "react";
import Loader from "./loader";
import loader1 from "../../assets/images/loader.svg"




const CircularProgress = ({className}) => <div className={`loader ${className}`}>
{/*   <img src={loader1} alt="loader"/> */}
  <Loader> </Loader>

</div>;
export default CircularProgress;
