import keyGenerator from "../../../util/keyGenerator";
import config_fields from "../../../util/configFields";
export const generateObjectCommissions = commissions => {
  let newArray = [];
  commissions.forEach(commission => {
    let arrayCommissions = [];
    let newObject = {};
    newObject.key = keyGenerator();
    newObject.level = 1;
    newObject.id = commission.country;
    if (Object.keys(commission.informacionCommission).length !== 0) {
      newObject.data = generateDataInicial(commission, config_fields);
    } else {
      newObject.data = [];
    }
    if (commission.comissions.length > 0) {
      commission.comissions.forEach(c => {
        let arrayCMInterior = [];
        let objectCommission = {};
        objectCommission.key = keyGenerator();
        objectCommission.level = 2;
        objectCommission.field = "";
        objectCommission.data = [];
        objectCommission.id = c.method;
        if(c.comision === undefined){
          objectCommission.children = [];
        } else if (Object.keys(c.comision).length > 0) {
          arrayCMInterior.push(generateDataTercerNivel(c, config_fields));
          objectCommission.children = arrayCMInterior;
        } else {
          objectCommission.children = [];
        }
        arrayCommissions.push(objectCommission);
      });
      newObject.children = arrayCommissions;
    } else {
      newObject.children = [];
    }
    newArray.push(newObject);
  });
  return newArray;
};
const generateDataInicial = (commission, config_fields) => {
  let nombres = Object.keys(commission.informacionCommission);
  let arrayInterior = [];
  nombres.forEach(nombre => {
    let nameField = config_fields.commissions.fields.filter(
      field => field.field === nombre
    );
    nameField = nameField[0];
    let objectInterior = {};
    objectInterior.key = keyGenerator();
   // console.log("Llegue aqui", nameField, nombre, nombres);
    objectInterior.field = nameField.field;
    objectInterior.value = commission.informacionCommission[nombre];
    objectInterior.type = nameField.type;
    arrayInterior.push(objectInterior);
  });

  return arrayInterior;
};
const generateDataTercerNivel = (c, config_fields) => {
  let object = {};
  object.key = keyGenerator();
  object.level = 3;
  object.field = "";
  object.id = c.id;
  let nombres = Object.keys(c.comision);
  let arrayInterior = [];
  nombres.forEach(nombre => {
    let nameField = config_fields.commissions.fields.filter(
      field => field.field === nombre
    );
    nameField = nameField[0];
    let objectInterior = {};
    objectInterior.key = keyGenerator();
    //console.log("Llegue aca", nameField.field, nameField, nombres);
    objectInterior.field = nameField.field;
    if (Array.isArray(c.comision[nombre])) {
      objectInterior.value = JSON.stringify(c.comision[nombre]);
    } else {
      objectInterior.value = c.comision[nombre];
    }
    objectInterior.type = nameField.type;
    arrayInterior.push(objectInterior);
  });
  object.data = arrayInterior;
  object.children = [];
  return object;
};
