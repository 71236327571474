import React from "react";
import { Input, Modal, message, Select } from "antd";
import { storage } from "../../../firebase/firebase";
import IntlMessages from "util/IntlMessages";
import NumberFormat from "react-number-format";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";

const Option = Select.Option;

class AddInvoice extends React.Component {
  state = {
    number: "",
    rut: "",
    razonSocial: "",
    neto: "",
    iva: "",
    total: "",
    observacion: ""
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.listCommerces.length > 0) {
      nextProps.listCommerces.forEach(element => {});
      this.setState({
        listCommerces: nextProps.listCommerces.map(commerce => (
          <Option value={commerce} key={commerce}>
            {commerce}
          </Option>
        ))
      });
    }
  }

  uploadFile = async invoicePDF => {
    message.warning(`Uploading...`);
    invoicePDF = invoicePDF.currentTarget.files[0];
    const uploadTask = storage
      .ref(`invoicesZippy/${invoicePDF.name}`)
      .put(invoicePDF);
    await uploadTask.on(
      "state_changed",
      snapshot => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      error => {
        // Error function ...
        console.log(error);
        console.log("ERROR EN UPLOAD", "=>", error);
        message.error(`${invoicePDF.name} file upload failed.`);
      },
      () => {
        // complete function ...
        storage
          .ref("invoicesZippy")
          .child(invoicePDF.name)
          .getDownloadURL()
          .then(url => {
            this.setState({
              url
            });
            message.success(`${invoicePDF.name} file uploaded successfully.`);
            console.log("url", "=>", this.state.url);
          });
      }
    );
  };

  render() {
    return (
      <Modal
        title={<IntlMessages id="invoice.addInvoice" />}
        toggle={this.props.onAddClose}
        visible={this.props.open}
        closable={false}
        onOk={this.props.onAddSave.bind(this, this.state)}
        onCancel={this.props.onAddClose}
      >
        <div className="gx-modal-box-row">
          <div className="gx-modal-box-form-item">
            <div className="gx-form-group">
              <Input
                required
                placeholder="Number"
                onChange={event =>
                  this.setState({ number: event.target.value })
                }
                margin="none"
                value={this.state.number}
              />
            </div>

            <div className="gx-form-group">
              <Select
                // className="gx-mr-3 gx-mb-3"
                style={{ width: "100%" }}
                placeholder="Select a Commerce"
                onChange={event => this.setState({ commerce: event })}
              >
                {this.state.listCommerces}
              </Select>
            </div>
            <div className="gx-form-group">
              <Input
                placeholder="Rut"
                onChange={event =>
                  this.setState({ rut: formatRut(event.target.value) })
                }
                margin="normal"
                value={this.state.rut}
              />
            </div>
            <div className="gx-form-group">
              <NumberFormat
                placeholder="Neto"
                onValueChange={values => {
                  const { value } = values;
                  // formattedValue = $2,223
                  // value ie, 2223
                  this.setState({
                    neto: value,
                    iva: (value * 0.19).toFixed(0)
                  });
                }}
                customInput={Input}
                // value={}
                displayType={"input"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
              />
            </div>
            <div className="gx-form-group">
              <NumberFormat
                disabled
                placeholder="IVA"
                // onChange={event => this.setState({ iva: event.target.value })}
                customInput={Input}
                value={this.state.iva}
                displayType={"input"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
              />
            </div>
            <div className="gx-form-group">
              <Input
                type="file"
                placeholder="Factura PDF"
                onChange={this.uploadFile}
                margin="normal"
              />
            </div>
            <div className="gx-form-group">
              <Input
                placeholder="Descripción"
                onChange={event =>
                  this.setState({ description: event.target.value })
                }
                autosize={{ minRows: 2, maxRows: 6 }}
                margin="normal"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddInvoice;
