import React, { useEffect, useState } from "react";
import { Collapse, notification } from "antd";
import moment from "moment";
import socketIOClient from "socket.io-client";
import LogsPayrollDetail from "./logs-payroll-detail";
import api from "../../../../util/api";

const { Panel } = Collapse;

let host = process.env.REACT_APP_DASHBOARD_API_URL;

const socket = socketIOClient(host);

const collapseOpenDefault = {
  9: "bot_authorization",
  7: "bot_validation",
  1: "bot_load",
  0: ""
};

const CollapseLogsPayroll = ({ data, payroll_id, loading }) => {
  const [socketSteps, setSocketSteps] = useState({});
  const [activeKey, setActiveKey] = useState(null);

  const fetchSteps = async () => {
    await api.utils
      .getSteps({
        payroll_id: payroll_id
      })
      .then(response => {
        setSocketSteps(response.data.steps);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const openNotification = msg => {
    notification.open({
      message: `New bot log`,
      placement: "topRight",
      description: `[${moment(msg.createdAt).format(
        "DD MMM YYYY HH:mm:ss"
      )}] - ${msg.step_description}`
    });
  };

  useEffect(() => {
    socket.on("steps", msg => {
      if (msg.step && msg.step.payroll_id.includes(payroll_id)) {
        // onFetchSocket();
        openNotification(msg.step);
        fetchSteps();
      }
    });

    return () => {
      socket.off();
    };
  }, [socketSteps]);

  useEffect(() => {
    setSocketSteps(data.steps);
    setActiveKey(collapseOpenDefault[data.status]);
  }, [data]);

  return (
    <div>
      <h3>Payroll status bot logs</h3>
      <Collapse
        activeKey={activeKey}
        expandIconPosition={"right"}
        onChange={item => setActiveKey(item)}
      >
        <Panel header="Load" key="bot_load">
          <LogsPayrollDetail
            steps={(socketSteps && socketSteps.bot_load) || []}
            fetching={loading}
            payroll_id={payroll_id}
          />
        </Panel>
        <Panel header="Authorization" key="bot_authorization">
          <LogsPayrollDetail
            steps={(socketSteps && socketSteps.bot_authorization) || []}
            fetching={loading}
            payroll_id={payroll_id}
          />
        </Panel>
        <Panel header="Validation" key="bot_validation">
          <LogsPayrollDetail
            steps={(socketSteps && socketSteps.bot_validation) || []}
            fetching={loading}
            payroll_id={payroll_id}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default CollapseLogsPayroll;
