import React from "react";

export const ExpandedRowRender = ({ record }) => {
  return (
    <div style={{ fontSize: "13px" }}>
      <p style={{ margin: 0 }}>
        RUT: <strong>{record.rut}</strong>
      </p>
      <p style={{ margin: 0 }}>
        Bank: <strong>{record.bank}</strong>
      </p>
      <p style={{ margin: 0 }}>
        Account Type: <strong>{record.typeAccountId}</strong>
      </p>
      <p style={{ margin: 0 }}>
        Account Number: <strong>{record.numAccount}</strong>
      </p>
      {/* <p style={{ margin: 0 }}>
        Email: <strong>{record.email}</strong>
      </p> */}
    </div>
  );
};
