import React, { Component } from "react";
import { Col, Row, notification, Button } from "antd";
import Auxiliary from "util/Auxiliary";
import { connect } from "react-redux";
import WelComeCard from "components/dashboard/CRM/WelComeCard";
import SiteVisit from "components/dashboard/CRM/SiteVisit";
import GrowthCard from "components/dashboard/CRM/GrowthCard";
import GrowthCardAgregator from "components/dashboard/CRM/GrowthCardAgregator";
import GrowthCardSkinBacks from "components/dashboard/CRM/GrowthCardSkinBacks";

import GrowthCardBill from "components/dashboard/CRM/GrowthCardBill";
import TaskByStatus from "components/dashboard/CRM/TaskByStatus";
import Balances from "components/dashboard/CRM/Balances";
import Comisiones from "components/dashboard/Crypto/Portfolio";
import Counter from "components/dashboard/Crypto/OrderHistory";
/* import TotalEnCuenta from "components/dashboard/CRM/TotalRevenueCard"; */
import PendingMerchants from "components/dashboard/CRM/PendingMerchants";
import PowerProducts from "components/dashboard/CRM/PowerProducts";
import { firebase } from "../../../firebase/firebase";
import _ from "lodash";
import NumberFormat from "react-number-format";
import searchCashoutForId from "../admin/searchCashoutForId";
import moment from "moment-timezone";
/* import { utils } from "redux-saga"; */
import Api from "../../../util/api";
import MerchantForId from "../admin/merchantForId";
import SemaphoneProducts from "../../../components/dashboard/CRM/SemaphoneProducts";
/* import Search from "antd/lib/input/Search"; */

const db = firebase.firestore();

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productMerchant: "",
      loadingInfo: false,
      updateTablePending: false,
      retention: 0,
      timeZone: 'America/Santiago',
      earlyPayments: 0,
      indicadores: {
        dolar: {
          valor: ""
        }
      },
      date: "",
      dataCashin: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0
      },
      dataWebpay: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        credito: {
          sum: 0,
          count: 0,
          commission: 0,
          ivaCommission: 0
        },
        debito: { sum: 0, count: 0, commission: 0, ivaCommission: 0 },
        oneclick: { sum: 0, count: 0, commission: 0, ivaCommission: 0 },
        factura: { commission: 0, ivaCommission: 0 }
      },
      dataWebpayC: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0
      },
      dataWebpayD: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0
      },
      dataCashout: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0
      },

      dataPayku: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },
      dataBankCard: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },
      dataBankTransfer: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },
      dataPayOut: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },
      dataCash: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },
      dataSkinsBacks: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },

      dataKushkiWebPay: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },

      dataKushkiBankTransfer: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },

      dataPagsmileBankTransfer: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },

      dataAirtm: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },

      useBankCard: false,
      useBankTransfer: false,
      useCash: false,
      useCashin: false,
      useCashout: false,
      usePayku: false,
      useWebpay: false,
      usePayOut: false,
      useSkinsBacks: false,
      useKushiki: false,
      useAirtm: false,
      usePagsmile:false,


      BalanceMnt: {
        data: [],
        status:""
      },

      BalanceAtm: {
        data: [],
        status:""
      },

      LoadingBalanceMnt: true,
      LoadingBalanceAtm: true
    };
  }

  componentDidMount = async () => {
    let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
    let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();

    await db
      .collection("parametrosGenerales")
      .doc("indicadores")
      .get()
      .then(doc => {
        this.setState({
          indicadores: { dolar: { valor: doc.data().USD_CLP } }
        });
      })
      .catch(console.log);

    // LLAMO LOS ESTADOS DE LOS PRODUCTOS
    await this.getStatePowerProduct()

    // LLAMO LOS DATOS PARA SER MOSTRADO EN LOS WIDGETS
    this.getAllData(firstDay, lastDay);
    this.updateDateTime();
    //TRAE BALANCES ACTUALIZADOS DE MONNET Y ATM
    if(process.env.REACT_APP_ENVIRONMENT == 'prod') this.getBalances();
  };

  async componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {

      // LLAMO LOS ESTADOS DE LOS PRODUCTOS
      await this.getStatePowerProduct()

      let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
      let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();
      if (
        this.props.merchant !== prevProps.merchant ||
        this.props.country !== prevProps.country
      ) {
        /*  console.log("componentDidUpdate"); */
        const merchant = localStorage.getItem("merchant_code");
        const country = localStorage.getItem("country_code");
        console.log(merchant, country);
        this.resetAllData();
        this.getAllData(firstDay, lastDay);
        this.updateDateTime();
      }
    }
  }

  getBalanceMnt = async () => {
    this.setState({ LoadingBalanceMnt: true });
    let allInfoBalanceMnt = await Api.balances.getBalanceMnt();
    if(!allInfoBalanceMnt.data.cl){
      allInfoBalanceMnt.data.cl = 0
    }
    this.setState({ LoadingBalanceMnt: false, BalanceMnt: allInfoBalanceMnt });

    return allInfoBalanceMnt;
  }

  getBalanceAtm = async () => {
    this.setState({ LoadingBalanceAtm: true });
    let allInfoBalanceAtm = await Api.balances.getBalanceAtm();
    this.setState({ LoadingBalanceAtm: false, BalanceAtm: allInfoBalanceAtm });

    return allInfoBalanceAtm;
  }


  getBalances = async () => {
    try {
      await this.getBalanceMnt();
      await this.getBalanceAtm();

      console.log("toda la data de getBalances", "====>>>", "allInfoBalanceMnt", this.state.BalanceMnt, "====>>>", "allInfoBalanceAtm", this.state.BalanceAtm);
      // if(this.state.BalanceMnt.data.cl[0]){
      //  let a=this.state.BalanceMnt.data.cl[0]
      //  console.log("BalanceMnt", "====>>>", a.clp);
      // }
    
    

    } catch (error) {
      console.log("error in getBalances", "====>>>", error);
    }
  };

  updateData = () => {
    let firstDay = moment
      .tz(this.state.timeZone)
      .startOf("month")
      .toDate();
    let lastDay = moment
      .tz(this.state.timeZone)
      .endOf("month")
      .toDate();
    this.getAllData(firstDay, lastDay);
    this.updateDateTime();
    // this.getBalances();

  };

  getStatePowerProduct = async () => {

    let useCashin = false
    let useCashout = false
    let usePayku = false
    let useWebpay = false
    let useBankCard = false
    let useBankTransfer = false
    let useCash = false
    let usePayOut = false
    let useSkinsBacks = false
    let useKushiki = true
    let useAirtm = false
    if (this.props.country.code === "CL") {


      let product = [];
      console.log("merchantContr", this.props.merchantContr)
      if (this.props.merchantContr) {

        product = this.props.merchantContr.filter(e => e.merchant === this.props.merchant.code.trim());
        /*  console.log("product[0]", product) */
        /*   this.setState({ productMerchant: product[0] }) */
        //console.log("product[0]", product[0].useCashin)
        useCashin = true// product[0].useCashin
        useCashout = product[0].useCashout
        usePayku = product[0].usePayku
        useWebpay = product[0].useWebpay
        /*   useBankCard = false
          useBankTransfer = false
          useCash = false */
        /*   console.log("powerSkinsBacks", this.state.powerSkinsBacks, useSkinsBacks) */
      }
    }

    let paymentsMethod = [];
    let payments = [];
    let payouts = [];



    let commissionesBd = this.props.comissionData
      ? this.props.comissionData
      : JSON.parse(localStorage.getItem("comisiones"));

    if (commissionesBd) {
      console.log("commissionesBd", commissionesBd);
      paymentsMethod = commissionesBd.filter(e => e.merchant === this.props.merchant.code);
      console.log("paymentsMethod", paymentsMethod);
      if (paymentsMethod.length > 0) {
        payments = paymentsMethod[0].payments.filter(e => e.country === this.props.country.code).map(e => {
          console.log("e", e);
          useBankCard = e.methodUse.filter(e => e == "bankCard")
          if (useBankCard.length != 0) { useBankCard = true } else { useBankCard = false }
          useBankTransfer = e.methodUse.filter(e => e == "bankTransfer")
          console.log("useBankTransfer", useBankTransfer);
          if (useBankTransfer.length != 0) { useBankTransfer = true } else { useBankTransfer = false }
          console.log("useBankTransfer", useBankTransfer);
          useCash = e.methodUse.filter(e => e == "cash")
          if (useCash.length != 0) { useCash = true } else { useCash = false }

          useSkinsBacks = e.methodUse.filter(e => e == "skin")
          if (useSkinsBacks.length != 0) { useSkinsBacks = true } else { useSkinsBacks = false }
          console.log("skin", useSkinsBacks);
          /*  this.setState({ useBankCard, useBankTransfer, useCash, useCashin: useCashin, useCashout, usePayku, useWebpay }) */

          useAirtm = e.methodUse.filter(e => e == "airtm")
          if (useAirtm.length != 0) { useAirtm = true } else { useAirtm = false }
          console.log("useAirtm", useAirtm);
        })
        payouts = paymentsMethod[0].payouts.filter(e => e.country === this.props.country.code)
        console.log("payouts", payouts);
        if (!payouts) { usePayOut = false }
        else {
          payouts.map(e => {
            usePayOut = true
          })
        }
        this.setState({ usePayOut })
      }
    }

    this.setState({ useBankCard, useBankTransfer, useCash, useCashin: useCashin, useCashout, usePayku, useWebpay, usePayOut, useSkinsBacks, useKushiki, useAirtm })
    console.log("use", "index", "useBankCard", useBankCard, "useBankTransfer", useBankTransfer, "useCash", useCash, "useCashin", useCashin, "usePayOut", usePayOut, "useCashout", useCashout, "usePayku", usePayku, "useWebpay", useWebpay, "useSkinsBacks", useSkinsBacks, "useKushiki", useKushiki, "useAirtm", useAirtm);

  };
  async getAllData(firstDay, lastDay) {
    // CALCULO EL MES ACTUAL
    moment.tz.setDefault("America/Santiago");
    let mes = moment.tz("America/Santiago").format("MMMM");
    this.setState({
      mes: mes,
      loadingInfo: true
    });
    // const code = merchant;
    // LLAMO LOS DATOS PARA SER MOSTRADO EN LOS WIDGETS
    let commissionesBd = this.props.comissionData
      ? this.props.comissionData
      : JSON.parse(localStorage.getItem("comisiones"));
    commissionesBd = commissionesBd.filter(
      item => item.merchant === this.props.merchant.code
    );
    /*  commissionesBd = commissionesBd[0].commission.filter(
       item => item.countrie === this.props.country.code
     ); */
    console.log("commissionesBd", commissionesBd);

    let payload = {
      merchant: this.props.merchant.code,
      firstDay: firstDay,
      lastDay: lastDay,
      comission: commissionesBd,
      country: this.props.country.code,
      code: 0
    };

    console.log("LLAMANDO A getInfoDashboard", payload);
    let allInfo = await Api.utils.getInfoDashboard(payload);

    console.log("toda la data", "====>>>", allInfo);

    console.log('allInfo.retention', allInfo.retention);
    /*  if(this.props.country.code==="CL")
     { */
    this.setState({
      dataCashin: allInfo.cashin ? allInfo.cashin : this.state.dataCashin,
      dataWebpay: allInfo.webpay ? allInfo.webpay : this.state.dataWebpay,
      dataCashout: allInfo.cashout ? allInfo.cashout : this.state.dataCashout,
      dataPayku: allInfo.payku ? allInfo.payku : this.state.dataPayku,
      retention: allInfo.retention,// ? allInfo.retention.retention : this.state.retention,
      earlyPayments: allInfo.earlyPayments,//? allInfo.earlyPayments.earlyPayments : this.state.earlyPayments,
      dataBankCard: allInfo.bankcard ? allInfo.bankcard : this.state.dataBankCard,
      dataBankTransfer: allInfo.banktransfer ? allInfo.banktransfer : this.state.dataBankTransfer,
      dataPayOut: allInfo.payout ? allInfo.payout : this.state.dataPayOut,
      dataCash: allInfo.cash ? allInfo.cash : this.state.dataCash,
      dataSkinsBacks: allInfo.skinbacks ? allInfo.skinbacks : this.state.dataSkinsBacks,
      dataKushkiWebPay: allInfo.kushkiWebPay ? allInfo.kushkiWebPay : this.state.dataKushkiWebPay,
      dataKushkiBankTransfer: allInfo.kushkiBankTransfer ? allInfo.kushkiBankTransfer : this.state.dataKushkiBankTransfer,
      dataPagsmileBankTransfer: allInfo.pagsmileBankTransfer ? allInfo.pagsmileBankTransfer : this.state.dataPagsmileBankTransfer,
      dataAirtm: allInfo.airtm ? allInfo.airtm : this.state.dataAirtm,
      loadingInfo: false,
      currency: allInfo.currency,
      usdCountry: allInfo.usdCountry,
      usdClp: allInfo.usdClp
    });

    /*   console.log("toda la data", "====>>>", this.state.retention, this.state.earlyPayments); */
    this.updateTotal();
    this.updateDepVsWith();
    this.updateCommissions();
    this.gettingPendFail();
  }


  resetAllData() {
    this.setState({
      /*     productMerchant: "",
          loadingInfo: false,
          updateTablePending: false, */
      retention: 0,
      // earlyPayments: 0,
      indicadores: {
        dolar: {
          valor: ""
        }
      },
      date: "",
      dataCashin: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0
      },
      dataWebpay: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        credito: {
          sum: 0,
          count: 0,
          commission: 0,
          ivaCommission: 0
        },
        debito: { sum: 0, count: 0, commission: 0, ivaCommission: 0 },
        oneclick: { sum: 0, count: 0, commission: 0, ivaCommission: 0 },
        factura: { commission: 0, ivaCommission: 0 }
      },
      dataWebpayC: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0
      },
      dataWebpayD: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0
      },
      dataCashout: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0
      },

      dataPayku: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },
      dataBankCard: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },
      dataBankTransfer: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },
      dataPayOut: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },
      dataCash: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },
      dataSkinsBacks: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },

      dataKushkiWebPay: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },

      dataKushkiBankTransfer: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },

      dataAirtm: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        factura: { commission: 0, ivaCommission: 0 }
      },

      /*     useBankCard: false,
          useBankTransfer: false,
          useCash: false,
          useCashin: false,
          useCashout: false,
          usePayku: false,
          useWebpay: false,
          usePayOut: false,
          useSkinsBacks: false, */

    })

  }

  async gettingPendFail() {
    this.setState({

      cashinPendientes: 0,
      cashinFailed: 0,
      webpayPending: 0,
      webpayFailed: 0,
      cashoutPendientes: 0,
      cashoutFailed: 0,
      paykuPendientes: 0,
      paykuFailed: 0,
      //agregator
      bankCardPendientes: 0,
      bankCardFail: 0,
      bankTransferPendientes: 0,
      bankTransferFail: 0,
      cashPendientes: 0,
      cashFail: 0,
      payOutPendientes: 0,
      payOutFail: 0,

      skinBacksPendientes: 0,
      skinBacksFail: 0,


      kushkiWebpayPendientes: 0,
      kushkiWebpayFail: 0,
      kushkiBankTransferPendientes: 0,
      kushkiBankTransferFail: 0,

      airtmPendientes: 0,
      airtmFail: 0,
    });
    console.log("gettingPendFail");
    try {
      let payload = { merchant: this.props.merchant.code, country: this.props.country.code }

      let allInfo = await Api.utils.gettingPendFail(payload);
      console.log("toda la data pendientes y fallidos ", "====>>>", allInfo);


      this.setState({
        cashinPendientes: allInfo.cashinPendientes,
        cashinFailed: allInfo.cashinFailed,
        webpayPending: allInfo.webpayPending,
        webpayFailed: allInfo.webpayFailed,
        cashoutPendientes: allInfo.cashoutPendientes,
        cashoutFailed: allInfo.cashoutFailed,
        paykuPendientes: allInfo.paykuPendientes,
        paykuFailed: allInfo.paykuFailed,
        //agregator
        bankCardPendientes: allInfo.bankCardPendientes,
        bankCardFail: allInfo.bankCardFail,
        bankTransferPendientes: allInfo.bankTransferPendientes,
        bankTransferFail: allInfo.bankTransferFail,
        cashPendientes: allInfo.cashPendientes,
        cashFail: allInfo.cashFail,
        payOutPendientes: allInfo.payOutPendientes,
        payOutFail: allInfo.payOutFail,

        skinBacksPendientes: allInfo.skinBacksPendientes,
        skinBacksFail: allInfo.skinBacksFail,

        kushkiWebpayPendientes: allInfo.kushkiWebpayPendientes,
        kushkiWebpayFail: allInfo.kushkiWebpayFail,
        kushkiBankTransferPendientes: allInfo.kushkiBankTransferPendientes,
        kushkiBankTransferFail: allInfo.kushkiBankTransferFail,

        airtmPendientes: allInfo.airtmPendientes,
        airtmFail: allInfo.airtmFail
      });
    }
    catch (error) {
      console.log("error in gettingPendFail", error);
    }
  }
  //este metodo ya no se ocupa
  updateCommissions() {
    // DEBERIA EXISTIR OTRO ENDPOINT QUE CALCULE LAS COMISIONES DEL MERCHANT PAIS Y RESUMENES POR MERCHANT Y POR PAIS.

    this.setState({
      totalNeto:
        this.state.dataCashin.commission +
        this.state.dataCashout.commission +
        this.state.dataPayku.commission +
        this.state.dataWebpay.commission +

        this.state.dataBankCard.commission +
        this.state.dataBankTransfer.commission +
        this.state.dataCash.commission +
        this.state.dataPayOut.commission +

        this.state.dataSkinsBacks.commission +
        this.state.dataKushkiWebPay.commission +
        this.state.dataAirtm.commission,
      totalIva:
        this.state.dataCashin.ivaCommission +
        this.state.dataCashout.ivaCommission +
        this.state.dataWebpay.ivaCommission +
        this.state.dataPayku.ivaCommission +
        this.state.dataBankCard.ivaCommission +
        this.state.dataBankTransfer.ivaCommission +
        this.state.dataCash.ivaCommission +
        this.state.dataPayOut.ivaCommission +
        this.state.dataSkinsBacks.ivaCommission +
        this.state.dataKushkiWebPay.ivaCommission +
        this.state.dataAirtm.ivaCommission,
    });
  }

  updateTotal() {
    this.setState({
      totalDepositos:
        Number(this.state.dataCashin.sum) +
        Number(this.state.dataWebpay.sum) +
        Number(this.state.dataPayku.sum) +
        //new
        Number(this.state.dataBankCard.sum) +
        Number(this.state.dataBankTransfer.sum) +
        Number(this.state.dataCash.sum) +
        Number(this.state.dataSkinsBacks.sum) +
        Number(this.state.dataKushkiWebPay.sum) +
        Number(this.state.dataAirtm.sum)
      ,
      numeroOperaciones:
        Number(this.state.dataCashin.count) +
        Number(this.state.dataWebpay.count) +
        Number(this.state.dataPayku.count) +
        Number(this.state.dataCashout.count) +
        //new
        Number(this.state.dataBankCard.count) +
        Number(this.state.dataBankTransfer.count) +
        Number(this.state.dataCash.count) +
        Number(this.state.dataPayOut.count) +
        Number(this.state.dataSkinsBacks.count) +
        Number(this.state.dataKushkiWebPay.count) +
        Number(this.state.dataAirtm.count)
      ,
      totalWithdrawals:
        //new
        Number(this.state.dataPayOut.sum) +
        Number(this.state.dataCashout.sum)
    });
  }

  updateDepVsWith() {
    let mergedDep = _.unionWith(
      this.state.dataCashin.data,
      this.state.dataWebpay.data,
      this.state.dataPayku.data,
      this.state.dataBankCard.data,
      this.state.dataBankTransfer.data,
      this.state.dataCash.data,
      this.state.dataSkinsBacks.data,
      this.state.dataKushkiWebPay.data,
 /*      this.state.dataKushkiBankTransfer.data, */
      this.state.dataAirtm.data,
    );


    let mergedWith = _.unionWith(
      this.state.dataCashout.data,
      this.state.dataPayOut.data
    );

    /*    console.log("this.state.dataCashin.data",this.state.dataCashin.data); */
    // OBTENGO DATA AGRUPADA POR FECHA
    let dataDep = [];
    dataDep = _(mergedDep)
      .groupBy("date")
      .map((objs, key) => {
        return {
          date: key,
          Deposits: _.sumBy(objs, item => Number(item.value))
        };
      })
      .value();

    let dataWith = _(mergedWith)
      .groupBy("date")
      .map((objs, key) => {
        return {
          date: key,
          Withdrawals: _.sumBy(objs, item => Number(item.value))
        };
      })
      .value();

    let dataDepVsWith = _.unionWith(dataDep, dataWith);

    //SOLUCION 1 DE STACKOVERFLOW (GRACIAS a NICK PARSONS)
    // dataDepVsWith = Object.values(
    //   dataDepVsWith.reduce((acc, { date, ...rest }) => {
    //     acc[date] = { ...(acc[date] || { date }), ...rest };
    //     return acc;
    //   }, {})
    // );

    //SOLUCION 2 DE STACKOVERFLOW (GRACIAS A INDIANCODING)
    dataDepVsWith = _.map(_.groupBy(dataDepVsWith, "date"), value =>
      _.assign(...value)
    );

    dataDepVsWith.sort(function compare(a, b) {
      var dateA = Number(
        moment(a.date, "DD/MM")
          .tz("America/Santiago")
          .toDate()
      );
      var dateB = Number(
        moment(b.date, "DD/MM")
          .tz("America/Santiago")
          .toDate()
      );
      // console.log("dateA", "=>", dateA);
      // console.log("dateB", "=>", dateB);
      return dateA - dateB;
    });

    /*     console.log("dataDepVsWith",dataDepVsWith); */
    this.setState({
      dataDepVsWith: dataDepVsWith
    });
  }

  openNotification = (name, quantity, method) => {
    notification["success"]({
      message: (
        <div>
          Nuevo Deposito de <b>{name}</b>
        </div>
      ),
      description: (
        <div>
          Por una suma de{" "}
          <span className="gx-chart-up">
            <NumberFormat
              value={quantity}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
            />
          </span>{" "}
          mediante <b>{method}</b>{" "}
        </div>
      ),

      duration: 0
    });
  };

  totalInvoice = () => {

    let dataCashin =
      this.state.dataCashin.commission + this.state.dataCashin.ivaCommission;
    let dataCashout =
      this.state.dataCashout.commission + this.state.dataCashout.ivaCommission;
    let dataWebpay =
      this.state.dataWebpay.factura.commission +
      this.state.dataWebpay.factura.ivaCommission;

    let dataPayku =
      this.state.dataPayku.factura.commission +
      this.state.dataPayku.factura.ivaCommission;

    let dataBankTransfer =
      this.state.dataBankTransfer.factura.commission +
      this.state.dataBankTransfer.factura.ivaCommission;

    let dataBankCard =
      this.state.dataBankCard.factura.commission +
      this.state.dataBankCard.factura.ivaCommission;

    let dataCash =
      this.state.dataCash.factura.commission +
      this.state.dataCash.factura.ivaCommission;
    let dataPayOut =
      this.state.dataPayOut.factura.commission +
      this.state.dataPayOut.factura.ivaCommission;

    let dataSkinsBacks =
      this.state.dataSkinsBacks.factura.commission +
      this.state.dataSkinsBacks.factura.ivaCommission;

    let dataKushkiBankTransfer =
      this.state.dataKushkiBankTransfer.factura.commission +
      this.state.dataKushkiBankTransfer.factura.ivaCommission;

    let dataKushkiWebPay =
      this.state.dataKushkiWebPay.factura.commission +
      this.state.dataKushkiWebPay.factura.ivaCommission;



    let dataAirtm =
      this.state.dataAirtm.factura.commission +
      this.state.dataAirtm.factura.ivaCommission;

    let totalInvoice =
      dataCashin + dataCashout + dataWebpay + dataPayku + dataBankTransfer + dataBankCard + dataCash + dataPayOut + dataSkinsBacks + dataKushkiWebPay + dataAirtm;
    //  console.log("totalInvoice dataCashin + dataCashout + dataWebpay + dataPayku + dataBankTransfer", dataBankTransfer, "dataBankCard + dataCash + dataPayOut;", dataCashin, dataCashout, dataWebpay, dataPayku, dataBankTransfer, dataBankCard, dataCash, dataPayOut + dataKushkiWebPay + dataKushkiBankTransfer+dataAirtm);


/*

    console.log("dataCashin", this.state.dataCashin.sum);
    console.log("dataWebpay", this.state.dataWebpay.sum);
    console.log("dataPayku", this.state.dataPayku.sum);
    console.log("dataBankTransfer", this.state.dataBankTransfer.sum);
    console.log("dataBankCard", this.state.dataBankCard.sum);
    console.log("dataSkinsBacks", this.state.dataSkinsBacks.sum);
    console.log("dataKushkiWebPay", this.state.dataKushkiWebPay.sum);
    console.log("dataKushkiBankTransfer", this.state.dataKushkiWebPay.sum);
    console.log("dataAirtm", this.state.dataAirtm.sum);
    console.log("dataCash", this.state.dataCash.sum);
    console.log("retention", this.state.retention);
    console.log("dataPayOut", this.state.dataPayOut.sum);
    console.log("earlyPayments", this.state.earlyPayments);
    console.log("totalInvoice", totalInvoice); */


    let suma = Math.round(
      Number(this.state.dataCashin.sum) +
      Number(this.state.dataWebpay.sum) +
      Number(this.state.dataPayku.sum) +
      Number(this.state.dataBankTransfer.sum) +
      Number(this.state.dataBankCard.sum) +
      Number(this.state.dataSkinsBacks.sum) +
      Number(this.state.dataKushkiWebPay.sum) +
      Number(this.state.dataAirtm.sum) +

      Number(this.state.dataCash.sum) +
      Number(this.state.retention) -
      Number(this.state.dataCashout.sum) -
      Number(this.state.dataPayOut.sum) -
      Number(this.state.earlyPayments) -

      Math.round(totalInvoice)
    )

   // console.log("suma", suma)

    if (this.props.country.code === "CL") {
      return Math.round(totalInvoice);
    }
    return totalInvoice;
  };



  updateDateTime = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    var hh = today.getHours();
    var min = today.getMinutes();
    var seg = today.getSeconds();
    this.setState({ updateTablePending: hh + ":" + min + ":" + seg });
  };

  render() {
    let comisionesWidget;
    let pendingWidget;
    let totalInvoiceWidget;
    let welcome;
    let widgetCashin;
    let widgetWebpay;
    let widgetPayku;
    let widgetCashout;
    let widgetBankCard;
    let widgetBankTransfer;
    let widgetPayOut;
    let widgetCash;
    let widgetBalance;
    let orderHistory;
    let powerProductsWidgtet;
    let semaphoneProducts;
    let searchId;
    let searchCashout;
    let widgetSkinBacks;
    let widgetKushkiBankTransfer;
    let widgetKushkiBankCard;
    let widgetAirtm;
    let widgetBalancesExn;

    comisionesWidget = (
      <Col xl={12} lg={24} md={12} sm={24} xs={24}>
        <Comisiones
          totalNeto={this.state.totalNeto}
          totalIva={this.state.totalIva}
          cashin={this.state.dataCashin.commission}
          ivaCashin={this.state.dataCashin.ivaCommission}
          cashout={this.state.dataCashout.commission}
          ivaCashout={this.state.dataCashout.ivaCommission}
          webpayC={this.state.dataWebpay.credito.commission}
          ivaWebpayC={this.state.dataWebpay.credito.ivaCommission}
          webpayD={this.state.dataWebpay.debito.commission}
          ivaWebpayD={this.state.dataWebpay.debito.ivaCommission}
          webpayO={this.state.dataWebpay.oneclick.commission}
          ivaWebpayO={this.state.dataWebpay.oneclick.ivaCommission}

          ivaPayku={this.state.dataPayku.ivaCommission}
          payku={this.state.dataPayku.commission}
          bankcard={this.state.dataBankCard.commission}
          ivaBankcard={this.state.dataBankCard.ivaCommission}
          banktransfer={this.state.dataBankTransfer.commission}
          ivaBanktransfer={this.state.dataBankTransfer.ivaCommission}
          cash={this.state.dataCash.commission}
          ivaCash={this.state.dataCash.ivaCommission}
          payout={this.state.dataPayOut.commission}
          ivaPayout={this.state.dataPayOut.ivaCommission}
          skinsbacks={this.state.dataSkinsBacks.commission}
          ivaSkinsbacks={this.state.dataSkinsBacks.ivaCommission}


          KushkiWebPay={this.state.dataKushkiWebPay.commission}
          ivaKushkiWebPay={this.state.dataKushkiWebPay.ivaCommission}
          /*
                    KushikiBankTransfer={this.state.dataKushkiBankTransfer.commission}
                    ivaKushikiBankTransfer={this.state.dataKushkiBankTransfer.ivaCommission} */

          airtm={this.state.dataAirtm.commission}
          ivaairtm={this.state.dataAirtm.ivaCommission}

          useBankCard={this.state.useBankCard}
          useBankTransfer={this.state.useBankTransfer}
          useCash={this.state.useCash}
          useCashin={this.state.useCashin}
          useCashout={this.state.useCashout}
          usePayku={this.state.usePayku}
          useWebpay={this.state.useWebpay}
          usePayOut={this.state.usePayOut}
          useSkinsBacks={this.state.useSkinsBacks}
          country={this.props.country.code}
          useKuishki={this.state.useKushiki}
          useAirtm={this.state.useAirtm}


        />
      </Col>
    );
    pendingWidget = (
      <Col xl={12} lg={24} md={12} sm={24} xs={24}>
        <>
          <PendingMerchants
            total={
              this.state.cashinPendientes +
              this.state.webpayPending +
              this.state.cashoutPendientes +
              this.state.paykuPendientes
            }
          />
        </>
      </Col>
    );

    /*powerProductsWidgtet = (
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <>
          <PowerProducts></PowerProducts>
        </>
      </Col>
    );*/
    semaphoneProducts = (
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <>
          <SemaphoneProducts></SemaphoneProducts>
        </>
      </Col>
    );

    totalInvoiceWidget = (
      <Col xl={8} lg={12} md={8} sm={24} xs={24}>
        {this.props.dataUser.typeUser === "merchant" ? (
          <GrowthCardBill
            title="Total invoice"
            month={this.state.mes}
            //suma={this.state.dataCashout.sum}
            suma={this.totalInvoice()}
            avg={parseFloat(this.state.dataCashout.avg).toFixed(2)}
            dailyAvg={parseFloat(this.state.dataCashout.dailyAvg).toFixed(0)}
            cashin={
              this.state.dataCashin.commission +
              this.state.dataCashin.ivaCommission
            }
            cashout={
              this.state.dataCashout.commission +
              this.state.dataCashout.ivaCommission
            }
            webpay={
              this.state.dataWebpay.factura.commission +
              this.state.dataWebpay.factura.ivaCommission +
              this.state.dataPayku.factura.commission +
              this.state.dataPayku.factura.ivaCommission +
              this.state.dataKushkiWebPay.factura.commission +
              this.state.dataKushkiWebPay.factura.ivaCommission
            }

            payku={
              0
            }
            typeUser={this.props.dataUser.typeUser}

            /* bankcard={parseFloat(this.state.dataBankCard.factura.commission).toFixed(2)  +parseFloat(this.state.dataBankCard.factura.ivaCommission).toFixed(2) } */
            bankcard={this.state.dataBankCard.factura.commission + this.state.dataBankCard.factura.ivaCommission}
            /*    banktransfer={parseFloat(this.state.dataBankTransfer.factura.commission).toFixed(2)  +parseFloat(this.state.dataBankTransfer.factura.ivaCommission).toFixed(2) } */
            banktransfer={this.state.dataBankTransfer.factura.commission + this.state.dataBankTransfer.factura.ivaCommission}
            /*   cash={parseFloat(this.state.dataCash.factura.commission).toFixed(2)  + parseFloat(this.state.dataCash.factura.ivaCommission).toFixed(2) }
     */
            cash={this.state.dataCash.factura.commission + this.state.dataCash.factura.ivaCommission}
            payout={this.state.dataPayOut.factura.commission + this.state.dataPayOut.factura.ivaCommission}
            skinsbacks={this.state.dataSkinsBacks.factura.commission + this.state.dataSkinsBacks.factura.ivaCommission}
            /*   payout={parseFloat(this.state.dataPayOut.factura.commission).toFixed(2) +parseFloat(this.state.dataPayOut.factura.ivaCommission).toFixed(2) } */
            kushkiWebpay={0}
            //kushkiBankTarnsfer={this.state.dataKushkiBankTransfer.factura.commission + this.state.dataKushkiBankTransfer.factura.ivaCommission}
            airtm={this.state.dataAirtm.factura.commission + this.state.dataAirtm.factura.ivaCommission}
            countryCode={this.props.country.code}
          />
        ) : (
          <GrowthCardBill
            title="Total invoice"
            month={this.state.mes}
            //suma={this.state.dataCashout.sum}
            suma={this.totalInvoice()}
            avg={parseFloat(this.state.dataCashout.avg).toFixed(2)}
            dailyAvg={parseFloat(this.state.dataCashout.dailyAvg).toFixed(0)}
            cashin={
              this.state.dataCashin.commission +
              this.state.dataCashin.ivaCommission
            }
            cashout={
              this.state.dataCashout.commission +
              this.state.dataCashout.ivaCommission
            }
            webpay={
              this.state.dataWebpay.factura.commission +
              this.state.dataWebpay.factura.ivaCommission
            }

            payku={
              this.state.dataPayku.factura.commission +
              this.state.dataPayku.factura.ivaCommission
            }
            typeUser={this.props.dataUser.typeUser}

            /* bankcard={parseFloat(this.state.dataBankCard.factura.commission).toFixed(2)  +parseFloat(this.state.dataBankCard.factura.ivaCommission).toFixed(2) } */
            bankcard={this.state.dataBankCard.factura.commission + this.state.dataBankCard.factura.ivaCommission}
            /*    banktransfer={parseFloat(this.state.dataBankTransfer.factura.commission).toFixed(2)  +parseFloat(this.state.dataBankTransfer.factura.ivaCommission).toFixed(2) } */
            banktransfer={this.state.dataBankTransfer.factura.commission + this.state.dataBankTransfer.factura.ivaCommission}
            /*   cash={parseFloat(this.state.dataCash.factura.commission).toFixed(2)  + parseFloat(this.state.dataCash.factura.ivaCommission).toFixed(2) }
     */
            cash={this.state.dataCash.factura.commission + this.state.dataCash.factura.ivaCommission}
            payout={this.state.dataPayOut.factura.commission + this.state.dataPayOut.factura.ivaCommission}
            skinsbacks={this.state.dataSkinsBacks.factura.commission + this.state.dataSkinsBacks.factura.ivaCommission}
            /*   payout={parseFloat(this.state.dataPayOut.factura.commission).toFixed(2) +parseFloat(this.state.dataPayOut.factura.ivaCommission).toFixed(2) } */
            kushkiWebpay={this.state.dataKushkiWebPay.factura.commission + this.state.dataKushkiWebPay.factura.ivaCommission}
            //kushkiBankTarnsfer={this.state.dataKushkiBankTransfer.factura.commission + this.state.dataKushkiBankTransfer.factura.ivaCommission}
            airtm={this.state.dataAirtm.factura.commission + this.state.dataAirtm.factura.ivaCommission}
            countryCode={this.props.country.code}
          />
        )}
      </Col>
    );
    welcome = (
      <Col span={24}>
        <div className="gx-card">
          <div className="gx-card-body">
            <Row>
              <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                <WelComeCard
                  dolar={
                    parseFloat(this.state.indicadores.dolar.valor).toFixed(2) ||
                    "N/D"
                  }
                 /*  currency="CLP" */
                  name={this.props.country.name}
                  country={this.props.country.code}
                  deposits={this.state.totalDepositos}
                  Withdrawals={this.state.totalWithdrawals}
                  pending={this.state.cashinPendientes}
                  pendingCashout={this.state.cashoutPendientes}
                  currency={this.state.currency}
                  usdCountry={this.state.usdCountry}
                  usdClp={this.state.usdClp}
                  countryCode={this.props.country.code}
                />
              </Col>
              <Col
                xl={12}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className="gx-visit-col"
              >
                <SiteVisit
                  siteVisit={_.takeRight(this.state.dataDepVsWith, 7)}
                />
              </Col>
              <Col
                xl={6}
                lg={12}
                md={12}
                sm={12}
                xs={24}
                className="gx-audi-col"
              >
                <TaskByStatus
                  total={this.state.numeroOperaciones}
                  numCashin={this.state.dataCashin.count}
                  numWebpay={
                    this.state.dataWebpay.count +
                    this.state.dataPayku.count +
                    this.state.dataKushkiWebPay.count
                  }
                  numCashout={this.state.dataCashout.count}
                  numBankTransfer={this.state.dataBankTransfer.count}
                  numBankCard={this.state.dataBankCard.count}
                  numBankCash={this.state.dataCash.count}
                  numBankPayout={this.state.dataPayOut.count}
                  numSkinBacks={this.state.dataSkinsBacks.count}
                  numAirtm={this.state.dataAirtm.count}
                /*    numSkinBacks={this.state.dataSkinsBacks.count} */
                />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    );
    widgetCashin = (
      <Col xl={8} lg={24} md={8} sm={24} xs={24}>
        <GrowthCard
          trafficData={_.takeRight(this.state.dataCashin.data, 5)}
          title="Cash In"
          month={this.state.mes}
          suma={this.state.dataCashin.sum}
          avg={parseFloat(this.state.dataCashin.avg).toFixed(0)}
          dailyAvg={parseFloat(this.state.dataCashin.dailyAvg).toFixed(0)}
          countryCode={this.props.country.code}
        />
      </Col>
    );
    widgetBankCard = (
      <Col xl={8} lg={24} md={8} sm={24} xs={24}>
        <GrowthCardAgregator
          trafficData={this.state.dataBankCard.data === [] ? 0 : _.takeRight(this.state.dataBankCard.data, 5)}
          title="Bank Card"
          month={this.state.mes}
          suma={this.state.dataBankCard.sum}
          avg={parseFloat(this.state.dataBankCard.avg).toFixed(0)}
          dailyAvg={parseFloat(this.state.dataBankCard.dailyAvg).toFixed(0)}
          monnetsuma={this.state.dataBankCard.monnet ? this.state.dataBankCard.monnet.sum : 0}
          monnetavg={this.state.dataBankCard.monnet ? parseFloat(this.state.dataBankCard.monnet.avg).toFixed(0) : 0}
          monnetdailyAvg={this.state.dataBankCard.monnet ? parseFloat(this.state.dataBankCard.monnet.dailyAvg).toFixed(0) : 0}
          alpssuma={this.state.dataBankCard.alps ? this.state.dataBankCard.alps.sum : 0}
          alpsavg={this.state.dataBankCard.alps ? parseFloat(this.state.dataBankCard.alps.avg).toFixed(0) : 0}
          alpsdailyAvg={this.state.dataBankCard.alps ? parseFloat(this.state.dataBankCard.alps.dailyAvg).toFixed(0) : 0}
          safetypaysuma={this.state.dataBankCard.safetypay ? this.state.dataBankCard.safetypay.sum : 0}
          safetypayavg={this.state.dataBankCard.safetypay ? parseFloat(this.state.dataBankCard.safetypay.avg).toFixed(0) : 0}
          safetypaydailyAvg={this.state.dataBankCard.safetypay ? parseFloat(this.state.dataBankCard.safetypay.dailyAvg).toFixed(0) : 0}
          typeUser={this.props.dataUser.typeUser}
          countryCode={this.props.country.code}

          kushkisuma={this.state.dataBankCard.kushki ? this.state.dataBankCard.kushki.sum : 0}
          kushkiavg={this.state.dataBankCard.kushki ? parseFloat(this.state.dataBankCard.kushki.avg).toFixed(0) : 0}
          kushkidailyAvg={this.state.dataBankCard.kushki ? parseFloat(this.state.dataBankCard.kushki.dailyAvg).toFixed(0) : 0}

          pagsmilesuma={this.state.dataBankCard.pagsmile ? this.state.dataBankCard.pagsmile.sum : 0}
          pagsmileavg={this.state.dataBankCard.pagsmile ? parseFloat(this.state.dataBankCard.pagsmile.avg).toFixed(0) : 0}
          pagsmiledailyAvg={this.state.dataBankCard.pagsmile ? parseFloat(this.state.dataBankCard.pagsmile.dailyAvg).toFixed(0) : 0}


        />
      </Col>
    );
    widgetBankTransfer = (
      <Col xl={8} lg={24} md={8} sm={24} xs={24}>
        {this.props.dataUser.typeUser === "merchant" ? (
          <GrowthCardAgregator
            trafficData={this.state.dataBankTransfer.data === [] ? 0 : _.takeRight(this.state.dataBankTransfer.data, 5)}
            title="Bank Transfer"
            month={this.state.mes}
            suma={this.state.dataBankTransfer.sum}
            avg={parseFloat(this.state.dataBankTransfer.avg + this.state.dataCashin.avg).toFixed(0)}
            dailyAvg={parseFloat(this.state.dataBankTransfer.dailyAvg + this.state.dataCashin.dailyAvg).toFixed(0)}

            monnetsuma={this.state.dataBankTransfer.monnet ? this.state.dataBankTransfer.monnet.sum : 0}
            monnetavg={this.state.dataBankTransfer.monnet ? parseFloat(this.state.dataBankTransfer.monnet.avg).toFixed(0) : 0}
            monnetdailyAvg={this.state.dataBankTransfer.monnet ? parseFloat(this.state.dataBankTransfer.monnet.dailyAvg).toFixed(0) : 0}

            alpssuma={this.state.dataBankTransfer.alps ? this.state.dataBankTransfer.alps.sum : 0}
            alpsavg={this.state.dataBankTransfer.alps ? parseFloat(this.state.dataBankTransfer.alps.avg).toFixed(0) : 0}
            alpsdailyAvg={this.state.dataBankTransfer.alps ? parseFloat(this.state.dataBankTransfer.alps.dailyAvg).toFixed(0) : 0}

            safetypaysuma={this.state.dataBankTransfer.safetypay ? this.state.dataBankTransfer.safetypay.sum : 0}
            safetypayavg={this.state.dataBankTransfer.safetypay ? parseFloat(this.state.dataBankTransfer.safetypay.avg).toFixed(0) : 0}
            safetypaydailyAvg={this.state.dataBankTransfer.safetypay ? parseFloat(this.state.dataBankTransfer.safetypay.dailyAvg).toFixed(0) : 0}

            pagsmilesuma={this.state.dataBankTransfer.pagsmile ? this.state.dataBankTransfer.pagsmile.sum : 0}
            pagsmileavg={this.state.dataBankTransfer.pagsmile ? parseFloat(this.state.dataBankTransfer.pagsmile.avg).toFixed(0) : 0}
            pagsmiledailyAvg={this.state.dataBankTransfer.pagsmile ? parseFloat(this.state.dataBankTransfer.pagsmile.dailyAvg).toFixed(0) : 0}

            typeUser={this.props.dataUser.typeUser}
            countryCode={this.props.country.code}
          />
        ) : (
          <GrowthCardAgregator
            trafficData={this.state.dataBankTransfer.data === [] ? 0 : _.takeRight(this.state.dataBankTransfer.data, 5)}
            title="Bank Transfer"
            month={this.state.mes}
            suma={this.state.dataBankTransfer.sum}
            avg={parseFloat(this.state.dataBankTransfer.avg).toFixed(0)}
            dailyAvg={parseFloat(this.state.dataBankTransfer.dailyAvg).toFixed(0)}
            monnetsuma={this.state.dataBankTransfer.monnet ? this.state.dataBankTransfer.monnet.sum : 0}
            monnetavg={this.state.dataBankTransfer.monnet ? parseFloat(this.state.dataBankTransfer.monnet.avg).toFixed(0) : 0}
            monnetdailyAvg={this.state.dataBankTransfer.monnet ? parseFloat(this.state.dataBankTransfer.monnet.dailyAvg).toFixed(0) : 0}
            alpssuma={this.state.dataBankTransfer.alps ? this.state.dataBankTransfer.alps.sum : 0}
            alpsavg={this.state.dataBankTransfer.alps ? parseFloat(this.state.dataBankTransfer.alps.avg).toFixed(0) : 0}
            alpsdailyAvg={this.state.dataBankTransfer.alps ? parseFloat(this.state.dataBankTransfer.alps.dailyAvg).toFixed(0) : 0}
            safetypaysuma={this.state.dataBankTransfer.safetypay ? this.state.dataBankTransfer.safetypay.sum : 0}
            safetypayavg={this.state.dataBankTransfer.safetypay ? parseFloat(this.state.dataBankTransfer.safetypay.avg).toFixed(0) : 0}
            safetypaydailyAvg={this.state.dataBankTransfer.safetypay ? parseFloat(this.state.dataBankTransfer.safetypay.dailyAvg).toFixed(0) : 0}



            kushkisuma={this.state.dataBankTransfer.kushki ? this.state.dataBankTransfer.kushki.sum : 0}
            kushkiavg={this.state.dataBankTransfer.kushki ? parseFloat(this.state.dataBankTransfer.kushki.avg).toFixed(0) : 0}
            kushkidailyAvg={this.state.dataBankTransfer.kushki ? parseFloat(this.state.dataBankTransfer.kushki.dailyAvg).toFixed(0) : 0}

            pagoEfectivosuma={this.state.dataBankTransfer.pagoEfectivo ? this.state.dataBankTransfer.pagoEfectivo.sum : 0}
            pagoEfectivoavg={this.state.dataBankTransfer.pagoEfectivo ? parseFloat(this.state.dataBankTransfer.pagoEfectivo.avg).toFixed(0) : 0}
            pagoEfectivodailyAvg={this.state.dataBankTransfer.pagoEfectivo ? parseFloat(this.state.dataBankTransfer.pagoEfectivo.dailyAvg).toFixed(0) : 0}

            pagsmilesuma={this.state.dataBankTransfer.pagsmile ? this.state.dataBankTransfer.pagsmile.sum : 0}
            pagsmileavg={this.state.dataBankTransfer.pagsmile ? parseFloat(this.state.dataBankTransfer.pagsmile.avg).toFixed(0) : 0}
            pagsmiledailyAvg={this.state.dataBankTransfer.pagsmile ? parseFloat(this.state.dataBankTransfer.pagsmile.dailyAvg).toFixed(0) : 0}



            typeUser={this.props.dataUser.typeUser}
            countryCode={this.props.country.code}
          />
        )}
      </Col>
    );
    widgetPayOut = (
      <Col xl={8} lg={24} md={8} sm={24} xs={24}>
        <GrowthCardAgregator
          trafficData={this.state.dataPayOut.data === [] ? 0 : _.takeRight(this.state.dataPayOut.data, 5)}
          title="Pay Out"
          month={this.state.mes}
          suma={this.state.dataPayOut.sum}
          avg={parseFloat(this.state.dataPayOut.avg).toFixed(0)}
          dailyAvg={parseFloat(this.state.dataPayOut.dailyAvg).toFixed(0)}

          monnetsuma={this.state.dataPayOut.monnet ? this.state.dataPayOut.monnet.sum : 0}
          monnetavg={this.state.dataPayOut.monnet ? parseFloat(this.state.dataPayOut.monnet.avg).toFixed(0) : 0}
          monnetdailyAvg={this.state.dataPayOut.monnet ? parseFloat(this.state.dataPayOut.monnet.dailyAvg).toFixed(0) : 0}
          alpssuma={this.state.dataPayOut.alps ? this.state.dataPayOut.alps.sum : 0}
          alpsavg={this.state.dataPayOut.alps ? parseFloat(this.state.dataPayOut.alps.avg).toFixed(0) : 0}
          alpsdailyAvg={this.state.dataPayOut.alps ? parseFloat(this.state.dataPayOut.alps.dailyAvg).toFixed(0) : 0}
          safetypaysuma={this.state.dataPayOut.safetypay ? this.state.dataPayOut.safetypay.sum : 0}
          safetypayavg={this.state.dataPayOut.safetypay ? parseFloat(this.state.dataPayOut.safetypay.avg).toFixed(0) : 0}
          safetypaydailyAvg={this.state.dataPayOut.safetypay ? parseFloat(this.state.dataPayOut.safetypay.dailyAvg).toFixed(0) : 0}
          pagsmilesuma={this.state.dataPayOut.pagsmile ? this.state.dataPayOut.pagsmile.sum : 0}
          pagsmileavg={this.state.dataPayOut.pagsmile ? parseFloat(this.state.dataPayOut.pagsmile.avg).toFixed(0) : 0}
          pagsmiledailyAvg={this.state.dataPayOut.pagsmile ? parseFloat(this.state.dataPayOut.pagsmile.dailyAvg).toFixed(0) : 0}
          typeUser={this.props.dataUser.typeUser}
          countryCode={this.props.country.code}
        />
      </Col>
    );
    widgetCash = (
      <Col xl={8} lg={24} md={8} sm={24} xs={24}>
        <GrowthCardAgregator
          trafficData={this.state.dataCash.data === [] ? 0 : _.takeRight(this.state.dataCash.data, 5)}
          title="Cash"
          month={this.state.mes}
          suma={this.state.dataCash.sum}
          avg={parseFloat(this.state.dataCash.avg).toFixed(0)}
          dailyAvg={parseFloat(this.state.dataCash.dailyAvg).toFixed(0)}
          monnetsuma={this.state.dataCash.monnet ? this.state.dataCash.monnet.sum : 0}
          monnetavg={this.state.dataCash.monnet ? parseFloat(this.state.dataCash.monnet.avg).toFixed(0) : 0}
          monnetdailyAvg={this.state.dataCash.monnet ? parseFloat(this.state.dataCash.monnet.dailyAvg).toFixed(0) : 0}
          alpssuma={this.state.dataCash.alps ? this.state.dataCash.alps.sum : 0}
          alpsavg={this.state.dataCash.alps ? parseFloat(this.state.dataCash.alps.avg).toFixed(0) : 0}
          alpsdailyAvg={this.state.dataCash.alps ? parseFloat(this.state.dataCash.alps.dailyAvg).toFixed(0) : 0}
          safetypaysuma={this.state.dataCash.safetypay ? this.state.dataCash.safetypay.sum : 0}
          safetypayavg={this.state.dataCash.safetypay ? parseFloat(this.state.dataCash.safetypay.avg).toFixed(0) : 0}
          safetypaydailyAvg={this.state.dataCash.safetypay ? parseFloat(this.state.dataCash.safetypay.dailyAvg).toFixed(0) : 0}
          pagsmilesuma={this.state.dataCash.pagsmile ? this.state.dataCash.pagsmile.sum : 0}
          pagsmileavg={this.state.dataCash.pagsmile ? parseFloat(this.state.dataCash.pagsmile.avg).toFixed(0) : 0}
          pagsmiledailyAvg={this.state.dataCash.pagsmile ? parseFloat(this.state.dataCash.pagsmile.dailyAvg).toFixed(0) : 0}
          typeUser={this.props.dataUser.typeUser}
          countryCode={this.props.country.code}
        />
      </Col>
    );



    widgetSkinBacks = (
      <Col xl={8} lg={24} md={8} sm={24} xs={24}>
        <GrowthCardSkinBacks
          trafficData={this.state.dataSkinsBacks.data === [] ? 0 : _.takeRight(this.state.dataSkinsBacks.data, 5)}
          title="Skins Backs"
          month={this.state.mes}
          suma={this.state.dataSkinsBacks.sum}
          avg={parseFloat(this.state.dataSkinsBacks.avg).toFixed(0)}
          dailyAvg={parseFloat(this.state.dataSkinsBacks.dailyAvg).toFixed(0)}
          typeUser={this.props.dataUser.typeUser}
          countryCode={this.props.country.code}

        />
      </Col>
    );

    widgetAirtm = (
      <Col xl={8} lg={24} md={8} sm={24} xs={24}>
        <GrowthCardSkinBacks
          trafficData={this.state.dataAirtm.data === [] ? 0 : _.takeRight(this.state.dataAirtm.data, 5)}
          title="Airtm"
          month={this.state.mes}
          suma={this.state.dataAirtm.sum}
          avg={parseFloat(this.state.dataAirtm.avg).toFixed(0)}
          dailyAvg={parseFloat(this.state.dataAirtm.dailyAvg).toFixed(0)}
          typeUser={this.props.dataUser.typeUser}
          countryCode={this.props.country.code}

        />
      </Col>
    );
    widgetKushkiBankCard = (
      <Col xl={8} lg={12} md={8} sm={24} xs={24}>
        {/*     {this.state.dataKushkiWebPay .data.length !=0? ( */}
        <GrowthCard
          trafficData={_.takeRight(this.state.dataKushkiWebPay.data, 5)}
          title="Kushki WP"
          month={this.state.mes}
          suma={this.state.dataKushkiWebPay.sum}
          avg={parseFloat(this.state.dataKushkiWebPay.avg).toFixed(0)}
          dailyAvg={parseFloat(this.state.dataKushkiWebPay.dailyAvg).toFixed(0)}
          countryCode={this.props.country.code}
        />
        {/*   ):("")} */}
      </Col>
    );

    widgetKushkiBankTransfer = (
      <Col xl={8} lg={12} md={8} sm={24} xs={24}>
        {/*   {this.state.dataKushkiBankTransfer.data.length !=0 ? ( */}
        <GrowthCard
          trafficData={_.takeRight(this.state.dataKushkiBankTransfer.data, 5)}
          title="Kushki BankTransfer"
          month={this.state.mes}
          suma={this.state.dataKushkiBankTransfer.sum}
          avg={parseFloat(this.state.dataKushkiBankTransfer.avg).toFixed(0)}
          dailyAvg={parseFloat(this.state.dataKushkiBankTransfer.dailyAvg).toFixed(0)}
          countryCode={this.props.country.code}
        />
        {/* ):("")} */}
      </Col>
    );

    widgetWebpay = (
      <Col xl={8} lg={12} md={8} sm={24} xs={24}>
        {this.props.dataUser.typeUser === "merchant" ? (
          <GrowthCard
            trafficData={_.takeRight(this.state.dataWebpay.data, 5)}
            title="Webpay"
            month={this.state.mes}
            suma={
              this.state.dataWebpay.sum +

              this.state.dataPayku.sum +
              this.state.dataKushkiWebPay.sum
            }
            avg={parseFloat(
              /*  this.state.dataWebpay.avg +
  */
              this.state.dataPayku.avg +
              this.state.dataKushkiWebPay.avg
            ).toFixed(0)}
            dailyAvg={parseFloat(
              /*     this.state.dataWebpay.dailyAvg + */

              this.state.dataPayku.dailyAvg +
              this.state.dataKushkiWebPay.dailyAvg
            ).toFixed(0)}
            countryCode={this.props.country.code}
          />
        ) : (
          <GrowthCard
            trafficData={_.takeRight(this.state.dataWebpay.data, 5)}
            title="Webpay"
            month={this.state.mes}
            suma={this.state.dataWebpay.sum}
            avg={parseFloat(this.state.dataWebpay.avg).toFixed(0)}
            dailyAvg={parseFloat(this.state.dataWebpay.dailyAvg).toFixed(0)}
            countryCode={this.props.country.code}
          />
        )}
      </Col>
    );

    widgetPayku = (
      <Col xl={8} lg={12} md={8} sm={24} xs={24}>
        <GrowthCard
          trafficData={_.takeRight(this.state.dataPayku.data, 5)}
          title="Payku WP"
          month={this.state.mes}
          suma={this.state.dataPayku.sum}
          avg={parseFloat(this.state.dataPayku.avg).toFixed(0)}
          dailyAvg={parseFloat(this.state.dataPayku.dailyAvg).toFixed(0)}
          countryCode={this.props.country.code}
        />
      </Col>
    );
    widgetBalance = (
      <Col xl={8} lg={12} md={12} sm={24} xs={24}>

        {this.props.dataUser.typeUser === "merchant" ? (

          <GrowthCard
            title="Balance"
            month={this.state.mes}
            suma={Math.round(
              Number(this.state.dataCashin.sum) +
              Number(this.state.dataWebpay.sum) +
              Number(this.state.dataPayku.sum) +
              Number(this.state.dataBankTransfer.sum) +
              Number(this.state.dataBankCard.sum) +
              Number(this.state.dataSkinsBacks.sum) +
              Number(this.state.dataCash.sum) +
              Number(this.state.dataKushkiWebPay.sum) +
              Number(this.state.dataAirtm.sum) +
              Number(this.state.retention) -
              Number(this.state.dataCashout.sum) -
              Number(this.state.dataPayOut.sum) -
              Number(this.state.earlyPayments) -

              Math.round(Number(this.totalInvoice()))
            )
            }
            avg={parseFloat(this.state.dataCashout.avg).toFixed(0)}
            dailyAvg={parseFloat(this.state.dataCashout.dailyAvg).toFixed(0)}
            /*  cashinAmount={this.state.dataCashin.sum} */
            webpayAmount={this.state.dataWebpay.sum + this.state.dataPayku.sum + this.state.dataKushkiWebPay.sum}
            lastMonthAmount={this.state.retention}
            earlyPayments={this.state.earlyPayments}
            cashOutAmount={this.state.dataCashout.sum}
            invoiceAmount={this.totalInvoice()}

            banktransferAmount={this.state.dataBankTransfer.sum + this.state.dataCashin.sum}
            bankCardAmount={this.state.dataBankCard.sum}
            cashAmount={this.state.dataCash.sum}
            payOutAmount={this.state.dataPayOut.sum}
            countryCode={this.props.country.code}
            skinsAmount={this.state.dataSkinsBacks.sum}
            airtAmount={this.state.dataAirtm.sum}
          />
        ) : (
          <GrowthCard
            title="Balance"
            month={this.state.mes}
            suma={/* Math.round( */
              Number(this.state.dataCashin.sum) +
              Number(this.state.dataWebpay.sum) +
              Number(this.state.dataPayku.sum) +
              Number(this.state.dataBankTransfer.sum) +
              Number(this.state.dataBankCard.sum) +
              Number(this.state.dataSkinsBacks.sum) +
              Number(this.state.dataCash.sum) +
              Number(this.state.dataKushkiWebPay.sum) +
              Number(this.state.dataAirtm.sum) +
              Number(this.state.retention) -
              Number(this.state.dataCashout.sum) -
              Number(this.state.dataPayOut.sum) -
              Number(this.state.earlyPayments) -
              Math.round(Number(this.totalInvoice()))
              /*  ) */
            }
            avg={parseFloat(this.state.dataCashout.avg).toFixed(0)}
            dailyAvg={parseFloat(this.state.dataCashout.dailyAvg).toFixed(0)}
            cashinAmount={this.state.dataCashin.sum}
            webpayAmount={this.state.dataWebpay.sum}

            paykuAmount={this.state.dataPayku.sum}
            lastMonthAmount={this.state.retention}
            earlyPayments={this.state.earlyPayments}
            cashOutAmount={this.state.dataCashout.sum}
            invoiceAmount={this.totalInvoice()}
            banktransferAmount={this.state.dataBankTransfer.sum}
            bankCardAmount={this.state.dataBankCard.sum}
            cashAmount={this.state.dataCash.sum}
            payOutAmount={this.state.dataPayOut.sum}
            countryCode={this.props.country.code}
            skinsAmount={this.state.dataSkinsBacks.sum}
        /*     kushkiBankTarnsferAmount={this.state.dataKushkiBankTransfer.sum} */
            kushkiWebpayAmount={this.state.dataKushkiWebPay.sum}
            airtAmount={this.state.dataAirtm.sum}
          />)}

      </Col>
    );
    widgetCashout = (
      <Col xl={8} lg={12} md={8} sm={24} xs={24}>
        <GrowthCard
          trafficData={_.takeRight(this.state.dataCashout.data, 5)}
          title="Cash out"
          month={this.state.mes}
          suma={this.state.dataCashout.sum}
          avg={parseFloat(this.state.dataCashout.avg).toFixed(0)}
          dailyAvg={parseFloat(this.state.dataCashout.dailyAvg).toFixed(0)}
          countryCode={this.props.country.code}

        />
      </Col>
    );

    widgetBalancesExn = (
      <Col xl={8} lg={12} md={8} sm={24} xs={24}>
         {this.props.dataUser.typeUser !== "merchant" ? (
        <Balances
        BalanceMntCl={this.state.BalanceMnt.data.cl?this.state.BalanceMnt.data.cl:0}
        BalanceMntPE={this.state.BalanceMnt.data.pe?this.state.BalanceMnt.data.pe:0}
        BalanceMntEC={this.state.BalanceMnt.data.ec?this.state.BalanceMnt.data.ec:0}
       /*  BalanceMntCl={this.state.BalanceMnt}
        BalanceMntCl={this.state.BalanceMnt} */
        BalanceAtm={this.state.BalanceAtm.data}
        LoadingBalanceMnt={this.state.LoadingBalanceMnt}
        LoadingBalanceAtm={this.state.LoadingBalanceAtm}
        title={"Monnet"}
        />):""}
      </Col>
    );

    orderHistory = (
      <Col xl={9} lg={24} md={16} sm={24} xs={24}>

        {this.props.dataUser.typeUser === "merchant" ? (

          <Counter
            /*   name1="CashIn"
              cashinVal={this.state.dataCashin.count}
              cashinPen={this.state.cashinPendientes}
              cashinFail={this.state.cashinFailed} */
            name2="WebPay"
            webpayVal={
              this.state.dataWebpay.count +
              this.state.dataPayku.count +
              this.state.dataKushkiWebPay.count
            }

            /*  webpayVal={
              0
             }
    */

            webpayPen={
              this.state.webpayPending +
              this.state.paykuPendientes +
              this.state.kushkiWebpayPendientes
            }
            webpayFail={
              this.state.webpayFailed +
              this.state.paykuFailed
              + this.state.kushkiWebpayFail
            }
            name3="CashOut"
            cashoutVal={this.state.dataCashout.count}
            cashoutPen={this.state.cashoutPendientes}
            cashoutFail={this.state.cashoutFailed}



            name4="BankCard"
            bankcardVal={this.state.dataBankCard.count}
            bankcardPen={this.state.bankCardPendientes}
            bankcardFail={this.state.bankCardFail}
            name5="BankTransfer"
            banktransferVal={this.state.dataBankTransfer.count + this.state.dataCashin.count}
            banktransferPen={this.state.bankTransferPendientes + this.state.kushkiBankTransferPendientes + this.state.cashinPendientes}
            banktransferFail={this.state.bankTransferFail + this.state.kushkiBankTransferFail + this.state.cashinFailed}
            name6="Cash"
            cashVal={this.state.dataCash.count}
            cashPen={this.state.cashPendientes}
            cashFail={this.state.cashFail}
            name7="PayOut"
            payoutVal={this.state.dataPayOut.count}
            payoutPen={this.state.payOutPendientes}
            payoutFail={this.state.payOutFail}


            name8="SkinsBacks"
            skinBacksVal={this.state.dataSkinsBacks.count}
            skinBacksPen={this.state.skinBacksPendientes}
            skinBacksFail={this.state.skinBacksFail}

            name9="Airtm"
            airtmVal={this.state.dataAirtm.count}
            airtmPen={this.state.airtmPendientes}
            airtmFail={this.state.airtmFail}


            merchantContr={this.props.merchantContr}
            merchant={this.props.merchant}
            typeUser={this.props.dataUser.typeUser}
            country={this.props.country}
            comissionData={this.props.comissionData}

            useBankCard={this.state.useBankCard}
            useBankTransfer={this.state.useBankTransfer}
            useCash={this.state.useCash}
            useCashin={this.state.useCashin}
            useCashout={this.state.useCashout}
            usePayku={this.state.usePayku}
            useWebpay={this.state.useWebpay}
            usePayOut={this.state.usePayOut}
            useSkinsBacks={this.state.useSkinsBacks}
            useKushki={false}
            useAirmt={true}
          />) : (<Counter
            name1="CashIn"
            cashinVal={this.state.dataCashin.count}
            cashinPen={this.state.cashinPendientes}
            cashinFail={this.state.cashinFailed}
            name2="Payku"
            webpayVal={
              this.state.dataWebpay.count +

              this.state.dataPayku.count
            }

            webpayPen={
              this.state.webpayPending +

              this.state.paykuPendientes
            }
            webpayFail={
              this.state.webpayFailed +

              this.state.paykuFailed
            }
            name3="CashOut"
            cashoutVal={this.state.dataCashout.count}
            cashoutPen={this.state.cashoutPendientes}
            cashoutFail={this.state.cashoutFailed}
            name4="BankCard"
            bankcardVal={this.state.dataBankCard.count}
            bankcardPen={this.state.bankCardPendientes}
            bankcardFail={this.state.bankCardFail}
            name5="BankTransfer"
            banktransferVal={this.state.dataBankTransfer.count}
            banktransferPen={this.state.bankTransferPendientes + this.state.kushkiBankTransferPendientes}
            banktransferFail={this.state.bankTransferFail + this.state.kushkiBankTransferFail}
            name6="Cash"
            cashVal={this.state.dataCash.count}
            cashPen={this.state.cashPendientes}
            cashFail={this.state.cashFail}
            name7="PayOut"
            payoutVal={this.state.dataPayOut.count}
            payoutPen={this.state.payOutPendientes}
            payoutFail={this.state.payOutFail}


            name8="SkinsBacks"
            skinBacksVal={this.state.dataSkinsBacks.count}
            skinBacksPen={this.state.skinBacksPendientes}
            skinBacksFail={this.state.skinBacksFail}

            name9="Airtm"
            airtmVal={this.state.dataAirtm.count}
            airtmPen={this.state.airtmPendientes}
            airtmFail={this.state.airtmFail}

            name10="Kushki Wp"
            kushikiWebPayVal={this.state.dataKushkiWebPay.count}
            kushikiWebPayPen={this.state.kushkiWebpayPendientes}
            kushikiWebPayFail={this.state.kushkiWebpayFail}




            /*     name10="Kushki BankTransfer"
                KushikiBankTransferVal={this.state.dataKushkiBankTransfer.count}
                KushikiBankTransferPen={this.state.kushkiBankTransferPendientes}
                kushkiBankTransferFail={this.state.kushkiBankTransferFail} */


            merchantContr={this.props.merchantContr}
            merchant={this.props.merchant}
            typeUser={this.props.dataUser.typeUser}
            country={this.props.country}
            comissionData={this.props.comissionData}

            useBankCard={this.state.useBankCard}
            useBankTransfer={this.state.useBankTransfer}
            useCash={this.state.useCash}
            useCashin={this.state.useCashin}
            useCashout={this.state.useCashout}
            usePayku={this.state.usePayku}
            useWebpay={this.state.useWebpay}
            usePayOut={this.state.usePayOut}
            useSkinsBacks={this.state.useSkinsBacks}
            useAirmt={true}
          />)}
      </Col>
    );
    searchId = (
      <Col xl={15} lg={24} md={12} sm={24} xs={24}>
        <MerchantForId />
      </Col>
    );
    searchCashout = (
      <Col xl={12} lg={24} md={12} sm={24} xs={24}>
        <MerchantForId />
      </Col>
    );

    return (
      <Auxiliary>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Button
              type="primary"
              loading={this.state.loadingInfo}
              onClick={e => this.updateData()}
            >
              Update
            </Button>
            <a style={{ fontSize: 10 }}>
              -Last Update :{this.state.updateTablePending}
            </a>
          </Col>

          {this.props.dataUser.typeUser === "admin" ? comisionesWidget : ""}
          {this.props.dataUser.typeUser === "admin" && this.props.country.code === "CL" ? pendingWidget : ""}
          {this.props.dataUser.typeUser === "soporte" ||
            this.props.dataUser.typeUser === "soporteCashout" ||
            this.props.dataUser.typeUser === "soporteAdmin"
            ? pendingWidget
            : ""}
          {this.props.dataUser.typeUser === "soporte" ||
            this.props.dataUser.typeUser === "soporteCashout" ||
            this.props.dataUser.typeUser === "soporteAdmin"
            ? ""
            : welcome}
          {/*    {this.props.dataUser.typeUser === "soporte" ||
            this.props.dataUser.typeUser === "soporteCashout" ||
            this.props.dataUser.typeUser === "soporteAdmin"
            ? ""
            : widgetCashin} */}


          {this.props.country.code === "CL" ?
            this.props.dataUser.typeUser === "soporte" ||
              this.props.dataUser.typeUser === "soporteCashout" ||
              this.props.dataUser.typeUser === "soporteAdmin" ||
              this.props.dataUser.typeUser === "merchant"
              ? ""
              : widgetCashin
            :
            ""
          }

          {/* this.state.useCashout ?  */this.props.country.code === "CL" ?
            this.props.dataUser.typeUser === "soporte" ||
              this.props.dataUser.typeUser === "soporteCashout" ||
              this.props.dataUser.typeUser === "soporteAdmin"
              ? ""
              : widgetCashout
            :
            ""
          }
          {this.state.useWebpay ?
            this.props.dataUser.typeUser === "soporte" ||
              this.props.dataUser.typeUser === "soporteCashout" ||
              this.props.dataUser.typeUser === "soporteAdmin" ||
              this.props.dataUser.typeUser === "merchant"
              ? ""
              : widgetPayku
            :
            ""
          }

          {this.state.useWebpay ?
            this.props.dataUser.typeUser === "soporte" ||
              this.props.dataUser.typeUser === "soporteCashout" ||
              this.props.dataUser.typeUser === "soporteAdmin" ||
              this.props.dataUser.typeUser === "merchant"
              ? ""
              : widgetKushkiBankCard
            :
            ""
          }

          {this.state.useWebpay ?
            this.props.dataUser.typeUser === "merchant" ? widgetWebpay : ""
            :
            ""
          }

          {/* esto es sobre agregadores */}

          {this.state.useBankCard ?

            this.props.dataUser.typeUser === "soporte" ||
              this.props.dataUser.typeUser === "soporteCashout" ||
              this.props.dataUser.typeUser === "soporteAdmin" /* ||
              this.props.dataUser.typeUser === "merchant" */
              ? ""
              : widgetBankCard
            :
            ""
          }
          {this.state.useBankTransfer ?

            this.props.dataUser.typeUser === "soporte" ||
              this.props.dataUser.typeUser === "soporteCashout" ||
              this.props.dataUser.typeUser === "soporteAdmin" /* ||
              this.props.dataUser.typeUser === "merchant"
 */
              ? ""
              : widgetBankTransfer
            :
            ""
          }
          {this.state.useCash ?

            this.props.dataUser.typeUser === "soporte" ||
              this.props.dataUser.typeUser === "soporteCashout" ||
              this.props.dataUser.typeUser === "soporteAdmin" /* ||
              this.props.dataUser.typeUser === "merchant" */
              ? ""
              : widgetCash
            :
            ""
          }


          {this.state.usePayOut ?

            this.props.dataUser.typeUser === "soporte" ||
              this.props.dataUser.typeUser === "soporteCashout" ||
              this.props.dataUser.typeUser === "soporteAdmin"  /*||
              this.props.dataUser.typeUser === "merchant" */
              ? ""
              : widgetPayOut
            :
            ""
          }
          {/*  {this.state.dataKushkiWebPay.data.length != 0 ?
            this.props.dataUser.typeUser !== "merchant" ? widgetWebpay : ""
            :
            ""
          }
          {this.state.dataKushkiWebPay.data.length != 0 ? (
            widgetKushkiBankCard) : ("")} */}

          {/* {this.state.dataKushkiBankTransfer.data.length !=0 ? (
          widgetKushkiBankTransfer):("")} */}

          {this.state.useSkinsBacks ?

            this.props.dataUser.typeUser === "soporte" ||
              this.props.dataUser.typeUser === "soporteCashout" ||
              this.props.dataUser.typeUser === "soporteAdmin"
              ? ""
              : widgetSkinBacks
            :
            ""
          }
          {this.state.useAirtm ?

            this.props.dataUser.typeUser === "soporte" ||
              this.props.dataUser.typeUser === "soporteCashout" ||
              this.props.dataUser.typeUser === "soporteAdmin"
              ? ""
              : widgetAirtm
            :
            ""
          }


          {this.props.dataUser.typeUser === "admin" ||
            this.props.dataUser.typeUser === "merchant"
            ? totalInvoiceWidget
            : ""}
          {this.props.dataUser.typeUser === "admin" ||
            this.props.dataUser.typeUser === "merchant" ||
            this.props.dataUser.typeUser === "soporteCashout"
            ? widgetBalance
            : ""}
          {this.props.dataUser.typeUser !== "merchant" ? powerProductsWidgtet : ""}
          {this.props.dataUser.typeUser !== "merchant" ? semaphoneProducts : ""}
          {orderHistory}
          {this.props.dataUser.typeUser === "merchant" ? "" : searchId}
          {this.props.dataUser.typeUser === "merchant"
            ? ""
            : searchCashoutForId}
{widgetBalancesExn}
        </Row>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const {
    merchant,
    comissionData,
    country,
    dataUser,
    merchantContr
  } = settings;
  return {
    authUser,
    merchant,
    comissionData,
    country,
    dataUser,
    merchantContr
  };
};

export default connect(mapStateToProps)(Dashboard);
