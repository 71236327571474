import React, { useEffect, useState } from "react";

import { Input, Select } from "antd";
import queryParams from "../../../../util/queryParams";
const InputGroup = Input.Group;
const { Search } = Input;
const { Option } = Select;

const SearchPayroll = ({ onChange, options, placeholder = "Search..." }) => {
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const search = value => {
    onChange({
      search: value,
      filter: filterValue
    });
  };

  const onChangeFilter = value => {
    setFilterValue(value);
    if (searchValue) {
      onChange({
        search: searchValue,
        filter: value
      });
    }
  };

  useEffect(() => {
    setFilterValue(queryParams().filter || options[0].value);
    setSearchValue(queryParams().search);
  }, [document.location.search]);

  return (
    <>
      <div>
        <InputGroup compact>
          <Select
            defaultValue={options[0].value}
            value={filterValue}
            onChange={onChangeFilter}
          >
            {options.map(item => (
              <Option value={item.value} key={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Search
            placeholder={placeholder}
            onSearch={search}
            onChange={e => setSearchValue(e.target.value)}
            value={searchValue}
            style={{ width: 200 }}
          />
        </InputGroup>
      </div>
    </>
  );
};

export default SearchPayroll;
