import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Input, Alert, Row, Col, Select } from "antd";
import Widget from "components/Widget/index";
import { firebase } from "../../firebase/firebase";
import Dynamic from "./Dynamic";
const db = firebase.firestore();
const FormItem = Form.Item;
const { TextArea } = Input;

class BlacklistCashout extends Component {
  state = {
    resp: "",
    updateTable: false
  };

  getBlacklistTemp = () => {
    //SIMULA  UN CARTOLA EN DESARROLLO , PARA QUE CUADRE SE DEBEN CREAR LOS DATOS EN LA BD DE DESARROLLO

    let data = [
      {
        valor: "271164629",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "138851133",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "131213700",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "182595918",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      /* {
        "valor": "264185742",
        "tipo": "rut",
        "reason": "TEF MASIVA",
      }, */
      {
        valor: "265388043",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "175426868",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "17483.1300",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "90751891",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "189351569",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      /*  {
        "valor": "191094824",
        "tipo": "rut",
        "reason": "TEF MASIVA",
      }, */
      {
        valor: "19014237K",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "169010811",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "227974222",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "17083853K",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "131213700",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "241968782",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "191565894",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "265457541",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "200789342",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "164927245",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "151853498",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "193672434",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      /* {
        "valor": "184421860",
        "tipo": "rut",
        "reason": "TEF MASIVA",
      }, */
      {
        valor: "164927245",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "171355966",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "265457541",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "155635126",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "241968782",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "93530411",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "175341668",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "169010811",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "192911192",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "151546951",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "178109499",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      /* {
        "valor": "271127448",
        "tipo": "rut",
        "reason": "TEF MASIVA",
      }, */
      {
        valor: "173875177",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "109353388",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "201011167",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "159660125",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "134841192",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "192911192",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "169010811",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "208816896",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "172444555",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "239858244",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "167429955",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "191201515",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "17050688K",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "208240269",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "171607558",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "17083853K",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "131213700",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "241968782",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "191565894",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "265457541",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "200789342",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "164927245",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "151853498",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "193672434",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "184421860",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "164927245",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "191201515",
        tipo: "rut",
        reason: "TEF MASIVA"
      },
      {
        valor: "17050688K",
        tipo: "rut",
        reason: "TEF MASIVA"
      }
    ];

    console.log(",data", data);
    return data;
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }

    /*   let flag = this.validaExistencia(values.valor); */
      console.log("flag ", flag);
      let flag =false;
      if (!flag) {
        //Grabar en db
        db.collection("parametrosGenerales")
          .doc("seguridad")
          .collection("blacklistCashout")
          .add({
            /*  ...req */
            tipo: values.tipo,
            valor: values.valor,
            reason: values.reason,
            admin: this.props.authUser,
          })
          .then(
            this.setState({
              resp: `Black List Added Successfully.\ntipo: ${values.tipo}\nvalor: ${values.valor}\nreason: ${values.reason}`,
              updateTable: !this.state.updateTable
            })
          )
          .catch(
            error => this.setState({
              resp: `Black List Error when trying to add a record .\ntipo: ${values.tipo}\nvalor: ${values.valor}\nreason: ${values.reason}. ${error}`,
              updateTable: !this.state.updateTable
            })

          );
      } else {
        this.setState({
          resp: `Black List: There is already a record with this value .\ntipo: ${values.tipo}\nvalor: ${values.valor}\nreason: ${values.reason}`,
          updateTable: !this.state.updateTable
        });
      }
    });

    setTimeout(
      function() {
        this.setState({ resp: "" });
      }.bind(this),
      5000
    );
  };

  validaExistencia = async value => {
    let isValid = false;
    console.log("value", value);
    await db
      .collection("parametrosGenerales")
      .doc("seguridad")
      .collection("blacklistCashout")
      .where("valor", "==", value)
      .get()
      .then(async snapshot => {
        let resFirestore = snapshot.docs.map(doc => {
          return doc.data();
        });

        if (resFirestore.length > 0) {
          isValid = true;
        }
      })
      .catch(error => console.log("error en validaExistencia", error));

    return isValid;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    return (
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Widget
            title={
              <h4
                className=" gx-text-capitalize gx-mb-0"
                style={{ color: "#070707" }}
              >
                {/*  <i className="icon icon-mail-open gx-mr-3" /> */}
                {"☠️ "}
                ADD TO BLACKLIST CASHOUT
              </h4>
            }
            style={{ backgroundColor: "rgb(220 34 34 / 14%)" }}
          >
            <Form
              className="gx-signup-form gx-form-row0 gx-mb-0"
              onSubmit={this.handleSubmit}
            >
              <FormItem label="Type:">
                <div className="gx-mb-3">
                  {getFieldDecorator("tipo", {
                    validateTrigger: ["onChange", "onBlur"],
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Please enter the Type!"
                      }
                    ]
                  })(
                    // <Input placeholder="Código" onChange={this.onChangeID}/>
                    <Select
                      style={{ width: "300px" }}
                      onChange={this.onChangeID}
                    >
                      <Option value="">Select Type</Option>
                      <Option value="rut">rut </Option>
                      <Option value="ip">ip</Option>
                    </Select>
                  )}
                </div>
              </FormItem>
              <FormItem label="Value:">
                <div className="gx-mb-3">
                  {getFieldDecorator("valor", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter the value!"
                      }
                    ]
                  })(
                    <Input
                      placeholder="192.168.0.1 / 158448092"
                      style={{ width: "300px" }}
                    />
                  )}
                </div>
              </FormItem>
              <FormItem label="Reason:">
                <div className="gx-mb-3">
                  {getFieldDecorator("reason", {
                    rules: [
                      {
                        required: true,
                        message: "Please, enter the reason!"
                      }
                    ]
                  })(
                    <TextArea
                      rows={4}
                      placeholder="enter the reason"
                      style={{ width: "300px" }}
                    />
                  )}
                </div>
              </FormItem>
              {this.state.resp ? (
                <Alert
                  message="Resultado"
                  description={this.state.resp}
                  type="success"
                />
              ) : null}

              <>
                {" "}
                <Button
                  type="primary"
                  className="gx-mb-0"
                  htmlType="submit"
                  style={{ marginTop: "10px" }}
                >
                  Validar
                </Button>{" "}
              </>
            </Form>
          </Widget>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Widget
            title={
              <h4
                className=" gx-text-capitalize gx-mb-0"
                style={{ color: "#070707" }}
              >
                {"🏴‍ "}
                BLACKLIST CASHOUT
              </h4>
            }
            style={{ backgroundColor: "rgb(220 34 34 / 14%)" }}
          >
            <Dynamic
              updateTable={this.state.updateTable}
              type={"blacklistCashout"}
            />
          </Widget>
        </Col>
        {/*     <Col lg={12} md={12} sm={24} xs={24}>

        </Col> */}
      </Row>
    );
  }
}

const BlacklistForm = Form.create()(BlacklistCashout);
const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;

  const { dataUser } = settings;

  return { authUser, dataUser };
};

export default connect(mapStateToProps)(BlacklistForm);

// export default BlacklistForm;
