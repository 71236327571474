
export function isActiveNewCashout(parametrosGenerales, merchantCode, loading) {
  if(loading) return false;

  return ((parametrosGenerales.isActiveNewCashout) && (parametrosGenerales.commerces[merchantCode].isActiveNewCashout && parametrosGenerales.commerces[merchantCode].isAllowedNewCashout))
}

export function isActiveOldCashout(parametrosGenerales, merchantCode, loading) {
  if(loading) return false;

  return !((parametrosGenerales.isActiveNewCashout) && (parametrosGenerales.commerces[merchantCode].isActiveNewCashout && parametrosGenerales.commerces[merchantCode].isAllowedNewCashout))
}

// export function isVisibleTextareaNewCashout(parametrosGenerales, merchantCode, loading) {

//   if(loading) return false;

//   if(parametrosGenerales.isActiveNewCashout){
//     if(parametrosGenerales.commerces[merchantCode].isAllowedNewCashout){
//         if(parametrosGenerales.commerces[merchantCode].isActiveNewCashout){
//             return false
//         }else {
//           return true
//         }
//     } else {
//         return true
//     }
//   }
//   return true
// }

// export function isVisibleOperationButtonsNewCashout(parametrosGenerales, merchantCode, loading) {

//   if(loading) return false;

//   if(parametrosGenerales.isActiveNewCashout){
//     if(parametrosGenerales.commerces[merchantCode].isAllowedNewCashout){
//         if(parametrosGenerales.commerces[merchantCode].isActiveNewCashout){
//             return true
//         }else {
//           return false
//         }
//     } else {
//         return false
//     }
//   }
//   return false
// }