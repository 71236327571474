import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

const SubMenu = Menu.SubMenu;

class HorizontalNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productMerchant: "",
      useBankCard: false,
      useBankTransfer: false,
      useCash: false,
      useCashin: true,
      useCashout: false,
      usePayku: false,
      useWebpay: false,
      usePayOut: false,
      useSkinsBacks: false,
      useKushki: false,
      useAirtm: false,
      useCrypto: false,
      useMach: false,
      useFri: false,
      useCoinspaid: false
    };


  }

  componentDidMount = async () => {
    console.log("componentDidMount horizontal nav")
    console.log("state", this.state)

    let useCashin = true
    let useCashout = false
    let usePayku = false
    let useWebpay = false
    let useBankCard = false
    let useBankTransfer = false
    let useCash = false
    let usePayOut = false
    let useSkinsBacks = false
    let useKushki = false
    let useCrypto = false
    let useMach = false
    let useFri = false
    let useCoinspaid = false
    this.setState({ useBankCard, useBankTransfer, useCash, useCashin: useCashin, useCashout, usePayku, useWebpay, usePayOut, useSkinsBacks, useKushki, useCrypto, useMach, useFri, useCoinspaid })
    /*       console.log("state1",this.state) */

    // LLAMO LOS ESTADOS DE LOS PRODUCTOS
    await this.getStatePowerProduct()
  };


  async componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      console.log("state", this.state)

      let useCashin = false
      let useCashout = false
      let usePayku = false
      let useWebpay = false
      let useBankCard = false
      let useBankTransfer = false
      let useCash = false
      let usePayOut = false
      let useSkinsBacks = false
      let useKushki = true
      let useCrypto = false
      let useMach = false
      let useFri = false
      let useCoinspaid = false


      if (this.props.dataUser.typeUser === "merchant") {
        useKushki = false
      }


      this.setState({ useBankCard, useBankTransfer, useCash, useCashin: useCashin, useCashout, usePayku, useWebpay, usePayOut, useSkinsBacks, useKushki, useCrypto, useMach, useFri, useCoinspaid  })
      /*     console.log("state1",this.state)
          console.log("componentDidUpdate horizontal nav") */
      // LLAMO LOS ESTADOS DE LOS PRODUCTOS
      await this.getStatePowerProduct()
    }
  }

  getStatePowerProduct = async () => {

    let useCashin = false
    let useCashout = false
    let usePayku = false
    let useWebpay = false
    let useBankCard = false
    let useBankTransfer = false
    let useCash = false
    let usePayOut = false
    let useSkinsBacks = false
    let useKushki = true
    let useAirtm = false
    let useCrypto  = false
    let useMach = false
    let useFri = false
    let useCoinspaid = false

    if (this.props.dataUser.typeUser === "merchant") {
      useKushki = false
    }





    if (this.props.country.code === "CL") {


      let product = [];
      console.log("merchantContr horizon", this.props.merchantContr)
      if (this.props.merchantContr) {

        product = this.props.merchantContr.filter(e => e.merchant === this.props.merchant.code.trim());

        useCashin =  product[0].useCashin
        useCashout = product[0].useCashout
        usePayku = product[0].usePayku
        useWebpay = product[0].useWebpay
        useCrypto  = product[0].useCrypto
      }
    }

    let paymentsMethod = [];
    let payments = [];
    let payouts = [];



    let commissionesBd = this.props.comissionData
      ? this.props.comissionData
      : JSON.parse(localStorage.getItem("comisiones"));

    if (commissionesBd) {
      console.log("commissionesBd", commissionesBd);
      paymentsMethod = commissionesBd.filter(e => e.merchant === this.props.merchant.code);
      console.log("paymentsMethod", paymentsMethod);
      if (paymentsMethod.length > 0) {
        payments = paymentsMethod[0].payments.filter(e => e.country === this.props.country.code).map(e => {
          console.log("e", e);
          useBankCard = e.methodUse.filter(e => e == "bankCard")
          if (useBankCard.length != 0) { useBankCard = true } else { useBankCard = false }

          useBankTransfer = e.methodUse.filter(e => e == "bankTransfer")

          if (useBankTransfer.length != 0) { useBankTransfer = true } else { useBankTransfer = false }
          console.log("useBankTransfer", useBankTransfer);

          useCash = e.methodUse.filter(e => e == "cash")
          if (useCash.length != 0) { useCash = true } else { useCash = false }

          useMach = e.methodUse.filter(e => e == "mach")
          if (useMach.length != 0) { useMach = true } else { useMach = false }

          useFri = e.methodUse.filter(e => e == "fri")
          if (useFri.length != 0) { useFri = true } else { useFri = false }

          useCoinspaid = e.methodUse.filter(e => e == "coinspaid" || e == "coinspaid-praxis")
          if (useCoinspaid.length != 0) { useCoinspaid = true } else { useCoinspaid = false }

          useSkinsBacks = e.methodUse.filter(e => e == "skin")
          if (useSkinsBacks.length != 0) { useSkinsBacks = true } else { useSkinsBacks = false }
          console.log("skin", useSkinsBacks);
          /*  this.setState({ useBankCard, useBankTransfer, useCash, useCashin: useCashin, useCashout, usePayku, useWebpay }) */


          useSkinsBacks = e.methodUse.filter(e => e == "skin")
          if (useSkinsBacks.length != 0) { useSkinsBacks = true } else { useSkinsBacks = false }
          console.log("skin", useSkinsBacks);

          useAirtm = e.methodUse.filter(e => e == "airtm")
          if (useAirtm.length != 0) { useAirtm = true } else { useAirtm = false }
          console.log("useAirtm", useAirtm);

        })

        console.log(" paymentsMethod", paymentsMethod[0].payouts.length);
        payouts = paymentsMethod[0].payouts.filter(e => e.country === this.props.country.code)

        if (!payouts) { console.log("payouts", payouts); usePayOut = false }

        else {
          /*      payouts.map(e => { */
          usePayOut = true
          /*  }) */
        }
        this.setState({ usePayOut })
      }
    }

    // this.setState({ powerBankCard: useBankCard, powerBankTransfer: useBankTransfer, powerCash: useCash, powerCashin: useCashin, powerCashout: useCashout, powerPayku: usePayku, powerWebpay: useWebpay, powerSkinsBacks: useSkinsBacks })
    this.setState({ useBankCard, useBankTransfer, useCash, useCashin: useCashin, useCashout, usePayku, useWebpay, useSkinsBacks, useKushki, useAirtm, useCrypto, useMach, useFri, useCoinspaid  })
    console.log("use", "horizontalnav", "useBankCard", useBankCard, "useBankTransfer", useBankTransfer, "useCash", useCash, "useCashin", useCashin, "usePayOut", usePayOut, "useCashout", useCashout, "usePayku", usePayku, "useWebpay", useWebpay, "useSkinsBacks", useSkinsBacks, "useKushki", useKushki, "useCrypto",useCrypto );

  };

  getNavStyleSubMenuClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };





  render() {
    const { pathname, navStyle } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    const { SubMenu } = Menu;
    let menu;
    let menuflow;
    let menupayku;
    let menuWebpay;
    let menuBankTransfer;
    let menuBankCard;
    let menuCash;
    let menuMach;
    let menuFri;
    let menuCoinspaid;
    let menuPayIn;
    let menuPayOut;
    let menuCashin;
    let menuCashout;
    let menuPayroll;
    let menuSkinback;
    let menuKushki;
    let menuKushkiWebPay;
    let menuKushkiBankTransfer;
    let menuAirtm;
    let menuAirtmPayOut;
    let menuCrypto

    switch (this.props.authUser) {
      case "ncornejo@me.com":
      case "johnkaroka@gmail.com":
      case "zeroray@gmail.com":
      case "alonso.marchant@hotmail.com":
      case "pabloignacio.lan.tus@gmail.com":
      case "jarias@stratechcorp.com":
      case "avidal@stratechcorp.com":
      case "jcontreras@stratechcorp.com":
      case "Panfossi@stratechcorp.com":
      case "panfossi@stratechcorp.com":
      case "fmansilla@stratechcorp.com":
      case "ralvarez@stratechcorp.com":
      case "rponce@stratechcorp.com":
      case "jaguayo@stratechcorp.com":
        
        menu = (
          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="admin"
            title={<IntlMessages id="sidebar.admin" />}
          >
            <Menu.Item key="main/admin/blacklist">
              <Link to="/main/admin/blacklist">
                <i className="icon icon-uncheck-squire" />
                <IntlMessages id="sidebar.admin.blacklist" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/callback">
              <Link to="/main/admin/callback">
                <i className="icon icon-reply" />
                <IntlMessages id="sidebar.admin.callback" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/cierres">
              <Link to="/main/admin/cierres">
                <i className="icon icon-treeselect" />
                <IntlMessages id="sidebar.admin.cierres" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/reports">
              <Link to="/main/admin/reports">
                <i className="icon icon-revenue-new" />
                <IntlMessages id="sidebar.admin.Report" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/cierresInvoiceFilter">
              <Link to="/main/admin/cierresInvoiceFilter">
                <i className="icon icon-select" />
                <IntlMessages id="sidebar.admin.cierresInvoice" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/cartola">
              <Link to="/main/admin/cartola">
                <i className="icon icon-table" />
                <IntlMessages id="sidebar.admin.cartola" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/commerces">
              <Link to="/main/admin/commerces">
                <i className="icon icon-shopping-cart " />
                <IntlMessages id="sidebar.admin.commcerces" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/users">
              <Link to="/main/admin/users">
                <i className="icon icon-user" />
                <IntlMessages id="sidebar.admin.users" />
              </Link>
            </Menu.Item>

          {/*   <Menu.Item key="main/admin/callbackCashout">
              <Link to="/main/admin/callbackCashout">
                <i className="icon icon-reply-o" />
                <IntlMessages id="sidebar.admin.callbackCashout" />
              </Link>
            </Menu.Item> */}

            <Menu.Item key="main/admin/changelog">
              <Link to="/main/admin/changelog">
                <i className="icon icon-timeline-left-align" />
                {"Changelog"}
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/multySearch">
              <Link to="/main/admin/multySearch">
                <i className="icon icon-search" />
                {"MultiSearch"}
              </Link>
            </Menu.Item>
            <Menu.Item key="main/admin/retention">
              <Link to="/main/admin/retention">
                <i className="icon icon-callout" />
                {"Retention"}
              </Link>
            </Menu.Item>
            <Menu.Item key="main/admin/burnCode">
              <Link to="/main/admin/burnCode">
                <i className="icon icon-callout" />
                {"Burn Operation Code"}
              </Link>
            </Menu.Item>
            <Menu.Item key="main/admin/effectiveness">
              <Link to="/main/admin/effectiveness">
                <i className="icon icon-map-overlay" />
                {"Effectiveness"}
              </Link>
            </Menu.Item>
            <Menu.Item key="main/admin/payroll">
              <Link to="/main/admin/payroll">
                <i className="icon icon-contacts" />
                {"Payroll"}
              </Link>
            </Menu.Item>
            <Menu.Item key="main/admin/operationalTraffic">
              <Link to="/main/admin/operationalTraffic">
                <i className="icon icon-contacts" />
                {"Operational Traffic"}
              </Link>
            </Menu.Item>
          </SubMenu>
        );
        break;

      case "hsalasnovikov@stratechcorp.com":
        menu = (
          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="Soporte"
            title={<IntlMessages id="sidebar.soporte" />}
          >
            <Menu.Item key="main/admin/callback">
              <Link to="/main/admin/callback">
                <i className="icon icon-reply" />
                <IntlMessages id="sidebar.admin.callback" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/blacklist">
              <Link to="/main/admin/blacklist">
                <i className="icon icon-uncheck-squire" />
                <IntlMessages id="sidebar.admin.blacklist" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/cartola">
              <Link to="/main/admin/cartola">
                <i className="icon icon-table" />
                <IntlMessages id="sidebar.admin.cartola" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/reports">
              <Link to="/main/admin/reports">
                <i className="icon icon-revenue-new" />
                <IntlMessages id="sidebar.admin.Report" />
              </Link>
            </Menu.Item>

         {/*    <Menu.Item key="main/admin/callbackCashout">
              <Link to="/main/admin/callbackCashout">
                <i className="icon icon-reply-o" />
                <IntlMessages id="sidebar.admin.callbackCashout" />
              </Link>
            </Menu.Item> */}


            <Menu.Item key="main/admin/multySearch">
              <Link to="/main/admin/multySearch">
                <i className="icon icon-search" />
                {"MultiSearch"}
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/effectiveness">
              <Link to="/main/admin/effectiveness">
                <i className="icon icon-map-overlay" />
                {"Effectiveness"}
              </Link>
            </Menu.Item>

          </SubMenu>
        );
        break;
      default:
      // Show regular user UI.
    }

    switch (this.props.dataUser.typeUser) {
      case "soporteAdmin":
        menu = (
          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="Soporte"
            title={<IntlMessages id="sidebar.soporte" />}
          >
            <Menu.Item key="main/admin/callback">
              <Link to="/main/admin/callback">
                <i className="icon icon-reply" />
                <IntlMessages id="sidebar.admin.callback" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/blacklist">
              <Link to="/main/admin/blacklist">
                <i className="icon icon-uncheck-squire" />
                <IntlMessages id="sidebar.admin.blacklist" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/cartola">
              <Link to="/main/admin/cartola">
                <i className="icon icon-table" />
                <IntlMessages id="sidebar.admin.cartola" />
              </Link>
            </Menu.Item>

      {/*       <Menu.Item key="main/admin/callbackCashout">
              <Link to="/main/admin/callbackCashout">
                <i className="icon icon-reply-o" />
                <IntlMessages id="sidebar.admin.callbackCashout" />
              </Link>
            </Menu.Item> */}

            <Menu.Item key="main/admin/changelog">
              <Link to="/main/admin/changelog">
                <i className="icon icon-timeline-left-align" />
                {"Changelog"}
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/multySearch">
              <Link to="/main/admin/multySearch">
                <i className="icon icon-search" />
                {"MultiSearch"}
              </Link>
            </Menu.Item>
            <Menu.Item key="main/admin/burnCode">
              <Link to="/main/admin/burnCode">
                <i className="icon icon-callout" />
                {"Burn Operation Code"}
              </Link>
            </Menu.Item>
          </SubMenu>
        );
        break;
      case "soporteCashout":
        menu = (
          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="Soporte"
            title={<IntlMessages id="sidebar.soporte" />}
          >
            {/* <Menu.Item key="main/admin/callbackCashout">
              <Link to="/main/admin/callbackCashout">
                <i className="icon icon-reply-o" />
                <IntlMessages id="sidebar.admin.callbackCashout" />
              </Link>
            </Menu.Item> */}
            <Menu.Item key="main/admin/changelog">
              <Link to="/main/admin/changelog">
                <i className="icon icon-timeline-left-align" />
                {"Changelog"}
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/multySearch">
              <Link to="/main/admin/multySearch">
                <i className="icon icon-search" />
                {"MultiSearch"}
              </Link>
            </Menu.Item>
          </SubMenu>
        );
        break;
      case "soporte":
        menu = (

          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="Soporte"
            title={<IntlMessages id="sidebar.soporte" />}
          >
            <Menu.Item key="main/admin/callback">
              <Link to="/main/admin/callback">
                <i className="icon icon-reply" />
                <IntlMessages id="sidebar.admin.callback" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/cartola">
              <Link to="/main/admin/cartola">
                <i className="icon icon-table" />
                <IntlMessages id="sidebar.admin.cartola" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/changelog">
              <Link to="/main/admin/changelog">
                <i className="icon icon-timeline-left-align" />
                {"Changelog"}
              </Link>
            </Menu.Item>

            <Menu.Item key="main/admin/multySearch">
              <Link to="/main/admin/multySearch">
                <i className="icon icon-search" />
                {"MultiSearch"}
              </Link>
            </Menu.Item>
            <Menu.Item key="main/admin/burnCode">
              <Link to="/main/admin/burnCode">
                <i className="icon icon-callout" />
                {"Burn Operation Code"}
              </Link>
            </Menu.Item>
          </SubMenu>
        );
        break;
      default:

    }


    if (this.props.dataUser.typeUser !== "merchant") {
      /*   case "soporte": */
      menuflow = (

        <SubMenu
          className={this.getNavStyleSubMenuClass(navStyle)}
          key="flow"
          title={<IntlMessages id="sidebar.flow" />}
        >
          <Menu.Item key="main/flow/validated">
            <Link to="/main/flow/validated">
              <i className="icon icon-check" />
              <IntlMessages id="sidebar.flow.validated" />
            </Link>
          </Menu.Item>

          <Menu.Item key="main/flow/pending">
            <Link to="/main/flow/pending">
              <i className="icon icon-timepicker" />
              <IntlMessages id="sidebar.flow.pending" />
            </Link>
          </Menu.Item>

          <Menu.Item key="main/flow/failed">
            <Link to="/main/flow/failed">
              <i className="icon icon-close-circle" />
              <IntlMessages id="sidebar.flow.failed" />
            </Link>
          </Menu.Item>
        </SubMenu>

      );
      /*    break;
       default: */
      // Show regular user UI.
    }
    if (this.state.useWebpay || this.state.usePayku) {
      if (this.props.dataUser.typeUser !== "merchant") {
        /*   case "soporte": */
        menupayku = (

          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="payku"
            title={<IntlMessages id="sidebar.payku" />}
          >
            <Menu.Item key="main/payku/validated">
              <Link to="/main/payku/validated">
                <i className="icon icon-check" />
                <IntlMessages id="sidebar.payku.validated" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/payku/pending">
              <Link to="/main/payku/pending">
                <i className="icon icon-timepicker" />
                <IntlMessages id="sidebar.payku.pending" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/payku/failed">
              <Link to="/main/payku/failed">
                <i className="icon icon-close-circle" />
                <IntlMessages id="sidebar.payku.failed" />
              </Link>
            </Menu.Item>
          </SubMenu>

        );
        /*    break;
         default: */
        // Show regular user UI.
      }





      menuWebpay = (
        <SubMenu
          className={this.getNavStyleSubMenuClass(navStyle)}
          key="webpay"
          title={<IntlMessages id="sidebar.webpay" />}
        >
          <Menu.Item key="main/webpay/merchant/validated">
            <Link to="/main/webpay/merchant/validated">
              <i className="icon icon-check" />
              <IntlMessages id="sidebar.webpay.validated" />
            </Link>
          </Menu.Item>

          <Menu.Item key="main/webpay/pending">
            <Link to="/main/webpay/pending">
              <i className="icon icon-timepicker" />
              <IntlMessages id="sidebar.webpay.pending" />
            </Link>
          </Menu.Item>

          <Menu.Item key="main/webpay/failed">
            <Link to="/main/webpay/failed">
              <i className="icon icon-close-circle" />
              <IntlMessages id="sidebar.webpay.failed" />
            </Link>
          </Menu.Item>
        </SubMenu>
      );
    }

    /* if (this.state.productMerchant) { */
    if (this.state.useBankTransfer) {

      menuBankTransfer = (

        <SubMenu
          className={this.getNavStyleSubMenuClass(navStyle)}
          key="banktransfer"
          title={<IntlMessages id="sidebar.banktransfer" />}
        >

          <Menu.Item key="main/banktransfer/validated">
            <Link to="/main/banktransfer/validated">
              <i className="icon icon-check" />
              <IntlMessages id="sidebar.banktransfer.validated" />
            </Link>
          </Menu.Item>

          <Menu.Item key="main/banktransfer/pending">
            <Link to="/main/banktransfer/pending">
              <i className="icon icon-timepicker" />
              <IntlMessages id="sidebar.banktransfer.pending" />
            </Link>
          </Menu.Item>

          <Menu.Item key="main/banktransfer/failed">
            <Link to="/main/banktransfer/failed">
              <i className="icon icon-close-circle" />
              <IntlMessages id="sidebar.banktransfer.failed" />
            </Link>
          </Menu.Item>
        </SubMenu>

      );
    }

      if (this.state.useBankCard) {

        menuBankCard = (

          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="card"
            title={"Bank Card"}
          >

            <Menu.Item key="main/bankcard/validated">
              <Link to="/main/card/validated">
                <i className="icon icon-check" />
                <IntlMessages id="sidebar.card.validated" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/card/pending">
              <Link to="/main/card/pending">
                <i className="icon icon-timepicker" />
                <IntlMessages id="sidebar.card.pending" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/card/failed">
              <Link to="/main/card/failed">
                <i className="icon icon-close-circle" />
                <IntlMessages id="sidebar.card.failed" />
              </Link>
            </Menu.Item>
          </SubMenu>



        );
      }

      if (this.state.useCash) {

        menuCash = (

          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="cash"
            title={"Cash"}
          >

            <Menu.Item key="main/cash/validated">
              <Link to="/main/cash/validated">
                <i className="icon icon-check" />
                <IntlMessages id="sidebar.cash.validated" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/cash/pending">
              <Link to="/main/cash/pending">
                <i className="icon icon-timepicker" />
                <IntlMessages id="sidebar.cash.pending" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/cash/failed">
              <Link to="/main/cash/failed">
                <i className="icon icon-close-circle" />
                <IntlMessages id="sidebar.cash.failed" />
              </Link>
            </Menu.Item>
          </SubMenu>



        );
      }

      if (this.state.useMach) {

        menuMach = (

          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="mach"
            title={"Mach"}
          >

            <Menu.Item key="main/mach/validated">
              <Link to="/main/mach/validated">
                <i className="icon icon-check" />
                <IntlMessages id="sidebar.mach.validated" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/mach/pending">
              <Link to="/main/mach/pending">
                <i className="icon icon-timepicker" />
                <IntlMessages id="sidebar.mach.pending" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/mach/failed">
              <Link to="/main/mach/failed">
                <i className="icon icon-close-circle" />
                <IntlMessages id="sidebar.mach.failed" />
              </Link>
            </Menu.Item>
          </SubMenu>



        );
      }


      if (this.state.useFri) {

        menuFri = (

          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="fri"
            title={"Fri"}
          >

            <Menu.Item key="main/fri/validated">
              <Link to="/main/fri/validated">
                <i className="icon icon-check" />
                <IntlMessages id="sidebar.fri.validated" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/fri/pending">
              <Link to="/main/fri/pending">
                <i className="icon icon-timepicker" />
                <IntlMessages id="sidebar.fri.pending" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/fri/failed">
              <Link to="/main/fri/failed">
                <i className="icon icon-close-circle" />
                <IntlMessages id="sidebar.fri.failed" />
              </Link>
            </Menu.Item>
          </SubMenu>



        );
      }

      if (this.state.useCoinspaid) {

        menuCoinspaid = (

          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="coinspaid"
            title={"Coinspaid"}
          >

            <Menu.Item key="main/coinspaid/validated">
              <Link to="/main/coinspaid/validated">
                <i className="icon icon-check" />
                <IntlMessages id="sidebar.coinspaid.validated" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/coinspaid/pending">
              <Link to="/main/coinspaid/pending">
                <i className="icon icon-timepicker" />
                <IntlMessages id="sidebar.coinspaid.pending" />
              </Link>
            </Menu.Item>

            <Menu.Item key="main/coinspaid/failed">
              <Link to="/main/coinspaid/failed">
                <i className="icon icon-close-circle" />
                <IntlMessages id="sidebar.coinspaid.failed" />
              </Link>
            </Menu.Item>
          </SubMenu>



        );
      }

      

      if (this.state.useCash || this.state.useBankCard || this.state.useBankTransfer || this.state.useMach || this.state.useFri || this.state.useCoinspaid) {

        menuPayIn = (

          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="payin"
            title={"Pay IN"}
          >

            {menuBankTransfer}
            {menuBankCard}
            {menuCash}
            {menuMach}
            {menuFri}
            {menuCoinspaid}

          </SubMenu>

        );
      }

    
    if (this.state.usePayOut) {

      menuPayOut = (<SubMenu
        className={this.getNavStyleSubMenuClass(navStyle)}
        key="payout"
        title={"Pay Out"}
      >
        <Menu.Item key="main/payout/validated">
          <Link to="/main/payout/validated">
            <i className="icon icon-check" />
            <IntlMessages id="sidebar.payout.validated" />
          </Link>
        </Menu.Item>

        <Menu.Item key="main/payout/pending">
          <Link to="/main/payout/pending">
            <i className="icon icon-timepicker" />
            <IntlMessages id="sidebar.payout.pending" />
          </Link>
        </Menu.Item>

        <Menu.Item key="main/payout/failed">
          <Link to="/main/payout/failed">
            <i className="icon icon-close-circle" />
            <IntlMessages id="sidebar.payout.failed" />
          </Link>
        </Menu.Item>
      </SubMenu>
      );
    }

    if (this.state.useCashin /* && this.props.authUser != "latamerica@1xbet.com" */) {

      menuCashin = (<SubMenu
        className={this.getNavStyleSubMenuClass(navStyle)}
        key="cashin"
        title={<IntlMessages id="sidebar.cashin" />}
      >
        <Menu.Item key="main/cashin/validated">
          <Link to="/main/cashin/validated">
            <i className="icon icon-check" />
            <IntlMessages id="sidebar.cashin.validated" />
          </Link>
        </Menu.Item>

        <Menu.Item key="main/cashin/pending">
          <Link to="/main/cashin/pending">
            <i className="icon icon-timepicker" />
            <IntlMessages id="sidebar.cashin.pending" />
          </Link>
        </Menu.Item>

        <Menu.Item key="main/cashin/failed">
          <Link to="/main/cashin/failed">
            <i className="icon icon-close-circle" />
            <IntlMessages id="sidebar.cashin.failed" />
          </Link>
        </Menu.Item>
      </SubMenu>


      );
    }


    /*  if (this.state.useCashout) { */

    menuCashout = (<SubMenu
      className={this.getNavStyleSubMenuClass(navStyle)}
      key="cashout"
      title={<IntlMessages id="sidebar.cashout" />}
    >
      <Menu.Item key="main/cashout/validated">
        <Link to="/main/cashout/validated">
          <i className="icon icon-check" />
          <IntlMessages id="sidebar.cashout.validated" />
        </Link>
      </Menu.Item>

      <Menu.Item key="main/cashout/pending">
        <Link to="/main/cashout/pending">
          <i className="icon icon-timepicker" />
          <IntlMessages id="sidebar.cashout.pending" />
        </Link>
      </Menu.Item>

      <Menu.Item key="main/cashout/failed">
        <Link to="/main/cashout/failed">
          <i className="icon icon-close-circle" />
          <IntlMessages id="sidebar.cashout.failed" />
        </Link>
      </Menu.Item>
    </SubMenu>
    );
    /*    } */

    if(this.props.dataUser.typeUser!=="merchant") {
      menuPayroll = (
        <SubMenu
          className={this.getNavStyleSubMenuClass(navStyle)}
          key="Nominas"
          title={<IntlMessages id="sidebar.payroll" />}
        >
          <Menu.Item key="main/payroll/validated">
            <Link to="/main/payroll/validated">
              <i className="icon icon-check" />
              <IntlMessages id="sidebar.payroll.validated" />
            </Link>
          </Menu.Item>

          <Menu.Item key="main/payroll/pending">
            <Link to="/main/payroll/pending">
              <i className="icon icon-timepicker" />
              <IntlMessages id="sidebar.payroll.pending" />
            </Link>
          </Menu.Item>

          <Menu.Item key="main/payroll/disabled">
            <Link to="/main/payroll/disabled">
              <i className="icon icon-timepicker" />
              <IntlMessages id="sidebar.payroll.disabled" />
            </Link>
          </Menu.Item>

          <Menu.Item key="main/payroll/authorized">
            <Link to="/main/payroll/authorized">
              <i className="icon icon-close-circle" />
              <IntlMessages id="sidebar.payroll.authorized" />
            </Link>
          </Menu.Item>

          <Menu.Item key="main/payroll/created">
            <Link to="/main/payroll/created">
              <i className="icon icon-close-circle" />
              <IntlMessages id="sidebar.payroll.created" />
            </Link>
          </Menu.Item>
        </SubMenu>
      )
    }

    if (this.state.useSkinsBacks) {

      menuSkinback = (<SubMenu
        className={this.getNavStyleSubMenuClass(navStyle)}
        key="skinback"
        title={<IntlMessages id="sidebar.skinback" />}
      >
        <Menu.Item key="main/skinsback/validated">
          <Link to="/main/skinsback/validated">
            <i className="icon icon-check" />
            <IntlMessages id="sidebar.skinback.validated" />
          </Link>
        </Menu.Item>
        <Menu.Item key="main/skinsback/pending">
          <Link to="/main/skinsback/pending">
            <i className="icon icon-timepicker" />
            <IntlMessages id="sidebar.skinback.pending" />
          </Link>
        </Menu.Item>
        <Menu.Item key="main/skinsback/failed">
          <Link to="/main/skinsback/failed">
            <i className="icon icon-close-circle" />
            <IntlMessages id="sidebar.skinback.failed" />
          </Link>
        </Menu.Item>
      </SubMenu>
      );
    }

    if (this.state.useKushki && this.props.dataUser.typeUser != "merchant") {

      menuKushkiWebPay = (<SubMenu
        className={this.getNavStyleSubMenuClass(navStyle)}
        key="KushkiWp"
        title={"Kushki Wp"}
      >
        <Menu.Item key="main/kushkiWebpay/validated">
          <Link to="/main/kushkiWebpay/validated">
            <i className="icon icon-check" />
            <IntlMessages id="sidebar.kushki.validated" />
          </Link>
        </Menu.Item>
        <Menu.Item key="main/kushkiWebpay/pending">
          <Link to="/main/kushkiWebpay/pending">
            <i className="icon icon-timepicker" />
            <IntlMessages id="sidebar.kushki.pending" />
          </Link>
        </Menu.Item>
        <Menu.Item key="main/kushkiWebpay/failed">
          <Link to="/main/kushkiWebpay/failed">
            <i className="icon icon-close-circle" />
            <IntlMessages id="sidebar.kushki.failed" />
          </Link>
        </Menu.Item>
      </SubMenu>
      );


    }


    if (this.state.useAirtm && this.props.dataUser.typeUser != "merchant") {

      menuAirtm = (<SubMenu
        className={this.getNavStyleSubMenuClass(navStyle)}
        key="airtm"
        title={"Airtm"}
      >
        <Menu.Item key="main/airtm/validated">
          <Link to="/main/airtm/validated">
            <i className="icon icon-check" />
            <IntlMessages id="sidebar.airtm.validated" />
          </Link>
        </Menu.Item>
        <Menu.Item key="main/airtm/pending">
          <Link to="/main/airtm/pending">
            <i className="icon icon-timepicker" />
            <IntlMessages id="sidebar.airtm.pending" />
          </Link>
        </Menu.Item>
        <Menu.Item key="main/airtm/failed">
          <Link to="/main/airtm/failed">
            <i className="icon icon-close-circle" />
            <IntlMessages id="sidebar.airtm.failed" />
          </Link>
        </Menu.Item>
      </SubMenu>
      );


    }
    if (this.state.useAirtm && this.props.dataUser.typeUser != "merchant") {

      menuAirtmPayOut = (<SubMenu
        className={this.getNavStyleSubMenuClass(navStyle)}
        key="airtmPayOut"
        title={"AirtmPayOut"}
      >
        <Menu.Item key="main/airtmPayOut/validated">
          <Link to="/main/airtmPayOut/validated">
            <i className="icon icon-check" />
            <IntlMessages id="sidebar.airtm.validated" />
          </Link>
        </Menu.Item>
        <Menu.Item key="main/airtmPayOut/pending">
          <Link to="/main/airtmPayOut/pending">
            <i className="icon icon-timepicker" />
            <IntlMessages id="sidebar.airtm.pending" />
          </Link>
        </Menu.Item>
        <Menu.Item key="main/airtmPayOut/failed">
          <Link to="/main/airtmPayOut/failed">
            <i className="icon icon-close-circle" />
            <IntlMessages id="sidebar.airtm.failed" />
          </Link>
        </Menu.Item>
      </SubMenu>
      );


    }
    console.log("useCrypto", this.state.useCrypto)
    if (this.state.useCrypto && (this.props.dataUser.typeUser == "admin" || this.props.dataUser.typeUser ==  "merchant" || this.props.dataUser.typeUser ==  "soporte" || this.props.dataUser.typeUser ==  "soporteAdmin")){
      menuCrypto = (<SubMenu
        className={this.getNavStyleSubMenuClass(navStyle)}
        key="crypto"
        title={"Crypto"}
      >
        <Menu.Item key="main/crypto">
          <Link to="/main/crypto">
            <i className="icon icon-check" />
            <IntlMessages id={this.props.dataUser.typeUser == "admin" ? "sidebar.crypto.maintainer" :"sidebar.crypto.payin" }/>
          </Link>
        </Menu.Item>
      </SubMenu>
      );
    }
    return (
      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal"
      >
        <Menu.Item key="main/dashboard">
          <Link to="/main/dashboard">
            <div style={{ color: "white" }}>
              {/* <i className="icon icon-dasbhoard" /> */}
              <IntlMessages id="sidebar.dashboard" />
            </div>
          </Link>
        </Menu.Item>
        {menuCashin}
        {menuWebpay}
        {menuCashout}
        {menuPayroll}
        {menuPayIn}
        {menuPayOut}
        {/*   {menuflow} */}
        {menupayku}
        {menuSkinback}
        {menuKushkiWebPay}
        {menuAirtm}
        {menuAirtmPayOut}
        {menu}
        {menuCrypto}
      </Menu>
    );
  }
}

HorizontalNav.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { themeType, navStyle, pathname, locale, dataUser, merchantContr, merchant, comissionData, country } = settings;
  console.log("settings", settings)
  const { authUser } = auth;
  return { themeType, navStyle, pathname, locale, authUser, dataUser, merchantContr, merchant, comissionData, country };
};
export default connect(mapStateToProps)(HorizontalNav);
