import React, { Component } from "react";
import { firebase } from "../../../firebase/firebase";
import { connect } from "react-redux";
import moment from "moment-timezone";
import Widget from "components/Widget/index";
import { Table } from "antd";
import { switchMerchant } from "../../../appRedux/actions/Setting";
import { withRouter } from "react-router-dom";
const db = firebase.firestore();

class PendingMerchants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cashinPendientes1xbet: "0",
      cashinPendientesJuegaloBet: "0",
      cashinPendientesCEstelar:"0",
      cashinPendientesJuegaloPro:"0",
      cashinPendientesDw:"0",
      cashinPendientesEManagement: "0",
      cashinPendientesPayretailers:"0",
      cashinPendientesAbudantia: "0",
      cashinPendientesTechsolution: "0",
      cashinPendientesZavbin:"0",
      cashinPendientesBetBoom:"0",
      cashinPendientesGranawin:"0",
      cashinPendientesMegapari:"0",
      cashinPendientesTechOptions:"0",
      cashinPendientes22bet:"0",
      cashinPendientesOptima:"0",
      cashinPendientesMobinc:"0",
      

      cashoutPendientes1xbet:"0",
      cashoutPendientesJuegaloBet: "0",
      cashoutPendientesCEstelar:"0",
      cashoutPendientesJuegaloPro:"0",
      cashoutPendientesDw: "0",
      cashoutPendientesEManagement: "0",
      cashoutPendientesPayretailers: "0",
      cashoutPendientesAbudantia: "0",
      cashoutPendientesTechsolution: "0",
      cashoutPendientesJuegaloCom:"0",
      cashoutPendientesZavbin:"0",
      cashoutPendientesBetBoom:"0",
      cashoutPendientesGranawin:"0",
      cashoutPendientesMegapari:"0",
      cashoutPendientesTechOptions:"0",
      cashoutPendientes22bet:"0",
      cashoutPendientesOptima:"0",
      cashoutPendientesMobinc:"0",

      payOutPendientes1xbet:"0",
      payOutPendientesJuegaloBet: "0",
      payOutPendientesCEstelar:"0",
      cpayOutPendientesJuegaloPro:"0",
      payOutPendientesDw: "0",
      payOutPendientesEManagement: "0",
      payOutPendientesPayretailers: "0",
      payOutPendientesAbudantia: "0",
      payOutPendientesTechsolution: "0",
      payOutPendientesJuegaloCom:"0",
      payOutPendientesZavbin:"0",
      payOutPendientesBetBoom:"0",
      payOutPendientesGranawin:"0",
      payOutPendientesMegapari:"0",
      payOutPendientesTechOptions:"0",
      payOutPendientes22bet:"0",
      payOutPendientesOptima:"0",
      payOutPendientesMobinc:"0",

    };
  }
  componentDidMount = () => {

    if (this.props.country.code === "CL") {
      this.getAllData();
    }
    else{
      this.reset();
    }
  };
  componentDidUpdate(prevProps) {
  /*   console.log("this.props.country","componentDidUpdate",this.props.country); */
    if (this.props !== prevProps) {
      if (this.props.country.code === "CL") {
        this.getAllData();
      }
      else{
        this.reset();
      }

    }
  }

  reset =()=>
  {
    this.state = {
      cashinPendientes1xbet: "0",
      cashinPendientesJuegaloBet: "0",
      cashinPendientesCEstelar:"0",
      cashinPendientesJuegaloPro: "0",
      cashinPendientesDw: "0",
      cashinPendientesEManagement:"0",
      cashinPendientesPayretailers:"0",
      cashinPendientesAbudantia:"0",
      cashinPendientesTechsolution:"0",
      cashinPendientesJuegaloCom:"0",
      cashinPendientesZavbin:"0",
      cashinPendientesBetBoom:"0",
      cashinPendientesGranawin:"0",
      cashinPendientesMegapari:"0",
      cashinPendientesTechOptions:"0",
      cashinPendientes22bet:"0",
      cashinPendientesOptima:"0",
      cashinPendientesMobinc:"0",

      cashoutPendientes1xbet:"0",
      cashoutPendientesJuegaloBet:"0",
      cashoutPendientesCEstelar: "0",
      cashoutPendientesJuegaloPro: "0",
      cashoutPendientesDw: "0",
      cashoutPendientesEManagement: "0",
      cashoutPendientesPayretailers:"0",
      cashoutPendientesAbudantia:"0",
      cashoutPendientesTechsolution: "0",
      cashoutPendientesJuegaloCom: "0",
      cashoutPendientesZavbin: "0",
      cashoutPendientesBetBoom:"0",
      cashoutPendientesGranawin:"0",
      cashoutPendientesMegapari:"0",
      cashoutPendientesTechOptions:"0",
      cashoutPendientes22bet:"0",
      cashoutPendientesOptima:"0",
      cashoutPendientesMobinc:"0",

      payOutPendientes1xbet:"0",
      payOutPendientesJuegaloBet: "0",
      payOutPendientesCEstelar:"0",
      cpayOutPendientesJuegaloPro:"0",
      payOutPendientesDw: "0",
      payOutPendientesEManagement: "0",
      payOutPendientesPayretailers: "0",
      payOutPendientesAbudantia: "0",
      payOutPendientesTechsolution: "0",
      payOutPendientesJuegaloCom:"0",
      payOutPendientesZavbin:"0",
      payOutPendientesBetBoom:"0",
      payOutPendientesGranawin:"0",
      payOutPendientesMegapari:"0",
      payOutPendientesTechOptions:"0",
      payOutPendientes22bet:"0",
      payOutPendientesOptima:"0",
      payOutPendientesMobinc:"0",
    };
  }

  getAllData = () => {

    /*  console.log("update pending table"); */

  /*   let now = moment
      .tz("America/Santiago")
      .startOf("month")
      .toDate(); */
      let now = moment().tz("America/Santiago").subtract(5, "days").toDate();

    let queryCashinPending = db
      .collection("transfers")
      .where("code", "==", 9)
      .where("dateRequest", ">", now)
      /* .where("country","==", this.props.country.code); */

    let queryCashoutPending = db
      .collection("cashouts")
      .where("code", "==", 9)
      .where("dateRequest", ">", now)
      /* .where("country", ">", now); */

      let queryPayOutPending = db
      .collection("monnetPayOuts")
      .where("code", "==", 9)
      .where("dateRequest", ">", now)
      //.where("country", ">", "CL")


    this.cashinPendientes(queryCashinPending);
    this.cashoutPendientes(queryCashoutPending);
    this.payOutPendientes(queryPayOutPending);
  };
  cashinPendientes = queryPending => {
    let cashinPendientes1xbet;
    let cashinPendientesJuegaloBet;
    let cashinPendientesCEstelar;
    let cashinPendientesJuegaloPro;
    let cashinPendientesDw;
    let cashinPendientesEManagement;
    let cashinPendientesPayretailers;
    let  cashinPendientesAbudantia;
    let cashinPendientesTechsolution;
    let cashinPendientesJuegaloCom;
    let cashinPendientesZavbin;
    let cashinPendientesBetBoom;
    let cashinPendientesGranawin;
    let cashinPendientesMegapari;
    let cashinPendientesTechOptions;
    let cashinPendientes22bet;
    let cashinPendientesOptima;
    let cashinPendientesMobinc;

    queryPending.get().then(
      querySnapshot => {
        var docs = querySnapshot.docs.map(doc => doc.data());
        
        cashinPendientes1xbet = docs.filter(
          reg => reg.commerceId === "2019CL1xbet-8k3y"
        );
        this.setState({
          cashinPendientes1xbet: cashinPendientes1xbet.length
        });
        cashinPendientesJuegaloBet = docs.filter(
          reg => reg.commerceId === "2020juegalo-5n2q"
        );
        this.setState({
          cashinPendientesJuegaloBet: cashinPendientesJuegaloBet.length
        });

        cashinPendientesCEstelar = docs.filter(
          reg => reg.commerceId === "2020cestelar-3j9s"
        );
        this.setState({
          cashinPendientesCEstelar: cashinPendientesCEstelar.length
        });

        cashinPendientesJuegaloPro = docs.filter(
          reg => reg.commerceId === "2020juegalopro-7j7g"
        );
        this.setState({
          cashinPendientesJuegaloPro: cashinPendientesJuegaloPro.length
        });

        cashinPendientesDw = docs.filter(
          reg => reg.commerceId === "2020dw-6d9w"
        );
        this.setState({
          cashinPendientesDw: cashinPendientesDw.length
        });

        cashinPendientesEManagement = docs.filter(
          reg => reg.commerceId === "2020e-Management2u5i"
        );
        this.setState({
          cashinPendientesEManagement: cashinPendientesEManagement.length
        });

        cashinPendientesPayretailers = docs.filter(
          reg => reg.commerceId === "2020Payretailers7g21"
        );
        this.setState({
          cashinPendientesPayretailers: cashinPendientesPayretailers.length
        });

        cashinPendientesAbudantia = docs.filter(
          reg => reg.commerceId === "2021abudantia-2m5i"
        );
        this.setState({
          cashinPendientesAbudantia: cashinPendientesAbudantia.length
        });

        cashinPendientesTechsolution = docs.filter(
          reg => reg.commerceId === "2020techsolutions22gf"
        );
        this.setState({
          cashinPendientesTechsolution: cashinPendientesTechsolution.length
        });

        cashinPendientesJuegaloCom = docs.filter(
          reg => reg.commerceId === "2021juegaloCom-9n3u"
        );
        this.setState({
          cashinPendientesJuegaloCom: cashinPendientesJuegaloCom.length
        });

        cashinPendientesZavbin = docs.filter(
          reg => reg.commerceId === "2022Zavbin-6k9f"
        );
        this.setState({
          cashinPendientesZavbin: cashinPendientesZavbin.length
        });

        cashinPendientesBetBoom = docs.filter(
          reg => reg.commerceId === "2022Betboom-9d2k"
        );
        this.setState({
          cashinPendientesBetBoom: cashinPendientesBetBoom.length
        });

        cashinPendientesGranawin = docs.filter(
          reg => reg.commerceId === "2023Granawin-4g5w"
        );
        this.setState({
          cashinPendientesGranawin: cashinPendientesGranawin.length
        });

        cashinPendientesMegapari = docs.filter(
          reg => reg.commerceId === "2022Megapari-10u7"
        );
        this.setState({
          cashinPendientesMegapari: cashinPendientesMegapari.length
        });

        cashinPendientesTechOptions = docs.filter(
          reg => reg.commerceId === "2023TechOptions1F7c"
        );
        this.setState({
          cashinPendientesTechOptions: cashinPendientesTechOptions.length
        });

        cashinPendientes22bet = docs.filter(
          reg => reg.commerceId === "202322Bet-5f3s"
        );
        this.setState({
          cashinPendientes22bet: cashinPendientes22bet.length
        });

        cashinPendientesOptima = docs.filter(
          reg => reg.commerceId === "2024Optima-1a7x"
        );
        this.setState({
          cashinPendientesOptima: cashinPendientesOptima.length
        });

        cashinPendientesMobinc = docs.filter(
          reg => reg.commerceId === "2023MobInc7h4s"
        );
        this.setState({
          cashinPendientesMobinc: cashinPendientesMobinc.length
        });

      },
      err => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  cashoutPendientes = queryPending => {
    let cashoutPendientes1xbet;
    let cashoutPendientesJuegaloBet;
    let cashoutPendientesCEstelar;
    let cashoutPendientesJuegaloPro;
    let cashoutPendientesDw;
    let cashoutPendientesEManagement;
    let cashoutPendientesPayretailers;
    let cashoutPendientesAbudantia;
    let cashoutPendientesTechsolution;
    let cashoutPendientesJuegaloCom;
    let cashoutPendientesZavbin;
    let cashoutPendientesBetBoom;
    let cashoutPendientesGranawin;
    let cashoutPendientesMegapari;
    let cashoutPendientesTechOptions;
    let cashoutPendientes22bet;
    let cashoutPendientesOptima;
    let cashoutPendientesMobinc;
    
    queryPending.get().then(
      querySnapshot => {
        var docs = querySnapshot.docs.map(doc => doc.data());

        cashoutPendientes1xbet = docs.filter(
          reg => reg.commerceId === "2019CL1xbet-8k3y"
        );
        console.log(`resFirestore`,cashoutPendientes1xbet.length);
        this.setState({
          cashoutPendientes1xbet: cashoutPendientes1xbet.length
        });
        cashoutPendientesJuegaloBet = docs.filter(
          reg => reg.commerceId === "2020juegalo-5n2q"
        );
        this.setState({
          cashoutPendientesJuegaloBet: cashoutPendientesJuegaloBet.length
        });

        cashoutPendientesCEstelar = docs.filter(
          reg => reg.commerceId === "2020cestelar-3j9s"
        );
        this.setState({
          cashoutPendientesCEstelar: cashoutPendientesCEstelar.length
        });


        cashoutPendientesJuegaloPro = docs.filter(
          reg => reg.commerceId === "2020juegalopro-7j7g"
        );
        this.setState({
          cashoutPendientesJuegaloPro: cashoutPendientesJuegaloPro.length
        });

        cashoutPendientesDw = docs.filter(
          reg => reg.commerceId === "2020dw-6d9w"
        );
        this.setState({
          cashoutPendientesDw: cashoutPendientesDw.length
        });

        cashoutPendientesEManagement = docs.filter(
          reg => reg.commerceId === "2020e-Management2u5i"
        );
        this.setState({
          cashoutPendientesEManagement: cashoutPendientesEManagement.length
        });

        cashoutPendientesPayretailers = docs.filter(
          reg => reg.commerceId === "2020Payretailers7g21"
        );
        this.setState({
          cashoutPendientesPayretailers: cashoutPendientesPayretailers.length
        });

        cashoutPendientesAbudantia = docs.filter(
          reg => reg.commerceId === "2021abudantia-2m5i"
        );
        this.setState({
          cashoutPendientesAbudantia: cashoutPendientesAbudantia.length
        });

        cashoutPendientesTechsolution = docs.filter(
          reg => reg.commerceId === "2020techsolutions22gf"
        );
        this.setState({
          cashoutPendientesTechsolution: cashoutPendientesTechsolution.length
        });

        cashoutPendientesJuegaloCom = docs.filter(
          reg => reg.commerceId === "2021juegaloCom-9n3u"
        );
        this.setState({
          cashoutPendientesJuegaloCom: cashoutPendientesJuegaloCom.length
        });

        cashoutPendientesZavbin = docs.filter(
          reg => reg.commerceId === "2022Zavbin-6k9f"
        );
        this.setState({
          cashoutPendientesZavbin: cashoutPendientesZavbin.length
        });

        cashoutPendientesBetBoom = docs.filter(
          reg => reg.commerceId === "2022Betboom-9d2k"
        );
        this.setState({
          cashoutPendientesBetBoom: cashoutPendientesBetBoom.length
        });

        cashoutPendientesGranawin = docs.filter(
          reg => reg.commerceId === "2023Granawin-4g5w"
        );
        this.setState({
          cashoutPendientesGranawin: cashoutPendientesGranawin.length
        });

        cashoutPendientesMegapari = docs.filter(
          reg => reg.commerceId === "2022Megapari-10u7"
        );
        this.setState({
          cashoutPendientesMegapari: cashoutPendientesMegapari.length
        });

        cashoutPendientesTechOptions = docs.filter(
          reg => reg.commerceId === "2023TechOptions1F7c"
        );
        this.setState({
          cashoutPendientesTechOptions: cashoutPendientesTechOptions.length
        });

        cashoutPendientes22bet = docs.filter(
          reg => reg.commerceId === "202322Bet-5f3s"
        );
        this.setState({
          cashoutPendientes22bet: cashoutPendientes22bet.length
        });

        cashoutPendientesOptima = docs.filter(
          reg => reg.commerceId === "2024Optima-1a7x"
        );
        this.setState({
          cashoutPendientesOptima: cashoutPendientesOptima.length
        });

        cashoutPendientesMobinc = docs.filter(
          reg => reg.commerceId === "2023MobInc7h4s"
        );
        this.setState({
          cashoutPendientesMobinc: cashoutPendientesMobinc.length
        });
       
      },
      err => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  payOutPendientes = queryPending => {
    let payOutPendientes1xbet;
    let payOutPendientesJuegaloBet;
    let payOutPendientesCEstelar;
    let payOutPendientesJuegaloPro;
    let payOutPendientesDw;
    let payOutPendientesEManagement;
    let payOutPendientesPayretailers;
    let payOutPendientesAbudantia;
    let payOutPendientesTechsolution;
    let payOutPendientesJuegaloCom;
    let payOutPendientesZavbin;
    let payOutPendientesBetBoom;
    let payOutPendientesGranawin;
    let payOutPendientesMegapari;
    let payOutPendientesTechOptions;
    let payOutPendientes22bet;
    let payOutPendientesOptima;
    let payOutPendientesMobinc;

    queryPending.get().then(
      querySnapshot => {
        var docs = querySnapshot.docs.map(doc => doc.data());

        payOutPendientes1xbet = docs.filter(
          reg => reg.commerceId === "2019CL1xbet-8k3y"
        );
        console.log(`resFirestore`,payOutPendientes1xbet.length);
        this.setState({
          payOutPendientes1xbet: payOutPendientes1xbet.length
        });
        payOutPendientesJuegaloBet = docs.filter(
          reg => reg.commerceId === "2020juegalo-5n2q"
        );
        this.setState({
          payOutPendientesJuegaloBet: payOutPendientesJuegaloBet.length
        });

        payOutPendientesCEstelar = docs.filter(
          reg => reg.commerceId === "2020cestelar-3j9s"
        );
        this.setState({
          payOutPendientesCEstelar: payOutPendientesCEstelar.length
        });

        payOutPendientesJuegaloPro = docs.filter(
          reg => reg.commerceId === "2020juegalopro-7j7g"
        );
        this.setState({
          payOutPendientesJuegaloPro: payOutPendientesJuegaloPro.length
        });

        payOutPendientesDw = docs.filter(
          reg => reg.commerceId === "2020dw-6d9w"
        );
        this.setState({
          payOutPendientesDw: payOutPendientesDw.length
        });

        payOutPendientesEManagement = docs.filter(
          reg => reg.commerceId === "2020e-Management2u5i"
        );
        this.setState({
          payOutPendientesEManagement: payOutPendientesEManagement.length
        });

        payOutPendientesPayretailers = docs.filter(
          reg => reg.commerceId === "2020Payretailers7g21"
        );
        this.setState({
          payOutPendientesPayretailers: payOutPendientesPayretailers.length
        });

        payOutPendientesAbudantia = docs.filter(
          reg => reg.commerceId === "2021abudantia-2m5i"
        );
        this.setState({
          payOutPendientesAbudantia: payOutPendientesAbudantia.length
        });

        payOutPendientesTechsolution = docs.filter(
          reg => reg.commerceId === "2020techsolutions22gf"
        );
        this.setState({
          payOutPendientesTechsolution: payOutPendientesTechsolution.length
        });

        payOutPendientesJuegaloCom = docs.filter(
          reg => reg.commerceId === "2021juegaloCom-9n3u"
        );
        this.setState({
          payOutPendientesJuegaloCom: payOutPendientesJuegaloCom.length
        });

        payOutPendientesZavbin = docs.filter(
          reg => reg.commerceId === "2022Zavbin-6k9f"
        );
        this.setState({
          payOutPendientesZavbin: payOutPendientesZavbin.length
        });

        payOutPendientesBetBoom = docs.filter(
          reg => reg.commerceId === "2022Betboom-9d2k"
        );
        this.setState({
          payOutPendientesBetBoom: payOutPendientesBetBoom.length
        });

        payOutPendientesGranawin = docs.filter(
          reg => reg.commerceId === "2023Granawin-4g5w"
        );
        this.setState({
          payOutPendientesGranawin: payOutPendientesGranawin.length
        });

        payOutPendientesMegapari = docs.filter(
          reg => reg.commerceId === "2022Megapari-10u7"
        );
        this.setState({
          payOutPendientesMegapari: payOutPendientesMegapari.length
        });

        payOutPendientesTechOptions = docs.filter(
          reg => reg.commerceId === "2023TechOptions1F7c"
        );
        this.setState({
          payOutPendientesTechOptions: payOutPendientesTechOptions.length
        });

        payOutPendientes22bet = docs.filter(
          reg => reg.commerceId === "202322Bet-5f3s"
        );
        this.setState({
          payOutPendientes22bet: payOutPendientes22bet.length
        });

        payOutPendientesOptima = docs.filter(
          reg => reg.commerceId === "2024Optima-1a7x"
        );
        this.setState({
          payOutPendientesOptima: payOutPendientesOptima.length
        });

        payOutPendientesMobinc = docs.filter(
          reg => reg.commerceId === "2023MobInc7h4s"
        );
        this.setState({
          payOutPendientesMobinc: payOutPendientesMobinc.length
        });
       
      },
      err => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };


  goToCashin = (record, switchMerchant) => {
    /*   console.log("record", "=>", record); */
    let merchant;
    if (record) {
      switch (record.name) {
        case "1xbet":
          merchant = { code: "2019CL1xbet-8k3y", name: "1xBet" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");
        case "JuegaloBet":
          merchant = { code: "2020juegalo-5n2q", name: "JuegaloBet" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");

        /*         case "CEstelar":
          merchant = { code: "2020cestelar-3j9s", name: "CEstelar" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending"); */

        case "JuegaloPro":
          merchant = { code: "2020juegalopro-7j7g", name: "JuegaloPro" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");

        /*
        case "Dotworkers":
          merchant = { code: "2020dw-6d9w", name: "Dotworkers" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending"); */

        case "E-Management":
          merchant = { code: "2020e-Management2u5i", name: "E-Management" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");

        case "PayRetailers":
          merchant = { code: "2020Payretailers7g21", name: "PayRetailers" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");

        case "Abudantia":
          merchant = { code: "2021abudantia-2m5i", name: "Abudantia" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");

        case "Techsolutions":
          merchant = { code: "2020techsolutions22gf", name: "Techsolutions" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");
        case "JuegaloCom":
          merchant = { code: "2021juegaloCom-9n3u", name: "JuegaloCom" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");
        case "Zavbin":
          merchant = { code: "2022Zavbin-6k9f", name: "Zavbin" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");
        case "BetBoom":
          merchant = { code: "2022Betboom-9d2k", name: "BetBoom" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");
        case "Granawin":
          merchant = { code: "2023Granawin-4g5w", name: "Granawin" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");
        case "Megapari":
          merchant = { code: "2022Megapari-10u7", name: "Megapari" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");
        case "TechOptions":
          merchant = { code: "2023TechOptions1F7c", name: "TechOptions" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");
        case "22Bet":
          merchant = { code: "202322Bet-5f3s", name: "22Bet" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");
        case "Optima":
          merchant = { code: "2024Optima-1a7x", name: "Optima" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");
        case "MobInc-Betnroll":
          merchant = { code: "2023MobInc7h4s", name: "MobInc-Betnroll" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashin/pending");
          return this.props.history.push("/main/cashin/pending");
        default:
          console.log("-----------------");
          return null;
      }
    }
  };
  goToCashout = (record, switchMerchant) => {
    //cambia el combo merchant
    /*  console.log("record vgoToJuegalobet", "=>", record); */
    let merchant = { code: "2020juegalo-5n2q", name: "JuegaloBet" };
    this.props.switchMerchant(merchant);
    localStorage.setItem("merchant_code", merchant.code);
    localStorage.setItem("merchant_name", merchant.name);

    //redirecciona segun link
    if (record) {
      switch (record.name) {
        case "1xbet":
          merchant = { code: "2019CL1xbet-8k3y", name: "1xBet" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");
        case "JuegaloBet":
          merchant = { code: "2020juegalo-5n2q", name: "JuegaloBet" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");

        /*  case "CEstelar":
          merchant = { code: "2020cestelar-3j9s", name: "CEstelar" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending"); */

        case "JuegaloPro":
          merchant = { code: "2020juegalopro-7j7g", name: "JuegaloPro" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");

        /*  case "Dotworkers":
          merchant = { code: "2020dw-6d9w", name: "Dotworkers" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending"); */
        case "E-Management":
          merchant = { code: "2020e-Management2u5i", name: "E-Management" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");
        case "PayRetailers":
          merchant = { code: "2020Payretailers7g21", name: "PayRetailers" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");

        case "Abudantia":
          merchant = { code: "2021abudantia-2m5i", name: "Abudantia" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");

        case "Techsolutions":
          merchant = { code: "2020techsolutions22gf", name: "Techsolutions" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");
        case "JuegaloCom":
          merchant = { code: "2021juegaloCom-9n3u", name: "JuegaloCom" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");
        case "Zavbin":
          merchant = { code: "2022Zavbin-6k9f", name: "Zavbin" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");
        case "BetBoom":
          merchant = { code: "2022Betboom-9d2k", name: "BetBoom" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");
        case "Granawin":
          merchant = { code: "2023Granawin-4g5w", name: "Granawin" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");
        case "Megapari":
          merchant = { code: "2022Megapari-10u7", name: "Megapari" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");
        case "TechOptions":
          merchant = { code: "2023TechOptions1F7c", name: "TechOptions" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");
        case "22Bet":
          merchant = { code: "202322Bet-5f3s", name: "22Bet" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");
        case "Optima":
          merchant = { code: "2024Optima-1a7x", name: "Optima" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");
        case "MobInc-Betnroll":
          merchant = { code: "2023MobInc7h4s", name: "MobInc-Betnroll" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending");
        default:
          console.log("-----------------");
          return null;
      }
    }
  };

  goToPayOut= (record, switchMerchant) => {
    //cambia el combo merchant
    /*  console.log("record vgoToJuegalobet", "=>", record); */
    let merchant = { code: "2020juegalo-5n2q", name: "JuegaloBet" };
    this.props.switchMerchant(merchant);
    localStorage.setItem("merchant_code", merchant.code);
    localStorage.setItem("merchant_name", merchant.name);

    //redirecciona segun link
    if (record) {
      switch (record.name) {
        case "1xbet":
          merchant = { code: "2019CL1xbet-8k3y", name: "1xBet" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        case "JuegaloBet":
          merchant = { code: "2020juegalo-5n2q", name: "JuegaloBet" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");

        /*  case "CEstelar":
          merchant = { code: "2020cestelar-3j9s", name: "CEstelar" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending"); */

        case "JuegaloPro":
          merchant = { code: "2020juegalopro-7j7g", name: "JuegaloPro" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");

        /*  case "Dotworkers":
          merchant = { code: "2020dw-6d9w", name: "Dotworkers" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/cashout/pending");
          return this.props.history.push("/main/cashout/pending"); */
        case "E-Management":
          merchant = { code: "2020e-Management2u5i", name: "E-Management" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        case "PayRetailers":
          merchant = { code: "2020Payretailers7g21", name: "PayRetailers" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        case "Abudantia":
          merchant = { code: "2021abudantia-2m5i", name: "Abudantia" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");

        case "Techsolutions":
          merchant = { code: "2020techsolutions22gf", name: "Techsolutions" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        case "JuegaloCom":
          merchant = { code: "2021juegaloCom-9n3u", name: "JuegaloCom" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        case "Zavbin":
          merchant = { code: "2022Zavbin-6k9f", name: "Zavbin" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        case "BetBoom":
          merchant = { code: "2022Betboom-9d2k", name: "BetBoom" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        case "Granawin":
          merchant = { code: "2023Granawin-4g5w", name: "Granawin" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        case "Megapari":
          merchant = { code: "2022Megapari-10u7", name: "Megapari" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        case "TechOptions":
          merchant = { code: "2023TechOptions1F7c", name: "TechOptions" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        case "22Bet":
          merchant = { code: "202322Bet-5f3s", name: "22Bet" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        case "Optima":
          merchant = { code: "2024Optima-1a7x", name: "Optima" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        case "MobInc-Betnroll":
          merchant = { code: "2023MobInc7h4s", name: "MobInc-Betnroll" };
          this.props.switchMerchant(merchant);
          localStorage.setItem("merchant_code", merchant.code);
          localStorage.setItem("merchant_name", merchant.name);
          localStorage.setItem("pathname", "/main/payout/pending");
          return this.props.history.push("/main/payout/pending");
        default:
          console.log("-----------------");
          return null;
      }
    }
  };



  render() {
    const columns = [
      {
        title: "Merchant",
        dataIndex: "name",
        width: 150,
      },
      {
        title: "Cashin",
        dataIndex: "Cashin",
        width: 50,
      },
      {
        title: "Action", //action 1xbet
        key: "action1",
        width: 50,
        render: (text, record) => (
          <a
            href="#/"
            style={{ marginRight: 16 }}
            onClick={e => this.goToCashin(record, this.props.switchMerchant)}
          >
           go
          </a>
        )
      },
      {
        title: "Cashout", //action 1xbet
        dataIndex: "Cashout"
        ,
        width: 50,
      },
      {
        title: "Action",
        key: "action",
        width: 50,
        render: (text, record) => (
          <a
            href="#/"
            style={{ marginRight: 16 }}
            onClick={e => this.goToCashout(record, this.props.switchMerchant)}
          >
            go
          </a>
        )
      },
      {
        title: "PayOut", //action 1xbet
        dataIndex: "PayOut",
        width: 50,
      },
      {
        title: "Action",
        key: "action",
        width: 50,
        render: (text, record) => (
          <a
            href="#/"
            style={{ marginRight: 16 }}
            onClick={e => this.goToPayOut(record, this.props.switchMerchant)}
          >
            go
          </a>
        )
      }
    ];

    const data = [
      {
        key: "1",
        name: "1xbet",
        Cashin: this.state.cashinPendientes1xbet,
        Cashout: this.state.cashoutPendientes1xbet,
        PayOut: this.state.payOutPendientes1xbet,
      },
      {
        key: "2",
        name: "BetBoom",
        Cashin: this.state.cashinPendientesBetBoom,
        Cashout: this.state.cashoutPendientesBetBoom,
        PayOut: this.state.payOutPendientesBetBoom
      },
    /*   {
        key: "3",
        name: "CEstelar",
        Cashin: this.state.cashinPendientesCEstelar,
        Cashout: this.state.cashoutPendientesCEstelar
      }, */
      // {
      //   key: "3",
      //   name: "JuegaloPro",
      //   Cashin: this.state.cashinPendientesJuegaloPro,
      //   Cashout: this.state.cashoutPendientesJuegaloPro,
      //   PayOut: this.state.payOutPendientesJuegaloPro
      // },
     /*  {
        key: "5",
        name: "Dotworkers",
        Cashin: this.state.cashinPendientesDw,
        Cashout: this.state.cashoutPendientesDw
      }, */
      {
        key: "4",
        name: "E-Management",
        Cashin: this.state.cashinPendientesEManagement,
        Cashout: this.state.cashoutPendientesEManagement,
        PayOut: this.state.payOutPendientesEManagement
      },
      // {
      //   key: "5",
      //   name: "PayRetailers",
      //   Cashin: this.state.cashinPendientesPayretailers,
      //   Cashout: this.state.cashoutPendientesPayretailers,
      //   PayOut: this.state.payOutPendientesPayretailers
      // },

      {
        key: "6",
        name: "Abudantia",
        Cashin: this.state.cashinPendientesAbudantia,
        Cashout: this.state.cashoutPendientesAbudantia,
        PayOut: this.state.payOutPendientesAbudantia
      },
      {
        key: "7",
        name: "Techsolutions",
        Cashin: this.state.cashinPendientesTechsolution,
        Cashout: this.state.cashoutPendientesTechsolution,
        PayOut: this.state.payOutPendientesTechsolution
      },
      {
        key: "8",
        name: "JuegaloCom",
        Cashin: this.state.cashinPendientesJuegaloCom,
        Cashout: this.state.cashoutPendientesJuegaloCom,
        PayOut: this.state.payOutPendientesJuegaloCom
      },
      {
        key: "9",
        name: "Zavbin",
        Cashin: this.state.cashinPendientesZavbin,
        Cashout: this.state.cashoutPendientesZavbin,
        PayOut: this.state.payOutPendientesZavbin
      },
      {
        key: "10",
        name: "Granawin",
        Cashin: this.state.cashinPendientesGranawin,
        Cashout: this.state.cashoutPendientesGranawin,
        PayOut: this.state.payOutPendientesGranawin
      },
      {
        key: "11",
        name: "Megapari",
        Cashin: this.state.cashinPendientesMegapari,
        Cashout: this.state.cashoutPendientesMegapari,
        PayOut: this.state.payOutPendientesMegapari
      },
      {
        key: "12",
        name: "TechOptions",
        Cashin: this.state.cashinPendientesTechOptions,
        Cashout: this.state.cashoutPendientesTechOptions,
        PayOut: this.state.payOutPendientesTechOptions
      },
      {
        key: "13",
        name: "22Bet",
        Cashin: this.state.cashinPendientes22bet,
        Cashout: this.state.cashoutPendientes22bet,
        PayOut: this.state.payOutPendientes22bet
      },
      {
        key: "14",
        name: "Optima",
        Cashin: this.state.cashinPendientesOptima,
        Cashout: this.state.cashoutPendientesOptima,
        PayOut: this.state.payOutPendientesOptima
      },
      {
        key: "15",
        name: "MobInc-Betnroll",
        Cashin: this.state.cashinPendientesMobinc,
        Cashout: this.state.cashoutPendientesMobinc,
        PayOut: this.state.payOutPendientesMobinc
      },
    ];

    return (
      <Widget
        styleName="gx-order-history"
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0">
            Merchants Pending{" "}
            <i
              className={`flag flag-24  flag-${this.props.country.code.toLowerCase()}`}
            />
          </h2>
        }
      >
        <div className="gx-table-responsive">
          <Table
            className="gx-table-no-bordered"
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered={false}
            size="small"
          />
        </div>
      </Widget>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, country, dataUser } = settings;
  return { authUser, merchant, country, dataUser };
};

/* export default connect(
  mapStateToProps,
  switchMerchant
)(PendingMerchants);

 */
export default withRouter(
  connect(mapStateToProps, { switchMerchant })(PendingMerchants)
);
