import { Button, Form, Modal, Select, Switch } from "antd";
import React, { useEffect } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

const { Option } = Select;
export default function ModalMerchantAdministration({
  modal,
  onCancel,
  commercesAll,
  merchants,
  onHandleSubmit
}) {
  const shemaValidation = Yup.object().shape({
    commerce_id: Yup.string().required("Commerce is required"),
    isActiveNewCashout: Yup.boolean(),
    isAllowedNewCashout: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      commerce_id: "",
      isActiveNewCashout: false,
      isAllowedNewCashout: false
    },
    validationSchema: shemaValidation,
    onSubmit: values => {
      onHandleSubmit({
        type: modal.type,
        values
      });
      formik.resetForm();
      onCancel();
    }
  });

  useEffect(() => {
    formik.setValues(modal.data);
  }, [modal]);

  return (
    <>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        onSubmit={formik.handleSubmit}
      >
        <Modal
          title={modal.type == "edit" ? "Update merchant" : "Add merchant"}
          visible={modal.isVisible}
          onCancel={onCancel}
          footer={[
            <Button htmlType="submit" onClick={onCancel} key="cancel-button">
              Cancel
            </Button>,
            <Button
              type="primary"
              htmlType="submit"
              onClick={formik.handleSubmit}
              key="submit-button"
            >
              {modal.type == "edit" ? "Update merchant" : "Add merchant"}
            </Button>
          ]}
        >
          <Form.Item
            label="Commerces"
            hasFeedback
            validateStatus={
              formik.errors.commerce_id &&
              formik.touched.commerce_id &&
              formik.errors.commerce_id
                ? "error"
                : ""
            }
            help={
              formik.errors.commerce_id &&
              formik.touched.commerce_id &&
              formik.errors.commerce_id
            }
          >
            <Select
              placeholder="Please select a country"
              onChange={value =>
                formik.setFieldValue("commerce_id", value, true)
              }
              value={formik.values.commerce_id}
              style={{ width: "100%" }}
              disabled={modal.type == "edit"}
            >
              <Option value="">-- Commerces --</Option>
              {commercesAll.map(commerce => (
                <Option
                  key={commerce.id}
                  value={commerce.id}
                  disabled={merchants.find(
                    merchant => merchant.commerce_id == commerce.id
                  )}
                >
                  {commerce.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="isActiveNewCashout">
            <Switch
              onChange={value => {
                formik.setFieldValue("isActiveNewCashout", value, true);
                if (!value) {
                  formik.setFieldValue("isAllowedNewCashout", false, true);
                }
              }}
              checked={formik.values.isActiveNewCashout}
            />
          </Form.Item>
          <Form.Item label="isAllowedNewCashout">
            <Switch
              onChange={value => {
                formik.setFieldValue("isAllowedNewCashout", value, true);
              }}
              checked={formik.values.isAllowedNewCashout}
              disabled={!formik.values.isActiveNewCashout}
            />
          </Form.Item>
        </Modal>
      </Form>
    </>
  );
}
