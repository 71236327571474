import { Alert, Col, Row } from "antd";
import React, { useState } from "react";
import api from "../../../util/api";
import Widget from "../../Widget";
import FilterReportEffectiveness from "./filter";
import * as moment from "moment";
import TableReportEffectiveness from "./table";
import DownloadReportEffectiveness from "./download-report";

const ReportEffectiveness = ({}) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  const fetchFilter = params => {
    return new Promise(function(resolve, reject) {
      api.utils
        .getAllEffectivenessByCommerce({
          commerceId: params.commerceId,
          country: params.country,
          startDate: moment(params.rangeDate[0])
            .startOf("day")
            .format("DD/MM/YYYY HH:mm:ss"),
          endDate: moment(params.rangeDate[1])
            .endOf("day")
            .format("DD/MM/YYYY HH:mm:ss")
        })
        .then(result => resolve(result))
        .catch(error => {
          reject(error);
        });
    });
  };

  const onSubmitFilter = values => {
    setLoading(true);
    fetchFilter(values)
      .then(response => {
        setData(response.data.efficiencies);

        if (response.data.success == false) {
          setError(response.data.message);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Row align="middle" type="flex" gutter={[10, 20]}>
            <Col span={12}>
              <h3>Report effectiveness</h3>
            </Col>
            <Col span={12}>
              <div className="section-button">
                <DownloadReportEffectiveness data={data} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={6} lg={8} md={24} sm={24} xs={24}>
          <Widget>
            <FilterReportEffectiveness onSubmitFilter={onSubmitFilter} />
            {error ? (
              <>
                <br />
                <Alert message={error} type="error" />
              </>
            ) : null}
          </Widget>
        </Col>
        <Col xl={18} lg={16} md={24} sm={24} xs={24}>
          <Widget>
            <TableReportEffectiveness loading={loading} data={data} />
          </Widget>
        </Col>
      </Row>
    </>
  );
};

export default ReportEffectiveness;
