import {
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH
} from "constants/ActionTypes";
import {
  LAYOUT_TYPE,
  NAV_STYLE,
  THEME_COLOR_SELECTION,
  THEME_TYPE
} from "../../constants/ThemeSetting";

export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function updateWindowWidth(width) {
  return { type: WINDOW_WIDTH, width };
}

export function setThemeType(themeType) {
  return { type: THEME_TYPE, themeType };
}

export function setThemeColorSelection(colorSelection) {
  return { type: THEME_COLOR_SELECTION, colorSelection };
}

export function onNavStyleChange(navStyle) {
  return { type: NAV_STYLE, navStyle };
}

export function onLayoutTypeChange(layoutType) {
  return { type: LAYOUT_TYPE, layoutType };
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale
  };
}

export function switchMerchant(merchant) {
  return {
    type: "SWITCH_MERCHANT",
    payload: merchant
  };
}
export function switchCountry(country) {
  return {
    type: "SWITCH_COUNTRY",
    payload: country
  };
}
export function setDataUser(dataUser) {
  return {
    type: "SET_DATAUSER",
    payload: dataUser
  };
}

export function setToken(token) {
  return {
    type: "SET_TOKEN",
    payload: token
  };
}

export function setTypeUser(TypeUser) {
  return {
    type: "SET_TYPEUSER",
    payload: TypeUser
  };
}

export function setMerchantCountries(merchantContr) {
  return {
    type: "SET_MERCHANTCOUNTRIES",
    payload: merchantContr
  };
}

export function setCommissions(comissionData) {
  return {
    type: "SET_COMMISSIONS",
    payload: comissionData
  };
}
export function setDateRange(DateRange) {
  return {
    type: "SET_DATERANGE",
    payload: DateRange
  };
}

// export const CleanSetting = () => {
//   return {
//     type: "CLEAN_SETTING"
//   };
// };
