import React, { Component } from "react";
import { Button, Table, Form, Input, Alert, Row, Col, Badge, Spin, Tooltip } from "antd";
import Widget from "components/Widget/index";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { format as formatRut } from "rut.js";
import { AllHtmlEntities } from "html-entities";
import NumberFormat from "react-number-format";
import currencyNumberFormat from "../../../util/currencyNumberFormat";
import "./stylesCallback.css";
import Api from "../../../util/api";

const FormItem = Form.Item;
const entities = new AllHtmlEntities();
class searchMerchant extends Component {
  state = {
    id: "",
    resp: "",
    dataTransfer: [],
    dataTable: [],
    columns: [
      {
        title: 'ZippyId',
        dataIndex: 'zippyId',
        key: 'zippyId',
      },
      {
      title: 'MerchantName',
      dataIndex: 'merchantName',
      key: 'merchantName',
    },
    {
      title: 'Type',
      dataIndex: 'typeCollection',
      key: 'typeCollection',
    },
    {
      title: 'Date',
      dataIndex: 'dateRequest',
      key: 'dateRequest',
    },
    {
      title:"ViewTransaction",
      dataIndex: 'view',
      key: 'view',
      render: (_, record, index) => {
        return (
          <Button
        icon="eye"
        type="primary"
        onClick={() => this.getDataTransaction(record, index)}
      >
        View Transaction
      </Button>
        )
        
      }
    }
  
  
  ],
    hasDataTable: false,
    timeZone: "America/Santiago",
    dateRequest: "",
    colorStatus: "rgb(8,5,5,0.13489145658263302)",
    status: "",
    merchant: "",
    spin: false
  };

  resetTransactionInfo = () => {
    this.setState({
      dataTransfer: [],
      colorStatus: "rgb(8,5,5,0.13489145658263302)",
      status: "",
      resp: "",
      merchant: "",
      spin: false,
      dateRequest: ""
    });
  };

  getDataTransaction = async(data, index) =>{
    console.log("getDataTransaction", data, index)
    const { zippyId, typeCollection, merchantName} = data
    this.setState({
      spin: true,
      resp: ""
    });
   
    try {
      Api.utils
        .getDataTransaction(zippyId,typeCollection)
        .then(trxData => {
          console.log("DATA getDataTransaction", trxData)
          if (trxData.status  === "ok") {
            let data = [];
            let dateRequest = moment(
              new Date(
                trxData.data.dateRequest
                  ? trxData.data.dateRequest._seconds * 1000
                  : trxData.data.dateRequest._seconds * 1000
              ).toISOString()
            )
              .toDate()
              .toLocaleString("es-CL", {
                timeZone: this.state.timeZone,
                timeZoneName: "short"
              });
            this.setState({
              dateRequest: dateRequest,
              merchant: merchantName
            });
            switch (trxData.data.code.toString()) {
              case "0":
                this.setState({
                  colorStatus: "rgb(53 138 42 / 13%)",
                  status: "OK"
                });
                break;
              case "9":
                this.setState({
                  colorStatus: "rgb(243 216 26 / 13%)",
                  status: "Pending"
                });

                break;
              case "12":
                this.setState({
                  colorStatus: "rgb(243 26 26 / 13%)",
                  status: "Failed"
                });
                break;
              default:
                this.setState({
                  colorStatus: "rgb(8,5,5,0.13489145658263302)"
                });
                break;
            }

            if(trxData.data.commerceReqId === this.state.id){
              this.setState({id: trxData.data.zippyId})
            }
           
            data.push({
              ZippyId: this.state.id,
              type: typeCollection,
              ...trxData.data
            });
            this.setState({ dataTransfer: data[0], spin: false });
          } else {
            // no encontrado
            this.resetTransactionInfo();
            this.setState({
              resp: {
                message: "No Data",
                type: "info"
              }
            });
          }
        })
        .catch(error => {
          console.log("getDataTransaction :", error);
          this.resetTransactionInfo();
        });
    } catch (err) {
      console.log("Error en getDataTransaction:", err);
      this.resetTransactionInfo();
    }
    setTimeout(
      function() {
        this.setState({ resp: "" });
      }.bind(this),
      5000
    );
    
  }

  getDataTable = async e => {
    this.setState({
      spin: true,
      resp: "",
      hasDataTable: false,
      dataTable: []
    });
    let ZippyId = e.target.value.trim();
    try {
      Api.utils
        .getDataForIdZippyV2(ZippyId)
        .then(trxData => {
          console.log("DATA getDataForIdZippyV2", trxData)
          if (trxData.length > 0) {
            let data = [];
            trxData.map((t, index) => {
              const { typeCollection, merchantName, zippyId } = t 
              let dateRequest = moment(
                new Date(
                  t.dateRequest
                    ? t.dateRequest._seconds * 1000
                    : t.dateRequest._seconds * 1000
                ).toISOString()
              )
                .toDate()
                .toLocaleString("es-CL", {
                  timeZone: this.state.timeZone,
                  timeZoneName: "short"
                });
              data.push({ key: String(index),  zippyId, typeCollection, merchantName, dateRequest})
            })
              this.setState({ dataTable: data, spin: false, hasDataTable: true, id: ZippyId  });
          } else {
            // no encontrado
            this.resetTransactionInfo();
            this.setState({
              resp: {
                message: "No Data",
                type: "info"
              }
            });
          }
        })
        .catch(error => {
          console.log("Error en getDataTable:", error);
          // this.resetTransactionInfo();
        });
    } catch (err) {
      console.log("Error en getDataTable :", err);
      // this.resetTransactionInfo();
    }
    setTimeout(
      function() {
        this.setState({ resp: "" });
      }.bind(this),
      5000
    );
  };
  /*
  getDataTrx = async e => {
    this.setState({
      spin: true,
      resp: ""
    });
    let ZippyId = e.target.value.trim();
    console.log("getDataTrx", e.target.value);
    try {
      Api.utils
        .getDataForIdZippy(ZippyId)
        .then(trxData => {
          console.log("DATA DE API", trxData)
          if (trxData.length > 0) {
            let data = [];
            console.log("getDataForIdZippy", trxData);

            let dateRequest = moment(
              new Date(
                trxData[0].dateRequest
                  ? trxData[0].dateRequest._seconds * 1000
                  : trxData[0].dateRequest._seconds * 1000
              ).toISOString()
            )
              .toDate()
              .toLocaleString("es-CL", {
                timeZone: this.state.timeZone,
                timeZoneName: "short"
              });
            this.setState({
              dateRequest: dateRequest,
              merchant: trxData[2].merchant
            });
            switch (trxData[0].code.toString()) {
              case "0":
                this.setState({
                  colorStatus: "rgb(53 138 42 / 13%)",
                  status: "OK"
                });
                break;
              case "9":
                this.setState({
                  colorStatus: "rgb(243 216 26 / 13%)",
                  status: "Pending"
                });

                break;
              case "12":
                this.setState({
                  colorStatus: "rgb(243 26 26 / 13%)",
                  status: "Failed"
                });
                break;
              default:
                this.setState({
                  colorStatus: "rgb(8,5,5,0.13489145658263302)"
                });
                break;
            }

            if(trxData[0].commerceReqId === ZippyId){
              ZippyId = trxData[0].zippyId
            }
            data.push({
              ZippyId,
              ...trxData[1],
              ...trxData[0]
            });
            this.setState({ dataTransfer: data[0], spin: false });
          } else {
            // no encontrado
            this.resetTransactionInfo();
            this.setState({
              resp: {
                message: "No Data",
                type: "info"
              }
            });
          }
        })
        .catch(error => {
          console.log("Error en getDataTrx :", error);
          this.resetTransactionInfo();
        });
    } catch (err) {
      console.log("Error en getDataTrx :", err);
      this.resetTransactionInfo();
    }
    setTimeout(
      function() {
        this.setState({ resp: "" });
      }.bind(this),
      5000
    );
  }; */

  render() {
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 20
        },
        sm: {
          span: 10
        }
      },
      wrapperCol: {
        xs: {
          span: 20
        },
        sm: {
          span: 14
        }
      }
    };
    return (
      <Widget
        title={
          <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
            <i className="icon icon-search gx-mr-3" />
            Search Zippy Id:
          </h4>
        }
      >
        <div
          className="ant-card gx-card-widget "
          style={{ background: this.state.colorStatus }}
        >
          <div className="ant-card-body">
            <span className="gx-widget-badge">
              transaction info{" "}
              {this.state.status === "OK" ? (
                <Badge color="green" text={"Validated"} />
              ) : this.state.status === "Pending" ? (
                <Badge color="yellow" text={this.state.status} />
              ) : (
                <Badge color="red" text={this.state.status} />
              )}
            </span>
            <br></br>
            <Row>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Row>
                  <Col lg={6} md={6} sm={24} xs={24}>
                    <b>Amount: </b>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    {this.state.dataTransfer ? (
                      <>
                        {this.state.dataTransfer.quantity
                          ? /*  <NumberFormat
                         value={this.state.dataTransfer.quantity}
                         displayType={"text"}
                         thousandSeparator={"."}
                         decimalSeparator={","}
                         prefix={"$"}
                       /> */
                            currencyNumberFormat(
                              this.state.dataTransfer.quantity,
                              this.props.country.code
                            )
                          : null}
                      </>
                    ) : null}
                  </Col>
                </Row>
                <br />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Row>
                  <Col lg={6} md={6} sm={24} xs={24}>
                    <b>Zippy Id: </b>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    {this.state.dataTransfer
                      ? this.state.dataTransfer.ZippyId
                      : ""}
                  </Col>
                </Row>
                <br />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Row>
                  <Col lg={6} md={6} sm={24} xs={24}>
                    <b>Commerce ReqId:</b>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    {this.state.dataTransfer
                      ? this.state.dataTransfer.commerceReqId
                      : ""}
                  </Col>
                </Row>
                <br />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>Name: </b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer
                      ? entities.decode(this.state.dataTransfer.name)
                      : ""}
                  </Col>
                </Row>
                <br />
              </Col>

              <Col lg={12} md={12} sm={24} xs={24}>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>Rut: </b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer.rut
                      ? formatRut(this.state.dataTransfer.rut)
                      : formatRut(this.state.dataTransfer.documentId)}
                  </Col>
                </Row>
                <br />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Row>
                  <Col lg={6} md={6} sm={24} xs={24}>
                    <b>Email:</b>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    {this.state.dataTransfer
                      ? this.state.dataTransfer.email
                      : ""}
                  </Col>
                </Row>
                <br />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b> Date Request:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dateRequest ? this.state.dateRequest : ""}
                  </Col>
                </Row>
                <br />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b> Merchant:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.merchant}
                  </Col>
                </Row>
                <br />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b> Payment type:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer
                      ? this.state.dataTransfer.type
                      : ""}
                  </Col>
                </Row>
                <br />
                {this.state.dataTransfer.ticket_number && this.state.dataTransfer.type === "kushkis" ? (
                  <Row>
                    <Col lg={6} md={8} sm={24} xs={24}>
                      <b> Ticket Number:</b>
                    </Col>
                    <Col lg={18} md={12} sm={24} xs={24}>
                      {this.state.dataTransfer.ticket_number}
                    </Col>
                  </Row>
                ) : null}
                <br />
              </Col>

              {this.state.dataTransfer ? (
                this.state.dataTransfer.payMethod ? (
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Row>
                      <Col lg={6} md={8} sm={24} xs={24}>
                        <b> payMethod:</b>
                      </Col>
                      <Col lg={18} md={12} sm={24} xs={24}>
                        {this.state.dataTransfer
                          ? this.state.dataTransfer.payMethod
                          : ""}
                      </Col>
                    </Row>
                    <br />
                    {this.state.dataTransfer.country ? (
                      <Row>
                        <Col lg={6} md={8} sm={24} xs={24}>
                          <b> Country:</b>
                        </Col>
                        <Col lg={18} md={12} sm={24} xs={24}>
                          {this.state.dataTransfer.country == "CL" ? (
                            <i className={`flag flag-24 gx-mr-2 flag-cl`}></i>
                          ) : this.state.dataTransfer.country == "PE" ? (
                            <i className={`flag flag-24 gx-mr-2 flag-pe`}></i>
                          ) : this.state.dataTransfer.country == "CR" ? (
                            <i className={`flag flag-24 gx-mr-2 flag-cr`}></i>
                          ) : this.state.dataTransfer.country == "AR" ? (
                            <i className={`flag flag-24 gx-mr-2 flag-ar`}></i>
                          ) : this.state.dataTransfer.country == "BR" ? (
                            <i className={`flag flag-24 gx-mr-2 flag-br`}></i>
                          ) : this.state.dataTransfer.country == "CRC" ? (
                            <i className={`flag flag-24 gx-mr-2 flag-cr`}></i>
                          ) : this.state.dataTransfer.country == "EC" ? (
                            <i className={`flag flag-24 gx-mr-2 flag-ec`}></i>
                          ) : (
                            <i className={`flag flag-24 gx-mr-2 flag-cl`}></i>
                          )}
                        </Col>
                      </Row>
                    ) : null}
                    <br />
                  </Col>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              <Col lg={12} md={12} sm={24} xs={24}>
                {this.state.dataTransfer.operationCode ? (
                  <Row>
                    <Col lg={6} md={8} sm={24} xs={24}>
                      <b> Operation Code:</b>
                    </Col>
                    <Col lg={18} md={12} sm={24} xs={24}>
                      {this.state.dataTransfer.operationCode}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <br />
              </Col>
              {this.state.dataTransfer ? (
                this.state.dataTransfer.idPayroll ? (
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Row>
                      <Col lg={6} md={8} sm={24} xs={24}>
                        <b> Id Payroll:</b>
                      </Col>
                      <Col lg={18} md={12} sm={24} xs={24}>
                        {this.state.dataTransfer.idPayroll}
                      </Col>
                    </Row>
                    <br />
                  </Col>
                ) : (
                  ""
                )
              ) : (
                ""
              )}         
            </Row>
          </div>
        </div>
        {this.state.hasDataTable ? <Table dataSource={this.state.dataTable} columns={this.state.columns} />: <></>}
        <Form {...formItemLayout} onSubmit={this.getDataTable}>
          <Row>
            <Col lg={16} md={16} sm={24} xs={24}>
              <FormItem label="ZippyyID/CommerceReqId:">
                <Input
                  placeholder="Insert Id"
                  onChange={this.getDataTable}
                />
              </FormItem>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              {this.state.spin ? (
                <div>
                  <Spin size="large" />
                </div>
              ) : (
                ""
              )}
              {this.state.resp.message ? (
                <Tooltip title={this.state.resp.message}>
                  {" "}
                  <Alert
                    showIcon
                    // message="Result"
                    description={`${this.state.resp.message}`}
                    type={this.state.resp.type}
                  />
                </Tooltip>
              ) : null}
            </Col>
          </Row>
        </Form>
      </Widget>
    );
  }
}

const searchMerchantForm = Form.create()(searchMerchant);
const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;

  const { dataUser, country } = settings;

  return { authUser, dataUser, country };
};
export default connect(mapStateToProps)(searchMerchantForm);
