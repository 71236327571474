import React, { Component } from "react";
import { Layout, Popover } from "antd";
import { connect } from "react-redux";
import UserInfo from "components/UserInfo";
import HorizontalNav from "../HorizontalNav";
import HorizontalNavMx from "../HorizontalNavMx";
import { Link } from "react-router-dom";
import {
  switchLanguage,
  toggleCollapsedSideNav,
  switchMerchant,
  setDataUser,
  setToken,
  setTypeUser,
  setMerchantCountries,
  switchCountry
} from "../../../appRedux/actions/Setting";
import IntlMessages from "../../../util/IntlMessages";
import { firebase } from "../../../firebase/firebase";
// import API from "../../../util/api";
import CustomScrollbars from "../../../util/CustomScrollbars";

import { withRouter } from "react-router-dom";
import { compose } from "redux";

// eslint-disable-next-line
const auth = firebase.auth();
const { Header } = Layout;
class InsideHeader extends Component {
  state = {};
  componentDidMount = () => {

   /*  debugger; */
  };

  merchantMenu = (merchantCode) => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {this.props.dataUser.merchants.map(merchant => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(merchant)}
            onClick={e => (merchantCode != merchant.code) && this.onsSwitchMerchant(merchant)}
            style={merchantCode == merchant.code ? {PointerEvent: 'none', opacity: '0.6'} : {}}
          >
          {/* {console.log("merchant.name",merchant.name)} */}
            <span className="gx-language-text" >{merchant.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  onsSwitchMerchant = merchant => {
    this.props.switchMerchant(merchant);
    localStorage.setItem("merchant_code", merchant.code);
    localStorage.setItem("merchant_name", merchant.name);
    this.props.switchCountry("");

  /*   console.log("  merchant que entra","=>>>>>",  merchant);
console.log("  this.props.merchantContr","=>>>>>",  this.props.merchantContr);
console.log("  this.props.country","=>>>>>",  this.props.country); */

    this.props.merchantContr
      .filter(merchantContry => merchantContry.merchant === merchant.code)
      // eslint-disable-next-line
      .map(merchantContry => {
        merchantContry.countries
          .filter(country => this.props.country.code === country.code)
          // eslint-disable-next-line
          .map(country => this.props.switchCountry(country));
      });
    //window.location.reload();
    localStorage.setItem("pathname", this.props.pathname);
    return this.props.history.push(this.props.pathname);
  };
  onSwitchCountry = country => {
    this.props.switchCountry(country);
    localStorage.setItem("country_code", country.code);
    localStorage.setItem("country_name", country.name);

/*     if (country) {
      switch (country.code) {
        case "CL":
          localStorage.setItem("pathname", "/main/dashboard");
          return this.props.history.push("/main/dashboard");
        case "MX":
          localStorage.setItem("pathname", "/main/dashboardMx");
          return this.props.history.push("/main/dashboardMx");
        default:
          console.log("-----------------");
          return null;
      }
    } */
  };

  CountriesMenu = merchantContry => {
    return (
      <CustomScrollbars className="gx-popover-lang-scroll">
        <ul className="gx-sub-popover">
          {merchantContry
            ? merchantContry
                .filter(
                  merchantContry =>
                    merchantContry.merchant === this.props.merchant.code
                )
                .map(merchantContry =>
                  merchantContry.countries.map(country =>
                    this.props.dataUser.countries.map(userCountry =>
                      userCountry.code === country.code ? (
                        <li
                          className="gx-media gx-pointer"
                          key={JSON.stringify(country)}
                          onClick={e => this.onSwitchCountry(country)}
                        >
                          <i
                            className={`flag flag-24 gx-mr-2 flag-${country.code.toLowerCase()}`}
                          />
                          <span className="gx-language-text">
                            {country.name}
                          </span>
                        </li>
                      ) : (
                        ""
                      )
                    )
                  )
                )
            : ""}
        </ul>
      </CustomScrollbars>
    );
  };

  getNavCountry = (country, merchant) => {
    switch (country) {
      case "CL":
        return <HorizontalNav />;
     /*  case "MX":
        return <HorizontalNavMx />; */
      default:
        return <HorizontalNav />;
    }
  };

  DefaultCountry=(merchantContry, merchant) => {
  merchantContry.filter(merchantContry =>{
      if(merchantContry.merchant === merchant){
        console.log("merchantContry.countries[0]",merchantContry.countries[0]) ;
        this.props.switchCountry(merchantContry.countries[0]);
        this.onSwitchCountry(merchantContry.countries[0])
       }
     return "OK" })
  };

  render() {
    const { navCollapsed, toggleCollapsedSideNav } = this.props;

    return (
      <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
        <div className="gx-header-horizontal-top">
          <div className="gx-container">
            <div className="gx-header-horizontal-top-flex">
              <div className="gx-header-horizontal-top-left">
                <i className="icon icon-alert gx-mr-3" />
                <p className="gx-mb-0 gx-text-truncate">
                  <IntlMessages id="app.announced" />
                </p>
              </div>
            </div>
          </div>
        </div>

        <Header className="gx-header-horizontal-main">
          <div className="gx-container">
            <div className="gx-header-horizontal-main-flex">
              <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
                <i
                  className="gx-icon-btn icon icon-menu"
                  onClick={() => {
                    toggleCollapsedSideNav(!navCollapsed);
                  }}
                />
              </div>

              <Link
                to="/"
                className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
              >
                <img
                  alt=""
                  width={40}
                  src={require("assets/images/z-logo.png")}
                />
              </Link>
              <Link
                to="/"
                className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
              >
                <img
                  alt=""
                  width={150}
                  src={require("assets/images/zippy-Logo-white.svg")}
                />
              </Link>

              <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
                {this.props.country
                  ? this.getNavCountry(
                      this.props.country.code,
                      this.props.merchant
                    )
                  : ""}
              </div>
              <ul className="gx-header-notifications gx-ml-auto">
                <li className="gx-language">
                  <Popover
                    overlayClassName="gx-popover-horizantal"
                    placement="bottomRight"
                    content={this.props.dataUser ? this.merchantMenu(this.props.merchant.code) : ""}
                    trigger="hover"
                  >
                    <span className="gx-pointer gx-flex-row gx-align-items-center">
                      <i className="ant-menu-submenu-title">
                        {" "}
                        {this.props.dataUser
                          ? this.props.merchant.name
                          : "Merchant"}
                      </i>
                      <i className="icon icon-chevron-down gx-pl-2"></i>
                    </span>
                  </Popover>
                </li>
                <li className="gx-language">
                  <Popover
                    overlayClassName="gx-popover-horizantal"
                    placement="bottomRight"
                    content={
                      this.props.dataUser
                        ? this.CountriesMenu(this.props.merchantContr)
                        : ""
                    }
                    trigger="hover"
                  >
                    <span className="gx-pointer gx-flex-row gx-align-items-center">
                      {this.props.dataUser.countryDefault === "ALL" ? (
                        <img
                          alt=""
                          width="25"
                          src={require("assets/images/z-logo.png")}
                        ></img>
                      ) : (
                        <i
                          className={
                            this.props.country
                              ? `flag flag-24 flag-${this.props.country.code.toLowerCase()}`
                              : ""
                          }
                        />
                      )}

                      <i className="ant-menu-submenu-title">
                        {" "}
                        {this.props.country
                          ? this.props.country.name
                          :
                          this.DefaultCountry(this.props.merchantContr,this.props.merchant.code)
                          }
                      </i>
                      <i className="icon icon-chevron-down gx-pl-2"></i>
                    </span>
                  </Popover>
                </li>

                <li className="gx-user-nav">
                  <UserInfo />
                </li>
              </ul>
            </div>
          </div>
        </Header>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const {
    navCollapsed,
    merchant,
    dataUser,
    country,
    merchantContr,
    pathname
  } = settings;
  const { authUser } = auth;

  return {
    navCollapsed,
    merchant,
    authUser,
    dataUser,
    country,
    merchantContr,
    pathname
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    toggleCollapsedSideNav,
    switchLanguage,
    switchMerchant,
    setDataUser,
    setToken,
    setTypeUser,
    setMerchantCountries,
    switchCountry
  })
)(InsideHeader);
