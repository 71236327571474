import React from "react";
import { Row, Col, Card, Table, Input, Popconfirm, Alert, Badge } from "antd";
import _ from "lodash";
import { firebase } from "../../firebase/firebase";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";
import { AllHtmlEntities } from "html-entities";
import {
   format as formatRut
   // validate as validateRut,
   // clean as cleanRut
 } from "rut.js";
 import currencyNumberFormat from "../../util/currencyNumberFormat"
const db = firebase.firestore();
const pagination = { position: "bottom" }; //bottom top both none
const entities = new AllHtmlEntities();

class Dynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue: "",
      bordered: false,
    /*   loading: props.loading, */
      pagination,
      size: "middle", // default middle small
      expandedRowRender: props.expandedRowRender,
      title: undefined,
      showHeader: true,
      total: 0,
      footer: () => (
        <>
        <div>

          Total Amount:{" "}
          {currencyNumberFormat(this.props.suma,this.props.country)}
       {/*    <NumberFormat
            value={this.props.suma}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
          /> */}
                  
        </div>
             <div>
           Total Records:{" "}
          <NumberFormat
            value={this.props.totalRegister}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
          />
          
        </div>
        </>
      ),
      //rowSelection:  false, // {} or false
      scroll: undefined,
      searchFilter: "",
      updateTable: false,
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      timeZone: "America/Santiago",
      filteredInfo: null,
    };
  }

  componentDidMount = () => {
    this.getBurnOperationCode();
  }


  getBurnOperationCode = async () => {
    console.log("getBurnOperationCode");

    let dataList = [];
    await db
      .collection("burnOperationCodes")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          dataList.push({
            ...doc.data(),
            id: doc.id,
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN getBurnOperationCode", err);
      });

      console.log("dataList",dataList);
      let total =dataList.length;
      this.setState({ dataList,total:total})

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.updateTable) {
     
    }
  }
  incrementNumber = () => {
    let i;

    if (this.props.dataList) {
      /*  i = this.props.dataList.length - 1;
       console.log(i); */

      return index => {
        /*   console.log("index", index); */
        if (this.state.pagination.current === 1) {
          //  console.log("index++", index++);
          return index + 1;
        } else {
          let valor = this.state.pagination.current - 1;
          return index + 1 + valor * 10;
        }
      };
    }
  };

  getColumns = () => [
   /*  {
      title: "",
      dataIndex: "indexTemp",
      key: "indexTemp",
      width: 50,

    }, */
    {
      title: "Date Request",
      dataIndex: "dateRequest",
      key: "dateRequest",
      width: 200,
      render: dateRequest => (
        <span>{moment(new Date(dateRequest._seconds * 1000).toISOString()).toDate().toLocaleString("es-CL", {
          timeZone: this.state.timeZone,
          timeZoneName: "short"
        })}</span>
      ),
      sorter: (a, b) => a.dateRequest._seconds - b.dateRequest._seconds,
      sortOrder:
        this.state.sortedInfo.columnKey === "dateRequest" &&
        this.state.sortedInfo.order
    },
  /*   {
      title: "Date Request",
      dataIndex: "dateRequestForm",
      key: "dateRequestForm",
      width: 100,
      sorter: (a, b) => a.dateRequest._seconds - b.dateRequest._seconds,
      sortOrder:
        this.state.sortedInfo.columnKey === "dateRequest" &&
        this.state.sortedInfo.order
    }, */
    {
      title: "Zippy ID ",
      dataIndex: "id",
      key: "id",
    
    },
   
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      render: name => entities.decode(name)
    },
   
    {
      title: "RUT",
      dataIndex: "rut",
      key: "rut",
   //    width: 100, */
      render: rut => formatRut(rut)
    },
    {
      title: "Amount",
      dataIndex: "quantity",
      key: "quantity",
      //width: 100,
      render: text => (
        <div style={{ float: "right" }}>
           {currencyNumberFormat(text,this.props.country)}
        {/*   <NumberFormat
            value={text}
            displayType={"text"}
            thousandSeparator={","}
            decimalSeparator={"."}
            prefix={"$"}
          /> */}
        </div>
      )
    },
    
   
    {
      title: `Status `,
      dataIndex: "code",
      key: "code",
    /*   width: 100 */
    },
    {
      title: `Method Pay `,
      dataIndex: "method",
      key: "method",
    /*   width: 100 */
   /*  filteredValue: filteredInfo.name || null, */
    sorter: (a, b) => a.method.length - b.method.length,
    sortOrder:
      this.state.sortedInfo.columnKey === "method" && this.state.sortedInfo.order,
   /*    ellipsis: true, */
    },
    
    {
      title: `Merchant `,
      dataIndex: "nameCommerce",
      key: "nameCommerce",
    /*   width: 100 */
    },
    {
      title: `commerceReqId `,
      dataIndex: "commerceReqId",
      key: "commerceReqId",
    /*   width: 100 */
    },
   
  /*   ,
    {
      title: `nameCommerce `,
      dataIndex: "nameCommerce",
      key: "nameCommerce",
    } */
    
  ];
  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const fromCurrentIndex = this.incrementNumber();
   
    const columnsIndex = [
      {
        title: "n°",
        width: 50,
        render: (text, record, index) => {
          return fromCurrentIndex(index);
        }
      }
    ];

  const  columns =  [
    /*  {
       title: "",
       dataIndex: "indexTemp",
       key: "indexTemp",
       width: 50,
 
     }, */
     {
       title: "Date Request",
       dataIndex: "dateRequest",
       key: "dateRequest",
       width: 200,
       render: dateRequest => (
         <span>{moment(new Date(dateRequest._seconds * 1000).toISOString()).toDate().toLocaleString("es-CL", {
           timeZone: this.state.timeZone,
           timeZoneName: "short"
         })}</span>
       ),
       sorter: (a, b) => a.dateRequest._seconds - b.dateRequest._seconds,
       sortOrder:
         this.state.sortedInfo.columnKey === "dateRequest" &&
         this.state.sortedInfo.order
     },
     {
       title: "Zippy ID ",
       dataIndex: "id",
       key: "id",
     
     },
     {
       title: "Name",
       dataIndex: "name",
       key: "name",
       width: 150,
       render: name => entities.decode(name)
     },
    
     {
       title: "RUT",
       dataIndex: "rut",
       key: "rut",
    //    width: 100, */
       render: rut => formatRut(rut)
     },
     {
       title: "Amount",
       dataIndex: "quantity",
       key: "quantity",
       //width: 100,
       render: text => (
         <div style={{ float: "right" }}>
            {currencyNumberFormat(text,this.props.country)}
         {/*   <NumberFormat
             value={text}
             displayType={"text"}
             thousandSeparator={","}
             decimalSeparator={"."}
             prefix={"$"}
           /> */}
         </div>
       )
     },
     {
       title: `Status `,
       dataIndex: "code",
       key: "code",
     /*   width: 100 */
     },
     {
       title: `Method Pay `,
       dataIndex: "method",
       key: "method",
     /*   width: 100 */
    /*  filteredValue: filteredInfo.name || null, */
     sorter: (a, b) => a.method.length - b.method.length,
     sortOrder:
       this.state.sortedInfo.columnKey === "method" && this.state.sortedInfo.order,
    /*    ellipsis: true, */
     },
     
     {
       title: `Merchant `,
       dataIndex: "nameCommerce",
       key: "nameCommerce",
     /*   width: 100 */
     },
     {
       title: `commerceReqId `,
       dataIndex: "commerceReqId",
       key: "commerceReqId",
     /*   width: 100 */
     },
    
   /*   ,
     {
       title: `nameCommerce `,
       dataIndex: "nameCommerce",
       key: "nameCommerce",
     } */
     
   ];


    return (
      <Card>
      {/*   <div className="components-table-demo-control-bar">
          <Row>
            <Col xl={15} lg={15} md={15} sm={24} xs={24} className="gx-pr-md-2">
            </Col>
            <Col xl={9} lg={9} md={9} sm={24} xs={24} className="gx-pr-md-2">
              <Input
                id="idSearch"
                defaultValue={this.state.defaultValue}

                placeholder="Search..."
                onChange={this.updateSearchFilter.bind(this)}
              ></Input>
            </Col>
          </Row>
        </div>
 */}
        <Table
          /* loading={this.props.loading} */
          className="gx-table-responsive" 
          {...this.state}
         /*  columns={this.getColumns()} */
  columns={columns}  
        /*   columns={
            (this.columnsIndex = columnsIndex.concat(columns))
          } */


          dataSource={this.state.dataList}
          rowKey="id"
          onChange={this.onTableChange}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50', '100'] }}
        />
{/* <Table
className="gx-table-responsive"
          {...this.state}
          columns={this.columns }
          dataSource={this.props.dataList}
       
          rowKey="id"
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50", "100"]
          }}
        /> */}

      {/*   {this.state.resp ? (<Alert
          message="Resultado"
          description={this.state.resp}
          type="success"
        /> ) : null} } */}

      </Card>
    );
  }
}

export default Dynamic;
