import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col, Select, DatePicker, Button } from "antd";

const { RangePicker } = DatePicker;
const { Option } = Select;

const dateFormat = "YYYY/MM/DD";

const FilterReportEffectiveness = ({ onSubmitFilter }) => {
  const shemaValidation = Yup.object().shape({
    commerceId: Yup.string().required("Merchant is required."),
    rangeDate: Yup.array()
      .required("Date is required.")
      .min(2, "Must be a correct date range"),
    country: Yup.string()
      .nullable()
      .notRequired()
  });

  const formik = useFormik({
    initialValues: {
      commerceId: "",
      rangeDate: [],
      country: ""
    },
    validationSchema: shemaValidation,
    onSubmit: values => {
      onSubmitFilter(values);
    }
  });

  return (
    <>
      <Row gutter={[16, 20]}>
        <Col span={24}>
          <RangePicker
            style={{ width: "100%" }}
            onChange={value => formik.setFieldValue(`rangeDate`, value, true)}
            value={formik.values.rangeDate}
            format={dateFormat}
          />
          <p className="p-error">
            {formik.errors &&
              formik.touched &&
              formik.errors.rangeDate &&
              formik.touched.rangeDate &&
              formik.errors.rangeDate}
          </p>
        </Col>
        <Col span={24}>
          <Select
            placeholder="Select at least one merchant"
            onChange={value => formik.setFieldValue(`commerceId`, value, true)}
            value={formik.values.commerceId}
            style={{ width: "100%" }}
          >
            <Option value="" disabled>
              -- Merchans --
            </Option>
            <Option value="2019CL1xbet-8k3y">1xBet</Option>
            <Option value="2020Payretailers7g21">PayRetailers</Option>
            {/* <Option value="2020cestelar-3j9s">CEstelar</Option> */}
            {/* <Option value="2020dw-6d9w">WD</Option> */}
            <Option value="2020e-Management2u5i">E-Management</Option>
            <Option value="2021juegaloCom-9n3u">JuegaloCom</Option>
            <Option value="2020juegalopro-7j7g">JuegaloPRO</Option>
            <Option value="2020techsolutions22gf">Techsolutions</Option>
            <Option value="2021abudantia-2m5i">Abudantia</Option>
            {/* <Option value="starka-ltd">Starka</Option> */}
          </Select>
          <p className="p-error">
            {formik.errors &&
              formik.touched &&
              formik.errors.commerceId &&
              formik.touched.commerceId &&
              formik.errors.commerceId}
          </p>
        </Col>
        <Col span={24}>
          <Select
            placeholder="Select at least one country"
            onChange={value => formik.setFieldValue(`country`, value, true)}
            value={formik.values.country}
            style={{ width: "100%" }}
          >
            <Option value="" disabled>
              -- Contries --
            </Option>
            <Option value="CL">Chile</Option>
            <Option value="PE">Peru</Option>
          </Select>
          <p className="p-error">
            {formik.errors &&
              formik.touched &&
              formik.errors.country &&
              formik.touched.country &&
              formik.errors.country}
          </p>
        </Col>
        <Col span={24}>
          <div className="section-button">
            <Button onClick={formik.handleSubmit} type="primary" size="large">
              Generar
            </Button>
          </div>
        </Col>
      </Row>
      <style jsx="true">{`
        .p-error {
          position: absolute;
          color: red;
          font-size: 12px;
        }
        .section-button {
          display: flex;
          justify-content: flex-end;
        }
      `}</style>
    </>
  );
};

export default FilterReportEffectiveness;
