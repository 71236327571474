import React from "react";
import NumberFormat from "react-number-format";
import { Icon } from "antd";
import currencyNumberFormat from "../../../util/currencyNumberFormat"

const WelComeCard = props => {
  return (
    <div className="gx-wel-ema gx-pt-xl-2">
      <h1 className="gx-mb-3">Welcome to {props.name} <i className={`flag flag-24 gx-mr-2 flag-${props.country.toLowerCase()}`} /></h1>

      <ul className="gx-list-group">
        <li>
          {/* <Icon type="message" /> */}
          <span>
            $1 USD =   {currencyNumberFormat(props.usdCountry,props.countryCode) }
         {/*    <NumberFormat
              value={"$" + parseFloat(props.usdCountry).toFixed(2)}
              displayType={"text"}
              thousandSeparator={","}
              decimalSeparator={"."}
              prefix={"$"}
            /> */}
           {/*  ${props.usdCountry} */} {props.currency}
          </span>
        </li>
        <li>
          {/* <Icon type="message" /> */}
          <span>
            Total Deposits = {""}
            {props.deposits!=0? currencyNumberFormat(props.deposits,props.countryCode):0 }
        {/*     <NumberFormat
              value={"$" + parseFloat(props.deposits).toFixed(2)}
              displayType={"text"}
              thousandSeparator={","}
              decimalSeparator={"."}
              prefix={"$"}
            /> */}
            {" "}
            {props.currency}
          </span>
        </li>
        <li>
          {/* <Icon type="message" /> */}
          <span>
            Total Withdrawals ={" "}
            {props.deposits!=0? currencyNumberFormat(props.Withdrawals,props.countryCode):0 }
           {/*  <NumberFormat
              value={"$" + parseFloat(props.Withdrawals).toFixed(2)}
              displayType={"text"}
              thousandSeparator={","}
              decimalSeparator={"."}
              prefix={"$"}
            /> */}

            {" "}
            {props.currency}
          </span>
        </li>
        <li>
          <span>
            <br></br>
            <br></br>
          </span>
        </li>
        {props.pending > 0 && (
          <li>
            <Icon type="arrow-down" />
            <span>Pending CashIn = {props.pending}</span>
          </li>
        )}
        {props.pendingCashout > 0 && (
          <li>
            <Icon type="arrow-up" />
            <span>Pending CashOut = {props.pendingCashout}</span>
          </li>
        )}

        {/* <li>
          <Icon type="mail" />
          <span>2 Pending invitations</span>
        </li>
        <li>
          <Icon type="profile" />
          <span>7 Due tasks</span>
        </li>
        <li>
          <Icon type="bell" />
          <span>3 Other notifications</span>
        </li> */}
      </ul>
    </div>
  );
};

export default WelComeCard;
