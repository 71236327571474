import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
} from "antd";
import { connect } from "react-redux";
import "./stylesCallback.css";
import CallbackCashin from "./callbackCashin.js";
import CallbackFlow from "./callbackFlow.js";
import CallbackPayku from "./callbackPayku.js";
import CallbackWebPay from "./callbackWebPay.js";
import CallbackPayIn from "./callbackPayIn.js";
import SearchMerchant from "./merchantForId.js";
import CallbackKushki from "./callbackKushki.js";
import CallbackAirtm from "./callbackAirtm.js";
import CallbackPayOut from "./callbackPayOut.js";
import CallbackPagoEfectivo from "./callbackPagoEfectivo.js";

class Callback extends Component {
  state = {
    resp: "",
    updateTable: false,
    type: "cashin"
  };

  componentDidMount = async e => {

  };


  getPage = type => {
    switch (type) {
      case "payku":
        return <CallbackPayku />;
      case "cashin":
        return <CallbackCashin />;
      case "flow":
        return <CallbackFlow />;
      case "webpay":
        return <CallbackWebPay />;
      case "banktransfer":
        return <CallbackPayIn payMethod={"bankTransfer"} />;
      case "bankcard":
        return <CallbackPayIn payMethod={"bankCard"} />;
      case "cash":
        return <CallbackPayIn payMethod={type} />;
      case "kushki":
        return <CallbackKushki payMethod={type} />;
      case "airtm":
        return <CallbackAirtm payMethod={type} />;
      case "payOut":
        return <CallbackPayOut payMethod={type} />;
      case "pagoEfectivo":
        return <CallbackPagoEfectivo payMethod={type} />;
      default:
        return null;
    }
  };

  onchangeToFlow = () => {
    this.setState({
      type: "flow"
    });
  };
  onchangeToCashin = () => {
    this.setState({
      type: "cashin"
    });
  };
  onchangeToPayku = () => {
    this.setState({
      type: "payku"
    });
  };
  onchangeToWebpay = () => {
    this.setState({
      type: "webpay"
    });
  };

  onchangeToBankTransfer = () => {
    this.setState({
      type: "banktransfer"
    });
  };
  onchangeToBankCard = () => {
    this.setState({
      type: "bankcard"
    });
  };
  onchangeToCash = () => {
    this.setState({
      type: "cash"
    });
  };
  onchangeToKushki = () => {
    this.setState({
      type: "kushki"
    });
  };

  onchangeToAirtm = () => {
    this.setState({
      type: "airtm"
    });
  };
  onchangeToPayOut = () => {
    this.setState({
      type: "payOut"
    });
  };
  onchangeToPagoEfectivo = () => {
    this.setState({
      type: "pagoEfectivo"
    });
  };
  render() {
    return (
      <Row className="container p-0">
        <Col lg={24} md={24} sm={24} xs={24}>
          <SearchMerchant />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <h1>CALLBACKS</h1>
          <Button
            type={this.state.type === "cashin" ? "" : "primary"}
            className="gx-mb-1"
            onClick={this.onchangeToCashin}
          >
            CASHIN
          </Button>
          <Button
            type={this.state.type === "payku" ? "" : "primary"}
            className="gx-mb-1"
            onClick={this.onchangeToPayku}
          >
            PAYKU
          </Button>
          <Button
            type={this.state.type === "bankcard" ? "" : "primary"}
            className="gx-mb-1"
            onClick={this.onchangeToBankCard}
          >
            BankCard
          </Button>
          <Button
            type={this.state.type === "banktransfer" ? "" : "primary"}
            className="gx-mb-1"
            onClick={this.onchangeToBankTransfer}
          >
            BankTransfer
          </Button>

          <Button
            type={this.state.type === "cash" ? "" : "primary"}
            className="gx-mb-1"
            onClick={this.onchangeToCash}
          >
            Cash
          </Button>

          <Button
            type={this.state.type === "kushki" ? "" : "primary"}
            className="gx-mb-1"
            onClick={this.onchangeToKushki}
          >
            Kushki
          </Button>
          <Button
            type={this.state.type === "airtm" ? "" : "primary"}
            className="gx-mb-1"
            onClick={this.onchangeToAirtm}
          >
            Airtm
          </Button>

          <Button
            type={this.state.type === "payOut" ? "" : "primary"}
            className="gx-mb-1"
            onClick={this.onchangeToPayOut}
          >
            PayOut
          </Button>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div style={{ marginTop: "10px" }}>
            {" "}
            {this.getPage(this.state.type)}
          </div>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { dataUser } = settings;
  return { authUser, dataUser };
};
export default connect(mapStateToProps)(Callback);
