import React, { Component, useState } from "react";
import { Button, Form, Input, Alert, Row, Col, Select, Collapse } from "antd";
import Widget from "components/Widget/index";
import { firebase } from "../../firebase/firebase";
import Dynamic from "../Merchant/Dynamic";
import API from "../../../src/util/api";
import FormMaster from "./FormMaster";
import config_fields from "../../util/configFields";
import CircularProgress from "components/CircularProgress/index";

import { generateObjectPayments } from "./utils/utils.payments";
import { generateObjectMethodsPay } from "./utils/utils.methodsPay";
import { generateObjectCommissions } from "./utils/utils.commissions";
import { generateObjectPayouts } from "./utils/utils.payouts";
const db = firebase.firestore();
const FormItem = Form.Item;
const { TextArea } = Input;
const { Panel } = Collapse;
let query;
class merchants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resp: "",
      updateTable: false,
      typeAlert: "success",
      showLoading: false,
      data: {
        payments: [],
        commissions: [],
        payouts: [],
        methodsPay: []
      }
    };
    this.cambiarEstado = this.cambiarEstado.bind(this); // Enlazar la función al contexto actual
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      try {
        if (!err) {
          //console.log("Received values of form: ", values);

          /*   let flag = this.validaExistencia(values.id);*/

          let flag = false;
          let countries = [];
          values.countries.map(e => {
            /*  console.log("map_merchant",e); */
            let countriesfilter;
            countriesfilter = this.state.boxCountries.filter(
              item => item.code === e
            );

            /*  console.log("map_merchant",e,merchant); */
            countries.push(...countriesfilter);
          });
          let payments = this.state.data.payments;
          let commissions = this.state.data.commissions;
          let payouts = this.state.data.payouts;
          let methodsPay = this.state.data.methodsPay;
          let payload = {
            name: String(values.name)
              .trim()
              .replace(/\s/g, ""),
            cashoutPublicKeyFile: String(values.cashoutPublicKeyFile).trim(),
            cardChargesPublicKeyFile: String(
              values.cardChargesPublicKeyFile
            ).trim(),
            providerKushkiPublicKeyFile: String(
              values.providerKushkiPublicKeyFile
            ).trim(),
            cashoutMin: Number(values.cashoutMin),
            cashoutMax: Number(values.cashoutMax),
            markUpSkinsback: Number(values.markUpSkinsback),
            maxAmountInCashoutInPayout: Number(
              values.maxAmountInCashoutInPayout
            ),
            url_callback_aggregator_payIn: String(
              values.url_callback_aggregator_payIn
            ).trim(),
            url_callback_aggregator_payOut: String(
              values.url_callback_aggregator_payOut
            ).trim(),
            webhook_signature_kushki_CL: String(
              values.webhook_signature_kushki_CL
            ).trim(),
            webhook_signature_kushki_PE: String(
              values.webhook_signature_kushki_PE
            ).trim(),
            transfersRoundForMonth: Number(values.transfersRoundForMonth),
            url_ok_new: String(values.url_ok_new).trim(),
            visitorRoundForHours: Number(values.visitorRoundForHours),
            visitorRoundForMinutes: Number(values.visitorRoundForMinutes),
            id: String(values.id)
              .trim()
              .replace(/\s/g, ""),
            key_callback: String(values.key_callback).trim(),
            retention: String(values.retention).trim(),
            url_callback_cashin: String(values.url_callback_cashin).trim(),
            url_callback_cashout: String(values.url_callback_cashout).trim(),
            url_callback_webpay: String(values.url_callback_webpay).trim(),
            url_fail: String(values.url_fail).trim(),
            url_ok: String(values.url_ok).trim(),
            useCrypto: values.useCrypto === "true",
            useCashoutInPayOut: values.useCashoutInPayOut === "true",
            sendEmailToClienteCashoutOk:
              values.sendEmailToClienteCashoutOk === "true",
            sendEmailToClienteCashoutFail:
              values.sendEmailToClienteCashoutFail === "true",
            sendEmailToCliente: values.sendEmailToCliente === "true",
            keepCryptoApirone: values.keepCryptoApirone === "true",
            isUseNewUrlReturn: values.isUseNewUrlReturn === "true",
            conciliationSendEmailToCliente:
              values.conciliationSendEmailToCliente === "true",
            IsEnabled_webHookKushkiPE:
              values.IsEnabled_webHookKushkiPE === "true",
            IsEnabled_webHookKushkiCL:
              values.IsEnabled_webHookKushkiCL === "true",
            IsEnabled_3ds: values.IsEnabled_3ds === "true",
            balanceAnalysisByCommerce:
              values.balanceAnalysisByCommerce === "true",
            /*Products*/
            useCashin: values.useCashin === "true",
            useCashout: values.useCashout === "true",
            useWebpay: values.useWebpay === "true",
            useFlow: values.useFlow === "true",
            usePayku: values.usePayku === "true"
          };
          let payloadCountries = {
            countries: countries
          };
          /*Commissions*/
          let payLoadCommissions = {
            cashin: values.cashin,
            cashout: values.cashout,
            flow: String(values.flow)
              .trim()
              .replace(/\s/g, ""),
            flowCobro: String(values.flowCobro)
              .trim()
              .replace(/\s/g, ""),
            payku: String(values.payku)
              .trim()
              .replace(/\s/g, ""),
            paykuCobro: String(values.paykuCobro)
              .trim()
              .replace(/\s/g, ""),
            webPayCobro: String(values.webPayCobro)
              .trim()
              .replace(/\s/g, ""),
            webPayC: String(values.webPayC)
              .trim()
              .replace(/\s/g, ""),
            webPayD: String(values.webPayD)
              .trim()
              .replace(/\s/g, ""),
            countrie: "CL"
            /*    idcommission:values.idcommission */
          };

          if (flag === false) {
            let respAction;

            if (this.state.type === "create") {
              respAction = await this.saveCommerce(
                payload,
                payLoadCommissions,
                payloadCountries,
                payments,
                commissions,
                payouts,
                methodsPay,
                values
              );
            } else {
              respAction = await this.UpdateCommerce(
                payload,
                payLoadCommissions,
                values.idcommission,
                payloadCountries,
                payments,
                commissions,
                payouts,
                methodsPay,
                values
              );
            }
            if (respAction) {
              this.setState({
                resp: `Commerce ${this.state.type} Successfully.\n`,
                updateTable: !this.state.updateTable,
                typeAlert: "success"
              });
              this.clearFrom();
              window.scrollTo({ top: 10, behavior: "smooth" });
            } else {
              this.setState({
                resp: `Commerce ${this.state.type}  Error.\nName: ${values.Name}\n`,
                updateTable: !this.state.updateTable,
                typeAlert: "error"
              });
            }
          } else {
            this.setState({
              resp: `Commerce: There is already a record with this value .\nName: ${values.Name}\n`,
              updateTable: !this.state.updateTable,
              typeAlert: "error"
            });
          }

          setTimeout(
            function() {
              this.setState({ resp: "" });
            }.bind(this),
            20000
          );
        }
      } catch (error) {
        console.error(error);
        this.setState({
          resp: `Commerce: Error when trying to add a record .\nerror:: ${error}\n`,
          updateTable: !this.state.updateTable,
          typeAlert: "error"
        });
      }
    });
  };

  validaExistencia = async value => {
    /*    new Promise(async (resolve, reject) => { */
    let isValid = false;
    console.log("value", value);
    /*   query = db.collection("parametrosGenerales");
    await query
    .doc("seguridad")
    .collection("blacklistCashout")
    .where("valor", "==", value)
    .get()
    .then(async snapshot => {
      let resFirestore = snapshot.docs.map(doc => {
        return doc.data();
      });

      if (resFirestore.length > 0) {
        isValid = true;
      }
    })
    .catch(error => console.log("error en validaExistencia", error)); */

    let merchant = [];

    await db
      .collection("commerces")
      .doc(value)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          isValid = true;
        }
        console.log("merchant", snapshot.data());
        //merchant = snapshot.data()
        // res.status(200).send(response)
      })
      .catch(err => {
        //  res.status(400).json({ message: "Error in commerces" })
        console.log("ERROR IN commerces:", err);
      });

    /*    if(merchant)
       {
         isValid=true;
        } */
    /*     console.log("merchant", merchant) */

    return isValid;

    /*  }); */
  };

  saveCommerce = async (
    payload,
    payLoadCommissions,
    payloadCountries,
    payments,
    commissions,
    payouts,
    methodsPay
  ) => {
    //Grabar en db

    return await API.merchant
      .addMerchant({
        payload,
        payLoadCommissions,
        payloadCountries,
        payments,
        commissions,
        payouts,
        methodsPay
      })
      .then(cons => {
        return cons.resp;
      })
      .catch(err => {
        console.log("ERROR IN CreateMerchant:", err);
        this.setState({
          resp: err,
          typeAlert: "error"
        });
      });
  };

  UpdateCommerce = async (
    payload,
    payLoadCommissions,
    idcommission,
    payloadCountries,
    payments,
    commissions,
    payouts,
    methodsPay,
    values
  ) => {
    return await API.merchant
      .updateMerchant({
        payload,
        payLoadCommissions,
        idcommission,
        payloadCountries,
        payments,
        commissions,
        payouts,
        methodsPay,
        values
      })
      .then(cons => {
        return cons.resp;
      })
      .catch(err => {
        console.log("ERROR IN UpdateMerchant:", err);
        this.setState({
          resp: err,
          typeAlert: "error"
        });
      });
  };

  OnEdit = value => {
    this.setState({ type: "edit", resp: "" });
    this.clearFrom();
    this.setState({ countries: value.countries });
    let payments = generateObjectPayments(value.payments);
    let methodsPay = generateObjectMethodsPay(value.methodsPay);
    let commissions = generateObjectCommissions(value.commissions);
    let payouts = generateObjectPayouts(value.payouts);
    this.setState({
      data: { ...this.state.data, payments }
    });
    this.setState({
      data: { ...this.state.data, methodsPay }
    });
    this.setState({
      data: { ...this.state.data, commissions }
    });
    this.setState({
      data: { ...this.state.data, payouts }
    });
    /*  let srtUseWebpay=  ;*/
    this.props.form.setFieldsValue({
      ["cashoutPublicKeyFile"]: value.cashoutPublicKeyFile,
      ["country"]: value.country,
      ["id"]: value.idmerchant,
      ["key_callback"]: value.key_callback,
      ["name"]: value.name,
      ["retention"]: value.retention,
      ["url_callback_cashin"]: value.url_callback_cashin,
      ["url_callback_cashout"]: value.url_callback_cashout,
      ["url_callback_webpay"]: value.url_callback_webpay,
      ["url_fail"]: value.url_fail,
      ["url_ok"]: value.url_ok,
      ["cardChargesPublicKeyFile"]: value.cardChargesPublicKeyFile,
      ["providerKushkiPublicKeyFile"]: value.providerKushkiPublicKeyFile,
      ["cashoutMin"]: value.cashoutMin,
      ["cashoutMax"]: value.cashoutMax,
      ["markUpSkinsback"]: value.markUpSkinsback,
      ["maxAmountInCashoutInPayout"]: value.maxAmountInCashoutInPayout,
      ["url_callback_aggregator_payIn"]: value.url_callback_aggregator_payIn,
      ["url_callback_aggregator_payOut"]: value.url_callback_aggregator_payOut,
      ["webhook_signature_kushki_CL"]: value.webhook_signature_kushki_CL,
      ["webhook_signature_kushki_PE"]: value.webhook_signature_kushki_PE,
      ["transfersRoundForMonth"]: value.transfersRoundForMonth,
      ["url_ok_new"]: value.url_ok_new,
      ["visitorRoundForHours"]: value.visitorRoundForHours,
      ["visitorRoundForMinutes"]: value.visitorRoundForMinutes,
      ["useCrypto"]: String(value.useCrypto),
      ["useCashoutInPayOut"]: String(value.useCashoutInPayOut),
      ["sendEmailToClienteCashoutOk"]: String(
        value.sendEmailToClienteCashoutOk
      ),
      ["sendEmailToClienteCashoutFail"]: String(
        value.sendEmailToClienteCashoutFail
      ),
      ["sendEmailToCliente"]: String(value.sendEmailToCliente),
      ["keepCryptoApirone"]: String(value.keepCryptoApirone),
      ["isUseNewUrlReturn"]: String(value.isUseNewUrlReturn),
      ["conciliationSendEmailToCliente"]: String(
        value.conciliationSendEmailToCliente
      ),
      ["IsEnabled_webHookKushkiPE"]: String(value.IsEnabled_webHookKushkiPE),
      ["IsEnabled_webHookKushkiCL"]: String(value.IsEnabled_webHookKushkiCL),
      ["IsEnabled_3ds"]: String(value.IsEnabled_3ds),
      ["balanceAnalysisByCommerce"]: String(value.balanceAnalysisByCommerce),
      /*products*/
      ["useCashin"]: String(value.useCashin),
      ["useCashout"]: String(value.useCashout),
      ["useWebpay"]: String(value.useWebpay),
      ["useFlow"]: String(value.useFlow),
      ["usePayku"]: String(value.usePayku)
    });
    window.scrollTo({ top: 1500, behavior: "smooth" });
  };
  clearFrom = () => {
    this.props.form.resetFields();
    this.setState({
      countries: ""
    });

    this.setState({
      data: {
        payments: [],
        commissions: [],
        payouts: [],
        methodsPay: []
      }
    });
  };

  setType = e => {
    this.setState({
      type: e,
      resp: ""
    });
    this.clearFrom();
    window.scrollTo({ top: 1000, behavior: "smooth" });
  };
  componentDidMount = async e => {
    this.setState({
      type: "create",
      resp: ""
    });
    this.getCountries();
  };
  getCountries = async () => {
    await API.utils.getCountries().then(cons => {
      this.setState({
        boxCountries: cons
      });
    });
  };
  cambiarEstado(valor) {
    this.setState({ showLoading: valor });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    /*    const AutoCompleteOption = AutoComplete.Option; */

    const children = [];
    const childrenEdit = [];
    const childrenDefault = [];
    if (this.state.countries) {
      this.state.countries.map(country => {
        childrenEdit.push(country.code.toString());
      });
    }
    if (this.state.boxCountries) {
      this.state.boxCountries.map(country => {
        children.push(
          <Option key={country.code.toString()}>
            {country.name.toString()}
          </Option>
        );
      });
    }

    function handleChange(values) {
      console.log(`selected ${values}`);

      var arreglo = values.toString().split(",");
      arreglo.map(value => {
        /*  if (this.state.boxMerchant) {
        this.state.boxMerchant.filter(merchant => {
          if(value===merchant.code)
          {
 */
        childrenDefault.push(
          <Option key={value.toString()}>{value.toString()}</Option>
        );
        /*    }
        });
      } */
      });
    }
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 12
        },
        sm: {
          span: 8
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16
        }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    return this.state.showLoading ? (
      <div className="gx-loader-view gx-loader-position">
        <CircularProgress />
      </div>
    ) : (
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Widget
            title={
              <h4
                className=" gx-text-capitalize gx-mb-0"
                style={{ color: "#070707" }}
              >
                {"🛒 ‍ "}
                Merchants
              </h4>
            }
            // style={{ backgroundColor: "#60ec9866" }}
          >
            <Button type="dashed" onClick={e => this.setType("create")}>
              New Merchant
            </Button>
            <Dynamic
              updateTable={this.state.updateTable}
              cambiarEstado={this.cambiarEstado}
              type={"blacklist"}
              //delete={() => this.delete()}
              OnEdit={e => this.OnEdit(e)}
            />
          </Widget>
        </Col>

        <Col lg={24} md={24} sm={24} xs={24}>
          {/* {this.props.type!=="table"? */}
          <Widget
            title={
              <h4
                className=" gx-text-capitalize gx-mb-0"
                style={{ color: "#070707" }}
              >
                {/*  <i className="icon icon-mail-open gx-mr-3" /> */}

                {this.state.type === "edit" ? (
                  <span>{"✏️ Edit Merchant"} </span>
                ) : (
                  <span>{"➕ Add New Merchant"}</span>
                )}
              </h4>
            }
          >
            {this.state.type === "edit" ? (
              <Button type="dashed" onClick={e => this.setType("create")}>
                New Merchant
              </Button>
            ) : null}

            <Form
              {...formItemLayout}
              onSubmit={this.handleSubmit}
              //form={form}
              name="register"
              // onFinish={onFinish}
              //   initialValues={{
              //Dynamic.js       residence: ["zhejiang", "hangzhou", "xihu"],
              //        prefix: "86"
              //     }}
              scrollToFirstError
            >
              <Row>
                <Collapse style={{ width: "100%" }}>
                  <Panel header="Informacion Inicial" key={1}>
                    <Row>
                      <Col lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name="name" label="Name">
                          {getFieldDecorator("name", {
                            initialValue: "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter the value!"
                              }
                            ]
                          })(
                            <Input
                              placeholder="enter the name Merchant"
                              disabled={this.state.type === "edit"}
                            />
                          )}
                        </Form.Item>

                        <Form.Item name="id" label="ID">
                          {getFieldDecorator("id", {
                            initialValue: "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter the id!"
                              }
                            ]
                          })(
                            <Input
                              placeholder="YearCns2232-23dfa"
                              disabled={this.state.type === "edit"}
                            />
                          )}
                        </Form.Item>
                        <Form.Item
                          name="cashoutPublicKeyFile"
                          label="cashoutPublicKeyFile"
                        >
                          {getFieldDecorator("cashoutPublicKeyFile", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message:
                                  "Please enter the cashoutPublicKeyFile!"
                              }
                            ]
                          })(<Input placeholder="cashoutPublicKeyFile" />)}
                        </Form.Item>
                        <Form.Item name="key_callback" label="key_callback">
                          {getFieldDecorator("key_callback", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the key_callback!"
                              }
                            ]
                          })(<Input placeholder="key_callback" />)}
                        </Form.Item>

                        <Form.Item name="retention" label="retention">
                          {getFieldDecorator("retention", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the retention!"
                              }
                            ]
                          })(<Input placeholder="retention" />)}
                        </Form.Item>
                        <Form.Item
                          name="url_callback_cashin"
                          label="url_callback_cashin"
                        >
                          {getFieldDecorator("url_callback_cashin", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the url_callback_cashin!"
                              }
                            ]
                          })(<Input placeholder="url_callback_cashin" />)}
                        </Form.Item>
                        <Form.Item
                          name="url_callback_cashout"
                          label="url_callback_cashout"
                        >
                          {getFieldDecorator("url_callback_cashout", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message:
                                  "Please enter the url_callback_cashout!"
                              }
                            ]
                          })(<Input placeholder="url_callback_cashout" />)}
                        </Form.Item>
                        <Form.Item
                          name="providerKushkiPublicKeyFile"
                          label="providerKushkiPublicKeyFile"
                        >
                          {getFieldDecorator("providerKushkiPublicKeyFile", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message:
                                  "Please enter the providerKushkiPublicKeyFile!"
                              }
                            ]
                          })(
                            <Input placeholder="providerKushkiPublicKeyFile" />
                          )}
                        </Form.Item>
                        <Form.Item
                          name="url_callback_aggregator_payIn"
                          label="url_callback_aggregator_payIn"
                        >
                          {getFieldDecorator("url_callback_aggregator_payIn", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message:
                                  "Please enter the url_callback_aggregator_payIn!"
                              }
                            ]
                          })(
                            <Input placeholder="url_callback_aggregator_payIn" />
                          )}
                        </Form.Item>
                        <Form.Item
                          name="url_callback_aggregator_payOut"
                          label="url_callback_aggregator_payOut"
                        >
                          {getFieldDecorator("url_callback_aggregator_payOut", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message:
                                  "Please enter the url_callback_aggregator_payOut!"
                              }
                            ]
                          })(
                            <Input placeholder="url_callback_aggregator_payOut" />
                          )}
                        </Form.Item>
                        <Form.Item
                          name="webhook_signature_kushki_CL"
                          label="webhook_signature_kushki_CL"
                        >
                          {getFieldDecorator("webhook_signature_kushki_CL", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message:
                                  "Please enter the webhook_signature_kushki_CL!"
                              }
                            ]
                          })(
                            <Input placeholder="webhook_signature_kushki_CL" />
                          )}
                        </Form.Item>
                        <Form.Item
                          name="webhook_signature_kushki_PE"
                          label="webhook_signature_kushki_PE"
                        >
                          {getFieldDecorator("webhook_signature_kushki_PE", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message:
                                  "Please enter the webhook_signature_kushki_PE!"
                              }
                            ]
                          })(
                            <Input placeholder="webhook_signature_kushki_PE" />
                          )}
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name="url_callback_webpay"
                          label="url_callback_webpay"
                        >
                          {getFieldDecorator("url_callback_webpay", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the value!"
                              }
                            ]
                          })(<Input placeholder="url_callback_webpay" />)}
                        </Form.Item>
                        <Form.Item name="url_fail" label="url_fail">
                          {getFieldDecorator("url_fail", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the value!"
                              }
                            ]
                          })(<Input placeholder="url_fail" />)}
                        </Form.Item>
                        <Form.Item name="url_ok" label="url_ok">
                          {getFieldDecorator("url_ok", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the value!"
                              }
                            ]
                          })(<Input placeholder="url_ok" />)}
                        </Form.Item>
                        <Form.Item
                          name="cardChargesPublicKeyFile"
                          label="cardChargesPublicKeyFile"
                        >
                          {getFieldDecorator("cardChargesPublicKeyFile", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the value!"
                              }
                            ]
                          })(<Input placeholder="cardChargesPublicKeyFile" />)}
                        </Form.Item>
                        <Form.Item name="cashoutMin" label="cashoutMin">
                          {getFieldDecorator("cashoutMin", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the value!"
                              }
                            ]
                          })(<Input placeholder="cashoutMin" />)}
                        </Form.Item>
                        <Form.Item name="cashoutMax" label="cashoutMax">
                          {getFieldDecorator("cashoutMax", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the value!"
                              }
                            ]
                          })(<Input placeholder="cashoutMax" />)}
                        </Form.Item>
                        <Form.Item
                          name="markUpSkinsback"
                          label="markUpSkinsback"
                        >
                          {getFieldDecorator("markUpSkinsback", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the value!"
                              }
                            ]
                          })(<Input placeholder="markUpSkinsback" />)}
                        </Form.Item>
                        <Form.Item
                          name="maxAmountInCashoutInPayout"
                          label="maxAmountInCashoutInPayout"
                        >
                          {getFieldDecorator("maxAmountInCashoutInPayout", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the value!"
                              }
                            ]
                          })(
                            <Input placeholder="maxAmountInCashoutInPayout" />
                          )}
                        </Form.Item>
                        <Form.Item
                          name="transfersRoundForMonth"
                          label="transfersRoundForMonth"
                        >
                          {getFieldDecorator("transfersRoundForMonth", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the value!"
                              }
                            ]
                          })(<Input placeholder="transfersRoundForMonth" />)}
                        </Form.Item>
                        <Form.Item name="url_ok_new" label="url_ok_new">
                          {getFieldDecorator("url_ok_new", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the value!"
                              }
                            ]
                          })(<Input placeholder="url_ok_new" />)}
                        </Form.Item>
                        <Form.Item
                          name="visitorRoundForHours"
                          label="visitorRoundForHours"
                        >
                          {getFieldDecorator("visitorRoundForHours", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the value!"
                              }
                            ]
                          })(<Input placeholder="visitorRoundForHours" />)}
                        </Form.Item>
                        <Form.Item
                          name="visitorRoundForMinutes"
                          label="visitorRoundForMinutes"
                        >
                          {getFieldDecorator("visitorRoundForMinutes", {
                            initialValue: "",
                            rules: [
                              {
                                required: false,
                                message: "Please enter the value!"
                              }
                            ]
                          })(<Input placeholder="visitorRoundForMinutes" />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                      <hr></hr>
                      <Row>
                        <Col lg={8} md={8} sm={24} xs={24}>
                          <Form.Item name="IsEnabled_3ds" label="Enabled3ds">
                            {getFieldDecorator("IsEnabled_3ds", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/* <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                          <Form.Item
                            name="IsEnabled_webHookKushkiCL"
                            label="EnabledWebHookKushkiCL"
                          >
                            {getFieldDecorator("IsEnabled_webHookKushkiCL", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/*  <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                          <Form.Item
                            name="IsEnabled_webHookKushkiPE"
                            label="EnabledWebHookKushkiPE"
                          >
                            {getFieldDecorator("IsEnabled_webHookKushkiPE", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/*  <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                          <Form.Item name="useCrypto" label="useCrypto">
                            {getFieldDecorator("useCrypto", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/*  <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                          <Form.Item
                            name="conciliationSendEmailToCliente"
                            label="conciliationSendEmailToCliente"
                          >
                            {getFieldDecorator(
                              "conciliationSendEmailToCliente",
                              {
                                initialValue: "false",
                                validateTrigger: ["onChange", "onBlur"],
                                rules: [
                                  {
                                    required: false,
                                    message: "Please enter the value!"
                                  }
                                ]
                              }
                            )(
                              <Select onChange={this.onChangeID}>
                                {/*   <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                          <Form.Item
                            name="isUseNewUrlReturn"
                            label="UseNewUrlReturn"
                          >
                            {getFieldDecorator("isUseNewUrlReturn", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/*   <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                          <Form.Item
                            name="keepCryptoApirone"
                            label="keepCryptoApirone"
                          >
                            {getFieldDecorator("keepCryptoApirone", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/*   <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                          <Form.Item
                            name="useCashoutInPayOut"
                            label="useCashoutInPayOut"
                          >
                            {getFieldDecorator("useCashoutInPayOut", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/*   <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                          <Form.Item
                            name="sendEmailToCliente"
                            label="sendEmailToCliente"
                          >
                            {getFieldDecorator("sendEmailToCliente", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/* <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                          <Form.Item
                            name="sendEmailToClienteCashoutFail"
                            label="sendEmailToClienteCashoutFail"
                          >
                            {getFieldDecorator(
                              "sendEmailToClienteCashoutFail",
                              {
                                initialValue: "false",
                                validateTrigger: ["onChange", "onBlur"],
                                rules: [
                                  {
                                    required: false,
                                    message: "Please enter the value!"
                                  }
                                ]
                              }
                            )(
                              <Select onChange={this.onChangeID}>
                                {/* <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                          <Form.Item
                            name="sendEmailToClienteCashoutOk"
                            label="sendEmailToClienteCashoutOk"
                          >
                            {getFieldDecorator("sendEmailToClienteCashoutOk", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/* <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                          <Form.Item
                            name="balanceAnalysisByCommerce"
                            label="balanceAnalysisByCommerce"
                          >
                            {getFieldDecorator("balanceAnalysisByCommerce", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/* <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <hr></hr>
                      <h4 style={{ textAlign: "center" }}>Products</h4>
                      <Row>
                        <Col lg={8} md={8} sm={24} xs={24}>
                          <Form.Item name="useCashin" label="Cashin">
                            {getFieldDecorator("useCashin", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/* <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                          <Form.Item name="useFlow" label="Flow">
                            {getFieldDecorator("useFlow", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/*  <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                          <Form.Item name="useCashout" label="Cashout">
                            {getFieldDecorator("useCashout", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/*   <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                          <Form.Item name="usePayku" label="Payku">
                            {getFieldDecorator("usePayku", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/*   <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                          <Form.Item name="useWebpay" label="WebPay">
                            {getFieldDecorator("useWebpay", {
                              initialValue: "false",
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  message: "Please enter the value!"
                                }
                              ]
                            })(
                              <Select onChange={this.onChangeID}>
                                {/* <Option value="">Select Status</Option> */}
                                <Option value={"true"}>On </Option>
                                <Option value={"false"}>OFF </Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Panel>
                  <Panel header="Paises" key={2}>
                    <Col lg={8} md={8} sm={24} xs={24}>
                      <Form.Item name="countries" label="Countries">
                        {getFieldDecorator("countries", {
                          initialValue: childrenEdit, // ['a10', 'c12'],//childrenEdit,//
                          validateTrigger: ["onChange", "onBlur"],
                          rules: [
                            {
                              required: false,
                              message: "Please enter the Countries!"
                            }
                          ]
                        })(
                          <Select
                            mode="tags"
                            // mode="multiple"
                            onChange={handleChange}
                          >
                            {children}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Panel>
                  <Panel header="MethodsPay" key={3}>
                    <div>
                      <FormMaster
                        title="MethodsPay"
                        data={this.state.data.methodsPay}
                        onChange={methodsPay =>
                          this.setState({
                            data: { ...this.state.data, methodsPay }
                          })
                        }
                        fields={config_fields.methodsPay}
                      />
                    </div>
                  </Panel>
                  <Panel header="Payments" key={4}>
                    <div>
                      <FormMaster
                        title="Payments"
                        data={this.state.data.payments}
                        onChange={payments =>
                          this.setState({
                            data: { ...this.state.data, payments }
                          })
                        }
                        fields={config_fields.payments}
                      />
                    </div>
                  </Panel>
                  <Panel header="Comisiones" key={5}>
                    <div>
                      <FormMaster
                        title="Comissions"
                        data={this.state.data.commissions}
                        onChange={commissions =>
                          this.setState({
                            data: { ...this.state.data, commissions }
                          })
                        }
                        fields={config_fields.commissions}
                      />
                    </div>
                  </Panel>
                  <Panel header="Payouts" key={6}>
                    <div>
                      <FormMaster
                        title="Payouts"
                        data={this.state.data.payouts}
                        onChange={payouts =>
                          this.setState({
                            data: { ...this.state.data, payouts }
                          })
                        }
                        fields={config_fields.payouts}
                      />
                    </div>
                  </Panel>
                </Collapse>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <hr></hr>
                  {/*<pre>{JSON.stringify(this.state.data, null, 2)}</pre>*/}
                </Col>

                <Col lg={24} md={24} sm={24} xs={24}>
                  {/* <h4
                    className=" gx-text-capitalize gx-mb-0"
                    style={{ color: "#070707" }}
                  >
                    {this.state.type === "edit" ? (
                      <span>{"✏️ Edit Commissions"}</span>
                    ) : (
                      <span>{"➕ Add Commissions"}</span>
                    )}
                    <br></br>
                  </h4>
                  <Row>
                    <Col lg={6} md={6} sm={24} xs={24}>
                      <Form.Item name="cashin" label="Cashin">
                        {getFieldDecorator("cashin", {
                          initialValue: "0",
                          rules: [
                            {
                              required: true,
                              message: "Please enter the value!"
                            }
                          ]
                        })(<Input placeholder="enter the value" />)}
                      </Form.Item>
                      <Form.Item name="cashout" label="Cashout">
                        {getFieldDecorator("cashout", {
                          initialValue: "0",
                          rules: [
                            {
                              required: true,
                              message: "Please enter the value!"
                            }
                          ]
                        })(<Input placeholder="enter the value" />)}
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={6} sm={24} xs={24}>
                      <Form.Item name="flow" label="Flow">
                        {getFieldDecorator("flow", {
                          initialValue: "0",
                          rules: [
                            {
                              required: true,
                              message: "Please enter the value!"
                            }
                          ]
                        })(<Input placeholder="enter the value" />)}
                      </Form.Item>
                      <Form.Item name="flowCobro" label="Flow Cobro">
                        {getFieldDecorator("flowCobro", {
                          initialValue: "0",
                          rules: [
                            {
                              required: true,
                              message: "Please enter the value!"
                            }
                          ]
                        })(<Input placeholder="enter the value" />)}
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={6} sm={24} xs={24}>
                      <Form.Item name="webPayC" label="WebPayC">
                        {getFieldDecorator("webPayC", {
                          initialValue: "0",
                          rules: [
                            {
                              required: true,
                              message: "Please enter the value!"
                            }
                          ]
                        })(<Input placeholder="enter the value" />)}
                      </Form.Item>
                      <Form.Item name="webPayCobro" label="Webpay Cobro">
                        {getFieldDecorator("webPayCobro", {
                          initialValue: "0",
                          rules: [
                            {
                              required: true,
                              message: "Please enter the value!"
                            }
                          ]
                        })(<Input placeholder="enter the value" />)}
                      </Form.Item>
                      <Form.Item name="webPayD" label="WebPayD">
                        {getFieldDecorator("webPayD", {
                          initialValue: "0",
                          rules: [
                            {
                              required: true,
                              message: "Please enter the value!"
                            }
                          ]
                        })(<Input placeholder="enter the value" />)}
                      </Form.Item>

                      <Form.Item name="idcommission">
                        {getFieldDecorator("idcommission", {
                          initialValue: "0",
                          rules: [
                            {
                              required: false,
                              message: "Please enter the value!"
                            }
                          ]
                        })(
                          <Input placeholder="enter the value" type="hidden" />
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={6} sm={24} xs={24}>
                      <Form.Item name="payku" label="Payku">
                        {getFieldDecorator("payku", {
                          initialValue: "0",
                          rules: [
                            {
                              required: true,
                              message: "Please enter the value!"
                            }
                          ]
                        })(<Input placeholder="enter the value" />)}
                      </Form.Item>
                      <Form.Item name="paykuCobro" label="Payku Cobro">
                        {getFieldDecorator("paykuCobro", {
                          initialValue: "0",
                          rules: [
                            {
                              required: true,
                              message: "Please enter the value!"
                            }
                          ]
                        })(<Input placeholder="enter the value" />)}
                      </Form.Item>
                    </Col>
                      </Row>*/}
                  <Form.Item {...tailFormItemLayout}>
                    {this.state.type === "edit" ? (
                      <Button type="primary" htmlType="submit">
                        Edit Merchant
                      </Button>
                    ) : (
                      <>
                        <Button type="primary" htmlType="submit">
                          Create Merchant
                        </Button>
                        <Button type="primary" onClick={this.clearFrom}>
                          Clean
                        </Button>
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            {this.state.resp ? (
              <Alert
                message="Resultado"
                description={this.state.resp}
                type={this.state.typeAlert}
              />
            ) : null}
          </Widget>
        </Col>
      </Row>
    );
  }
}

const MerchantsForm = Form.create()(merchants);

export default MerchantsForm;
