import React from "react";
import { Row, Col, Card, Table, Input, DatePicker, Badge, Button } from "antd";
import _, { first } from "lodash";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";
import ReactExport from "react-export-excel";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import { AllHtmlEntities } from "html-entities";

const entities = new AllHtmlEntities();
class UserPayHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bordered: false,
      loading: false,
      pagination: { current: 1 },
      size: "middle", // default middle small
      expandedRowRender: props.expandedRowRender,
      title: undefined,
      showHeader: true,
      footer: () => (
        <div>
          Suma:{" "}
          <NumberFormat
            value={this.state.sumDataList}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        </div>
      ),
      rowSelection: false, // {} or false
      scroll: undefined,
      searchFilter: "",
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      timeZone: "America/Santiago"
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
        dataLostUserPay: nextProps.dataLostUserPay,
      sumDataList: _.sumBy(nextProps.dataLostUserPay, item => Number(item.quantity))
    });
  }

  filter = searchValue => {

    if (searchValue === "") {
      this.setState({
        dataLostUserPay: this.props.dataLostUserPay,
        sumDataList: _.sumBy(this.props.dataLostUserPay, item => Number(item.quantity))
      });
    } else {
      try {
        const filter = _.filter(
          this.props.dataLostUserPay,
          cashin =>
            (cashin.name
              ? cashin.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cashin.email
              ? cashin.email.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cashin.rut
              ? cashin.rut.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cashin.quantity ? cashin.quantity.toString().indexOf(searchValue) > -1
              : "") ||
            (cashin.commerceReqId ? cashin.commerceReqId
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1 : "") ||
            (cashin.cashinId ? cashin.cashinId.toLowerCase().indexOf(searchValue.toLowerCase()) >
              -1 : "") ||
            (cashin.operationCode
              ? cashin.operationCode
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cashin.prepaidCode
              ? cashin.prepaidCode
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cashin.adminCallBack
              ? cashin.adminCallBack
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "")
              ||
            (cashin.commerceId
              ? cashin.commerceId
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "")
              ||
              (cashin.id
                ? cashin.id
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) > -1
                : "")
                ||
                (cashin.nameStatus
                  ? cashin.nameStatus
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) > -1
                  : "")
           
        );
        this.setState({
          dataLostUserPay: filter,
          sumDataList: _.sumBy(filter, item => Number(item.quantity))
        });
      } catch {
        console.log("Error en el  filtro ");
      }
    }
  };

  updateSearchFilter(evt) {
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }

  onChangeRange = (dates, dateStrings) => {
    let firstDay = moment(dateStrings[0], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .toDate();
    let lastDay = moment(dateStrings[1], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .endOf("day")
      .toDate();
    console.log(firstDay)
    console.log(lastDay)
    this.props.updateQuery(firstDay, lastDay);
  };

  onTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      pagination:pagination
    });
  };

  onTimeZoneChange = zone => {
    console.log("zone", "=>", zone);
    this.setState({
      timeZone: zone
    });
  };
  dateFormat = (data) => {
    if (typeof data !== "undefined") {
      return  data.toDate().toLocaleString("es-CL", {
        timeZone: "America/Santiago",
        timeZoneName: "short"
      }) 
    }
    else {
      return "without date"
    }
  };

   transformCommerceID(commerceId, merchants) {
    let valor;
    merchants.merchants.filter(val => {
      if (val.code.toString() === commerceId.toString()) {
        valor = val.name
      }
    })
    return valor;
  };
  
   transformStatus(status) {
    switch (status.toString()) {
      case "0":
        return "Validated";
      case "9":
        return "Pending";
      case "12":
        return "Failed";
      case "1":
        return "Visitor";
    
      default:
        return status.toString();
    }
  };

  incrementNumber = (index) => {
    if (this.state.dataLostUserPay) {
        if (this.state.pagination.current === 1) {
          //  console.log("index++", index++);
          return (index + 1).toString();
        } else {
          let valor = this.state.pagination.current - 1;
          return (index + 1 + valor * 10).toString();
        }
    }
  };

 render() {
    this.columnsIndex = [
      {
        title: "n°",
        width: 50,
        render: (text, record, index) => {
          return this.incrementNumber(index);
        }
      }
    ];
    this.columns = [
        {
          title: "Date Request",
          dataIndex: "dateRequest",
          key: "dateRequest",
          width: 200,
          render:dateRequest => this.dateFormat(dateRequest)
        },
           
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          width: 150,
          render: name => entities.decode(name)
          
        },
        {
          title: "RUT",
          dataIndex: "rut",
          key: "rut",
          width: 150,
          render: rut => formatRut(rut)
        },
        {
          title: `Email`,
          dataIndex: "email",
          key: "email",
          width: 100
        },
        {
          title: `Status`,
          dataIndex: "nameStatus",
          key: "nameStatus",
          width: 100
        },
        {
          title: "Amount",
          dataIndex: "quantity",
          key: "quantity",
          width: 100,
          render: text => (
            <div style={{ float: "right" }}>
              <NumberFormat
                value={text}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
              />
            </div>
          )
        },
        {
          title: `ID  Merchant`,
          dataIndex: "commerceReqId",
          key: "commerceReqId",
          width: 100
        },
        {
          title: `Merchant`,
          dataIndex: "commerceId",
          key: "commerceId",
          width: 100,
          render: text => (this.transformCommerceID(text, this.props.merchants)),
          sorter: (a, b) => a.commerceId - b.commerceId,
          sortOrder:
          this.state.sortedInfo.columnKey === "commerceId" &&
          this.state.sortedInfo.order
        },
        {
          title: "ID Zippy",
          dataIndex: "id",
          key: "id",
          width: 100
        }
      ];
    return (
      <>
        <div className="components-table-demo-control-bar">
          <Row>
            <Col
              xl={15}
              lg={15}
              md={15}
              sm={24}
              xs={24}
              className="gx-pr-md-2"
            >
            </Col>
            <Col
              xl={9}
              lg={9}
              md={9}
              sm={24}
              xs={24}
              className="gx-pr-md-2"
            >
                {" "}
                <Input
                  size="small"
                  placeholder="Search..."
                  onChange={this.updateSearchFilter.bind(this)}
                ></Input>
            </Col>
          </Row>
        </div>
        <div  style={{ padding: "20px 20px"}} >
        <Row>
        <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
         <Table
         className="gx-table-responsive"
         columns={
          (this.columnsIndex = this.columnsIndex.concat(this.columns))
        }
         {...this.state}
         dataSource={this.state.dataLostUserPay}
         rowKey="id"
         onChange={this.onTableChange}
         pagination={{
           defaultPageSize: 5,
           showSizeChanger: true,
           pageSizeOptions: ["10", "20", "30", "50", "100"]
         }}
       />
         </Col>
       </Row>
        </div>
     </>
  );
  }
}

export default UserPayHistory;
