import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "antd";
import { firebase } from "../../../firebase/firebase";
import Dynamic from "./Dynamic";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import Api from "../../../util/api";

const db = firebase.firestore();
let query;
let dataList = [];

export class cashoutFallida extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Cashout failed",
      filename: `Cashout Failed ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      banks: [],
      commerces: {},
      typeAccount: [],
    };

    this.columns = [
      {
        title: "Date",
        dataIndex: "dateRequest",
        key: "dateRequest",
        width: 150,
        render: date =>
          date.toDate().toLocaleString("es-CL", {
            timeZone: this.state.timeZone,
            timeZoneName: "short"
          })
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 200
        //render: text => <span className="gx-link">{text}</span>
      },
      {
        title: "Amount",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        render: text => (
          <NumberFormat
            value={text}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        )
      },

      {
        title: `ID ${this.props.merchant.name}`,
        dataIndex: "commerceReqId",
        key: "commerceReqId",
        width: 100
      },
      {
        title: "Zippy ID",
        dataIndex: "cashoutId",
        key: "cashoutId",
        width: 100
      },
      ,
      {
        title: `Reason`,
        dataIndex: "reason",
        key: "reason",
        width: 100,
        render: text => {if(text){return text.name}else{return "other"}},
      },
      {
        title: `Rejection Reason`,
        dataIndex: "rejectionReason",
        key: "rejectionReason",
        width: 100,
        render: text => text || '--',
      },
    ];

    this.expandedRowRender = record => (
      <div>
        <p>
          RUT: <b>{formatRut(record.rut)}</b>
        </p>
        <p>
          Bank: <b>{record.bankName}</b>
        </p>
        <p>
          Account Type: <b>{record.typeAccount}</b>
        </p>
        <p>
          Account Number: <b>{record.numAccount}</b>
        </p>
        <p>
          Email: <b>{record.email}</b>
        </p>
      </div>
    );
  }
  componentDidMount =async () => {
    await this.getParamCashout();
    moment.tz.setDefault(this.state.timeZone);

    let firstDay = moment
      .tz(this.state.timeZone)
      .startOf("month")
      .toDate();
    let lastDay = moment
      .tz(this.state.timeZone)
      .endOf("month")
      .toDate();

    this.getData(firstDay, lastDay);
    this.getParametrosGenerales();
  };

  componentDidUpdate(prevProps) {
    if (this.props.merchant !== prevProps.merchant) {
      console.log("componentDidUpdate", "=>", "componentDidUpdate");

      const merchant = localStorage.getItem("merchant_code");
      moment.tz.setDefault(this.state.timeZone);

      let firstDay = moment
        .tz(this.state.timeZone)
        .startOf("month")
        .toDate();
      let lastDay = moment
        .tz(this.state.timeZone)
        .endOf("month")
        .toDate();
      query = db
        .collection("cashouts")
        .where("commerceId", "==", merchant)
        .where("code", "==", 12)
        .where("dateRequest", ">=", new Date(Number(firstDay)))
        .where("dateRequest", "<=", new Date(Number(lastDay)));
      this.updateQuery();

      this.setState({
        filename: `Cashout Failed ${localStorage.getItem("merchant_name")}`
      });
    }
  }
  onChangeRange = (dates, dateStrings) => {
    // console.log("From: ", dates[0], ", to: ", dates[1]);
    console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    this.getData(dateStrings, "rango");
  };

  getData = (firstDay, lastDay) => {
    query = db
      .collection("cashouts")
      .where("commerceId", "==", this.props.merchant.code)
      .where("code", "==", 12)
      .where("dateRequest", ">=", new Date(Number(firstDay)))
      .where("dateRequest", "<=", new Date(Number(lastDay)));
    this.updateQuery();
  };
  getParamCashout= async () => {
    await Api.cashout.getParamCashout().then(resp => {
  
      this.setState({
        banks: resp.banks,
        typeAccount: resp.typeAccount
  
      });
      console.log("resp getParamCashout", "=>", resp);
    })
      .catch(error => {
        console.log("error getParamCashout", "=>", error);
      })
    }
  updateQuery = () => {
    dataList = [];
    query
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          this.setState({
            dataList
          });
          return;
        }

        let bankName = "";
        let typeAccount = "";
        snapshot.forEach(doc => {
          let cashoutId = doc.id;
          console.log("this.state.banks", this.state.banks)
          var databank = this.state.banks.filter(dato => dato.id == doc.data().bankId.toString())
          console.log("databank", databank)
          bankName = databank[0].name;
          /* switch (Number(doc.data().bankId).toString()) {
            case "12":
              bankName = "BANCO ESTADO";
              break;
            case "0":
              bankName = "BANCO ESTADO";
              break;
            case "1":
              bankName = "BANCO DE CHILE";
              break;
            case "37":
              bankName = "BANCO SANTANDER";
              break;
            case "9":
              bankName = "BANCO INTERNACIONAL";
              break;
            case "14":
              bankName = "SCOTIABANK-DESARROLLO";
              break;
            case "16":
              bankName = "BCI";
              break;
            case "27":
              bankName = "CORP-BANCA";
              break;
            case "28":
              bankName = "BICE";
              break;
            case "31":
              bankName = "HSBC BANK CHILE";
              break;
            case "39":
              bankName = "BANCO ITAU";
              break;
            case "45":
              bankName = "MUFG BANK, LTD.";
              break;
            case "49":
              bankName = "BANCO SECURITY";
              break;
            case "51":
              bankName = "BANCO FALABELLA";
              break;
            case "53":
              bankName = "BANCO RIPLEY";
              break;
            case "55":
              bankName = "BANCO CONSORCIO";
              break;
            case "504":
              bankName = "BANCO BBVA";
              break;
            case "672":
              bankName = "COOPEUCH";
              break;
            default:
              bankName = "---";
              break;
          } */
          var datatypeAccount = this.state.typeAccount.filter(dato => dato.id == doc.data().typeAccountId)
          console.log("data datatypeAccount", datatypeAccount)
          typeAccount = datatypeAccount[0].name
      /*     switch (doc.data().typeAccountId) {
            case "CCT":
              typeAccount = "Cuenta Corriente";
              break;
            case "CTV":
              typeAccount = "Chequera Electrónica / Cuenta Vista";
              break;
            case "CRUT":
              typeAccount = "CuentaRUT";
              break;
            case "AHO":
              typeAccount = "Cuenta Ahorro";
              break;
            default:
              typeAccount = "---";
              break;
          } */

          dataList.push({
            ...doc.data(),
            bankName,
            typeAccount,
            cashoutId
          });

          // console.log(doc.id, "=>", doc.data());
        });

        this.setState({
          dataList
        });
        //console.log("CASHOUT", "=>", data);
        console.log("DATALIST", "=>", dataList);
      })
      .catch(console.log);
  };

  getParametrosGenerales= () => {
    db.collection("parametrosGenerales").doc('cashout').get().then(parametrosGenerales => {
      const { payrollMaxAmount = 0, payrollMinAmount = 0, payrollTxnsRange = 0, commerces  } = parametrosGenerales.data();
      this.setState({
        dataModalGeneratePayroll: {
          payrollMaxAmount,
          payrollMinAmount,
          payrollTxnsRange
        },
        commerces
      });
    })
  }

  render() {
    return (
      <Row>
        <Col span={24}>
          <Dynamic
            key="dynamic-cashout-failed"
            filename={this.state.filename}
            title={this.state.title}
            dataList={this.state.dataList}
            columns={this.columns}
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            merchant={this.props.merchant}
            typeUser={this.props.dataUser.typeUser}
            newCashoutConfig={this.state.commerces[this.props.merchant.code]}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { merchant,dataUser } = settings;
  return { merchant,dataUser };
};

export default connect(mapStateToProps)(cashoutFallida);
