import React, { useEffect, useState } from "react";
import Widget from "../../Widget";

const EffectivenessColor = ({ value }) => {
  const [classNameColor, setClassNameColor] = useState("red");

  useEffect(() => {
    if (value <= 70) {
      setClassNameColor("red");
    } else if (value >= 71 && value <= 74) {
      setClassNameColor("yellow");
    } else if (value >= 75) {
      setClassNameColor("green");
    }
  }, [value]);

  return (
    <>
      <span className={`${classNameColor}`}>{value}%</span>
      <style jsx="true">{`
        .green {
          color: #089e08;
        }
        .yellow {
          color: #ff9200;
        }
        .red {
          color: #ff4242;
        }
      `}</style>
    </>
  );
};

const EffectivenessResults = ({ data }) => {
  return (
    <>
      <Widget>
        <div className="effectiveness-results">
          <div className="header-effectiveness-results">
            <span className="header-effectiveness-results__name">
              {data.aggregator ? data.aggregator || "Aggregator" : "Aggregator"}
            </span>
          </div>
          <ul className="effectiveness-results__ul">
            <li className="effectiveness-results__li">
              <span className="effectiveness-results__price">
                {data.effectiveness ? data.effectiveness.successes || 0 : 0}
              </span>
              <span className="effectiveness-results__description">
                Validated
              </span>
            </li>
            <li className="effectiveness-results__li">
              <span className="effectiveness-results__price">
                {data.effectiveness ? data.effectiveness.pendings || 0 : 0}
              </span>
              <span className="effectiveness-results__description">
                Pending
              </span>
            </li>
            <li className="effectiveness-results__li">
              <span className="effectiveness-results__price">
                {data.effectiveness ? data.effectiveness.errors || 0 : 0}
              </span>
              <span className="effectiveness-results__description">Failed</span>
            </li>
            <li className="effectiveness-results__li">
              <span className="effectiveness-results__price">
                <EffectivenessColor
                  value={
                    data.effectiveness ? data.effectiveness.efficiency || 0 : 0
                  }
                />
              </span>
              <span className="effectiveness-results__description">
                Effectiveness
              </span>
            </li>
          </ul>
        </div>
      </Widget>

      <style jsx="true">{`
        .header-effectiveness-results__name {
          display: block;
          font-size: 1.2rem;
          color: black;
        }

        .effectiveness-results__ul {
          list-style: none;
          padding: 0;
          margin: 0;
          margin-top: 1em;
          display: flex;
          gap: 1em 3em;
          flex-wrap: wrap;
        }

        .effectiveness-results__li > span {
          display: block;
        }

        .effectiveness-results__price {
          font-size: 1rem;
        }

        .effectiveness-results__description {
          color: #aaa;
        }
      `}</style>
    </>
  );
};

export default EffectivenessResults;
