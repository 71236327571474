import React, { Component } from "react";
import { Button, Form, Input, Alert, Row, Col, Select, DatePicker, Badge } from "antd";
import Widget from "components/Widget/index";
import { firebase, auth } from "../../firebase/firebase";
import { connect } from "react-redux";
import moment from "moment";
import API from "../../util/api";
import {
  // format as formatRut
  // validate as validateRut,
  clean as cleanRut
} from "rut.js";

const db = firebase.firestore();
/* const FormItem = Form.Item;
const { TextArea } = Input; */
/* let query; */
const { RangePicker } = DatePicker;
class RetentionForm extends Component {
  state = {
    resp: "",
    earlyPayments: "0",
    ivaCobro: true,
    ivaValue: "0",
    retention: "0",

  };
  componentDidMount = async e => {
    let payload = {
      merchant: this.props.merchant.code,
      country: this.props.country.code,
    };
    API.merchant.getRetentionErlyPayment(payload).then(trxData => {
    this.setState({ earlyPayments: trxData.earlyPayments, ivaValue: trxData.ivaValue, retention: trxData.retention, resp: "" })

    })
   .catch(error => {
        console.log("Error en getRetentionErlyPayment :", error);
   })
   setTimeout(
    function () {
      this.setState({ resp: "" });
    }.bind(this),
    20000
  );
  };


  componentDidUpdate (prevProps) {

    if (this.props !== prevProps) {
      console.log("ALGO CAMBIO :");

      //si cambia el combo de merchant pais 
      if (
        this.props.merchant !== prevProps.merchant ||
        this.props.country !== prevProps.country
      ) {
        console.log(" CAMBIO MERCHANT O PAIS:");
        let payload = {
          merchant: localStorage.getItem("merchant_code"),
          country: localStorage.getItem("country_code"),
        };
        API.merchant.getRetentionErlyPayment(payload).then(trxData => {
        this.setState({ earlyPayments: trxData.earlyPayments, ivaValue: trxData.ivaValue, retention: trxData.retention, resp: "OK" })
        })
       .catch(error => {
            console.log("Error en getRetentionErlyPayment :", error);
       })
      }
    }
  };



  handleSubmit = async e => {
    e.preventDefault();
  /*   this.setState({ loading: true, dataList: [], suma: 0, totalRegister: 0 }) */
    this.props.form.validateFields((err, values) => {
      try {
        if (!err) {
          console.log("!!!!!!!!!!!!!! ");
          console.log("Received values of form: ", values);

          let payload = {
            merchant: localStorage.getItem("merchant_code"),
            country: localStorage.getItem("country_code"),
            retention:values.retention,
            earlyPayments:values.earlyPayment
          };
           //SE LLAMA API editRetentionErlyPayment
          API.merchant.editRetentionErlyPayment(payload).then(trxData => {
            console.log("trxData :", trxData);
          this.setState({ earlyPayments: trxData.earlyPayments, retention: trxData.retention, resp: "OK" })
      
          })
         .catch(error => {
              console.log("Error en editRetentionErlyPayment :", error);
         })


         setTimeout(
          function () {
            this.setState({ resp: "" });
          }.bind(this),
          20000
        );

        }
      } catch (error) {
        console.error(error);
        this.setState({
          resp: `Commerce: Error when trying to add a record .\nerror:: ${error}\n`,
          updateTable: !this.state.updateTable,
          typeAlert: "error"
        });
      }
    });
  };
  onChangeRange = (dates, dateStrings) => {
    let firstDay = ""; let lastDay = ""; let defaultValueRange = "";
    console.log("dates", dates)
    if (dates) {
      firstDay = moment(dateStrings[0], "DD/MM/YYYY").tz(this.state.timeZone).toDate();
      lastDay = moment(dateStrings[1], "DD/MM/YYYY").tz(this.state.timeZone).endOf("day").toDate();
    }

    console.log(firstDay)
    console.log(lastDay)
    this.setState({ firstDay, lastDay })

  }
  getCommerces = async () => {
    let merchant = [];
    await db
      .collection("commerces")
      .get()
      .then(snapshot => {
        snapshot.docs.map(async docCommerce => {
          merchant.push({
            code: docCommerce.id,
            name: docCommerce.data().name
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN merchant", err);
      });
    this.setState({ boxMerchant: merchant });
  };

 /*  clearFrom = () => {
    this.props.form.resetFields();
    this.onChangeRange("", "")
    this.setState({
      dataList: [], suma: 0, totalRegister: 0, country: "CL"
    })
  };
 */

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const rangeConfig = {
      rules: [
        {
          type: 'array',
          required: true,
          message: 'Please select time!',
        },
      ],
    };
    /*    const AutoCompleteOption = AutoComplete.Option; */

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 12
        },
        sm: {
          span: 8
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16
        }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    const children = [];

    /*   if (this.state.boxMerchant) {
        this.state.boxMerchant.map(merchant => {
          children.push(
            <Option key={merchant.code.toString()}>
              {merchant.name.toString()}
            </Option>
          );
        });
      } */


    return (
      <Row>

        <Col lg={24} md={24} sm={24} xs={24}>
          <Widget
            title={
              <h4
                className=" gx-text-capitalize gx-mb-0"
                style={{ color: "#070707" }}
              >
                <i className="icon icon-callout" />
                <span>{" Retention/Early Payment"}</span>
              </h4>
            }
          >
                <hr></hr>
            {this.state.resp ? (
              <Alert
                message="Resultado"
                description={this.state.resp}
                type="success"
              />
            ) : null} 
            <br></br>
            <Form
              {...formItemLayout}
              onSubmit={this.handleSubmit}
              //form={form}
              name="register"
              scrollToFirstError
            >
              <Row>
                <Col lg={12} md={12} sm={24} xs={24}>
                  {/*  {this.state.type==="edit"? */}
                  <Form.Item name="retention" label="Retention">
                    {getFieldDecorator("retention", {
                      initialValue: this.state.retention,
                      rules: [
                        {
                          pattern: '^([-]?[1-9][0-9]*|0)$',
                          message: 'Please enter the Retention '
                        }
                      ]
                    })(<Input placeholder="Retention" />)}
                  </Form.Item>


                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Form.Item name="earlyPayment" label="Early Payment">
                    {getFieldDecorator("earlyPayment", {
                      initialValue: this.state.earlyPayments,
                      rules: [
                        {
                          pattern: '^([-]?[1-9][0-9]*|0)$',
                          message: "Please enter the Early Payment!"
                        }
                      ]
                    })(<Input placeholder="earlyPayment" />)}
                  </Form.Item>

                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item {...tailFormItemLayout}>
                
                        <Button type="primary" htmlType="submit">
                          Edit
                        </Button>
                   
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Widget>
        </Col>
        {/*     <Col lg={24} md={24} sm={24} xs={24}>
          <Widget
            title={
              <h4
                className=" gx-text-capitalize gx-mb-0"
                style={{ color: "#070707" }}
              >
                Result
              </h4>
            }
          >
           <Dynamic
              updateTable={this.state.updateTable}
              dataList={this.state.dataList}
              suma={this.state.suma}
              totalRegister={this.state.totalRegister}
              expandedRowRender={this.expandedRowRender}
              loading={this.state.loading}
              country={this.state.country}
            /> 
          </Widget>
        </Col> */}
      </Row>
    );
  }
}

const UsersForm = Form.create()(RetentionForm);

/* export default UsersForm; */

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, country, } = settings;
  return { authUser, country, merchant, };
};

export default connect(mapStateToProps)(UsersForm);