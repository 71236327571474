import React from "react";
import { Switch, Input, Select } from "antd";
import "./form.css";
const { Option } = Select;

const InputText = props => <Input type="text" {...props} />;
const InputArray = props => <Input type="text" {...props} />;
const InputNumber = props => <Input type="number" {...props} />;
const InputBoolean = props => <Switch {...props} />;

function DynamicInputField({ data, fields, type, onChangeInput }) {
  const children = [];
  const childrenEdit = [];
  const childrenSelect = [];
  let childrenEditSelect = "";
  if (data.type === "selection" && data.value !== "") {
    let values = JSON.parse(data.value);
    values.forEach(v => {
      childrenEdit.push(v);
    });
  }
  if (data.type === "select" && data.value !== "") {
    childrenEditSelect = data.value.toString();
  }

  fields.forEach(field => {
    if (field.type == "selection") {
      field.options.forEach(v => {
        children.push(<Option key={v}>{v}</Option>);
      });
    }
    if (field.type == "select") {
      field.repositories.forEach(v => {
        childrenSelect.push(<Option key={v}>{v}</Option>);
      });
    }
  });

  return (
    <>
      {type == "text" && (
        <InputText
          key={`${data.key}-${type}`}
          onChange={e => onChangeInput(data, e.target.value, "value")}
          value={data.value}
          className="input"
        />
      )}
      {type == "number" && (
        <InputNumber
          key={`${data.key}-${type}`}
          onChange={e => onChangeInput(data, e.target.value, "value")}
          value={data.value}
          className="input"
        />
      )}
      {type == "boolean" && (
        <InputBoolean
          key={`${data.key}-${type}`}
          onChange={e => onChangeInput(data, e, "value")}
          checked={data.value}
        />
      )}
      {type == "array" && (
        <InputArray
          key={`${data.key}-${type}`}
          onChange={e => onChangeInput(data, e.target.value, "value")}
          value={data.value}
          className="input"
        />
      )}
      {type == "selection" && (
        <Select
          defaultValue={childrenEdit}
          mode="tags"
          // mode="multiple"
          onChange={e => onChangeInput(data, JSON.stringify(e), "value")}
        >
          {children}
        </Select>
      )}
      {type == "select" && (
        <Select
          value={childrenEditSelect}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option && option.props
              ? option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              : false
          }
          // mode="multiple"
          onChange={e => onChangeInput(data, e, "value")}
        >
          {childrenSelect}
        </Select>
      )}
    </>
  );
}

export default DynamicInputField;
