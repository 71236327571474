import {
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH
} from "constants/ActionTypes";
import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_COLOR_SELECTION,
  THEME_COLOR_SELECTION_PRESET,
  THEME_TYPE,
  THEME_TYPE_SEMI_DARK
} from "../../constants/ThemeSetting";

const initialSettings = {
  navCollapsed: true,
  navStyle: NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  colorSelection: THEME_COLOR_SELECTION_PRESET,
  pathname: localStorage.getItem("pathname"),
  width: window.innerWidth,
  isDirectionRTL: false,
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us"
  },
  merchant: {
    code: localStorage.getItem("merchant_code") || "",
    name: localStorage.getItem("merchant_name") || ""
  },
  country: {
    code: localStorage.getItem("country_code") || "",
    name: localStorage.getItem("country_name") || ""
  },
  dataUser: JSON.parse(localStorage.getItem("dataUser")) || "",
  merchantContr: JSON.parse(localStorage.getItem("merchant_country")) || "",
  comissionData:JSON.parse(localStorage.getItem("comisiones")) || "",
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        pathname: action.payload.pathname,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType
      };
    case THEME_COLOR_SELECTION:
      return {
        ...state,
        colorSelection: action.colorSelection
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle
      };
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType
      };

    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload
      };
    case "SWITCH_MERCHANT":
      return {
        ...state,
        merchant: action.payload
      };
    case "SWITCH_COUNTRY":
      return {
        ...state,
        country: action.payload
      };
    case "SET_DATAUSER":
      return {
        ...state,
        dataUser: action.payload
      };

    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload
      };

    case "SET_TYPEUSER":
      return {
        ...state,
        typeUser: action.payload
      };

    case "SET_MERCHANTCOUNTRIES":
      return {
        ...state,
        merchantContr: action.payload
      };

      case "SET_COMMISSIONS":
        return {
          ...state,
          comissionData: action.payload
        };

    case "DEFAULT_DATA_SUCCESS":
      return {
        ...state,
        merchantContr: action.payload.merchantContr,
        dataUser: action.payload.dataUser,
        country: action.payload.country,
        merchant: action.payload.merchant
      };

      case "SET_DATERANGE":
      return {
        ...state,
        dataRange: action.payload
      };

    default:
      return state;
  }
};

export default settings;
