import { Table, Tag } from "antd";
import React from "react";

const TableReportEffectiveness = ({ data, loading }) => {
  const columns = [
    {
      title: "Aggregator",
      dataIndex: "aggregator",
      key: "aggregator"
    },
    {
      title: "Validated",
      dataIndex: "successes",
      key: "successes"
    },
    {
      title: "Pendings",
      dataIndex: "pendings",
      key: "pendings"
    },
    {
      title: "Failed",
      dataIndex: "errors",
      key: "errors"
    },
    {
      title: "Efficiency",
      dataIndex: "efficiency",
      key: "efficiency",
      render: value => {
        let color = "";

        if (value <= 70) {
          color = "red";
        } else if (value >= 71 && value <= 74) {
          color = "volcano";
        } else if (value >= 75) {
          color = "green";
        }
        return <Tag color={color}>{value}%</Tag>;
      }
    }
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      loading={loading}
      pagination={false}
    />
  );
};

export default TableReportEffectiveness;
