import React, { Component } from "react";
 import SumaryReport from "../../../components/Report/SumaryReport";
import TotalCashin from "../../../components/Report/TotalCashin";
import { Button, Row, Col } from "antd";
import AssessmentReport from "../../../components/Report/AssessmentReport";

class Reports extends Component {
  state = {
    resp: "",
    updateTable: false,
    type: "cashin"
  };

  componentDidMount = async e => {

  };


  /* getPage = type => {
    switch (type) {
      case "payku":
        return <CallbackPayku />;
      case "cashin":
        return <CallbackCashin />;
      case "flow":
        return <CallbackFlow />;
      case "webpay":
        return <CallbackWebPay />;
      default:
        return null;
    }
  }; */

  onchangeToFlow = () => {
    this.setState({
      type: "flow"
    });
  };
  onchangeToCashin = () => {
    this.setState({
      type: "cashin"
    });
  };
  onchangeToPayku = () => {
    this.setState({
      type: "payku"
    });
  };
  onchangeToWebpay = () => {
    this.setState({
      type: "webpay"
    });
  };
  render() {
    return (
      <Row className="container p-0">
        <Col lg={24} md={24} sm={24} xs={24}>
      {/*     <SearchMerchant /> */}
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <h1>REPORTS</h1>

        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div style={{ marginTop: "10px" }}>
            {" "}
           {/*  {this.getPage(this.state.type)} */}
            <SumaryReport/>
            <AssessmentReport/>
            <TotalCashin/>
          </div>
        </Col>
      </Row>
    );
  }
}
/* const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { dataUser } = settings;
  return { authUser, dataUser };
}; */
export default (Reports);
