import React from "react";
import { Row, Col, Card, Table, Input, Popconfirm, Alert, Badge } from "antd";
import _ from "lodash";
import { firebase } from "../../firebase/firebase";
import NumberFormat from "react-number-format";
import API from "../../../src/util/api";
const db = firebase.firestore();
const pagination = { position: "bottom" }; //bottom top both none

class Dynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue: "",
      bordered: false,
      loading: false,
      pagination,
      size: "middle", // default middle small
      expandedRowRender: props.expandedRowRender,
      title: undefined,
      showHeader: true,
      total: 0,
      footer: () => (
        <div>
          Total Records:{" "}
          <NumberFormat
            value={this.state.total}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
          />
        </div>
      ),
      rowSelection: false, // {} or false
      scroll: undefined,
      searchFilter: "",
      updateTable: false
    };
  }
  componentDidMount = () => {
    this.getCommerces();
  };

  getCommerces = async () => {
    let merchant = [];
    let commerces = [];
    await db
      .collection("commerces")
      .get()
      .then(snapshot => {
        snapshot.docs.map(async docCommerce => {
          merchant.push({
            idmerchant: docCommerce.id,
            ...docCommerce.data()
          });
          commerces.push({
            idCommerce: docCommerce.id,
            cashin: docCommerce.data().useCashin,
            cashout: docCommerce.data().useCashout,
            flow: docCommerce.data().useFlow,
            webpay: docCommerce.data().useWebpay,
            isActive: docCommerce.data().isActive,
            payku: docCommerce.data().usePayku
          })
        });
      })
      .catch(err => {
        console.log("ERROR IN merchant", err);
      });
    let total = merchant.length;
    this.setState({ merchantOrigin: merchant, merchant, total: total });
    this.setState({commerces: commerces})
  };
  componentWillReceiveProps(nextProps) {
    /*   console.log(nextProps); */
    if (nextProps.updateTable) {
      this.getCommerces();
    }
  }

  getCommisionsID = async id => {
    let commission = [];
    await db
      .collection("commerces")
      .doc(id)
      .collection("commissions")
      .where("countrie", "==", "CL")
      .get()
      .then(snapshot => {
        snapshot.docs.map(resp => {
          commission.push({
            idcommission: resp.id,
            ...resp.data()
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN getCommissions", err);
      });

    return commission;
  };
  getCountriesByCommerce = async id => {
    let countries = [];
    await db
      .collection("commerces")
      .doc(id)
      .collection("countries")
      /*       .where("countrie", "==", "CL") */
      .get()
      .then(snapshot => {
        snapshot.docs.map(resp => {
          countries.push({
            /*   idcommission: resp.id, */
            ...resp.data()
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN Countries", err);
      });

    return countries;
  };
  getPaymentsByCommerce = async id => {
    return await API.merchant.getPaymentsByMerchants({ id }).then(cons => {
      return cons.arrayObetosPayments;
    });
  };
  getMethodsPayByCommerce = async id => {
    return await API.merchant.getMethodsPayByMerchants({ id }).then(cons => {
      return cons.methodsPay;
    });
  };
  getCommissionsByCommerce = async id => {
    return await API.merchant.getCommissionsByMerchants({ id }).then(cons => {
      return cons.arrayObetosCommisiones;
    });
  };
  getPayoutsByCommerce = async id => {
    return await API.merchant.getPayoutsByMerchants({ id }).then(cons => {
      return cons.arrayObetosPayout;
    });
  };

  filter = searchValue => {
    if (searchValue === "") {
      this.setState({
        merchant: this.state.merchantOrigin
      });
    } else {
      try {
        const filter = _.filter(
          this.state.merchant,
          merchant =>
            (merchant.idmerchant
              ? merchant.idmerchant
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (merchant.name
              ? merchant.name.toLowerCase().indexOf(searchValue.toLowerCase()) >
                -1
              : "")
        );
        this.setState({
          merchant: filter
        });
      } catch {
        console.log("Error en  el filtro ");
      }
    }
  };

  updateSearchFilter(evt) {
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }

  onTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  edit = async record => {
    console.log("edit ");
    this.props.cambiarEstado(true);
    let commision = await this.getCommisionsID(record.idmerchant);
    record.commision = commision;
    let countries = await this.getCountriesByCommerce(record.idmerchant);
    record.countries = countries;
    let payments = await this.getPaymentsByCommerce(record.idmerchant);
    record.payments = payments;
    let methodsPay = await this.getMethodsPayByCommerce(record.idmerchant);
    record.methodsPay = methodsPay;
    let commissions = await this.getCommissionsByCommerce(record.idmerchant);
    record.commissions = commissions;
    let payouts = await this.getPayoutsByCommerce(record.idmerchant);
    record.payouts = payouts;

    /* console.log("commision",commision,"record.commision",record.commision,"record.idmerchant",record.idmerchant); */
    this.props.cambiarEstado(false);
    this.props.OnEdit(record);
    setTimeout(
      function() {
        this.setState({ resp: "" });
      }.bind(this),
      3000
    );
  };
  delete = async record => {
    console.log("delete ");
    const {isActive=true} = record
    let status;
    if (isActive) {
      status = false;
    } else {
      status = true;
    }
    let response = await API.merchant
      .getDeleteByMerchants({ id: record.idmerchant, status })
      .then(cons => {
        this.state.merchant.forEach(m =>{
          if(record.idmerchant == m.idmerchant){
            m.isActive = status
          }
        })
        // window.location.reload();
      });
    setTimeout(
      function() {
        this.setState({ resp: "" });
      }.bind(this),
      3000
    );
  };

  getColumns = () => [
    {
      title: "Zippy ID",
      dataIndex: "idmerchant",
      key: "idmerchant",
      width: 100,
      visible: false,
      display: "none",
      hideOnSmall: true,
      className: "hide"
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150 /* ,
    sorter: (a, b) => a.valor - b.valor,
    sortOrder:
      this.state.sortedInfo.columnKey === "valor" &&
      this.state.sortedInfo.order */
    },

    {
      title: "Cashin",
      dataIndex: "useCashin",
      key: "useCashin",
      width: 100,
      /*  sorter: (a, b) => a.valor - b.valor,
       sortOrder:
         this.state.sortedInfo.columnKey === "valor" &&
         this.state.sortedInfo.order */

      render: (text, record) => (
        <span>
          {String(text) === "true" ? (
            <Badge status="success" />
          ) : (
            <Badge status="error" />
          )}
        </span>
      )
    },

    {
      title: "Cashout",
      dataIndex: "useCashout",
      key: "useCashout",
      width: 100 /* ,
    sorter: (a, b) => a.valor - b.valor,
    sortOrder:
      this.state.sortedInfo.columnKey === "valor" &&
      this.state.sortedInfo.order */,
      render: (text, record) => (
        <span>
          {String(text) === "true" ? (
            <Badge status="success" />
          ) : (
            <Badge status="error" />
          )}
        </span>
      )
    },

    {
      title: "WebPay",
      dataIndex: "useWebpay",
      key: "useWebpay",
      width: 100,
      render: (text, record) => (
        <span>
          {String(text) === "true" ? (
            <Badge status="success" />
          ) : (
            <Badge status="error" />
          )}
        </span>
      )
      /* ,
      sorter: (a, b) => a.valor - b.valor,
      sortOrder:
        this.state.sortedInfo.columnKey === "valor" &&
        this.state.sortedInfo.order */
    },

    {
      title: "Flow",
      dataIndex: "useFlow",
      key: "useFlow",
      width: 100,
      render: (text, record) => (
        <span>
          {String(text) === "true" ? (
            <Badge status="success" />
          ) : (
            <Badge status="error" />
          )}
        </span>
      )
      /* ,
      sorter: (a, b) => a.valor - b.valor,
      sortOrder:
        this.state.sortedInfo.columnKey === "valor" &&
        this.state.sortedInfo.order */
    },
    {
      title: "Payku",
      dataIndex: "usePayku",
      key: "usePayku",
      width: 100,
      render: (text, record) => (
        <span>
          {String(text) === "true" ? (
            <Badge status="success" />
          ) : (
            <Badge status="error" />
          )}
        </span>
      )
      /* ,
      sorter: (a, b) => a.valor - b.valor,
      sortOrder:
        this.state.sortedInfo.columnKey === "valor" &&
        this.state.sortedInfo.order */
    },
    {
      title: "Status",
      width: 100,
      render: (text, record) => (
        <span>
          {String(record.isActive) === "true" ||
          record.isActive == undefined ? (
            <Badge status="success" />
          ) : (
            <Badge status="error" />
          )}
        </span>
      )
      /* ,
      sorter: (a, b) => a.valor - b.valor,
      sortOrder:
        this.state.sortedInfo.columnKey === "valor" &&
        this.state.sortedInfo.order */
    },

    {
      title: "Action",
      key: "action",
      /*  fixed: "right", */
      width: 150,
      render: (text, record) => (
        <>
          <span>
            <Popconfirm
              title="Do you want edit this  Merchant?"
              onConfirm={() => this.edit(record)}
              onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
            >
              <span className="gx-link">
                {record.prepared ? "✅ " : ""}Edit
              </span>
            </Popconfirm>
          </span>
          &nbsp;
          <span>
            <Popconfirm
              title={`Do you want  ${
                record.isActive == true || record.isActive == undefined
                  ? "disabled"
                  : "enabled"
              } this Merchant?`}
              onConfirm={() => this.delete(record)}
              onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
            >
              <span
                className="gx-link"
                style={{
                  color:
                    record.isActive == true || record.isActive == undefined
                      ? "red"
                      : "green"
                }}
              >
                {record.prepared ? "✅ " : ""}{" "}
                {record.isActive == true || record.isActive == undefined
                  ? "disabled"
                  : "enabled"}
              </span>
            </Popconfirm>
          </span>
        </>
      )
    }
  ];
  render() {
    return (
      <Card>
        <div className="components-table-demo-control-bar">
          <Row>
            <Col
              xl={15}
              lg={15}
              md={15}
              sm={24}
              xs={24}
              className="gx-pr-md-2"
            ></Col>
            <Col xl={9} lg={9} md={9} sm={24} xs={24} className="gx-pr-md-2">
              <Input
                id="idSearch"
                defaultValue={this.state.defaultValue}
                placeholder="Search..."
                onChange={this.updateSearchFilter.bind(this)}
              ></Input>
            </Col>
          </Row>
        </div>

        <Table
          className="gx-table-responsive"
          {...this.state}
          // columns={this.props.columns || this.columns}

          columns={this.getColumns()}
          dataSource={this.state.merchant}
          rowKey="valor"
          onChange={this.onTableChange}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50", "100"]
          }}
        />
        {this.state.resp ? (
          <Alert
            message="Resultado"
            description={this.state.resp}
            type="success"
          />
        ) : null}
      </Card>
    );
  }
}

export default Dynamic;
