import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";

import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

const SubMenu = Menu.SubMenu;

class HorizontalNavMx extends Component {
  getNavStyleSubMenuClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  render() {
    const { pathname, navStyle } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];

    let menu;
    switch (this.props.authUser) {
      case "ncornejo@me.com":
      case "johnkaroka@gmail.com":
      case "zeroray@gmail.com":
      case "alonso.marchant@hotmail.com":
      case "pabloignacio.lan.tus@gmail.com":
      case "jarias@stratechcorp.com":
      case "avidal@stratechcorp.com":
      case "jcontreras@stratechcorp.com":
      case "Panfossi@stratechcorp.com":
      case "panfossi@stratechcorp.com":
      case "fmansilla@stratechcorp.com":
      case "ralvarez@stratechcorp.com":
      case "rponce@stratechcorp.com":
        menu = (
          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="admin"
            title={<IntlMessages id="sidebar.admin" />}
          >
            <Menu.Item key="main/admin/blacklist">
              <Link to="/main/admin/blacklist">
                <i className="icon icon-list" />
                <IntlMessages id="sidebar.admin.blacklist" />
              </Link>
            </Menu.Item>
            <Menu.Item key="main/admin/callback">
              <Link to="/main/admin/callback">
                <i className="icon icon-check" />
                <IntlMessages id="sidebar.admin.callback" />
              </Link>
            </Menu.Item>
            <Menu.Item key="main/admin/cierres">
              <Link to="/main/admin/cierres">
                <i className="icon icon-check" />
                <IntlMessages id="sidebar.admin.cierres" />
              </Link>
            </Menu.Item>
          </SubMenu>
        );
        break;
      default:
      // Show regular user UI.
    }

    return (
      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal"
      >
        <Menu.Item key="main/dashboardMx">
          <Link to="/main/dashboardMx">
            <div style={{ color: "white" }}>
              {/* <i className="icon icon-dasbhoard" /> */}
              <IntlMessages id="sidebar.dashboard" />
            </div>
          </Link>
        </Menu.Item>
        {/*
        <SubMenu
          className={this.getNavStyleSubMenuClass(navStyle)}
          key="cashin"
          title={<IntlMessages id="sidebar.cashin" />}
        >
          <Menu.Item key="main/cashin/validated">
            <Link to="/main/cashin/validated">
              <i className="icon icon-check" />
              <IntlMessages id="sidebar.cashin.validated" />
            </Link>
          </Menu.Item>
          <Menu.Item key="main/cashin/pending">
            <Link to="/main/cashin/pending">
              <i className="icon icon-timepicker" />
              <IntlMessages id="sidebar.cashin.pending" />
            </Link>
          </Menu.Item>
          <Menu.Item key="main/cashin/failed">
            <Link to="/main/cashin/failed">
              <i className="icon icon-close-circle" />
              <IntlMessages id="sidebar.cashin.failed" />
            </Link>
          </Menu.Item>
        </SubMenu> */}
        <SubMenu
          className={this.getNavStyleSubMenuClass(navStyle)}
          key="pct "
          title={<IntlMessages id="sidebar.admin.pct" />}
        >
          <Menu.Item key="main/pct/validated">
            <Link to="/main/pct/validated">
              <i className="icon icon-check" />
              <IntlMessages id="sidebar.pct.validated" />
            </Link>
          </Menu.Item>
          <Menu.Item key="main/pct/pending">
            <Link to="/main/pct/pending">
              <i className="icon icon-timepicker" />
              <IntlMessages id="sidebar.pct.pending" />
            </Link>
          </Menu.Item>
          <Menu.Item key="main/pct/failed">
            <Link to="/main/pct/failed">
              <i className="icon icon-close-circle" />
              <IntlMessages id="sidebar.pct.failed" />
            </Link>
          </Menu.Item>
        </SubMenu>
        {/*     <SubMenu
          className={this.getNavStyleSubMenuClass(navStyle)}
          key="cashout"
          title={<IntlMessages id="sidebar.cashout" />}
        >
          <Menu.Item key="main/cashout/validated">
            <Link to="/main/cashout/validated">
              <i className="icon icon-check" />
              <IntlMessages id="sidebar.cashout.validated" />
            </Link>
          </Menu.Item>
          <Menu.Item key="main/cashout/pending">
            <Link to="/main/cashout/pending">
              <i className="icon icon-timepicker" />
              <IntlMessages id="sidebar.cashout.pending" />
            </Link>
          </Menu.Item>
          <Menu.Item key="main/cashout/failed">
            <Link to="/main/cashout/failed">
              <i className="icon icon-close-circle" />
              <IntlMessages id="sidebar.cashout.failed" />
            </Link>
          </Menu.Item>
        </SubMenu> */}
        {menu}
      </Menu>
    );
  }
}

HorizontalNavMx.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { themeType, navStyle, pathname, locale } = settings;
  const { authUser } = auth;
  return { themeType, navStyle, pathname, locale, authUser };
};
export default connect(mapStateToProps)(HorizontalNavMx);
