import React, { useState, useEffect } from "react";
import { Table, Tag, Badge } from "antd";
import { ExpandedRowRender } from "./expandedRowRender";
import Column from "antd/lib/table/Column";
import moment from "moment";
import NumberFormat from "react-number-format";

const colorStatus = {
  "12": "red",
  "9": "orange",
  "7": "blue",
  "1": "cyan",
  "0": "green"
};

const TablePayrollDetail = ({ txns, fetching, data }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(fetching);
  }, [fetching]);

  return (
    <>
      <Table
        dataSource={txns}
        pagination={false}
        size="small"
        loading={loading}
        style={{ background: "white" }}
        scroll={{ x: 1166 }}
        rowKey={record => record.id}
        expandedRowRender={record => <ExpandedRowRender record={record} />}
        footer={() => (
          <div style={{ display: "flex", gap: "1em 2em" }}>
            <div>
              <Badge status="blue" text={`All: ${txns.length}`} />
              <h4 style={{ margin: 0, paddingLeft: ".9em" }}>
                Total:{" "}
                <NumberFormat
                  value={txns.reduce(function(acc, obj) {
                    return acc + parseInt(obj.quantity);
                  }, 0)}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                />
              </h4>
            </div>

            <div>
              <Badge
                status="success"
                text={`Not Rejected: ${
                  txns.filter(txn => txn.code != 12).length
                }`}
              />
              <h4 style={{ margin: 0, paddingLeft: ".9em" }}>
                Total:{" "}
                <NumberFormat
                  value={txns
                    .filter(txn => txn.code != 12)
                    .reduce(function(acc, obj) {
                      return acc + parseInt(obj.quantity);
                    }, 0)}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                />
              </h4>
            </div>

            <div>
              <Badge
                status="error"
                text={`Rejected: ${txns.filter(txn => txn.code == 12).length}`}
              />
              <h4 style={{ margin: 0, paddingLeft: ".9em" }}>
                Total:{" "}
                <NumberFormat
                  value={txns
                    .filter(txn => txn.code == 12)
                    .reduce(function(acc, obj) {
                      return acc + parseInt(obj.quantity);
                    }, 0)}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                />
              </h4>
            </div>
            <div>
              <Badge status="blue" text={`Commerce ID`} />
              <h4 style={{ margin: 0, paddingLeft: ".9em" }}>
                {data
                  ? data.commerceId
                    ? data.commerceId.toUpperCase()
                    : "--"
                  : "--"}
              </h4>
            </div>
          </div>
        )}
      >
        <Column
          title="Nº"
          dataIndex="num"
          key="num"
          width={40}
          render={(text, record, index) => <span>{index + 1}</span>}
        />
        <Column
          title="Date"
          dataIndex="dateRequest"
          key="dateRequest"
          render={(text, record) => moment(text).format("DD MMM YYYY HH:mm:ss")}
          width={200}
        />
        <Column title="Name" dataIndex="name" key="name" />
        <Column
          title="Amount"
          dataIndex="quantity"
          key="quantity"
          render={(text, record) => (
            <NumberFormat
              value={text}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
            />
          )}
        />
        <Column
          title="Zippy ID"
          dataIndex="zippyId"
          key="zippyId"
          width={220}
        />
        <Column
          title="Commerce Req ID"
          dataIndex="commerceReqId"
          key="commerceReqId"
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(text, record) => (
            <Tag color={colorStatus[record.code]} style={{ margin: 0 }}>
              {text}
            </Tag>
          )}
        />
      </Table>
    </>
  );
};

export default TablePayrollDetail;
