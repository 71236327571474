import React from "react";
import { Row, Col, Card, Table, Input, DatePicker,Button } from "antd";
import _ from "lodash";
import NumberFormat from "react-number-format";
import moment from "moment";
import ReactExport from "react-export-excel";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import Auxiliary from "../../../../util/Auxiliary";
import { AllHtmlEntities } from "html-entities";



const entities = new AllHtmlEntities();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { RangePicker } = DatePicker;
const pagination = { position: "bottom" }; //bottom top both none

class Dynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bordered: false,
      loading: false,
      pagination,
      size: "middle", // default middle small
      expandedRowRender: props.expandedRowRender,
      title: undefined,
      showHeader: true,
      footer: () => (
        <div>
          Suma:{" "}
          <NumberFormat
            value={this.state.sumDataList}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        </div>
      ),
      rowSelection: false, // {} or false
      scroll: undefined,
      searchFilter: "",
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      timeZone: "America/Santiago"
    };
  }

  componentWillReceiveProps(nextProps) {

    console.log("dinamic payku")
    this.setState({
      dataList: nextProps.dataList,
      sumDataList: _.sumBy(nextProps.dataList, item => Number(item.quantity))
    });
  }

  filter = searchValue => {
    if (searchValue === "") {
      this.setState({
        dataList: this.props.dataList,
        sumDataList: _.sumBy(this.props.dataList, item => Number(item.quantity))
      });
    } else {

      try
      {
      const filter = _.filter(
        this.props.dataList,
        payku =>
        (payku.name
          ? payku.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
          : "") ||
          (payku.email
            ? payku.email.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
          : "") ||
          (payku.rut
            ?payku.rut.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
          : "") ||
          (payku.commerceReqId
            ?payku.commerceReqId.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
            : "") ||
            (payku.paykuId
              ?payku.paykuId.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
              : "") ||
          (payku.operationCode
            ? payku.operationCode.toLowerCase().indexOf(searchValue.toLowerCase()) > -1:"")
            ||
            (payku.id
              ? payku.id.toLowerCase().indexOf(searchValue.toLowerCase()) > -1:"")

      );
      this.setState({
        dataList: filter,
        sumDataList: _.sumBy(filter, item => Number(item.quantity))
      });
    }
    catch{
      console.log("Error en  el filtro ");
    }
    }
  };

  updateSearchFilter(evt) {
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }

  onChangeRange = (dates, dateStrings) => {
    let firstDay = moment(dateStrings[0], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .toDate();
    let lastDay = moment(dateStrings[1], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .endOf("day")
      .toDate();

    this.props.getData(firstDay, lastDay);
  };

  onTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  onTimeZoneChange = zone => {
    console.log("zone", "=>", zone);
    this.setState({
      timeZone: zone
    });
  };
  dateFormat = (data) => {
    if (data.dateRequest != "") {
      return moment(new Date(data.dateRequest._seconds * 1000).toISOString()).toDate().toLocaleString("es-CL", {
        timeZone: this.state.timeZone,
        timeZoneName: "short"
      })
    }
    else {
      return ""
    }
  }
  render() {
    this.columns = [
    /*   {
        title: "Date Request",
        dataIndex: "dateRequest",
        key: "dateRequest",
        width: 200,
        render: date =>
          date.toDate().toLocaleString("es-CL", {
            timeZone: this.state.timeZone,
            timeZoneName: "short"
          }),
        sorter: (a, b) => a.dateRequest.seconds - b.dateRequest.seconds,
        sortOrder:
          this.state.sortedInfo.columnKey === "dateRequest" &&
          this.state.sortedInfo.order
      }, */
      {
        title: "Date Request",
        dataIndex: "dateRequest",
        key: "dateRequest",
        width: 200,
        render: dateRequest => (
          <span>{moment(new Date(dateRequest._seconds * 1000)).toDate().toLocaleString("es-CL", {
            timeZone: this.state.timeZone,
            timeZoneName: "short"
          })}</span>
        ),
        sorter: (a, b) => a.dateRequest._seconds - b.dateRequest._seconds,
        sortOrder:
          this.state.sortedInfo.columnKey === "dateRequest" &&
          this.state.sortedInfo.order
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 150,
        render: name => entities.decode(name)
      },
      {
        title: "RUT",
        dataIndex: "rut",
        key: "rut",
        width: 150,
        render: rut => formatRut(rut)
      },
      {
        title: "Amount",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        render: text => (
          <div style={{ float: "right" }}>
            <NumberFormat
              value={text}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
            />
          </div>
        )
      },
      {
        title: `ID ${this.props.merchant.name}`,
        dataIndex: "commerceReqId",
        key: "commerceReqId",
        width: 100
      },
      {
        title: "Zippy ID",
        dataIndex: "id",
        key: "id",
        width: 100
      },
      {
        title: "Date Conciliacion",
        dataIndex: "dateConciliacion",
        key: "dateConciliacion",
        width: 100
      },
      {
        title: "Conciliado",
        dataIndex: "conciliado",
        key: "conciliado",
        width: 100,
        render: val => {
          if (val) {
            return "True";
          } else {
            return "False";
          }
        }
      }
    ];

    return (
      <Card
        title={this.props.title}
        extra={
          <Auxiliary>
      {/*     {this.props.user==="annasandoval777@gmail.com"?(<Button onClick={this.props.update}  className="Dashed">🔄</Button>):""} */}
            TimeZone: {this.state.timeZone}{" "}
            <i
              className={`flag flag-24 gx-mr-2 flag-ru`}
              onClick={this.onTimeZoneChange.bind(this, "Europe/Moscow")}
            ></i>
            <i
              className={`flag flag-24 gx-mr-2 flag-cl`}
              onClick={this.onTimeZoneChange.bind(this, "America/Santiago")}
            ></i>
          </Auxiliary>
        }
      >
        <div className="components-table-demo-control-bar">
          <Row>
            <Col xl={15} lg={15} md={15} sm={24} xs={24} className="gx-pr-md-2">


              <RangePicker
                className="gx-mb-3 gx-w-50"
                format="DD/MM/YYYY"
                ranges={{
                  All: [moment("01-01-2019", "MM-DD-YYYY"), moment()],
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                  ],
                  "This week": [
                    moment().startOf("isoweek"),
                    moment().endOf("isoweek")
                  ],
                  "Last 7 Days": [moment().subtract(6, "days"), moment()],
                  "Last Month": [
                    moment()
                      .tz(this.state.timeZone)
                      .startOf("month")
                      .subtract(1, "months"),
                    moment()
                      .tz(this.state.timeZone)
                      .endOf("month")
                      .subtract(1, "months")
                  ],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ]
                }}
                onChange={this.onChangeRange}
                defaultValue={[
                 // moment().startOf("month"),
                 // moment().endOf("month")
                  moment().tz(this.state.timeZone).subtract(1, "days"),
                  moment().tz(this.state.timeZone),
                ]}
              />
            </Col>
            <Col xl={9} lg={9} md={9} sm={24} xs={24} className="gx-pr-md-2">
              <Input
                placeholder="Buscar..."
                onChange={this.updateSearchFilter.bind(this)}
              ></Input>
            </Col>
          </Row>
        </div>

        <Table
          className="gx-table-responsive"
          {...this.state}
          columns={this.columns}
          dataSource={this.state.dataList}
          onChange={this.onTableChange}
          rowKey="paykuId"
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30','50','100']}}
        />

        <ExcelFile filename={this.props.filename}>
          <ExcelSheet data={this.state.dataList} name="Hoja 1">
           {/*  <ExcelColumn
              label="Date"
              value={col =>
                col.dateRequest.toDate().toLocaleString("es-CL", {
                  timeZone: this.state.timeZone,
                  timeZoneName: "short"
                })
              }
            /> */}

<ExcelColumn label="Date" value={col => this.dateFormat(col)} />
            <ExcelColumn label="Name" value="name" />
            <ExcelColumn label="Rut" value="rut" />
            <ExcelColumn label="Amount" value={col => Number(col.quantity)} />
            <ExcelColumn label="ID Zippy" value="paykuId" />
            {/* <ExcelColumn label="Email" value="email" /> */}
            <ExcelColumn label="Operation Code" value="operationCode" />
            <ExcelColumn
              label={`ID ${this.props.merchant.name}`}
              value={col => col.commerceReqId}
            />
            <ExcelColumn label="Date Conciliacion" value="dateConciliacion" />
            <ExcelColumn label="Commision" value="commision" />
            <ExcelColumn label="IVA" value="iva" />
            <ExcelColumn label="Tot Commision" value="totCommision" />
            <ExcelColumn label="Total" value="total" />
          </ExcelSheet>
        </ExcelFile>
      </Card>
    );
  }
}

export default Dynamic;

