const editElementForKey = (obj, id, item_to_replace) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === "key" && obj[key] === id) {
        return item_to_replace; // Devolver el nuevo objeto en lugar del encontrado
      } else if (typeof obj[key] === "object") {
        let result = editElementForKey(
          obj[key],
          id,
          item_to_replace
        );
        if (result !== null) {
          obj[key] = result; // Actualizar el objeto padre con el objeto editado
          return obj; // Devolver el objeto completo
        }
      }
    }
  }
  return null;
}

export default editElementForKey;
