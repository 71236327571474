import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  Alert,
  Row,
  Col,
  Select,
  Badge,
  Spin,
  Collapse
} from "antd";
import Widget from "components/Widget/index";

import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebase";
import moment from "moment-timezone";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import { AllHtmlEntities } from "html-entities";
import NumberFormat from "react-number-format";
import "./stylesCallback.css";

const FormItem = Form.Item;
const db = firebase.firestore();
const entities = new AllHtmlEntities();

class CallbackCashout extends Component {
  state = {
    resp: "",
  /*   Resend: false, */
    deco: false,
    dataTransfer: [],
    timeZone: "America/Santiago",
    dateRequest: "",
    colorStatus: "rgb(8,5,5,0.13489145658263302)",
    status: "",
    merchant: "",
    spin: false,
    spinListAdmin: false,
    Limit: 45000,
    TransactionPendingAdmin: [],
    ErrorLimit: false,
    codigoDropdown: "",
  };

  componentDidMount = async e => {
    /*   this.getLimit(); */
    /*  this.getAdminApproval(); */
  };



  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      resp: "",
      spin: true
    });



    this.props.form.validateFields(async (err, values) => {
      let idZippy = values.idZippy;
      if (!err) {

        console.log(
          "Received values of form: ",
          values,

        );

        //update collection Cashout
        let updateData = {};
        updateData = {
          code: parseInt(values.codigo)

        };
        let resp;
          var cashouts = db.collection("cashouts");
          await cashouts
          .doc(idZippy)
          .update(
            updateData
          )
          .then(function() {
            console.log("Document successfully updated!");
                resp= {
                  message:
                    " Document successfully updated!!"
                }
          })
          .catch(err => {
            console.log("ERROR IN handleSubmit", err);
            resp= {
              message:
                " ERROR !!"
            }

          });
          this.setState({
            resp: resp,
            spin: false
          });


          this.updateQuery(idZippy);


      } else {
        this.setState({
          resp: "",
          spin: false
        });
      }
    });

  };






  resetTransactionInfo = () => {
    this.setState({
      ErrorLimit: false,
      dataTransfer: [],
      colorStatus: "rgb(8,5,5,0.13489145658263302)",
      status: "",
      resp: "",
      merchant: "",
      spin: false,
      dateRequest: "",
      Resend: false
    });
  };

  resetAll = () => {
    this.props.form.setFieldsValue({
      ["idZippy"]: "",
      ["codigo"]: "",
      ["operationCode"]: ""
    });

    this.setState({
      resp: "",
      deco: false,
      dataTransfer: [],
      dateRequest: "",
      colorStatus: "rgb(8,5,5,0.13489145658263302)",
      status: "",
      merchant: "",
      spin: false,
      spinListAdmin: false,
      ErrorLimit: false,
      Resend: false
    });
  };




  cancelTransacion = async id => {
    await this.deleteAdminApproval(id);
    setTimeout(() => {
      this.getAdminApproval();
    }, 1000);
  };



  onChange = e => {
    console.log("onChange", e.target.checked);
    this.setState({ Resend: e.target.checked });
    if (e.target.checked) {
      /*   this.props.form.resetFields(
          ["operationCode"]
        ); */
      this.props.form.setFieldsValue({
        ["codigo"]: '0',

      });
    }



  };

  onChangeID = e => {
    console.log("onChangeID", e);
    if (e === "0") {
      this.setState({ deco: true });
      console.log("onChangeID", e);
    } else {
      this.setState({ deco: false });
      console.log("onChangeID", "false");
    }
  };

  getDataTrx = async e => {
    this.setState({
      spin: true
    });
    let id = e.target.value;
    console.log("getDataTrx", e.target.value);
    this.updateQuery(id);
  };

  getDataTrxAdmin = async (id, recommendCode, operationCode, resend, recommendedOperation) => {
    if (Number(recommendCode) === 0) {
      this.setState({
        deco: true,
        resp: ""
      });
      if (resend) {
        this.props.form.setFieldsValue({
          ["idZippy"]: id,
          ["codigo"]: recommendCode,
          ["operationCode"]: ""
        });
        this.setState({
          Resend: resend
        });
      } else {
        this.props.form.setFieldsValue({
          ["idZippy"]: id,
          ["codigo"]: recommendCode,
          ["operationCode"]: recommendedOperation
        });
        this.setState({
          Resend: false
        });
      }
    } else {
      this.setState({
        resp: "",
        Resend: false
      });
      this.props.form.setFieldsValue({
        ["idZippy"]: id,
        ["codigo"]: recommendCode
      });
    }

    this.setState({
      spin: true
    });
    this.updateQuery(id);
    return true;
  };

  transformCommerceID = commerceId => {
    switch (commerceId) {
      case "2019CL1xbet-8k3y":
        return "1xbet";
      case "2020juegalo-5n2q":
        return "JuegaloBet";
      case "2020cestelar-3j9s":
        return "CeStelar";
      case "2020dw-6d9w":
        return "Dotworkers";
      case "2020juegalopro-7j7g":
        return "JuegaloPro";
      case "2020e-Management2u5i":
        return "e-Management";
      case "2020Payretailers7g21":
        return "Payretailers";


      default:
        return "JuegaloBet";
    }
  };

  updateQuery = async id => {
    try {
      var cashouts = db.collection("cashouts");
      let data = [];
      await cashouts
        .doc(id)
        .get()
        .then(snapshot => {
          if (!snapshot.empty) {
            console.log(snapshot.data());

            let dateRequest = moment(
              snapshot.data().dateRequest.toDate()
            ).toLocaleString("es-CL", {
              timeZone: this.state.timeZone,
              timeZoneName: "short"
            });

            let format = moment(dateRequest).format("YYYY-MM-DD HH:mm:ss");

            this.setState({ dateRequest: format });

            switch (snapshot.data().code.toString()) {
              case "0":
                this.setState({
                  colorStatus: "rgb(53 138 42 / 13%)",
                  status: "OK"
                });
                break;
              case "9":
                this.setState({
                  colorStatus: "rgb(243 216 26 / 13%)",
                  status: "Pending"
                });

                break;
              case "12":
                this.setState({
                  colorStatus: "rgb(243 26 26 / 13%)",
                  status: "Failed"
                });
                break;
              default:
                this.setState({
                  colorStatus: "rgb(8,5,5,0.13489145658263302)"
                });
                break;
            }

            switch (snapshot.data().commerceId.toString()) {
              case "2019CL1xbet-8k3y":
                this.setState({
                  merchant: "1xbet"
                });
                break;
              case "2020juegalo-5n2q":
                this.setState({
                  merchant: "JuegaloBet"
                });
                break;
              case "2020cestelar-3j9s":
                this.setState({
                  merchant: "CeStelar"
                });
                break;
              case "2020dw-6d9w":
                this.setState({
                  merchant: "Dotworkers"
                });
                break;
              case "2020juegalopro-7j7g":
                this.setState({
                  merchant: "JuegaloPro"
                });
                break;



              case "2020e-Management2u5i":
                this.setState({
                  merchant: "e-Management"
                });
                break;


              case "2020Payretailers7g21":
                this.setState({
                  merchant: "Payretailers"
                });
                break;



              default:
                this.setState({
                  merchant: "JuegaloBet"
                });
                break;

            }

            data.push({
              id,
              ...snapshot.data()
            });
            this.setState({ dataTransfer: data[0], spin: false });
          } else {
            this.resetTransactionInfo();
          }
        })
        .catch(err => {
          console.log("ERROR IN updateQuery", err);
          this.resetTransactionInfo();
        });
    } catch (err) {
      console.log("Error en updateQuery :", err);
      this.resetTransactionInfo();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const codigoDropdown = this.state.codigoDropdown;
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 12
        },
        sm: {
          span: 8
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16
        }
      }
    };

    return (
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Widget
            title={
              <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
                <i className="icon icon-mail-open gx-mr-3" />
                Callback Manual Cash Out
              </h4>
            }
          >
            <hr></hr>

            {/* {this.state.dataTransfer ? ( */}
            <div
              class="ant-card gx-card-widget "
              style={{ background: this.state.colorStatus }}
            >
              <div class="ant-card-body">
                <span class="gx-widget-badge">
                  Cashout info{" "}
                  {this.state.status === "OK" ? (
                    <Badge color="green" text={"Validated"} />
                  ) : this.state.status === "Pending" ? (
                    <Badge color="yellow" text={this.state.status} />
                  ) : (
                        <Badge color="red" text={this.state.status} />
                      )}
                </span>
                <br></br>
                <h1 class="gx-fs-xxxl gx-font-weight-semi-bold gx-mb-3 gx-mb-sm-4">
                  Amount:
                  {this.state.dataTransfer ? (
                    <>
                      {this.state.dataTransfer.quantity ? (
                        <NumberFormat
                          value={this.state.dataTransfer.quantity}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                        />
                      ) : null}
                    </> //this.state.dataTransfer.quantity
                  ) : (
                      null
                    )}
                </h1>

                <p>
                  {" "}
                  <b>Zippy Id: </b>
                  {this.state.dataTransfer ? this.state.dataTransfer.id : ""}
                </p>
                <p>
                  {" "}
                  <b>Name: </b>
                  {this.state.dataTransfer
                    ? entities.decode(this.state.dataTransfer.name)
                    : ""}
                </p>
                <p>
                  {" "}
                  <b>rut: </b>
                  {this.state.dataTransfer
                    ? formatRut(this.state.dataTransfer.rut)
                    : ""}
                </p>
                <p>
                  {" "}
                  <b>Email:</b>
                  {this.state.dataTransfer ? this.state.dataTransfer.email : ""}
                </p>
                <p>
                  <b> Date Request:</b>
                  {this.state.dateRequest ? this.state.dateRequest : ""}
                </p>
                <p>
                  <b> Merchant/Commerce:</b> {this.state.merchant}
                </p>
                <p>
                  <b> Merchant/Commerce Request ID:</b> {this.state.dataTransfer.commerceReqId}
                </p>
                {this.state.dataTransfer.operationCode ? (
                  <p>
                    <b> Operation Code:</b>
                    <b>{this.state.dataTransfer.operationCode}</b>
                  </p>
                ) : (
                    ""
                  )}
                <hr></hr>
                <h6 className="gx-mb-3 gx-mb-m-2 gx-font-weight-light">
                  Si el Merchant es JuegaloBet , y el estado que pretendes cambiar es de  validados a fallido , Recuarda  solicitar a soporte que genere un pin de recarga y se le entregue al usuario!
                </h6>

                {/*
                <h6>
                  Resend: send Validated request to the merchant ,only
                  transactions ok!
                </h6> */}
              </div>
            </div>

            {this.state.resp ? (
              this.state.ErrorLimit ? (
                <Row>
                  <Col lg={24} className="contenedorAlert">
                    <Alert
                      message="Result"
                      description={`${this.state.resp.message} `}
                      type="success"
                    />
                  </Col>
                </Row>
              ) : (
                  <div>
                    <Alert
                      message="Result"
                      description={`  ${this.state.resp.message}  `}
                      type="success"
                    />
                    <hr></hr>
                  </div>
                )
            ) : (
                <div>
                  {this.state.spin ? (
                    <div>
                      Loading Operation......
                      <Spin size="large" />
                    </div>
                  ) : (
                      ""
                    )}
                  <hr></hr>
                </div>
              )}



            <Form
               {...formItemLayout}
              className="gx-signup-form gx-form-row0 gx-mb-0"
              onSubmit={this.handleSubmit}
            >
              <FormItem label="ID Zippy:">
                <div className="gx-mb-3" style={{ width: "300px" }}>
                  {getFieldDecorator("idZippy", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter the ID Zippy!!"
                      }
                    ]
                  })(
                    <Input placeholder="ID Zippy" onChange={this.getDataTrx} />
                  )}
                </div>
              </FormItem>
              <FormItem
                  label={this.state.ErrorLimit ? "Update To" : "Status Code: "}
              >
                <div className="gx-mb-3">
                  {getFieldDecorator("codigo", {
                    validateTrigger: ["onChange", "onBlur"],
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Please enter the state code!"
                      }
                    ]
                  })(
                    // <Input placeholder="Código" onChange={this.onChangeID}/>
                    <Select
                      style={{ width: "300px" }}
                      onChange={this.onChangeID}
                    >
                      <Option value="">Select state for Cashout</Option>
                      <Option value="0">Validate (0) </Option>
                      <Option value="9">Pending (9)</Option>
                      <Option value="12">Failed (12)</Option>
                    </Select>
                  )}
                </div>
              </FormItem>

              {/*            <FormItem label="Operation:">
                {this.state.deco && !this.state.Resend ? (
                  <div className="gx-mb-3" style={{ width: "300px" }}>
                    {getFieldDecorator("operationCode", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter the Operation code!!"
                        }
                      ]
                    })(<Input placeholder="código de Operación" />)}
                  </div>
                ) : (
                  <div className="gx-mb-3" style={{ width: "300px" }}>
                    {getFieldDecorator(
                      "operationCode",
                      {}
                    )(
                      <Input
                        placeholder="código de Operación"
                        disabled={this.state.Resend}
                      />
                    )}
                  </div>
                )}
              </FormItem> */}
              {this.state.ErrorLimit ? (
                <Row>
                  <Col lg={12} className="centrarBtn">
                    <Button
                      type="primary"
                      onClick={this.addTransactionListAdmin}
                    >
                      {" "}
                      + admin approval
                    </Button>
                    {/*  </Col>
                  <Col lg={12} className="centrarBtn"> */}

                    <Button
                      //blacklist.jsstyle={{ width: "50%" }}
                      block
                      type="danger"
                      onClick={this.resetAll}
                    >
                      Cancel
                    </Button>
                    <hr></hr>
                  </Col>
                </Row>
              ) : (
                  <>
                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                      Validate
                  </Button>
                    <Button
                      type="primary"
                      className="gx-mb-0"
                      onClick={this.resetAll}
                    >
                      clean
                  </Button>
                  </>
                )}
            </Form>
          </Widget>
        </Col>

      </Row>
    );
  }
}

const CallbackForm = Form.create()(CallbackCashout);

/* export default CallbackForm; */

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;

  const { dataUser } = settings;

  return { authUser, dataUser };
};
export default connect(mapStateToProps)(CallbackForm);
