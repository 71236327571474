import { Badge, Col, DatePicker, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import * as moment from "moment";
import queryParams from "../../../../util/queryParams";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#AAA", "#ff6347"];

const CustomTooltip = ({ active, payload, label }) => {
  if (
    active &&
    payload &&
    payload.length &&
    payload[0].payload.commerces.length
  ) {
    return (
      <>
        <div className="custom-tooltip">
          <div>
            <div>
              <Badge
                color={payload[0].payload.fill}
                text={`[${payload[0].payload.name.toUpperCase()}]: ${
                  payload[0].payload.total_payrolls
                }`}
              />
            </div>
            <hr />
            {payload[0].payload.commerces.map((item, index) => (
              <div key={index}>
                <Badge
                  color={payload[0].payload.fill}
                  text={`${item.name}: ${item.payrolls}`}
                />
              </div>
            ))}
          </div>
        </div>

        <style jsx="true">{`
          .custom-tooltip {
            background: #fff;
            padding: 1em;
            box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
          }
        `}</style>
      </>
    );
  }

  return null;
};

export const ChartTodayPayrolls = ({
  pieData,
  loading = true,
  commerces,
  onchangeDate
}) => {
  const validationData = () => {
    return pieData.data ? (pieData.data.length ? pieData.data : []) : [];
  };

  const [data, setData] = useState([]);

  const formatData = pieData => {
    let newFormat = pieData.map(item => {
      const obj_commerces = item.commerces
        ? item.commerces.map(value => {
            let findCommerce = commerces.find(
              commerce => commerce.id == value.commerceId
            );

            let name = findCommerce
              ? findCommerce.name
                ? findCommerce.name
                : ""
              : "";

            return {
              name: name.toUpperCase(),
              payrolls: value.payrolls
            };
          }, {})
        : {};
      return {
        name: item.name,
        total_payrolls: item.total_payrolls || 0,
        commerces: obj_commerces
      };
    });
    setData(newFormat);
  };

  const [date, setDate] = useState("");

  const changeDate = value => {
    setDate(value);

    value = moment(value)
      .format("YYYY-MM-DD")
      .toString();
    onchangeDate(value);
  };

  useEffect(() => {
    if (commerces.length) formatData(validationData());
  }, [pieData, commerces]);

  useEffect(() => {
    let { date_pie } = queryParams();
    setDate(moment(date_pie));
  }, [document.location.href]);

  const disabledDate = current => {
    return current > moment().endOf("day");
  };

  return (
    <>
      <Col span={24} style={{ padding: 0 }}>
        <Row align="middle" type="flex" gutter={[10, 20]}>
          <Col span={12}>
            <h2>Today's payrolls</h2>
          </Col>
          <Col span={12}>
            <div className="section-space-between">
              <DatePicker
                value={date}
                format="YYYY-MM-DD"
                placeholder="Select date"
                onChange={changeDate}
                disabledDate={disabledDate}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <hr />
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <Spin tip="Loading..." spinning={loading}>
        <ResponsiveContainer width="100%" height={251}>
          <PieChart>
            <Tooltip content={<CustomTooltip />} />
            <text
              x="50%"
              className="h1"
              y="50%"
              textAnchor="middle"
              dominantBaseline="middle"
              children=""
            >
              {pieData.total_payrolls || 0}
            </text>
            <Pie
              data={data}
              dataKey="total_payrolls"
              cx="50%"
              cy="50%"
              innerRadius={40}
              outerRadius={100}
              fill="#8884d8"
              minAngle={2}
            >
              {validationData().map((item, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div style={{ textAlign: "center" }}>
          {validationData().map((item, index) => (
            <Badge
              key={index}
              color={COLORS[index % COLORS.length]}
              text={`${item.name} (${item.total_payrolls})`}
            />
          ))}
        </div>
      </Spin>
      <style jsx="true">{`
        .section-space-between {
          display: flex;
          justify-content: flex-end;
        }
      `}</style>
    </>
  );
};
