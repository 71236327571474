const config_fields = {
  payments: {
    type: "payments",
    fields: [
      {
        field: "methodUse",
        type: "selection",
        options: [
          "bankCard",
          "bankTransfer",
          "cash",
          "blockchain",
          "skin",
          "airtm",
          "binancepay",
          "tbtc",
          "btc",
          "payQR",
          "webpayOneClick"
        ]
      },
      {
        field: "useDefaultRepository",
        type: "select",
        repositories: [
          "airtms",
          "alpss",
          "apirones",
          "binancepays",
          "ethereums",
          "flows",
          "kushkis",
          "machs",
          "monnets",
          "pagoEfectivos",
          "paykus",
          "redpays",
          "safetypays",
          "skinbacks",
          "webpays"
        ]
      },
      {
        field: "useKushki",
        type: "boolean",
        value: true
      },
      {
        field: "usePagoEfectivo",
        type: "boolean",
        value: true
      },
      {
        field: "useMonnet",
        type: "boolean",
        value: true
      },
      {
        field: "useSafetypay",
        type: "boolean",
        value: true
      },
      {
        field: "useAlps",
        type: "boolean",
        value: true
      },
      {
        field: "useMach",
        type: "boolean",
        value: true
      },
      {
        field: "usePayku",
        type: "boolean",
        value: true
      },
      {
        field: "useWebpay",
        type: "boolean",
        value: true
      },
      {
        field: "useAirtm",
        type: "boolean",
        value: true
      },
      {
        field: "useBinancepay",
        type: "boolean",
        value: true
      },
      {
        field: "useEthereum",
        type: "boolean",
        value: true
      },
      {
        field: "useApirone",
        type: "boolean",
        value: true
      },
      {
        field: "useRedpay",
        type: "boolean",
        value: true
      },
      {
        field: "useSkinsback",
        type: "boolean",
        value: true
      }
    ],
    collections: [
      { field: "airtm" },
      { field: "bankCard" },
      { field: "bankCardH2H" },
      { field: "bankTransfer" },
      { field: "binancepay" },
      { field: "blockchain" },
      { field: "btc" },
      { field: "cash" },
      { field: "mach" },
      { field: "payQR" },
      { field: "skin" },
      { field: "tbtc" },
      { field: "webpayOneClick" }
    ]
  },
  methodsPay: {
    type: "methodsPay",
    fields: [
      {
        field: "useDefaultRepository",
        type: "select",
        repositories: [
          "airtms",
          "alpss",
          "apirones",
          "binancepays",
          "ethereums",
          "flows",
          "kushkis",
          "monnets",
          "pagoEfectivos",
          "paykus",
          "redpays",
          "safetypays",
          "skinbacks",
          "webpays"
        ]
      },
      {
        field: "useKushki",
        type: "boolean",
        value: true
      },
      {
        field: "useMonnet",
        type: "boolean",
        value: true
      },
      {
        field: "useSafetypay",
        type: "boolean",
        value: true
      },
      {
        field: "useAlps",
        type: "boolean",
        value: true
      },
      {
        field: "usePayku",
        type: "boolean",
        value: true
      },
      {
        field: "useWebpay",
        type: "boolean",
        value: true
      },
      {
        field: "useAirtm",
        type: "boolean",
        value: true
      },
      {
        field: "useBinancepay",
        type: "boolean",
        value: true
      },
      {
        field: "useEthereum",
        type: "boolean",
        value: true
      },
      {
        field: "useApirone",
        type: "boolean",
        value: true
      },
      {
        field: "useRedpay",
        type: "boolean",
        value: true
      },
      {
        field: "useSkinsback",
        type: "boolean",
        value: true
      },
      {
        field: "useFlow",
        type: "boolean",
        value: true
      }
    ]
  },
  commissions: {
    type: "commissions",
    fields: [
      {
        field: "earlyPayments",
        type: "text"
      },
      {
        field: "retention",
        type: "text"
      },
      {
        field: "ivaValue",
        type: "text"
      },
      {
        field: "ivaCobro",
        type: "boolean",
        value: true
      },
      {
        field: "markUpBCT",
        type: "number"
      },
      {
        field: "markUpBCTtoFiat",
        type: "number"
      },
      {
        field: "markUpEthereum",
        type: "number"
      },
      {
        field: "isPreviousMonthRank",
        type: "boolean",
        value: true
      },
      {
        field: "cobro",
        type: "text"
      },
      {
        field: "currencyRange",
        type: "text"
      },
      {
        field: "markUpSkinsback",
        type: "number"
      },
      {
        field: "markUpEthereum",
        type: "number"
      },
      {
        field: "fijo",
        type: "text"
      },
      {
        field: "countrie",
        type: "text"
      },
      {
        field: "pct",
        type: "text"
      },
      {
        field: "cashin",
        type: "text"
      },
      {
        field: "cashout",
        type: "text"
      },
      {
        field: "pctCobro",
        type: "text"
      },
      {
        field: "pctC",
        type: "text"
      },
      {
        field: "pctD",
        type: "text"
      },
      {
        field: "webPayC",
        type: "text"
      },
      {
        field: "webpayC",
        type: "text"
      },
      {
        field: "webPayCobro",
        type: "text"
      },
      {
        field: "webpayCobro",
        type: "text"
      },
      {
        field: "webPayD",
        type: "text"
      },
      {
        field: "webpayD",
        type: "text"
      },
      {
        field: "flow",
        type: "text"
      },
      {
        field: "flowCobro",
        type: "text"
      },
      {
        field: "saftypayCobro",
        type: "text"
      },
      {
        field: "paykuCobro",
        type: "text"
      },
      {
        field: "payku",
        type: "text"
      },
      {
        field: "monnet",
        type: "text"
      },
      {
        field: "alps",
        type: "text"
      },
      {
        field: "alpsCobro",
        type: "text"
      },
      {
        field: "monnetCobro",
        type: "text"
      },
      {
        field: "markUpBTCtoFiat",
        type: "text"
      },
      {
        field: "markUpBTC",
        type: "text"
      },
      {
        field: "ranges",
        type: "array",
        value: "[...]"
      },
      {
        field: "useRange",
        type: "boolean",
        value: true
      },
      {
        field: "iva",
        type: "boolean",
        value: true
      }
    ],
    collections: [
      { field: "airtm" },
      { field: "bankCard" },
      { field: "bankTransfer" },
      { field: "binancepay" },
      { field: "blockchain" },
      { field: "btc" },
      { field: "cash" },
      { field: "mach" },
      { field: "payQR" },
      { field: "skin" },
      { field: "tbtc" },
      { field: "webpayOneClick" }
    ]
  },
  payouts: {
    type: "payouts",
    fields: [
      {
        field: "useAirtm",
        type: "boolean",
        value: true
      },
      {
        field: "useMonnet",
        type: "boolean",
        value: true
      },
      {
        field: "useAlps",
        type: "boolean",
        value: true
      },
      {
        field: "useDefaultRepository",
        type: "select",
        repositories: [
          "airtmPayOuts",
          "apironePayOuts",
          "alpsPayOuts",
          "cashouts",
          "ethereumPayOuts",
          "monnetPayOuts"
        ]
      },
      {
        field: "useApirone",
        type: "boolean",
        value: true
      },
      {
        field: "useEthereum",
        type: "boolean",
        value: true
      }
    ]
  }
};

export default config_fields;
