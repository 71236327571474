import { Badge, Col, DatePicker, Row, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";

import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import * as moment from "moment";
import queryParams from "../../../../util/queryParams";

const { MonthPicker } = DatePicker;

const renderCustomizedLabel = props => {
  const { x, y, width, height, value } = props;
  const radius = 10;
  return (
    <g>
      <text
        x={x + width / 2}
        y={y - radius}
        fill="#000"
        textAnchor="middle"
        fontSize={12}
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <>
        <div className="custom-tooltip">
          <p>Day: {label}</p>
          {payload.map(item => (
            <div>
              <Badge
                key={item.name}
                color={item.color}
                text={`${item.name}: ${item.value}`}
              />
            </div>
          ))}
        </div>

        <style jsx="true">{`
          .custom-tooltip {
            background: #fff;
            padding: 1em;
            box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
          }
        `}</style>
      </>
    );
  }

  return null;
};

export const ChartPayrollOfMonth = ({
  barData,
  loading = true,
  commerces,
  onchangeMonth
}) => {
  const [data, setData] = useState([]);

  const formatData = barData => {
    let newFormat = barData.map(item => {
      const obj_commerces = item.commerces
        ? item.commerces.reduce((accumulator, value) => {
            let findCommerce = commerces.find(
              commerce => commerce.id == value.commerceId
            );

            let name = findCommerce
              ? findCommerce.name
                ? findCommerce.name
                : ""
              : "";

            return {
              ...accumulator,
              [name.toUpperCase()]: value.payrolls
            };
          }, {})
        : {};
      return {
        date: item.date,
        "[TOTAL]": item.total_payrolls || 0,
        ...obj_commerces
      };
    });

    setData(newFormat);
  };

  const [monthDate, setMonthDate] = useState("");

  const changeMonth = value => {
    setMonthDate(value);

    value = moment(value)
      .format("YYYY-MM")
      .toString();
    onchangeMonth(value);
  };

  useEffect(() => {
    if (commerces.length) formatData(barData);
  }, [barData, commerces]);

  useEffect(() => {
    let { date_bar } = queryParams();
    setMonthDate(moment(date_bar));
  }, [document.location.href]);

  const disabledDate = current => {
    return current > moment().endOf("day");
  };

  return (
    <>
      <Col span={24} style={{ padding: 0 }}>
        <Row align="middle" type="flex" gutter={[10, 20]}>
          <Col span={12}>
            <h2>Payrolls this month</h2>
          </Col>
          <Col span={12}>
            <div className="section-space-between">
              <MonthPicker
                value={monthDate}
                format="YYYY-MM"
                placeholder="Select month"
                onChange={changeMonth}
                disabledDate={disabledDate}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <hr />
      <Spin tip="Loading..." spinning={loading}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={data}
            width={500}
            height={300}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" padding={{ left: 10, right: 10 }} />
            <YAxis padding={{ top: 20 }} />
            <Tooltip content={<CustomTooltip />} />
            {commerces.length
              ? commerces.map((commerce, index) => (
                  <Bar
                    key={commerce.id}
                    dataKey={commerce.name ? commerce.name.toUpperCase() : ""}
                    stackId="a"
                    fill="#00C49F"
                  />
                ))
              : null}
            <Bar dataKey="[TOTAL]" stackId="a" fill="#013366" minPointSize={2}>
              <LabelList dataKey="[TOTAL]" content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Spin>
      <style jsx="true">{`
        .section-space-between {
          display: flex;
          justify-content: flex-end;
        }
      `}</style>
    </>
  );
};
