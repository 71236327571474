import React, { useState } from "react";
import { Col, Row, Spin, Collapse, Badge, Modal, Table, Tooltip, Button } from "antd";
import NumberFormat from "react-number-format";
import moment from "moment";
import Metrics from "components/Metrics";
import copy from "copy-to-clipboard";
import "./styles.css";
import Widget from "components/Widget/index";
import {
  format as formatRut
} from "rut.js";
import FormLost from "../../Cartolas/FormLost";
import UserPayHistory from "../../Cartolas/UserPayHistory";
import { firebase } from "../../../firebase/firebase";
import { AllHtmlEntities } from "html-entities";

const db = firebase.firestore();
const entities = new AllHtmlEntities();
const { Panel } = Collapse;
function transformCommerceID(commerceId, merchants) {
  let valor;
  merchants.merchants.filter(val => {
    if (val.code.toString() === commerceId.toString()) {
      valor = val.name
    }
  })
  return valor;
};

function transformStatus(status) {
  switch (status.toString()) {
    case "0":
      return "Validated";
    case "9":
      return "Pending";
    case "12":
      return "Failed";
    case "1":
      return "Visitor";

    default:
      return "Visitor";
  }
};

const GrowthCardCartola = ({
  title,
  quantityTransaction,
  amountTransaction,
  balanced,
  typeWidget,
  lostAmount,
  prefix,
  loading,
  TransactionPending,
  dataPending,
  dataFailed,
  dataLost,
  dataLostUserPay,
  help,
  columnsActions,
  userPay, merchants
}) => {
  const [visible, setVisible] = useState(false);
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const [visibleForm, setVisibleForm] = useState(false);
  let columnsLost = [
    {
      title: "Date",
      dataIndex: "datetime",
      key: "datetime",
      width: 150,
      render: datetime => (
        <span>{datetime}</span>
      ),
      sorter: (a, b) => this.sorteDate(a, b),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Operation",
      dataIndex: "bankOperationCode",
      key: "bankOperationCode",
      width: 150,
    },
    {
      title: "Rut",
      dataIndex: "rut",
      key: "rut",
      width: 150,
      render: text => (
        <div style={{ float: "right" }}>
          <i
            onClick={() => {
              copy(text);
            }}
          >
            {formatRut(text)}
          </i>
        </div>
      )
    }
    ,
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      render: text => (
        <div style={{ float: "right" }}>
          <NumberFormat
            value={text}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        </div>
      )
    },
    {
      title: "Validate",
      key: "validate",
      width: 10,
      render: (text, record) => (
        <span>
          <Button
            onClick={() => changeHandler(record)}
            okText="Yes"
            cancelText="No"
          >
            <span className="gx-link">Validate</span>
          </Button>
        </span>
      )
    }
  ]
  const columnsPending = [
    {
      title: "Date Request",
      dataIndex: "dateRequest",
      key: "dateRequest",
      width: 200,
      render: date =>
        date.toDate().toLocaleString("es-CL", {
          timeZone: "America/Santiago",
          timeZoneName: "short"
        }),
    },
    {
      title: "Zippy id",
      dataIndex: "id",
      key: "id",
      width: 100
    },
    {
      title: "Merchant",
      dataIndex: "commerceId",
      key: "commerceId",
      width: 100,
      render: text => (transformCommerceID(text, merchants)),
      sorter: (a, b) => a.commerceId - b.commerceId,

    },
    {
      title: "Rut",
      dataIndex: "rut",
      key: "rut",
      width: 100,
      render: text => (
        <div style={{ float: "right" }}>
          <i
            onClick={() => {
              copy(text);
            }}
          >
            {formatRut(text)}
          </i>
        </div>
      )
    },
    {
      title: "Amount",
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      render: text => (
        <div style={{ float: "right" }}>
          <NumberFormat
            value={text}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        </div>
      )
    },]
  const columnsFailed = [
    {
      title: "Date Request",
      dataIndex: "dateRequest",
      key: "dateRequest",
      width: 200,
      render: date =>
        date.toDate().toLocaleString("es-CL", {
          timeZone: "America/Santiago",
          timeZoneName: "short"
        }),

    },
    {
      title: "Zippy ID",
      dataIndex: "id",
      key: "id",
      width: 100
    },

    {
      title: "Merchant",
      dataIndex: "commerceId",
      key: "commerceId",
      width: 100,
      render: text => (transformCommerceID(text, merchants))
    },
    {
      title: "Rut",
      dataIndex: "rut",
      key: "rut",
      width: 100,
      render: text => (
        <div style={{ float: "right" }}>

          {/*   <button onClick={this.copyClip(text)}>copy</button> */}
          <i
            onClick={() => {
              copy(text);
            }}
          >
            {formatRut(text)}
          </i>
        </div>
      )
    },
    {
      title: "Amount",
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      render: text => (
        <div style={{ float: "right" }}>
          <NumberFormat
            value={text}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        </div>
      )
    },]

  const columns = [
    {
      title: "Date Request",
      dataIndex: "dateRequest",
      key: "dateRequest",
      width: 200,
      render: date =>
        date.toDate().toLocaleString("es-CL", {
          timeZone: "America/Santiago",
          timeZoneName: "short"
        }),
      sorter: (a, b) => a.dateRequest._seconds - b.dateRequest._seconds,
      /* sortOrder:
        this.state.sortedInfo.columnKey === "dateRequest" &&
        this.state.sortedInfo.order */

    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      render: name => entities.decode(name)
    },
    {
      title: "RUT",
      dataIndex: "rut",
      key: "rut",
      width: 150,
      render: rut => formatRut(rut)
    },
    {
      title: `Email`,
      dataIndex: "email",
      key: "email",
      width: 100
    },
    {
      title: `Status`,
      dataIndex: "code",
      key: "code",
      render: text => (transformStatus(text)),
      width: 100
    },
    {
      title: "Amount",
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      render: text => (
        <div style={{ float: "right" }}>
          <NumberFormat
            value={text}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        </div>
      )
    },
    {
      title: `ID  Merchant`,
      dataIndex: "commerceReqId",
      key: "commerceReqId",
      width: 100
    },
    {
      title: `Merchant`,
      dataIndex: "commerceId",
      key: "commerceId",
      width: 100,
      render: text => (transformCommerceID(text, merchants)),
      sorter: (a, b) => a.commerceId - b.commerceId,
    },
    {
      title: "ID Zippy",
      dataIndex: "id",
      key: "id",
      width: 100
    }
  ];
  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const [RecordValues, setRecord] = useState({
    mobile: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const changeHandler = (record) => {
    setRecord(record);
    setVisibleForm(true);
  }

  /* const viewHistoryUserPay = async record => {
    console.log("viewHistoryUserPay", record.rut);
    let transfers = [];let statusName;
    let query = await db
      .collection("transfers")
      .where("rut", "==", record.rut)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
        } else {
          snapshot.docs.map(doc => {
            transfers.push({
              nameStatus:this.transformStatus(doc.data().code.toString()),
              id: doc.id,
              ...doc.data()
            });
          });
        }
      })
      .catch(err => {
        console.log("ERROR IN getalertok:", err);
      });


      let queryvisitor = await db
      .collection("visitors")
      .where("rut", "==", record.rut)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
        } else {
          snapshot.docs.map(doc => {
            transfers.push({
              nameStatus:this.transformStatus(doc.data().code),
              dateRequest:doc.data().dateVisitor,
              id: doc.id,
              ...doc.data()
            });
          });
        }
      })
      .catch(err => {
        console.log("ERROR IN getalertok:", err);
      });

    this.setState({dataLostUserPay:transfers,userPay:record.rut })
    console.log("transfers", transfers,record.rut,this.props.dataUser.merchants);
    return transfers;
  };
 */


  return (
    <Metrics
      styleName={
        typeWidget === "Cua"
          ? `gx-card-full contenedorCartola`
          : typeWidget === "Ok"
            ? `gx-card-full contenedorOk`
            : typeWidget === "Pending"
              ? `gx-card-full contenedorPending`
              : typeWidget === "Failed"
                ? `gx-card-full contenedorFailed`
                : `gx-card-full contenedorLost`
      }
    >
      <h2 style={{ marginTop: "10px" }}>{title}   {help ? (
        <>
          <Tooltip
            title={help}
          >
            {/* 💬❔ */}
               ℹ️
              {/*  🛈 */}
          </Tooltip>
        </>
      ) : null}</h2>
      {loading ? (
        <Spin></Spin>
      ) : (
          <Row className="gx-pb-0 gx-pl-4 gx-pt-3 contenedorWidget">
            <Col md={24}>
              <span>Transactions: {quantityTransaction}</span>
            </Col>
            <Col md={24}>
              <span>
                Amount:{" "}
                <NumberFormat
                  value={amountTransaction}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                />{" "}
              </span>
              {dataPending ? (
                <>
                  <a type="primary" onClick={() => setVisible(true)}>
                    📄
                </a>
                  <Modal
                    title="Pending records "
                    centered
                    width={900}
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                  //width={"%"}
                  >
                    <Table
                      className="gx-table-responsive"
                      columns={columnsPending}
                      dataSource={dataPending}
                      rowKey="valor"
                      //onChange={this.onTableChange}
                      pagination={{
                        defaultPageSize: 5,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30", "50", "100"]
                      }}
                    />
                  </Modal>
                </>
              ) : null}
              {dataFailed ? (
                <>
                  <a type="primary" onClick={() => setVisible(true)}>
                    📄
                </a>
                  <Modal
                    title="Failed records "
                    centered
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    width={900}
                  >
                    <Table
                      className="gx-table-responsive"
                      columns={columnsFailed}
                      dataSource={dataFailed}
                      rowKey="valor"
                      //onChange={this.onTableChange}
                      pagination={{
                        defaultPageSize: 5,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30", "50", "100"]
                      }}
                    />




                  </Modal>
                </>
              ) : null}
              {dataLost ? (
                <>
                  <a type="primary" onClick={() => setVisible(true)}>
                    📄
                </a>
                  <Modal
                    title="Lost records "
                    centered
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    width={1500}
                  >
                    <Widget
                      title={
                        <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
                          {/*  <i className="icon icon-mail-open gx-mr-3" /> */}
                          Lost Requests
                       </h4>
                      }
                    >
                      <Table
                        className="gx-table-responsive"
                        columns={columnsLost=columnsLost.concat(columnsActions)}//columnsActions
                    /*     (this.columnsIndex = this.columnsIndex.concat(this.columns)) */
                        dataSource={dataLost}
                        rowKey="valor"
                        //onChange={this.onTableChange}
                        pagination={{
                          defaultPageSize: 5,
                          showSizeChanger: true,
                          pageSizeOptions: ["10", "20", "30", "50", "100"]
                        }}
                      />
                      <Modal
                        title="Form Validate Manual "
                        centered
                        visible={visibleForm}
                        onOk={() => setVisibleForm(false)}
                        onCancel={() => setVisibleForm(false)}
                        width={500}
                      >
                        <FormLost RecordValues={RecordValues} />
                      </Modal>
                    </Widget>
                    <Widget
                      title={
                        <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
                          <i className="icon icon-user gx-mr-3" />
                          History User  {formatRut(userPay)}
                        </h4>
                      }
                    >
                      {/*  <Table
                        className="gx-table-responsive"
                        columns={columns}
                        dataSource={dataLostUserPay}
                        rowKey="valor"
                        //onChange={this.onTableChange}
                        pagination={{
                          defaultPageSize: 5,
                          showSizeChanger: true,
                          pageSizeOptions: ["10", "20", "30", "50", "100"]
                        }}
                      /> */}
                      <UserPayHistory
                        dataLostUserPay={dataLostUserPay}
                        merchants={merchants}
                        userPay={userPay}
                      /*  setVisible={setVisible()} */
                      />

                    </Widget>
                  </Modal>
                </>
              ) : null}
            </Col>
            <Col md={24}>
              {typeWidget === "Ok" ? (
                balanced ? (
                  <span>Los montos estan cuadrados correctamente</span>
                ) : (
                    <span>
                      {`(Cartola) - (Cashin OK)=`}

                      <NumberFormat
                        value={lostAmount}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"$"}
                      />
                    </span>
                  )
              ) : null}
            </Col>
            <Col md={24}>
              {typeWidget === "Pending"
                ? TransactionPending.length > 0
                  ? TransactionPending.map((transaction, index) => {
                    return (
                      <Row
                        className="contenedorPendientes"
                        style={{ fontSize: 12 }}
                      >
                        <Collapse bordered={false}>
                          <Panel
                            header={
                              <span>
                                <b> Bank:</b>
                                {transaction[0].bank}
                              </span>
                            }
                            style={{ fontSize: 12, borderBottom: 0 }}
                          ></Panel>
                        </Collapse>
                      </Row>
                    );
                  })
                  : null
                : null}
            </Col>
          </Row>
        )}
    </Metrics>
  );
};

export default GrowthCardCartola;
