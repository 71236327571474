import React, { useEffect, useState } from "react";
import { Button, Modal, Popconfirm, Table, Checkbox  } from "antd";
import { Link } from "react-router-dom";
const { Column } = Table;

const filterType = {
  0: "validated",
  1: "created",
  7: "authorized",
  9: "pending"
};

export default function ModalUndoPayrolls({
  onCancel,
  modal,
  onHandleUndoPayrolls,
  data,
  loading
}) {
  const [dataPayrollIds, setDataPayrollIds] = useState([]);
  const [buttonActive , setButtonActive] = useState(true)

 const rowSelection = {
   onChange: (selectedRowKeys, selectedRows) => {
     console.log("selectedRowKeys", selectedRowKeys);
     setDataPayrollIds(selectedRowKeys);
     selectedRowKeys.length > 0
       ? setButtonActive(false)
       : setButtonActive(true);
   }
 };
  return (
    <>
      <Modal
        title="List payrolls undo"
        visible={modal.isVisible}
        onCancel={onCancel}
        footer={[
          <Button htmlType="submit" onClick={onCancel} key="cancel-button">
            Cancel
          </Button>,
          <Popconfirm
            title="Are you sure undo payrolls?"
            onConfirm={() => onHandleUndoPayrolls(dataPayrollIds)}
            okText="Yes, undo"
            cancelText="No"
            key="Popconfirm-button"
            disabled={buttonActive }
          >
            <Button type="primary" htmlType="button" disabled={buttonActive}>
              Undo Payrolls
            </Button>
          </Popconfirm>
        ]}
      >
        <Table
          rowSelection={rowSelection}
          dataSource={data}
          pagination={false}
          size="small"
          style={{ background: "white" }}
          loading={loading}
          rowKey="_id"
          scroll={{ y: 240 }}
        >
          <Column
            title="Payroll ID"
            dataIndex="id"
            key="id"
            render={(text, record, index) => (
              <span>
                <span>{record._id}</span>
              </span>
            )}
          />
           <Column
            title="Commerce"
            dataIndex="commerceId"
            key="commerceId"
            render={(text, record, index) => (
              <span>
                <span>{text}</span>
              </span>
            )}
          />
          <Column
            title="Action"
            dataIndex="action"
            key="action"
            render={(text, record, index) => (
              <Link
                to={`/main/payroll/payroll-detail/${record._id}?type=${
                  filterType[record.status]
                }`}
                target="_blank"
              >
                <Button type="default">Go to detail</Button>
              </Link>
            )}
          />
         
        </Table>
      </Modal>
    </>
  );
}
