import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Divider, Popconfirm, Icon, Modal, Button, Badge, Checkbox, Tooltip, Form, Input, Alert, Select, Radio, Tag } from "antd";

import { firebase } from "../../../firebase/firebase";
import copy from "copy-to-clipboard";
import Dynamic from "./Dynamic";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";
import {
  format as formatRut,
  clean as cleanRut,
  validate as validateRut,
} from "rut.js";
import Api from "../../../util/api";
import { AllHtmlEntities } from "html-entities";
import { relativeTimeThreshold } from "moment";
/* import { ConfigurationServicePlaceholders } from "aws-sdk/lib/config_service_placeholders"; */
const entities = new AllHtmlEntities();
const FormItem = Form.Item;
const db = firebase.firestore();
let query;
let queryReason;
let dataList = [];
let dataListOperation = [];

export class cashoutPendingAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: "Cashout Pending",
      filename: `Cashout Pending ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      ModalText: "Preparando Cashout...",
      visible: false,
      confirmLoading: false,
      blacklist: [],
      firstDay: "", lastDay: "",
      reasons: [],
      recordReject: [],
      textOrBox: false,
      dataList: [],
      averageAmount: 0,
      typePage: "All",
      banks: [],
      typeAccount: [],
    };

  }
  componentDidMount = async () => {
    /*    console.log("validate rut 17.632.118-0",validateRut('176321180')) 
       console.log("validate rut 20.360.498-k",validateRut('20360498k'))  */

    await this.getParamCashout();

    console.log("componentDidMount", this.state.typePage)
    this.getBalcklist();

    this.getBalcklistCashin();
    moment.tz.setDefault(this.state.timeZone);
    /*  let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
     let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate(); */
    let firstDay2 = moment().tz(this.state.timeZone).subtract(5, "days");
    let lastDay2 = moment().tz(this.state.timeZone);

    /*     console.log("fechas componentDidMount",firstDay,lastDay,firstDay2,lastDay2) */
    this.getData(firstDay2, lastDay2);
    this.setState({ firstDay: firstDay2, lastDay: firstDay2 });


    ///carga combo reasons
    let reasons = [];
    var ret = await db.collection("parametrosGenerales")
      .doc("cashout")
      .collection("reasonReject").get().catch(console.log);
    ret.docs.map((e) => {
      reasons.push({ code: e.data().code, name: e.data().name });
    });
    /* console.log("reasons", reasons) */
    this.setState({
      reasons: reasons
    });
  };
  componentDidUpdate(prevProps) {
    /*    if (this.props.operator !== prevProps.operator) {
         console.log("componentDidUpdate cambio operator");
       } */
    // console.log("componentDidUpdate all",this.props.typePage,);
    if (this.props !== prevProps) {
      console.log("componentDidUpdate cambio typePage");
    }
    /*    debugger; */
    if (this.props.merchant !== prevProps.merchant) {
      this.setState({
        dataList: []
      });
      console.log("componentDidUpdate");

      this.getBalcklist();
      this.getBalcklistCashin();
      const merchant = localStorage.getItem("merchant_code");
      moment.tz.setDefault(this.state.timeZone);
      /*  let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
       let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate(); */
      let firstDay2 = moment().tz(this.state.timeZone).subtract(5, "days");
      let lastDay2 = moment().tz(this.state.timeZone);

      /*      console.log("fechas componentDidMount",firstDay,lastDay,firstDay2,lastDay2) */
      query = db
        .collection("cashouts")
        .where("commerceId", "==", merchant)
        .where("code", "==", 9)
        .where(
          "dateRequest",
          ">=",
          moment(firstDay2)
            .tz(this.state.timeZone)
            .toDate()
        )
        .where(
          "dateRequest",
          "<=",
          moment(lastDay2)
            .tz(this.state.timeZone)
            .toDate()
        );
      this.updateQuery();

      this.setState({
        filename: `Cashout Pending ${localStorage.getItem("merchant_name")}`
      });
    }
  }
  getParamCashout = async () => {
    await Api.cashout.getParamCashout().then(resp => {

      this.setState({
        banks: resp.banks,
        typeAccount: resp.typeAccount

      });
      console.log("resp getParamCashout", "=>", resp);
    })
      .catch(error => {
        console.log("error getParamCashout", "=>", error);
      })
  }
  prepararTransferencia = record => {
    this.setState({
      ModalText: "Preparando Cashout...",
      visible: true,
      confirmLoading: true
    });
    let datosApi = {
      typeAccountId: record.typeAccountId,
      numAccount: record.numAccount.replace("-", ""),
      rut: cleanRut(record.rut),
      email: record.email,
      name: entities.decode(record.name),
      bankId: Number(record.bankId),
      amount: record.quantity,
      id: record.cashoutId
    };
    console.log("record.cashoutId " + datosApi.id);

    Api.cashout
      .preparar(datosApi)
      .then(resp => {
        if (resp.resRobot.status) {
          this.updateQuery();
          this.setState({
            ModalText: "✅ Cashout preparado con éxito ✅"
          });
        } else {
          this.setState({
            ModalText: "❗️ ERROR: " + resp.resRobot.error
          });
        }
        this.setState({ confirmLoading: false });
        console.log("resp", "=>", resp.resRobot);
      })
      .catch(error => {
        this.setState({
          ModalText: "BOT ERROR: " + error,
          confirmLoading: false
        });
      });
  };
  confirmAuthorize = (record, bank) => {
    // message.success("Click on Yes");
    console.log("record", record);
    // LLAMAR API
    let datosApi = {
      id: record.cashoutId,
      message: "Retiro realizado con éxito",
      code: 0,
      status: "OK",
      quantity: record.quantity,
      merchantRequestId: record.commerceReqId,
      merchantId: record.commerceId,
      email: record.email,
      name: record.name,
      fecha: moment.tz("America/Santiago").toDate(),
      user: this.props.dataUser.idUser
    };

    let promesaValidate = new Promise(async (resolve, reject) => {
      try {
        await Api.cashout.validate(datosApi);
        resolve();
      } catch (error) {
        reject(error);
      }
    });

    promesaValidate
      .then(() => {
        /*  this.updateQuery(); */

        try {
          let fileListValidated = JSON.parse(localStorage.getItem("fileBch"));

          let filtro = fileListValidated.find(row => String(record.rut).replace(/[\. ,:-]+/g, "").trim() === row[3].toString().replace(/[\. ,:-]+/g, "") && record.quantity.toString() === row[8].toString().replace(/[\. ,.$]+/g, "") && row[9].toString() === "Aceptada");//&& reg.numAccount === row[7]);//&&reg.name.trim()===row[6].toString().trim());
          console.log("filtro", filtro);

          if (typeof filtro !== "undefined") {

            fileListValidated.splice(fileListValidated.findIndex(e => e === filtro), 1);
            localStorage.setItem("fileBch", JSON.stringify(fileListValidated));
          }
        } catch (error) {
          console.log("Error in fileBch:", error);
        }

        try {
          let fileListValidated = JSON.parse(localStorage.getItem("regValSanta"));

          let filtro = fileListValidated.find(row =>
            String(record.rut).replace(/[\. ,:-]+/g, "").trim() === row[3].toString().replace(/[\. ,:-]+/g, "") &&
            record.quantity.toString() ===
            row[5].toString().replace(/[\. ,.$]+/g, "") && row[6].toString() === "Realizada");//&& reg.numAccount === row[7]);//&&reg.name.trim()===row[6].toString().trim());
          console.log("filtro", filtro);

          if (typeof filtro !== "undefined") {

            fileListValidated.splice(fileListValidated.findIndex(e => e === filtro), 1);
            localStorage.setItem("regValSanta", JSON.stringify(fileListValidated));
          }
        } catch (error) {
          console.log("Error in regValSanta:", error);
        }


        this.upadateBankValidate(record.cashoutId, bank);
        //si son iguales ,significa que  esta seleccionado  mismo merchant y debe actualizar la grilla
        if (record.commerceId === this.props.merchant.code) {
          this.componentDidUpdate(this.state);
        }

        /*  alert("Autorizado"); */
      })
      .catch(error => alert("Error al autorizar" + error));
  };
  cancel = e => {
    console.log(e);
    //message.error("Click on No");
  };
  onChangeRange = (dates, dateStrings) => {
    // console.log("From: ", dates[0], ", to: ", dates[1]);
    console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    this.getData(dateStrings, "rango");
  };
  conLog(variable) {
    console.log("variable", "=>", variable);
  }
  getData = (firstDay, lastDay) => {
    console.log('firstDay', moment(firstDay)
    .tz(this.state.timeZone)
    .toDate());
    console.log('lastDay', moment(lastDay)
    .tz(this.state.timeZone)
    .toDate());
    query = db
      .collection("cashouts")
      .where("commerceId", "==", this.props.merchant.code)
      .where("code", "==", 9)
      .where(
        "dateRequest",
        ">",
        moment(firstDay)
          .tz(this.state.timeZone)
          .toDate()
      )
      .where(
        "dateRequest",
        "<=",
        moment(lastDay)
          .tz(this.state.timeZone)
          .toDate()
      );

    this.updateQuery();
  };
  getBalcklist = async () => {

    let blacklist = [];
    await db
      .collection("parametrosGenerales")
      .doc("seguridad")
      .collection("blacklistCashout")
      .get()
      .then(snapshot => {
        snapshot.docs.map(resp => {
          blacklist.push({
            ...resp.data()
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN blacklist", err);
      });

    /*    blacklist= this.getBlacklistTemp();
      console.log("blacklist",blacklist); */

    this.setState({ blacklist })

  }

  getBalcklistCashin = async () => {

    let blacklist = [];
    await db
      .collection("parametrosGenerales")
      .doc("seguridad")
      .collection("blacklist")
      .get()
      .then(snapshot => {
        snapshot.docs.map(resp => {
          blacklist.push({
            ...resp.data()
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN blacklist", err);
      });

    /*    blacklist= this.getBlacklistTemp();
      console.log("blacklist",blacklist); */

    this.setState({ blacklistCashin: blacklist })

  }
  updateQuery = async () => {
    console.log("entre a updateQuery.");
    /*  dataList = []; */
    query
      .get()
      .then(snapshot => {
        let dataList = [];
        if (snapshot.empty) {
          console.log("No matching documents.");
          this.setState({
            dataList
          });
          return;
        }

        //get the data of all the documents into an array
        // var data = snapshot.docs.map(function(doc) {
        //   return doc.data();
        // });
        let bankName = "";
        let typeAccount = "";

        let resFirestore = snapshot.docs.map(doc => { return doc.data(); });
        console.log("resFirestore", resFirestore.length)

        console.log("data bankS", this.state.banks, "averageAmount", this.props.averageAmount, dataList)
        snapshot.forEach(doc => {
          let cashoutId = doc.id;

          var databank = this.state.banks.filter(dato => dato.id == doc.data().bankId)
          bankName = databank.length ? databank[0].name : '';

          var datatypeAccount = this.state.typeAccount.filter(dato => dato.id == doc.data().typeAccountId)
          console.log("data datatypeAccount", datatypeAccount)
          typeAccount = datatypeAccount.length ? datatypeAccount[0].name : ''

          /******ALERT BLACKLIST***************************************** */
          let alertBlacklist = 0;

          alertBlacklist = this.state.blacklist.filter(
            reg => String(reg.valor).replace(/[\. ,:-]+/g, "").trim() === String(doc.data().rut).replace(/[\. ,:-]+/g, "").trim()
          )
          /*******ALERT BLACKLIST**************************************** */

          dataList.push({
            ...doc.data(),
            bankName,
            typeAccount,
            cashoutId,
            alertBlacklist: alertBlacklist.length
          });

          /* // console.log(doc.id, "=>", doc.data()); */


          dataListOperation.push({
            ...doc.data(),
            bankName,
            typeAccount,
            cashoutId,
            alertBlacklist: alertBlacklist.length
          });

        });


        //console.log("CASHOUT", "=>", data);
        /*    console.log("DATALIST cashout pendientes", "=>", dataList);
           console.log("dataListOperation cashout pendientes", "=>", dataListOperation); */

        /*    console.log("averageAmount", this.props.averageAmount); */
        console.log('dataList_2', dataList);
        let dataListFinal;
        if (this.state.typePage == "High") {
          dataListFinal = dataList.filter(reg => reg.quantity >= this.props.averageAmount);
        }
        else if (this.state.typePage == "Low") {
          dataListFinal = dataList.filter(reg => reg.quantity < this.props.averageAmount);
        }
        else {
          dataListFinal = dataList
        }
        console.log('dataListFinal', dataListFinal);
        this.setState({
          dataList: dataListFinal
        });

      })
      .catch(console.log);
    this.setState({ visibleModalReject: false })
  };
  onPressOK = () => {
    this.setState({ visible: false });
  };
  onCopyClipBoard = record => {

    console.log(record);
    let objCopy = {
      "accountDestination": record.numAccount,// "7000515617",
      "accountType": this.getTypeAccount(record.typeAccount),//"CTV",
      "aliasDestination": record.name,// "Córnéjó Nicolás",
      "amount": Number(record.quantity),//cashoutPendientes.js 60000,
      "bankDestination": record.bankId,
      "cashoutId": record.cashoutId,// "test-dev-iEHWh6GzBrGx0e24G476",
      "emailDestination": record.email,// "nik00.cd@gmail.com",
      "rutDestination": String(record.rut).replace(/[\. ,:-]+/g, "").trim(),//"265465943"
    }
    copy(JSON.stringify(objCopy));

  };
  getTypeAccount = id => {

    let typeAccount = "";
    var datatypeAccount = this.props.typeAccount.filter(dato => dato.id == id)
    console.log("data datatypeAccount", datatypeAccount)
    typeAccount = datatypeAccount[0].name
    return typeAccount;
  }

  /* onChange (record,checked) {
    console.log("onChange",record.cashoutId,checked);

    this.upadateDocumentation(record.cashoutId,"pending documentation")

  }; */

  onChangeCheck = async e => {

    /*  console.log("onChangeCheck", e.target.checked, e.target.id); */

    let data = e.target.id;
    let documentationPending = "";

    if (e.target.checked) { documentationPending = "document pending"; }
    else {
      documentationPending = "";
    }
    await this.upadateDocumentation(data.cashoutId, e.target.checked);
    this.setState({
      upadateComponet: true
    });
    this.componentDidUpdate(this.state);
  };
  upadateDocumentation = (id, documentation) => {
    new Promise(async (resolve, reject) => {
      let query = db
        .collection("cashouts")
      await query
        .doc(id)
        .update({
          documentation: documentation,
        })
        .then(function () {
          resolve(true)
          console.log("Document successfully updated upadateDocumentation!");

        })
        .catch(err => {
          reject(false)
          console.log("ERROR IN upadateDocumentation", err);
        });
    })

  };
  ///REJECT///
  hideModalReject = () => {
    this.setState({
      visibleModalReject: false,
      messageModal: "",
      recordReject: []
    });
    this.props.form.setFieldsValue({
      ["Reason"]: "",

    });
  };
  showModalReject = record => {

    /*  console.log("record", record); */


    var test = [];
    test = record;

    /*  console.log("test.cashoutId", test.cashoutId); */

    this.setState({
      visibleModalReject: true,
      messageModal: "",
      recordReject: test,
      textOrBox: false
    });
    this.props.form.setFieldsValue({
      ["Reason"]: "",
    });
  };
  handleSubmitReject = async e => {
    e.preventDefault();
    let flag = true;
    this.props.form.validateFields(async (err, values) => {
      console.log("[ERROR] VALIDATE", err)
      if (!err) {
        console.log("values in handleSubmitReject", values, values.Reason);
        let reason = values.Reason;
        /*    console.log("reason", reason); */
        console.log("[INFO] [!this.state.textOrBox]", this.state.textOrBox)
        if (!this.state.textOrBox) {
          let filter = this.state.reasons.filter(d => d.code === values.Reason)
          reason = filter[0];
        }
        else {
          reason = {
            code: values.Reason,
            name: values.Reason
          };
          //se agrega la nueva  reason a la coleccion
          flag = await this.addReason(reason);
          console.log("[INFO] [FLAG]", flag)

        }
        if (flag) {
          console.log("flag addReason", flag);
          await this.confirmReject(this.state.recordReject, reason)
          /*  setTimeout(async () => {
             await this.updateQuery();
           }, 1000); */


          /*         let promesaValidate = new Promise(async (resolve, reject) => {
                    try {
                      await this.confirmReject(this.state.recordReject,reason);
                      resolve();
                    } catch (error) {
                      reject(error);
                    }
                  });

                  promesaValidate
                    .then(() => {
                      this.updateQuery();
                       alert("Rechazado");
                    })
                    .catch(error => {
                      this.setState(
                        {messageModal:"Error al rechazar" ,
                         messageModalType:"error"}),
                      console.log("Error al rechazar" + error)} );
         */
        }
        else {
          this.setState({ messageModal: "Reason already exists, please enter a new one" })
        }
      }
    });
  }
  confirmReject = async (record, reason) => {
    // message.success("Click on Yes");

    // LLAMAR API
    let datosApi = {
      id: record.cashoutId,
      message: reason,
      code: 12,
      status: "Failed",
      quantity: record.quantity,
      merchantRequestId: record.commerceReqId,
      merchantId: record.commerceId,
      email: record.email,
      name: record.name,
      fecha: moment.tz("America/Santiago").toDate(),
      user: this.props.dataUser.idUser
    };

    /*  let promesaValidate = await Api.cashout.validate(datosApi); */



    let promesaValidate = new Promise(async (resolve, reject) => {
      try {
        await Api.cashout.validate(datosApi);
        resolve();
      } catch (error) {
        reject(error);
      }
    });

    promesaValidate
      .then(() => {
        /*  this.updateQuery(); */

        try {
          let fileListValidated = JSON.parse(localStorage.getItem("fileBch"));

          let filtro = fileListValidated.find(row => String(record.rut).replace(/[\. ,:-]+/g, "").trim() === row[3].toString().replace(/[\. ,:-]+/g, "") && record.quantity.toString() === row[8].toString().replace(/[\. ,.$]+/g, "") && row[9].toString() === "Rechazada");//&& reg.numAccount === row[7]);//&&reg.name.trim()===row[6].toString().trim());
          console.log("filtro", filtro);

          if (typeof filtro !== "undefined") {

            fileListValidated.splice(fileListValidated.findIndex(e => e === filtro), 1);
            localStorage.setItem("fileBch", JSON.stringify(fileListValidated));
          }
        } catch (error) {
          console.log("Error al eliminar registro de  fileBch:", error);
        }




        try {
          let fileListValidated = JSON.parse(localStorage.getItem("regValSanta"));

          let filtro = fileListValidated.find(row =>
            String(record.rut).replace(/[\. ,:-]+/g, "").trim() === row[3].toString().replace(/[\. ,:-]+/g, "") &&
            record.quantity.toString() ===
            row[5].toString().replace(/[\. ,.$]+/g, "") && row[6].toString() === "Rechazada");//&& reg.numAccount === row[7]);//&&reg.name.trim()===row[6].toString().trim());
          console.log("filtro", filtro);

          if (typeof filtro !== "undefined") {

            fileListValidated.splice(fileListValidated.findIndex(e => e === filtro), 1);
            localStorage.setItem("regValSanta", JSON.stringify(fileListValidated));
          }
        } catch (error) {
          console.log("Error al eliminar registro de  regValSanta:", error);
        }
        this.upadateReason(record.cashoutId, reason);
        this.componentDidUpdate(this.state);
        /*  alert("Autorizado"); */
      })
      .catch(error => alert("Error al autorizar" + error));

  };
  addReason = async reasons => {
    let resp = false;
    let resFirestore;

    await db.collection("parametrosGenerales")
      .doc("cashout")
      .collection("reasonReject")
      .where("code", "==", reasons.code)
      .get()
      .then(async snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          resp = true

        }
        else {
          resp = false
        }
        /*  return resp; */
      })
      .catch(error => console.log("error en Existencia", error));

    if (resp) {
      /*  console.log("addReason", reasons,resFirestore) */
      await db.collection("parametrosGenerales")
        .doc("cashout")
        .collection("reasonReject")
        .add({
          code: reasons.code,
          name: reasons.name,
        })
        .then(function () {
          resp = true;
        }
        )
        .catch(function () {
          resp = false;
        }
        );
    }


    return resp;
  }
  upadateReason = (id, reason) => {

    console.log("id", id, "Reason", reason);

    new Promise(async (resolve, reject) => {
      queryReason = db
        .collection("cashouts")
      await queryReason
        .doc(id)
        .update({
          reason: reason,
        })
        .then(function () {

          resolve(true)

          console.log("Document successfully updated!");

        })
        .catch(err => {
          reject(false)
          console.log("ERROR IN saveLocalStore", err);
        });
    })

  };
  upadateBankValidate = (id, bank) => {

    console.log("id", id, "bank", bank);

    new Promise(async (resolve, reject) => {
      queryReason = db
        .collection("cashouts")
      await queryReason
        .doc(id)
        .update({
          bankValidate: bank,
        })
        .then(function () {
          resolve(true)
          console.log("Document successfully updated!");

        })
        .catch(err => {
          reject(false)
          console.log("ERROR IN upadateBankValidate", err);
        });
    })

  };
  onChange = e => {
    console.log("onChange", e.target.checked);
    this.setState({ textOrBox: e.target.checked });
    if (e.target.checked) {
      /*   this.props.form.resetFields(
          ["operationCode"]
        ); */
      /*  this.props.form.setFieldsValue({
         ["codigo"]: '0',
       }); */
    }
  };
  /*incorporacion de montos altos y bajos  */
  onchangeTypePage = (e) => {
    console.log("onchangeTypePage value", e.target.value)
    this.setState({
      typePage: e.target.value
    });
    this.updateQuery();
  };


  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const { reasons, recordReject, visibleModalReject, messageModal } = this.state;

    // let { visible, confirmLoading, ModalText, sortedInfo } = this.state;
    let { visible, confirmLoading, ModalText } = this.state;
    // sortedInfo = sortedInfo || {};
    this.columns = [
      {
        title: "Payroll",
        dataIndex: "inProcess",
        /*    key: "dateRequest", */
        key: "inProcess",
        width: 60,
        render: record => (
          <Tag color={record ? 'green' : ''} style={{margin: 0}}>
            {record ? 'In Process' : '--'}
          </Tag>
        )
      },
      {
        title: "Date",
        dataIndex: "dateRequest",
        /*    key: "dateRequest", */
        key: "1",
        width: 200,
        render: date =>
          date.toDate().toLocaleString("es-CL", {
            timeZone: this.state.timeZone,
            timeZoneName: "short"
          })
      },
      {
        title: "Name",
        dataIndex: "name",
        /*    key: "name", */
        key: "2",
        width: 150,
        render: text => entities.decode(text),
        sorter: (a, b) => a.name - b.name,
        sortOrder:
          this.state.sortedInfo.columnKey === "name" &&
          this.state.sortedInfo.order
      },
      {
        title: "Amount",
        dataIndex: "quantity",
        /*  key: "quantity", */
        key: "3",
        width: 100,
        render: text => (
          <NumberFormat
            value={text}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        )
      },
      {
        title: `ID ${this.props.merchant.name}`,
        dataIndex: "commerceReqId",
        /* key: "commerceReqId", */
        key: "4",
        width: 100
      },
      {
        title: "Zippy ID",
        dataIndex: "cashoutId",
        key: "cashoutId",
        /*    key: "5", */
        width: 100,
        align: 'center',
      },


    ];

    this.columnsActions = [
      {
        title: (
          <Tooltip title="Pre Validation">
            <span>pre Val</span>{" "}
          </Tooltip>
        ),
        dataIndex: "preVal",
        key: "preVal",
        width: 10,
        render: text => (
          <span>
            {text === "Aceptada" ? (
              <div style={{ color: "#87d068" }}>{"Aceptada"}</div>
            ) : text === "INVALIDO" ? (
              <div style={{ color: "#ff0000" }}>
                <Tooltip title="INVALIDO!">
                  {" "}
                  <img
                    style={{ width: "100px", height: "100px" }}
                    alt=""
                    src={require("assets/images/not-valid.jpg")}
                  />
                </Tooltip>
              </div>
            ) : (
              <div>
                <Tooltip title="No encontrada!">
                  {" "}
                  <Badge color="grey" />
                </Tooltip>
              </div>
            )}
          </span>
        )
      },
      {
        title: (
          <Tooltip title="Validation Banco de Chile">
            <span style={{ color: "blue" }}>valBCH</span>{" "}
          </Tooltip>
        ),
        dataIndex: "valBCH",
        key: "valBCH",
        width: 10,
        render: text => (
          <span>
            {text === "Aceptada" ? (
              <div style={{ color: "#87d068" }}>
                {" "}
                <Tooltip title="Aceptada!">
                  {" "}
                  <img
                    style={{ width: "30px", height: "30px" }}
                    alt=""
                    src={require("assets/images/chile.jpg")}
                  />
                </Tooltip>
              </div>
            ) : text === "Rechazada" ? (
              <div style={{ color: "#ff0000" }}>
                <Tooltip title="Rechazada!">
                  {" "}
                  <img
                    style={{ width: "30px", height: "30px" }}
                    alt=""
                    src={require("assets/images/rechazoRed.png")}
                  />
                </Tooltip>
              </div>
            ) : (
              <div>
                <Tooltip title="No encontrada!">
                  {" "}
                  <Badge color="grey" />
                </Tooltip>
              </div>
            )}
          </span>
        )
      },
      {
        title: (
          <Tooltip title="Validation Banco santander">
            <span style={{ color: "red" }}>valSAN</span>{" "}
          </Tooltip>
        ),
        dataIndex: "valSAN",
        key: "valSAN",
        width: 10,
        render: text => (
          <span>
            {text === "Aceptada" ? (
              <div style={{ color: "#87d068" }}>
                {" "}
                <Tooltip title="Aceptada!">
                  {" "}
                  <img
                    style={{ width: "20px", height: "20px" }}
                    alt=""
                    src={require("assets/images/santa.jpg")}
                  />
                </Tooltip>{" "}
              </div>
            ) : text === "Rechazada" ? (
              <div style={{ color: "#ff0000" }}>
                <Tooltip title="Rechazada!">
                  {" "}
                  <img
                    style={{ width: "30px", height: "30px" }}
                    alt=""
                    src={require("assets/images/rechazoBlack.png")}
                  />
                </Tooltip>
              </div>
            ) : (
              <div>
                {" "}
                <Tooltip title="No encontrada!">
                  {" "}
                  <Badge color="grey" />
                </Tooltip>
              </div>
            )}
          </span>
        )
      },
      {
        title: () => <Tooltip title="Black List">🏴‍</Tooltip>,
        dataIndex: "alertBlacklist",
        key: "alertBlacklist",
        /*   key: "7", */
        width: 10,
        render: text => (
          <span>
            {text === 0 ? (
              <Badge color="#87d068" />
            ) : (
              <Tooltip title="black list alert!!!!">
                {" "}
                <img
                  style={{ width: "30px", height: "30px" }}
                  alt=""
                  src={require("assets/images/alerta.gif")}
                />
              </Tooltip>
            )}
          </span>
        ),
        sorter: (a, b) => a.alertBlacklist - b.alertBlacklist,
        sortOrder:
          this.state.sortedInfo.columnKey === "alertBlacklist" &&
          this.state.sortedInfo.order
      },
      /*   {
          title: "",
          dataIndex: "alertBlacklist",
          key: "alertBlacklist",
          width: 10,
          render: (text,record) => (
            <span className="gx-link" onClick={() => this.onCopyClipBoard(record)}><i  class="icon icon-copy"/></span>
          )

        }, */
    ];

    

    if (this.props.isActiveOldCashout) {
      const reject = {
        title: "Reject",
        /* key: "action", */
        key: "8",
        width: 100,
        align: 'center',
        render: (text, record) => (
          <span>
            <div onClick={() => this.showModalReject(record)}>
              <Tooltip title="Reject this CashOut!">
                <Button type="danger" disabled={record.inProcess}>
                  Reject</Button>
              </Tooltip>
            </div>
          </span>
        )
      }

      const authorize = {
        title: "Authorize",
        /* key: "action", */
        key: "8",
        width: 150,
        align: 'center',
        render: (text, record) => (
          <span>
            <Popconfirm
              title="Are you sure to authorize this CashOut by Bank Santander?"
              onConfirm={() => this.confirmAuthorize(record, "Banco Santander")}
              onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
              disabled={record.inProcess}
            >
              {/*  <Button type="danger"  className="gx-link">Authorize</Button> */}
              <Tooltip title="Authorized BY Bank Santander!">
                <Button type="dashed" disabled={record.inProcess}>
                  <img
                    style={{ width: "20px", height: "20px" }}
                    alt=""
                    src={require("assets/images/santa.jpg")}
                  />
                  Authorize</Button>
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              title="Are you sure to authorize this CashOut by Bank of Chile?"
              onConfirm={() => this.confirmAuthorize(record, "Banco de Chile")}
              onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
              disabled={record.inProcess}
            >
              <Tooltip title="Authorize BY Banco de Chile!">
                <Button type="dashed" disabled={record.inProcess}>
                  <img
                    style={{ width: "30px", height: "30px" }}
                    alt=""
                    src={require("assets/images/chile.jpg")}
                  />
                  Authorize</Button>
              </Tooltip>
            </Popconfirm>
          </span>
        )
      }

      this.columnsActions.push(authorize, reject);
    }

    /*  this.columnsActionsSoporte = [
       {
         title: () => <Tooltip title="Document awaiting validation">📄</Tooltip>,// ,//🔔
         dataIndex: "Document",
         key: "Document",
         width: 50,
         render: (text, record) => (
           <span>
             <Checkbox onChange={this.onChange} id={record} checked={record.documentation} ></Checkbox>
           </span>
         )
       },
     ]; */
    this.expandedRowRender = record => (
      <div>
        <p>
          RUT: <b>{formatRut(record.rut)}</b>
        </p>
        <p>
          Bank: <b>{record.bankName}</b>
        </p>
        <p>
          Account Type: <b>{record.typeAccount}</b>
        </p>
        <p>
          Account Number: <b>{record.numAccount}</b>
        </p>
        <p>
          Email: <b>{record.email}</b>
        </p>
      </div>
    );
    return (
      <Row>
        <Col span={24}>
          {/*    <Button type="dashed" style={{  color: "black" }}onClick={this.preValidation} >All Amounts</Button>    
            <Button type="dashed" style={{ backgroundColor: "blue", color: "white" }}onClick={this.preValidation} >Low Amounts</Button>
            <Button type="dashed" style={{ backgroundColor: "blue", color: "white" }}onClick={this.preValidation} >High Amounts</Button>  */}
        <Radio.Group value={this.state.typePage} onChange={this.onchangeTypePage}>
          <Radio.Button value="All">All amounts</Radio.Button>
          <Radio.Button value="Low">Low amounts</Radio.Button>
          <Radio.Button value="High">High amounts</Radio.Button>
        </Radio.Group>
          {/* <Button type={this.state.typePage === "All" ? "primary" : ""} className="gx-mb-0" onClick={e => this.onchangeTypePage("All")}>All Amounts</Button>
          <Button type={this.state.typePage === "Low" ? "primary" : ""} className="gx-mb-0" onClick={e => this.onchangeTypePage("Low")}>Low Amounts</Button>
          <Button type={this.state.typePage === "High" ? "primary" : ""} className="gx-mb-0" onClick={e => this.onchangeTypePage("High")}>High Amounts</Button> */}

          <Dynamic
            key="dynamic-cashout-pending-all"
            dataList={this.state.dataList}
            columns={
              this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporteCashout" || this.props.dataUser.typeUser === "soporteAdmin"
                ? (this.columns = this.columns.concat(this.columnsActions))
                // : this.props.dataUser.typeUser === "soporte"
                //    ? (this.columns = this.columns.concat(this.columnsActionsSoporte))
                : this.columns
            }
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            filename={this.state.filename}
            title={this.state.title}
            merchant={this.props.merchant}
            typeUser={this.props.dataUser.typeUser}
            merchants={this.props.dataUser.merchants}
            upadateComponet={this.state.upadateComponet}
            banks={this.state.banks}
            isActiveOldCashout={this.props.isActiveOldCashout}
          />
        </Col>
        <Modal
          title="Recarga - Transferencia Electrónica"
          visible={visible}
          closable={false}
          footer={[
            <Button
              key="submit"
              type="primary"
              loading={confirmLoading}
              onClick={this.onPressOK}
            >
              Ok
            </Button>
          ]}
        >
          <p>{ModalText}</p>
        </Modal>
        <Modal
          title={`⚠️Are you sure delete this CashOut?⚠️`}
          // icon: <i class="icon icon-exclamation"></i>
          visible={this.state.visibleModalReject}
          onCancel={this.hideModalReject}
          bodyStyle={{ backgroundColor: "#ffccc7" }}
          onOk={this.handleSubmitReject}
          footer={[
            <Button key="back" onClick={this.hideModalReject}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmitReject}>
              Reject
            </Button>,
          ]}
        >
          <p> Zippy Id :<b>{this.state.recordReject.cashoutId}</b> </p>
          <p> Amount :<b>   <NumberFormat
            value={this.state.recordReject.quantity}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          /></b> </p>
          <p> Name :<b>{this.state.recordReject.name}</b> </p>
          <Form
            className=""
            onSubmit={this.handleSubmitReject}
          >

            <hr></hr>
            <Checkbox checked={this.state.textOrBox} onChange={this.onChange}>
              new Reason
            </Checkbox>
            <FormItem label="Reason:">
              <div className="" style={{ width: "300px" }}>



                {!this.state.textOrBox ?
                  getFieldDecorator("Reason", {
                    validateTrigger: ["onChange", "onBlur"],
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Pleace, Enter the Reason!!"
                      }
                    ]
                  })(

                    <Select
                      style={{ width: "300px" }}
                      onChange={this.handleChange}
                    >
                      <Option value="">Select the Reason</Option>
                      {reasons.map(d => (
                        <Option key={d.code}>{d.name}</Option>
                      ))}
                    </Select>
                  )

                  :
                  getFieldDecorator("Reason", {

                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Pleace, Enter the Reason!!"
                      }
                    ]
                  })(

                    <Input placeholder="Pleace, Enter the Reason" />
                  )
                }
              </div>
            </FormItem>


            {/*  <div style={{ display: "flex", padding: "5px" }}> */}

            <br></br>
            {this.state.messageModal ? <Alert
              showIcon
              description={`${this.state.messageModal} `}
              type="error"
            /> : null}
            {/*   </div>
 */}
          </Form>

        </Modal>

      </Row>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { merchant, dataUser } = settings;
  return { merchant, dataUser };
};
const cashoutPendingForm = Form.create()(cashoutPendingAll);
export default connect(mapStateToProps)(cashoutPendingForm);
