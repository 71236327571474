import React, { Component } from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebase";
import Dynamic from "./Dynamic";
import moment from "moment-timezone";

const db = firebase.firestore();
let query;
let dataList = [];

export class pctRechazadas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: "Pct Failed",
      filename: `Pct Failed ${this.props.merchant.name}`
    };
  }

  componentDidMount = () => {
    moment.tz.setDefault(this.state.timeZone);

    let firstDay = moment
      .tz(this.state.timeZone)
      .startOf("month")
      .toDate();
    let lastDay = moment
      .tz(this.state.timeZone)
      .endOf("month")
      .toDate();

    this.getData(firstDay, lastDay);
  };
  componentDidUpdate(prevProps) {
    if (this.props.merchant !== prevProps.merchant) {
      console.log("componentDidUpdate", "=>", "componentDidUpdate");

      const merchant = localStorage.getItem("merchant_code");
      moment.tz.setDefault(this.state.timeZone);

      let firstDay = moment
        .tz(this.state.timeZone)
        .startOf("month")
        .toDate();
      let lastDay = moment
        .tz(this.state.timeZone)
        .endOf("month")
        .toDate();
      query = db
        .collection("mxBbvaPcts")
        .where("code", "==", 12)
        .where("merchantId", "==", merchant)
        .where("dateRequest", ">=", new Date(Number(firstDay)))
        .where("dateRequest", "<=", new Date(Number(lastDay)));

      this.updateQuery();

      this.setState({
        filename: `Pct Failed ${localStorage.getItem("merchant_name")}`
      });
    }
  }

  getData = (firstDay, lastDay) => {
    query = db
      .collection("mxBbvaPcts")
      .where("code", "==", 12)
      .where("merchantId", "==", this.props.merchant.code)
      .where("dateRequest", ">=", new Date(Number(firstDay)))
      .where("dateRequest", "<=", new Date(Number(lastDay)));
    this.updateQuery();
    console.log(dataList);
  };

  updateQuery = () => {
    dataList = [];
    query
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          this.setState({
            dataList
          });
          return;
        }

        snapshot.forEach(doc => {
          let PctId = doc.id;

          dataList.push({
            ...doc.data(),
            PctId
          });
        });

        this.setState({
          dataList
        });
        console.log("DATALIST", "=>", dataList);
      })
      .catch(console.log);
  };

  onTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  render() {
    this.expandedRowRender = record => (
      <div>
        <p>
          Card Number: <b>{record.card_number}</b>
        </p>
        <p>
          Email: <b>{record.email}</b>
        </p>
      </div>
    );

    return (
      <Row>
        <Col span={24}>
          <Dynamic
            filename={this.state.filename}
            title={this.state.title}
            dataList={this.state.dataList}
            // columns={this.columns}
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            merchant={this.props.merchant}
            // onTableChange={this.onTableChange}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant } = settings;
  return { authUser, merchant };
};
export default connect(mapStateToProps)(pctRechazadas);
