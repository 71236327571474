import React, { Component } from "react";
import { Col, Row } from "antd";
import { db } from "../../../firebase/firebase";
import Dynamic from "./Dynamic";
import NumberFormat from "react-number-format";
import AddInvoice from "./add";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
// import Api from "../../../util/api";
// import moment from "moment";

let query;
let dataList = [];

export class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = { addState: false, listCommerces: {} };

    this.columns = [
      {
        title: "Nº",
        dataIndex: "number",
        key: "number",
        width: 50
        //render: text => <span className="gx-link">{text}</span>
      },
      {
        title: "Date",
        dataIndex: "formatDate",
        key: "formatDate",
        width: 150
      },
      {
        title: "RUT",
        dataIndex: "rut",
        key: "rut",
        width: 200,
        render: text => formatRut(text)
      },
      {
        title: "ID",
        dataIndex: "invoiceId",
        key: "invoiceId",
        width: 200
      },
      {
        title: "Neto",
        dataIndex: "neto",
        key: "neto",
        width: 100,
        render: text => (
          <NumberFormat
            value={text}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        )
      },
      {
        title: "IVA",
        dataIndex: "iva",
        key: "iva",
        with: 100,
        render: text => (
          <NumberFormat
            value={text}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        )
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        width: 100,
        render: (text, record) => (
          <NumberFormat
            value={record.neto + record.iva}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        )
      }
    ];

    this.expandedRowRender = record => (
      <div>
        <p>
          Razón Social: <b>{record.razon_social}</b>
        </p>
        <p>
          Código Comercio: <b>{record.comercio}</b>
        </p>
        <p>
          Observación: <b>{record.observacion}</b>
        </p>
      </div>
    );
  }

  componentDidMount = () => {
    let fecha = new Date();
    let firstDay = new Date(2019, 0, 1).getTime();
    let lastDay = new Date(
      fecha.getFullYear(),
      fecha.getMonth(),
      fecha.getDate(),
      23,
      59,
      59
    ).getTime();

    this.setState({
      title: "Invoices",
      filename: "Invoices"
    });

    db.collection("commerces").onSnapshot(snapshot => {
      let docs = snapshot.docs.map(doc => doc.id);
      this.setState({ listCommerces: docs });
    });

    this.getData(firstDay, lastDay);
  };

  getData = (firstDay, lastDay) => {
    query = db
      .collectionGroup("invoices")
      .where("date", ">=", new Date(Number(firstDay)))
      .where("date", "<=", new Date(Number(lastDay)));
    this.updateQuery();
  };

  updateQuery = () => {
    dataList = [];
    query
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          this.setState({
            dataList
          });
          return;
        }

        snapshot.forEach(doc => {
          let comercio = doc.ref.parent.parent.id;
          let invoiceId = doc.id;
          let formatDate = doc
            .data()
            .date.toDate()
            .toLocaleDateString("en-GB");

          dataList.push({
            ...doc.data(),
            invoiceId,
            comercio,
            formatDate
          });
        });

        this.setState({
          dataList
        });
        console.log("DATALIST", "=>", dataList);
      })
      .catch(console.log);
  };

  onClickNewButton = () => {
    this.setState({ addState: true });
  };

  onAddClose = () => {
    this.setState({
      addState: false
    });
  };

  onAddSave = (a, b) => {
    db.collection("commerces");
    this.setState({
      addState: false
    });
  };

  render() {
    return (
      <Row>
        <Col span={24}>
          <Dynamic
            dataList={this.state.dataList}
            columns={this.columns}
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            filename={this.state.title}
            title={this.state.title}
            onClickNewButton={this.onClickNewButton}
          />
        </Col>

        <AddInvoice
          open={this.state.addState}
          onAddSave={this.onAddSave}
          onAddClose={this.onAddClose}
          listCommerces={this.state.listCommerces}
        />
      </Row>
    );
  }
}

export default Billing;
