import React, { useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Widget from "../../Widget";
// import moment from "moment";
import { Row, Col, Button, Alert } from "antd";

import FormMerchant from "./form-merchant";

const filterAgregatorType = {
  cashin: [],
  cashout: [],
  payku: [],
  kushki: ["webpay", "bankTransfer"],
  monnet: ["bankCard", "bankTransfer", "cash", "payout"],
  alps: ["bankCard", "bankTransfer", "cash", "payout"],
  saftypay: ["bankCard", "bankTransfer", "cash", "payout"],
  skinback: [],
  airtm: ["bankTransfer", "payout"]
};

const EffectivenessFilter = ({
  onSubmitForm,
  loading,
  errorsMerchant_1,
  errorsMerchant_2
}) => {
  const shemaValidation = Yup.object().shape({
    merchant_1: Yup.object().shape({
      commerceId: Yup.string().required("Merchant is required."),
      rangeDate: Yup.array()
        .required("Date is required.")
        .min(2, "Must be a correct date range"),
      aggregator: Yup.string().required("Aggregator is required."),
      aggregatorType: Yup.string().when("aggregator", {
        is: value =>
          value != undefined
            ? filterAgregatorType[value].length > 0
              ? true
              : false
            : true,
        then: rule => rule.required("Aggregator type is required.")
      }),
      country: Yup.string()
        .nullable()
        .notRequired()
    }),
    merchant_2: Yup.object().shape({
      commerceId: Yup.string().required("Merchant is required."),
      rangeDate: Yup.array()
        .required("Date is required.")
        .min(2, "Must be a correct date range"),
      aggregator: Yup.string().required("Aggregator is required."),
      aggregatorType: Yup.string().when("aggregator", {
        is: value =>
          value != undefined
            ? filterAgregatorType[value].length > 0
              ? true
              : false
            : true,
        then: rule => rule.required("Aggregator type is required.")
      }),
      country: Yup.string()
        .nullable()
        .notRequired()
    })
  });

  const formik = useFormik({
    initialValues: {
      merchant_1: {
        commerceId: "",
        rangeDate: [],
        country: "",
        aggregator: "",
        aggregatorType: ""
      },
      merchant_2: {
        commerceId: "",
        rangeDate: [],
        country: "",
        aggregator: "",
        aggregatorType: ""
      }
    },
    validationSchema: shemaValidation,
    onSubmit: values => {
      onSubmitForm(values);
    }
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={24} sm={12}>
            <Widget>
              <h3>Merchant 1</h3>
              <FormMerchant
                keyObject="merchant_1"
                filterAgregatorType={filterAgregatorType}
                formik={formik}
              />
              {errorsMerchant_1 ? (
                <>
                  <br />
                  <Alert message={errorsMerchant_1} type="error" />
                </>
              ) : null}
            </Widget>
          </Col>
          <Col xs={24} sm={12}>
            <Widget>
              <h3>Merchant 2</h3>
              <FormMerchant
                keyObject="merchant_2"
                filterAgregatorType={filterAgregatorType}
                formik={formik}
              />
              {errorsMerchant_2 ? (
                <>
                  <br />
                  <Alert message={errorsMerchant_2} type="error" />
                </>
              ) : null}
            </Widget>
          </Col>
          <Col xs={24}></Col>
        </Row>
        <div className="section-button">
          <Button
            onClick={formik.handleSubmit}
            type="primary"
            size="large"
            icon="filter"
            loading={loading}
          >
            Filtrar
          </Button>
        </div>
      </form>

      <style jsx="true">{`
        .section-button {
          display: flex;
          justify-content: flex-end;
        }
      `}</style>
    </>
  );
};

export default EffectivenessFilter;
